
.Input {
  width: 100%;
}
.CheckboxButton {
  [data-contentButton="true"] {
    width: 146px;
  }
}

.buttonCheckBox {
  width: 11.2308rem;
  height: 7.6154rem;
}
