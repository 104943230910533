.SimpleButton {
    width:146px;
    height:52px;
    display:inline-block;
    background:#2C74FF;
    color:#FFF;
    font-size:13px;
    text-align: center;
    border-radius:5px;
    margin: 20px 51px 0 0;
    vertical-align:top;
    cursor:pointer;
    position:relative;
    outline: none;
   
   
    img{
        vertical-align: middle;
        display: inline-block;
        margin-right:5px;
    }
    span{
        vertical-align: middle;
        display: inline-block;
    }
}