@import 'src/styles/variables/colors.scss';
.listDescriptionLabel{
  grid-template-columns:8.4615rem 1fr !important;
}
.list{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;
}
.divider {
  margin-top: 16px;
}

.buttonCheckBox{
  min-width: 10rem;
  min-height:9rem;
}

.radio{
  flex-basis: auto;
}
.radioContainer{
  gap:1rem;
}
.text{
  width:25%;
  input{
      width:25%;
  }
}

.gapItems {
  gap: 0;
}


.radioInput{
  label {
    color: black;
  }
  div {
    width: unset;
    margin-right: 10px;
  }
}

.textInputContainer {
  display: flex;
  label {
    width: 20%;
  }
  div {
    width: 100%;
  }
}
