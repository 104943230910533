@import "../../../../../../../styles/variables/index.scss";

.containerDirectorTab {
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
}

.containerDirector {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    margin-right: 1.25rem;
    cursor: pointer;
    p {
        color: $clara-blue;
        width: 7rem;
        margin-top: 0.5rem;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
