@import "./../../../../../styles/variables/colors.scss";

.ExpandContent {
  width: 100%;
  height: auto;
  position: relative;
}

.ButtonArrow {
  position: absolute;
  right: -0.4rem;
  top: 0.3rem;
  cursor: pointer;
  svg {
    color: $clara-light-grey;
  }
}

.ButtonArrowAlternative {
  position: absolute;
  right: -14px;
  top: -8px;
  cursor: pointer;
  svg {
    color: $clara-blue;
  }
}

.headerContainer {
  padding-right: 1.1538rem;
}
