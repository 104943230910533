@import "./../../../../styles/variables/colors.scss";

.containerDetail {
  // display: grid;
  // grid-template-columns: 9.8462rem 3.3846rem 8.4615rem;
  // column-gap: 0.6923rem;
  display: flex;
  width: 23.7692rem;
  height: 2.688rem;
  align-items: flex-end;
  justify-content: space-between;
  // .itemMonthly {
  //   display: grid;
  //   grid-template-rows: 1.875 1.75rem;
  //   justify-content: start;
  //   row-gap: 0.938rem;
  //   opacity: 0.4;
  //   transition: all 0.5s;
  //   &.select {
  //     opacity: 1;
  //   }
  //   .itemMonthlyText {
  //     text-align: center;
  //     align-items: start;
  //   }
  //   .stringMonthly {
  //     color: $clara-black;
  //     font-size: 0.92308rem;
  //     font-weight: 400;
  //   }
  //   .itemMonthlyPrice {
  //     display: flex;
  //     align-items: flex-end;
  //     justify-content: center;
  //   }
  //   .priceMonthly {
  //     font-size: 1.375rem;
  //     font-weight: 400;
  //     color: $clara-black;
  //     padding: 0.125rem 0rem;
  //   }
  // }
  // .itemAnnually {
  //   opacity: 0.4;
  //   transition: all 0.5s;
    
  //   > *{
  //     display: flex;
  //     justify-self: flex-end;
  //     justify-content: flex-end;
  //   }
  //   &.select {
  //     opacity: 1;
  //   }
  //   display: grid;
  //   grid-template-rows: auto auto auto;
  //   .stringAnnually {
  //     font-size: 0.75rem;
  //     font-weight: 700;
  //     color: $clara-black;
  //   }
  //   .tagOnly {
  //     width: 7.188rem;
  //     height: 1.5rem;
  //     text-align: center;
  //     padding: 0.25rem;
  //     border-radius: 12px;
  //     border: 1px solid $clara-upgrade-color;
  //     color: $clara-black;
  //     font-size: 0.75rem;
  //     font-weight: 600;
  //     background: $clara-white;
  //     justify-content: center;
  //   }
  //   .itemPriceAnnually {
  //     display: flex;
  //     align-items: flex-end;
  //   }
  //   .priceAnnually {
  //     font-size: 1.375rem;
  //     font-weight: 700;
  //     color: $clara-black;
  //     padding: 0.125rem 0rem;
  //   }
  // }
  .itemstringAnnually{
    .stringAnnually {
        font-size: 0.75rem;
        font-weight: 700;
        color: $clara-black;
      }
    }
    .itemPriceAnnually {
      .priceAnnually {
        font-size: 1.375rem;
        font-weight: 700;
        color: $clara-black;
        padding: 0.125rem 0rem;
      }
      .vatData{
        font-size: 1.275rem;
        font-weight: 500;
      }
    }
  }

// .ContinerRowSwitch {
//   width: 100%;
//   display: flex;
//   align-items: flex-end;
//   justify-content: center;
// }
// .SWITHC_root {
//   background: $clara-black !important;
//   padding: 1px !important;
//   border-radius: 12px !important;
//   height: 24px !important;
//   width: 44px !important;
//   -webkit-border-radius: 12px !important;
//   -moz-border-radius: 12px !important;
//   -ms-border-radius: 12px !important;
//   -o-border-radius: 12px !important;
// }
// .SWITHC_checked {
//   transform: translateX(20px) !important;
// }

// .SWITHC_track {
//   background-color: $clara-white !important;
//   border-radius: 11px !important;
//   opacity: 1 !important;
// }
// .SWITHC_thumb {
//   background: $clara-upgrade-color !important;
//   width: 18px !important;
//   height: 18px !important;
//   box-shadow: none;
// }
// .SWITHC_switchBase {
//   padding: 3px !important;
// }
// .ContinerRowSwitchV0 {
//   > div {
//     grid-template-columns: 1fr !important;
//   }
// }
