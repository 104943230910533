@import "../../../../../../styles/variables/index.scss";


.ModalMenu {
    display: block;
    div{
        box-shadow: unset;
    }
}
.Modal {
    div {
        p {
            margin-top: 1.8462rem;
            position: relative;
            margin-left: 1.5385rem;
            right: 0.0769rem;
            bottom: 0;
            top: 0;
            color: $color-label;
        }
        div {
            color: $palette-clara;
            margin-left: 0.6923rem;
            margin-bottom: 1.0769rem;
            top: 0.1692rem !important;
            right: 0 !important;
            height: 2.3077rem !important;
        }
    }

    height: 14.3846rem;
    overflow: hidden;
    min-width: 9.2308rem;
    width: 11.4615rem;
    margin: 0;
    padding: 0;
    border: 1px solid #bec9d7;
    box-sizing: border-box;
    border-radius: 9px;
}
.Link {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    align-items: center;
    color: #a9b0bc;
    padding: 23px 41px 23px 20px;
    display: block !important;
    &:before {
        content: " ";
        cursor: pointer;
        -webkit-mask-size: 16px 16px;
        width: 16px;
        height: 16px;
        margin: 0 15px 0 0;
    }
    span {
        display: inline-block;
    }
}
.LinkSeparator {
    border-top: 1px solid #ebeff2;
}
.Arrow {
    &:after {
        content: " ";
        cursor: pointer;
        -webkit-mask-size: 16px 116px;
        -webkit-mask: url(../../../../../../images/arrow_down.svg) no-repeat center;
        width: 16px;
        height: 16px;
        margin: 10px 10px 0 10px;
    }

    div {
        width: auto;
        height: auto;
        display: inline-block;
    }
}
.Open {
    &:after {
        transform: rotate(0deg);
    }
}
.Close {
    &:after {
        transform: rotate(180deg);
    }
}

.CapTableComponent {
    width: 300px;
    padding: 20px;
    padding-top: 10px;
    color: #6a6c6e;
    &:hover {
        color: blue;
        background-color: #f7f7f7;
    }
}

.ModalStyle {
    margin-top: 12px;
    margin-left: 20px;
    border-radius: 5px;
}

.SelectTitle {
    color: #6a6c6e;
    padding-left: 20px;
    font-size: 14px;
}
.MuiPaper-rounded {
    border-radius: 0px;
}