@import "../../../styles/variables/index.scss";

.TitleGroup {
  background: #fbfcff;
  height: 50px;
  vertical-align: top;
  td {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 100%;
    color: #192a3e;
    vertical-align: top;
  }
}

.Arrow {
  position: absolute;
  color: $clara-dark-grey;
  &:before {
    content: " ";
    cursor: pointer;
    -webkit-mask-size: 16px 116px;
    -webkit-mask: url(../../../images/arrow_down.svg) no-repeat center;
    width: 16px;
    height: 16px;
    margin: 0 10px 0 0;
    position: absolute;
    background: #000000;
    top: 13px;
    left: 0;
  }
  span {
    width: auto;
    height: auto;
    display: inline-block;
    position: relative;
    margin-left: 30px;
    line-height: 40px;
  }
}

.Open {
  &:before {
    transform: rotate(0deg);
  }
}

.Close {
  &:before {
    transform: rotate(180deg);
  }
}

.simpleHeader {
  color: $clara-dark-grey !important;
}
