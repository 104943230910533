@import "./../../../../styles/variables/colors.scss";

.DialogContainer {
  display: grid;
  flex-direction: row;
  width: 100%;
  grid-template-columns: 3.1538rem 1fr;
  .DialogIconContainer {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    .Icon {
      width: 1.8462rem;
      height: 1.8462rem;
      color: $clara-star;   

      
    }
    .academy {
      color: $clara-academy;
    }
    .IconAlert{
      color:$clara-red;
    }
    .IconDisabled {
      color: $clara-dark-grey;
    }
    .success {
      color: $clara-green;
    }
    .pay {
      color: $clara-pay;
    }
    .upgrade {
      color: $clara-upgrade-color;
    }
  }
}

.DialogWithBtn {
  margin-bottom: 0.9231rem;
  margin-right: 2.563rem;
}

.addTopMargin {
  margin-top: 0.9231rem;
}