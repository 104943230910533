@import "./../../../../../src/styles/variables/colors";
.cancelButton {
  width: auto;
  height: 3.0769rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2308rem;
  text-align: center;
  color: $clara-dark-grey;
  display: inline-block;
  background: $bg-secondary;
  border-radius: 0.3077rem;
  border: 0.0769rem solid $clara-light-grey;
  min-width: 9.2308rem;
  &:hover,
  &:focus-visible {
    background: $bg-secondary;
    color: $clara-dark-grey;
    opacity: 1;
  }
}
