@import "../../../../../../../../styles/variables/index.scss";

.TemplateTwoLines {
  display: flex;
  flex-direction: column;
  gap: 0.9231rem;
}

.partiesContent {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  .tagsContent {
    display: flex;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.tag {
  border: 1px solid $clara-light-blue;
  border-radius: 4px;
  padding: 0.9231rem;
  justify-content: center;
  height: auto;
  align-items: center;
  display: flex;
  > * {
    justify-content: center;
    align-items: center;
    font-weight: 400 !important;
    font-size: 0.9231rem !important;
  }
  svg {
    color: $clara-blue;
    cursor: pointer;
  }
}

.tagText {
  font-weight: 400;
  font-size: 1.0769rem;
  color: $color-disabled;
  min-width: 3.8462rem;
  margin-right: 0.4615rem !important;
}