.contentInput {
  width: 100%;
  margin: 1rem 0;
  text-align: right;
  position: relative;
  i {
    transform: translateY(-50%);
    top: 50%;
    left: 10px;
    position: absolute;
  }
}

.input {
  width: 100%;
  border: 1px solid #eaf1fe;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0.9rem 0.6rem 0.9rem 2.8rem;
  &:focus {
    outline: none;
  }
}