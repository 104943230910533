@import "./../../../styles/variables/colors.scss";
.buttonsDetails{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.verifyDetails{
  display: flex;
  padding: 1rem 1rem 0 0;
  label{
    color:$clara-dark-grey;
    width: 296px;
  }

}

.Check {
  border-color: $clara-green;
  background: $clara-green;
  &:before {
    background-color: #fff;
    display: inline-block;
    -webkit-mask-size: 19px 14px;
    width: 16px;
    height: 14px;
    -webkit-mask: url(../../../images/check.svg) no-repeat center;
  }
}
.CheckedRed {
  border-color: #ff606f;
  background: #ff606f;
  &:before {
    background-color: #fff;
    display: inline-block;
    -webkit-mask-size: 19px 14px;
    width: 16px;
    height: 14px;
    -webkit-mask: url(../../../images/check.svg) no-repeat center;
  }
}