@import "../../../../../../../../styles/variables/index.scss";

.containerParties {
  display: inline-flex;
}

.containerDocumentInvoice {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  color: $clara-blue;
  cursor: pointer !important;
}

.circle {
  -webkit-mask: url(../../../../../../../../images/ellipse.svg) no-repeat center !important;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 5px;
  position: relative;
}

.containerStatus {
  display: inline-flex;
  align-items: center;
}

.Missing {
  background-color: #ff606f !important;
}

.Signed {
  background-color: #71e096 !important;
}

.Unsigned {
  background-color: #ffdec0 !important;
}

.Expired {
  background-color: #ff606f !important;
  box-sizing: border-box;
}

.Executed {
  background: #71e096;
}

.Pending {
  background: #fbbd2c;
}

.Draft {
  background: #848f99;
}

.Valid {
  background-color: #71e096 !important;
}

.Uploaded {
  background-color: #71e096 !important;
}

.containerMenuOptionDocument {
  padding: 0.75rem;
}

.containerOption {
  padding: 0.5rem;
  cursor: pointer;
  color: $clara-black;
  font-size: 0.9231rem;
  font-weight: 400;
  &:hover {
    background: $clara-light-blue;
  }
}

.li {
  position: relative !important;
  transform: translateX(-50%);
}

.labelInvoice {
  cursor: pointer !important;
  position: relative;
  top: 0.1538rem;
}