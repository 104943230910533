.DueDiligence {
    height: 100%;
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    
}
.SubHeader{
    background: #FFF;
    width: 100%;
    height:59px;
    border-bottom: 1px solid #ebeff2;
    text-align: left;
    position:relative;
    img{
        margin:0 0 0 20px;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}
.ExportPDF{
    display: inline-block;
    width: 176px;
    height: 100%;
    background: rgba(44,116,255,0.1);
    text-align: center;
    
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #2C74FF;
    padding: 23px 0;
    box-sizing: border-box;
}
.Tabs{
    height: 70px!important;
    border-bottom: unset!important;
}
.TabsContainer{
    width: calc(100% - 170px - 176px - 50px);
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    margin-left: 35px;
    padding:  0;
    div{
        width: auto!important;
        margin: auto;
        margin-left: 0px;
        text-align: center!important;
        a:last-child{
            margin-right: 0;
        }
    }
}
.ClaraScore{
    display: inline-block;
    width: 140px;
    margin-right:30px;
}
        //viene por actualizacion
.TitleContainer {
    margin: 50px 0 0 50px;
}
.Title {
    font-weight: 300;
}