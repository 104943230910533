@import "../../../../../../../styles/variables/index.scss";

.sourceContainer {
  display: flex;
  flex-direction: column;
  .row {
    margin-bottom: 1.7692rem;
    display: flex;
    .titleArea {
      width: 20.6154rem;
    }
  }
}

.title {
  color: $clara-dark-grey;
}

.contain {
  color: $clara-black;
}