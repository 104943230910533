@import "../../../../../styles/variables/index";

$clara-red-upload: #ff7e8b;



.containerFileInput {
  border-width: 1px;
  border-radius: 5px;
  border-style: dashed;
  border-color: $clara-blue;
  padding-left: 1.0769rem;
  padding-right: 1.0769rem;
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  width: 25.9231rem;
  height: 30.61rem;
  
}

.containerNoFilesUploaded{
  border-width: 1px;
  border-radius: 5px;
  border-style: dashed;
  border-color: $clara-blue;
  padding-left: 1.0769rem;
  padding-right: 1.0769rem;
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  width: 25.9231rem;
  height: 30.61rem;
  justify-content: space-around;
}

.notFilesUploaded {
  flex-direction: row !important;
  box-sizing: border-box;
}

.FileUpload {
  width: 29rem !important;
  height: 28.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #eaf1fe;
  position: relative;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.FilesList{
  border:1px solid red;
}

.FilesListContainer{
  display: flex;
  flex-direction: column;
  border: 1px solid greenyellow;
}



.progressContainer {
    background: $clara-light-blue;
    height: 3.0769lem;
}

.container {
    display: flex;
    width: 100%;
    position: relative;
    border-radius: 0.3846rem;
    border: 1px solid $clara-light-blue;
    margin-bottom: 1lem;
}

.loadingContainer {
  width: 100%;
  position: absolute;
  left: 0px;
  padding-top: 0.7692rem;
  padding-bottom: 0.7692rem;
  padding-left: 1.0769rem;
  padding-right: 1.0769rem;
  height: 3.0769rem;
  color: $clara-blue;
  display: inline-flex;
  align-items: center;
  font-size: 0.7692rem;
}
.loading {
  color: $clara-blue !important;
}

.button {
  background-color: $clara-light-blue;
  :hover{
    background-color:  $clara-light-blue;
  }
}

.errorUploadedContainer {
  background: rgba($clara-red-upload, 0.15);
  color: $clara-red;
  position: relative;
}


.fileContainer{
  display: flex;
  flex-direction: row;
  background: $clara-light-blue;
  width: 23.07692rel;
  height: 3.0769rem;
  border-radius: .3077rem;
  align-items: center;
  padding: .7692rem 1.0769rem;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.notFile{
  margin-bottom: 1rem;
  width: 23.077rem;
}

.tick{
  color: $clara-blue;
}


.fileName{
  padding-left: .9231rem;
  // width: 15.5385rem;
  width: 100%;
}

.scrollbars{
  height: 25rem;
}

.addFiles{
  display: flex;
  flex-direction: row;
  .addFilesLink{
    > span {
      font-size: 14px;
      font-weight: 600;
      text-decoration: none;
      color: $clara-blue;
      cursor: pointer;
      &:hover {
        text-decoration: underline !important;
      }
    }
  }
  .addFilesLabels{
    display: flex;
    flex-direction: column;
  
  }
  .addFilesIcon{
    margin-right: .4615rem;
  }
}