.CalendarInput {
  width: 11.25rem;

  div div button {
    position: relative;
    right: 8px;
    &:hover {
      background: transparent;
    }
  }
  div input {
    position: relative;
    right: 10px;
    height: 40px;
    box-sizing: border-box;
  }
}

.disabled {
  background-color: #EAEAEA !important;
  width: 165px;
  div {
    color: #192a3e !important;
  }
}