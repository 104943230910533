@import "../../../../../styles/variables/index.scss";

.containerForm {
    max-height: 41.5rem;
    overflow-y: auto;
    padding-right: 2.8rem;
    padding-top: 3.6rem;
    border-bottom: 1px solid $clara-light-blue;
    padding-left: 2.8rem;
    padding-bottom: 3.8rem;
    min-width: 53.3rem;
}

.containerButtons {
    padding-right: 3rem;
}
.title {
    text-align: left;
    margin: 0;
    width: 100%;
    margin-bottom: 40px;
}
