@import "./../../../styles/variables/colors";

.Card{
  display: grid!important;
  justify-content: flex-start;
  align-items: center;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  padding-top: 3px;

  span{
    margin-right: 1.1538rem;
    color:$clara-black;
    font-size: 1.0769rem;
    width: auto;
  }
  img{
    width: auto;
    bottom: 0!important;
  }
  p{
    grid-column: 1/3;
    color: $clara-grey;
    font-size: 0.7692rem;
    padding: 0.3846rem 0;
  }
}

.afterComponent {
  width: 30px !important;
}
