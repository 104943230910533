@import "../../../../styles/variables/index";
.TransparentButton{
    width: auto;
    height: 3.0769rem;
    
    font-style: normal;
    font-weight: 600;
    
    line-height: 1.2308rem;
    text-align: center;
    color: $palette-clara;
    display: inline-block;
    background: $bg-secondary;
    border-radius: 0.3077rem;
    border: unset;
    min-width: 9.2308rem;
    &:hover{
        color: $color-link;
    }
}