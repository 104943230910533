@import "../../../../../../../../styles/variables/index";

.DetailsTab {
  max-width: 943px;
  overflow: hidden;
}

.labelDocument {
  cursor: pointer !important;
  color: $clara-blue !important;
}

.divider {
  margin-top: 2.0769rem !important;
  margin-bottom: 2.0769rem !important;
  border-color: $border-disabled !important;
}

.sourceOfFundsTitle {
  padding-bottom: 1rem;
}