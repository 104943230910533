@import "../../../../styles/variables/index";
@import "~./../../../../styles/extends/positions.scss";
.FormAdd {
    @extend .flex-top;
    @extend .flex-column;
    align-items: flex-start;
    margin: 0;
    width:100%;
    font-weight:normal;
    h1 {
        font-size: 1.3846rem;
        font-weight:normal;
        margin:0.7692rem 0;
        margin-bottom: 16px;
        text-align: left;
        width:100%;
    }
    h2 {
        color: $color-primary;
        font-weight:normal;
        text-align: left;
        margin:0;
        width:100%;
        margin-bottom: 40px;
        font-size:1rem;
    }
    .FormChildren{
        width:100%;
    }
    p {
        color: $color-label;

    }
}
