.ContainerView {
    width: 978px;
    text-align: left;
}

.containerSharesType {

    width: 100%;
    height: 70px;
    border-bottom: 1px solid;
    border-bottom-color: #EAF1FE;
}

.containerDataShares {
    display: inline-block;
    flex-direction: column;
    width: auto;
    margin: 0 60px 0 0;
    min-width: 80px;

    p {
        margin: 0px;
        text-align: start;
        
        font-style: normal;
        font-weight: normal;
        font-size: 23px;
        overflow: hidden;
        white-space: nowrap;
        position: relative;
    }
}



.shares {
    font-size: 14px;
    text-align: start;
    
    font-style: normal;
    font-weight: normal;
    
    color: #65707E;

    &:after {
        content: " ";
        width: 24px;
        height: 24px;
        position: absolute;
        bottom: -2px;
        margin-left: 5px;
        display: none;
        //display: inline-block; provitional
        background: url(../../../../../../images/clara_academy.png) no-repeat center;
    }

}

.buttonCapTable {
    width: 155px;
    height: 40px;
    align-self: center;
    justify-self: flex-end;
    background: #FFF;
    
    color: #2C74FF;
    border: solid 1px;
    border-radius: 5px;
    border-color: #2C74FF;
    position: absolute;
    right: 0;
    

    span {
        align-self: center;
        padding-left: 10px;

        &:before {
            content: "  ";
            width: 24px;
            height: 20px;
            position: absolute;
            right: 126px;
            bottom: 11px;
            -webkit-mask: url(../../../../../../images/capt_table.svg) no-repeat center;
            background: white;
            -webkit-mask-size: 24px 24px
        }
    }
}


//--------------------
.ContainerSearchAndTitle {
    display: grid;
    width: 100%;
    display: inline-grid;
    position: relative;
    top: 30px;
    grid-template-columns: 62% 15% 23%;

}

.inputSearch {
    background: #EAF1FE;
    
}

.ContainerAdd {
    color: #2c74ff;
    height: 24px;
    align-self: center;
    justify-self: flex-end;
    white-space: nowrap;
    cursor: pointer;

    &:before {

        content: " ";
        background-color: #2c74ff;
        display: inline-block;
        margin-right: 10px;
        -webkit-mask-size: 16px 16px;
        width: 12px;
        height: 12px;
        -webkit-mask: url(../../../../../../images/add_startup.svg) no-repeat center;

    }

}

.titleTable {

    font-size: 20px;
    text-align: start;
    
    font-style: normal;
    font-weight: normal;



}

.containerFilterBox {
    width: 160px;
    height: 30px;
    

}

.Tabs{
       margin-top: 0px;
   
    
}
.letterTabs{
    
    width: 100%;
    text-align: left;
    
    a {
        
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        margin-right:29px;
        color: #A9B0BC;
        cursor: pointer;
        &:hover{
            color:#2C74FF
        }
    }
    
}

//-------------------------