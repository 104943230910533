@import "../../../../../../../styles/variables/index";

.buttonContainer {
  display: flex;
  flex-direction: column;
}

.buttonCheckBox {
  display: flex;
  width: 11.2308rem !important;
  height: 7.6154rem !important;
  align-items: center;
}

.confirmation {
  display: flex;
  padding-bottom: 1.2rem;
}

.stakeholderTitles {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 1.2rem;
  gap: 1.2rem;
}

.stakeholdersRoles {
  border-top: 1px solid $clara-input-outline-grey;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 1.2rem;
  column-gap: 1.8rem;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
}

.stakeholder {
  display: flex;
  flex-direction: column;
}

.form {
  gap: 0 !important;
  grid-gap: 0 !important;
}

.description {
  font-size: 1.2308rem;
  margin-bottom: 1.8rem;
}

.scroll {
  padding: 0 2rem 0 1rem;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  height: 119px;
  overflow-y: auto;
  margin-bottom: 1.5rem;
}

.confirmationText {
  width: 37rem;
}

.confirmationOptions {
  width: 100%;
}

.founderTag {
  width: 65px;
  height: 24px;
  > span {
    margin-left: 0 !important;
  }
  margin-top: 0.6rem;
}

.container {
  display: grid;
  grid-template-columns: 21.1538rem 1fr;
}
.columList {
  font-weight: 400;
  font-size: 14px;
  line-height: normal;

  ul {
    padding-left: 2.3077rem;
    p {
      padding-bottom: 22px;
      padding-top: 22px;
      margin: 0;
    }
    li {
      padding-left: 6px;

      &:first-child {
        p {
          padding-top: 0;
        }
      }
    }
  }
}
.columnUpload {
  margin-left: 2.0769rem;
  width: 29.0769rem;
  height: 28.6923rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
