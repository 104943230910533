@import "../../../styles/variables/index";

.tagContainer {
  border-radius: 20px;
  color: white;
  font-size: 11px;
  padding: 3px 8px;
  justify-content: center;
  display: flex;
  align-content: center;
  align-items: center;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.newTag {
  background: #15c5a8;
}

.replacedTag {
  background: #18c5a8;
}

.engagementTag {
  background: #c7eded;
}

.scopingFormTag {
  background: #f4eed4;
}
.companyTypeTag {
  background: linear-gradient(
    138.29deg,
    rgba(97, 151, 255, 0.2) 14.2%,
    rgba(107, 58, 206, 0.2) 52.51%,
    rgba(246, 67, 136, 0.2) 90.6%
  );
}

.kycTag {
  background: #f4eed4;
}

.declinedDocuments {
  background: #f0aeae;
}

.approvedDocuments {
  background: #8ce4b4;
}

.invitedShareProfileTag {
  background-color: $status-amber;
  // position: static;
  // width: 4.3846rem;
  // height: 1.3077rem;
}

.inactiveShareProfile {
  // position: static;
  // width: 4.3846rem;
  // height: 1.3077rem;
  background: #bec9d7;
  color: white;
}

.dissolvedProfile {
  // position: static;
  // width: 4.3846rem;
  // height: 1.3077rem;
  background: #ff606f;
  color: white;
}

.ownerProfileTag {
  background: #18c5a8;
  color: #192a3e;
  font-size: 0.9231rem;
  line-height: 1.1538rem;
  padding: 0.1538rem 0.7692rem;
}

.collaboratorProfileTag {
  color: #192a3e;
  background: rgb(189, 235, 82);
  font-size: 0.9231rem;
  line-height: 1.1538rem;
  padding: 0.1538rem 0.7692rem;
}

.managerProfileTag {
  background: #6197ff;
  color: #192a3e;
  font-size: 0.9231rem;
  line-height: 1.1538rem;
  padding: 0.1538rem 0.7692rem;
}

.editorProfileTag {
  background: #c7eded;
  color: #192a3e;
  font-size: 0.9231rem;
  line-height: 1.1538rem;
  padding: 0.1538rem 0.7692rem;
}

.viewerProfileTag {
  background: #fca92d;
  color: #192a3e;
  font-size: 0.9231rem;
  line-height: 1.1538rem;
  padding: 0.1538rem 0.7692rem;
}

.inactiveProfileTag {
  background: #BEC9D7;
  color: white;
  font-size: 0.9231rem;
  line-height: 1.1538rem;
  padding: 0.1538rem 0.7692rem;
}

.isFounderTag {
  background: #838d99;
  color: white;
  font-size: 0.9231rem;
  line-height: 1.1538rem;
  padding: 0.1538rem 0.7692rem;
}
.customTag {
  background: #858D98;
  color: white;
  font-size: 0.9231rem;
  line-height: 1.1538rem;
  padding: 0.1538rem 0.7692rem;
  text-align: center;
  border-radius: 5px;

}

.isFounderSquareTag {
  background: #838d99;
  color: white;
  height: 1.8462rem;
  border-radius: 0.3077rem;
  font-size: 0.9231rem;
  line-height: 1.8462rem;
  -webkit-border-radius: 0.3077rem;
  -moz-border-radius: 0.3077rem;
  -ms-border-radius: 0.3077rem;
  -o-border-radius: 0.3077rem;
}
