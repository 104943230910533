@import "../../../../styles/variables/index.scss";
.TemplateLabel {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 16.4615rem 1fr;
    grid-column-gap: 1.5385rem;
    > * {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    > label {
    }
    > div {
    }
    &.top {
        > label {
            align-items: flex-start;
        }
        > div {
            align-items: flex-start;
        }
    }
    &.bottom {
        > label {
            align-items: flex-end;
        }
    }
}

.label-required {
    > label {
        &:after{
            content:"*";
            display: block;
            padding-left: 0.2em;
            color: $clara-red; 
        }
    }
}
