@import "./../../../../styles/variables/index.scss";

.NotPreviewCard {
  padding: 2.1rem 1.1rem 2rem 1.1rem;
  position: relative;
  width: 18.5rem ;
  height: 17.2rem;
  background: transparent; // $bg-white;
  // border: 1px solid $bg-border;
  box-sizing: border-box;
  display: inline-flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  top: 50%;
  left: 33%;
  transform: translateY(-50%);
  svg{
    margin-bottom: 2.0462rem;
  }
  span {
    color: $palette-clara;
    font-size: 96px;
    &::before {
      color: $palette-clara !important;
    }
  }
  h2{
    margin: 0.7692rem auto;
    text-align: center;
  }
  p {
    margin-top: 0;
    margin-bottom: 10px;
    height: auto;
    text-align: center;
    color: grey;
  }

  //button {
  //  &:hover {
  //    background: $palette-clara;
  //    color: #fff;
  //  }
  //}
}

.notPreviewCard {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;

  svg{
    margin-bottom: 2.0462rem;
  }
  span {
    color: $palette-clara;
    font-size: 96px;
    &::before {
      color: $palette-clara !important;
    }
  }
  h2{
    margin: 0.7692rem auto;
    text-align: center;
  }
  p {
    margin-top: 0;
    margin-bottom: 10px;
    height: auto;
    text-align: center;
    color: grey;
  }

  .Button {
    width: max-content;
    height: max-content;
  }

}
