@import "../../../../../../../../styles/variables/index.scss";

.container {
    display: flex;
    flex-direction: column;
}
.buttonCheckbox {
    background: white;
    padding: 0px;
    margin-bottom: 0.8077rem;
    span {
        font-weight: 400;
        font-family: "cl-regular";
        font-size: 12px;
        color: $clara-dark-grey;
    }
}
.buttonToCheck {
    &:before {
        cursor: pointer;
        content: " ";
        background-color: #a9b0bc;
        display: inline-block;
        -webkit-mask-size: 16px 16px;
        width: 16px;
        height: 16px;
        -webkit-mask: url(../../../../../../../../images/unborderedUncheckbox.svg) no-repeat center;
        display: inline-block;
        position: relative;
        top: 4px;
        margin-right: 0.6062rem;
    }
}

.buttonChecked {
    position: relative;
    &:before {
        cursor: default;
        content: " ";
        background-color: #2c74ff;
        display: inline-block;
        -webkit-mask-size: 16px 16px;
        width: 16px;
        height: 16px;
        -webkit-mask: url(../../../../../../../../images/uncheckbox.svg) no-repeat center;
        position: relative;
        top: 4px;
        margin-right: 15px;
        display: inline-block;
    }
    &:after {
        content: " ";
        position: absolute;
        background-color: #fff;
        display: inline-flex;
        -webkit-mask-size: 16px 16px;
        width: 16px;
        height: 16px;
        -webkit-mask: url(../../../../../../../../images/checked.svg) no-repeat center;
        position: absolute;
        margin-right: 15px;
        left: 0px;
        top: 0px;
        z-index: 100;
    }
}

.checkboxResponsibility {
    margin: 0.5rem 0;
    label{
        gap:0.5rem;
        font-size: 12px;
        font-weight: 400;
        font-family: 'cl-regular';
        color: $clara-dark-grey;
    }
}
.rootCheckbox {
    margin: 1rem 0;
    
    div:first-child {
        margin-top: 0rem;
    }

    div:last-child {
        margin-bottom: 0.5rem;
    }
}
