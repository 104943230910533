@import "../../../../../styles/variables/index.scss";

.container {
  position: relative;
  overflow-y: hidden;
  height: 100%;
}

.containerMessages {
  display: grid;
  grid-template-rows: 5.3077rem calc(100% - 5.0377rem);
  grid-template-columns: 32.9231rem minmax(38.4615rem, auto);
  width: 100%;
  height: 100%;
}

.containerTitle {
  grid-column: 1 / 3;
  align-self: end;
  border-bottom: 1px solid $color-border-line;
  padding: 1.5192rem 3rem;
}

.containerZoneMessages {
  width: 100%;
  height: calc(100% - 0.3077rem);
}

.containerPanelMessages {
  border-right: 1px solid $color-border-line;
}