.containerModalOnboarding {
  width: 100%;
  height: 100%;
  .MuiDialog-paper {
    margin-left: 0px !important;
    margin-right: 0px !important;
    box-sizing: border-box !important;
    border-top: none;
    position: relative;
    box-shadow: none;
    background: transparent;
    overflow: hidden;
    padding: 0px !important;
    border-radius: 0px !important;
    max-width: 100% !important;
    margin: 0px !important;
  }
  .MuiDialog-paperWidthMd {
    box-sizing: border-box;
    overflow: visible;
    width: 100%;
    height: 100%;
  }
  .MuiDialog-scrollPaper {
    overflow-y: auto !important;
    padding: 0px !important;
  }
  .MuiDialog-paperScrollPaper {
    max-height: 100% !important;
  }
}

.spacing_48px {
  padding-top: 3.6923rem !important;
}
