@import "../../../../../styles/variables/index";
.ErrorInput {
    width: 100%;
    margin-top: 3px !important;
    text-align: right;
    color: $color-error;
    min-height: 1rem;
    font-family: "cl-regular";
    font-weight: 300;
    font-size: 12px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    span {
        display: flex;
        width: 100%;
        text-align: right!important;
        flex-direction: column;
        align-items: flex-end;
    }
    svg {
        display: flex;
        width: 20%;
    }
}
.ErrorIcon {
    margin-left: 5px;
}
