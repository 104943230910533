@import "../../../../styles/variables/colors.scss";
.IconSecondaryButtonContent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    svg {
        margin-left: 10px;
        margin-right: 26.15px;
        display: flex !important;
        justify-content: flex-start;
        align-items: center;
        color: $clara-grey;
    }
    span {
        justify-content: center;
        align-items: center;

    }
}
.icon-color-primary{
    color: $clara-dark-blue !important;
}