@import "./../../../../styles/variables/colors.scss";
.TimeLine {
  text-align: left;
  font-weight: 700;
  font-family: 'cl-regular';
  min-width: 1000px;
  color: $clara-black;
  .header {
      margin: 0 0 20px 0;
      /* align-content: center; */
      align-items: center;
      display: flex;
    }
    .day {
      width: 100px;
      display: inline-block;
      font-size: 14px;
    }
    .line {
      color: $clara-light-blue;
      width: calc(100% - 100px);
      max-width: 800px;
      display: inline-block;
      border-bottom: 1px solid #f1ecec;
      height: 10px;
    }
  }

  .logs {
    width: 100%;
    display: block;
  }
  .SharingLogs{
    width:85%;
    display: block;
  }
.SharingLog{
  width: 87% !important;
  .action{
    padding: 0 100px 10px 40px !important;
  }
}
.Log {
  width: 100%;

  position: relative;
  text-align: left;
  .day {
    height: 60px;
    width: 100px;
    display: inline-block;
    &:before {
      content: " ";
      background: red;
      height: 100%;
    }
    .circle {
      border: 3px solid $clara-light-blue;
      width: 13px;
      height: 13px;
      display: inline-block;
      position: absolute;
      left: 10px;
      top: 0;
      border-radius: 50%;
    }
    .line {
      background: $clara-light-blue;
      width: 1px;
      height: calc(100% - 10px);
      display: inline-block;
      position: absolute;
      left: 16px;
      top: 10px;
    }
    .lastLine {
      display: none;
    }
    .timeDay {
      color: $color-text;
      position: absolute;
      left: 28px;
      top: 0px;
      font-size: 11px;
    }
  }
  .action {
    font-weight: 400;
    color: $color-text;
    height: 70px;
    background: transparent;
    width: calc(100% - 200px);
    display: inline-block;
 //   padding: 0 100px 10px 100px;
    text-align: left;
  span{
    font-weight: 400;
    color: $clara-blue;
    text-decoration: underline;
  }
    .bullet {
      width: 50px;
      height: 100%;
      display: inline-block;
      i:before {
        color: $clara-blue;

        //font-weight: 600;
        font-size: 2rem;
      }
    }
    .info {
      width: calc(100% - 70px);
      margin: 0 10px;
      height: 100%;
      display: inline-block;

      p {
        text-align: left;
        font-size: 12px;
        font-style: italic;
        font-weight: 400;

        margin: 2px 0;
      }

      span {
        margin: 0 !important;
      }
    }
  }
}
.labelFilter {
  width: 5.7692rem;
  height: 1.1538rem;
  font-family: 'cl-regular';
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #000000;
}
.botonera {
    /* width: 100%; */
    margin: 15px 0;
    justify-content: flex-end;
    display: flex;
}
.buttonFilter {
  justify-content: flex-start;
}
.FilterWith {
  display: flex;
  flex-direction: row;
  //grid-gap:2rem;
  flex-wrap: nowrap;
  label{
    width: 7rem;
    display: flex;

  }
  span,
  input {
    width: 18.923076923rem;
  }
  /*width: 100% !important;
  span,
  input {
    width: 100%;
  }
  a input {
    width: auto !important;
  }*/
}
.FilterWithInput {
  width: 100% !important;
  display: inline-block !important;
  height: 40px;
  & > div {
    width: 100% !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
    display: inline-block !important;
    & > div {
      width: 100% !important;
      margin-left: 0 !important;
      margin-top: 0 !important;
      display: inline-block !important;
      & > div {
        width: 100% !important;
        margin-left: 0 !important;
        display: inline-block !important;
        margin-top: 0 !important;
        & > div {
          width: 100% !important;
          margin-left: 0 !important;
          display: inline-block !important;
          margin-top: 0 !important;
        }
      }
    }
  }
  input {
    width: 100% !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
    display: inline-block !important;
  }
}
.startupLog {
  color: #2c74ff;
}
.container{
  margin-top: 2rem;
  display: flex;
  align-content: space-between;
  flex-direction: column;
  grid-row-gap: 2rem;
  overflow: hidden;
}
.limitSize{
  max-height: 25vh !important;
}
.calendarRoot{
  justify-content: flex-start !important;
  label{
    width: 7rem;
  }
  div{
    width: 14.281538462rem !important;
  }
}