@import "./../../../../styles/variables/colors.scss";

.assistanceViewContainerBlue {
  width: 100%;
  height: 100%;
  background-color: $clara-assistance-viewer-background;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-content: center;
  padding:  0 65px;
  .blueIcon {
    color: $clara-blue;
  }
  .titleFontStyle {
    margin-top: 1rem;
    font-size: 1.2308rem;
    font-weight: 400;
    color: $clara-black;
  }
}

.textTitle{
  text-align: center;
  line-height: 1.3;
  display: block;
}