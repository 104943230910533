@import "./../../../../styles/variables/colors.scss";

.askContainer {
  display: flex;
  margin-right: 2.563rem;
  margin-bottom: 0.7692rem;
  .askText {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    font-family: "cl-regular";
    font-size: 1.0769rem;
    font-weight: 400;
    color: $clara-black;
    margin-right: 0.7692rem;
    margin-top: 0.4615rem;
  }
}