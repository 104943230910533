@import "../../../../styles/variables/index";
@import "~./../../../../styles/extends/positions.scss";
.BotoneraAdd {
    @extend .flex;
    @extend .flex-end;
    @extend .flex-middle;
    >*{
        margin-left: 1.5385rem;
    }
}
