.ContainerView {
    margin: 0;
    padding: 0;
    height: auto;
    width: 978px;
    clear: both;
    position: relative;
    display: block;
    text-align: left;

    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 40px;
    color: #65707e;
    hr {
        height: 0;
        border: 1px solid #eaf1fe;
        box-sizing: border-box;
        border-radius: 4px;
    }
}

.botoneraActions {
    position: relative;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    button {
        background: #ffffff;
        border: 1px solid #2c74ff;
        box-sizing: border-box;
        border-radius: 4px;

        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        align-items: center;
        text-align: center;
        letter-spacing: 0.01em;
        color: #2c74ff;
        padding: 14px 1rem 14px 1rem;
        margin: 20px 20px 0 0;
    }
}

.letterCell {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    word-wrap: break-word;
    width: auto;
    text-align: left;

    &:before {
        content: " ";
        width: 24px;
        height: 24px;
        position: relative;
        top: 0.5em;
        margin-right: 0.1em;
        display: inline-block;
    }
}

.letter {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 13px !important;
    line-height: 32px !important;
    color: #848f99 !important;
    white-space: nowrap;
    table-layout: fixed;
    overflow: hidden;
    text-overflow: ellipsis;

    max-width: 20rem;
    text-align: left;
}
.containerName {
    display: inline-flex;
    align-items: center;
}
.replaceIcon {
    box-sizing: content-box;
    margin-left: 1.5rem;
    position: relative;
    span {
        padding: 0.2rem 0.7em;
        display: inline-flex;
        align-items: center;
        justify-items: center;
        transform: translateY(-50%);
        border-radius: 20px;
        color: #fff;
        background: #18c5a8;
        position: absolute;
        height: 1.5385rem;
    }
}

.containerElementTable {
    width: max-content;
}
.ContainerRow {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
}
.buttonModalOptions {
    transform: rotate(90deg);
    margin-top: 1rem;
}

.circle {
    &:before {
        -webkit-mask: url(../../../../../../images/ellipse.svg) no-repeat center !important;
        margin: 0 0.5rem 0 0rem;
        padding-top: 0;
        background: #fbbd2c;
        height: 1rem;
        line-height: 1rem;
        top: 2px;
        width: 1rem;
    }
}
.containerMenuList {
    padding: 1rem;

    > div {
        margin-bottom: 1rem;
    }

    p {
        padding: 0.2rem;
        text-align: left;
        font-style: normal !important;
        font-weight: 300 !important;
        font-size: 14px !important;
        line-height: 32px !important;
        white-space: nowrap;
        table-layout: fixed;
        overflow: hidden;
        margin: 0;
        &:hover {
            background: #eaf1fe;
        }
    }
    z-index: 10;
}

.avatar {
    label {
        display: inline-flex;
        align-items: center;
        position: relative;
        margin: 0 0 0 1rem;
        &:after {
            content: " ";
            width: 1.5rem;
            height: 1.5rem;
            margin-left: 1rem;
            //  margin-top: 0.1rem;
            position: absolute;
            right: -28px;
            font-size: 16px;
        }
    }
}

.signedSignatory {
    label {
        &:after {
            background: url(../../../../../../images/checkOk.svg) no-repeat center !important;
        }
    }
}
.noSignedSignatory {
    label {
        &:after {
            background: url(../../../../../../images/checkFaild.svg) no-repeat center !important;
        }
    }
}
.containerSignatories {
    display: inline-flex;
    flex-wrap: wrap;
    padding: 1rem 0;
    > div {
        margin-right: 4rem;
        margin-bottom: 1rem;
    }
    > div:nth-child(5n) {
        margin-right: 0;
    }
    > div:nth-child(n + 6) {
        margin-bottom: 0;
    }
}

.containerStatues {
    display: flex;
}

.Declined {
    &:before {
        background-color: #ff606f !important;
    }
}

.Signed {
    &:before {
        background-color: #2c74ff !important;
    }
}

.Pending {
    &:before {
        background-color: #ff794f !important;
    }
}

.Approved {
    &:before {
        background-color: #71e096 !important;
    }
}

.Draft {
    &:before {
        background: #848f99;
    }
}

.Generated {
    &:before {
        background: #EAF1FE;
    }
}

//--------icons
.EditIcon {
    a {
        &:before {
            -webkit-mask: url(../../../../../../images/edit.svg) no-repeat center !important;
        }
    }
}

.DeleteIcon {
    a {
        &:before {
            -webkit-mask: url(../../../../../../images/trash.svg) no-repeat center !important;
        }
    }
}

.DownloadIcon {
    a {
        &:before {
            -webkit-mask: url(../../../../../../images/download.svg) no-repeat center !important;
        }
    }
}

.SignatureIcon {
    a {
        &:before {
            -webkit-mask: url(../../../../../../images/signature_icon.svg) no-repeat center !important;
        }
    }
}

.ReplaceIcon {
    a {
        &:before {
            -webkit-mask: url(../../../../../../images/Group.svg) no-repeat center !important;
        }
    }
}

.ViewICon {
    a {
        &:before {
            -webkit-mask: url(../../../../../../images/view_doc_details.svg) no-repeat center !important;
        }
    }
}

.UploadIcon {
    a {
        &:before {
            -webkit-mask: url(../../../../../../images/UploadToEdit.svg) no-repeat center !important;
        }
    }
}

//--------------

.Col2 {
}
.buttonDisabled {
    a {
        cursor: not-allowed !important;
        &:hover {
            background: #eee !important;
            color: #192a3e;
            &:before {
                background: #848f99;
            }
        }
    }
    cursor: not-allowed !important;
    background: #eee;
    &:hover {
        background: #eee !important;
    }
}
.Popover {
    padding: 1rem;
    box-shadow: none;
    box-sizing: border-box;
    outline: 1px solid #eaf1fe !important;

    outline-offset: -1rem;
    background: white !important;
    display: block;

    &:before {
        content: " ";
        transform: rotate(45deg) translate(calc(50% - 1px));
        width: 1rem;
        top: 0;
        left: 15%;
        height: 1rem;
        border-top: 2px solid #eaf1fe;
        border-left: 2px solid #eaf1fe;
        z-index: 2;
        background: #fff;
        display: block;
        position: absolute;
    }
}