@import "../../../../../../styles/variables/index.scss";

.checkbox {
  display: flex;
  width: calc(100% - 64px);
  height: 100%;
  box-sizing: border-box;
  padding-right: 31px;
  > div {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
    grid-gap: 1.8462rem !important;
  }
}
.rightContentImage{
  width: calc(100% - 64px);
  position: relative;
  height: 100%;
  img{
    position: absolute;
    left: 10px;
    top: 0;
  }
}