.InfoLinks {
    display: flex;
    justify-content: center;
    z-index: 3;
    position: relative;
}

.InfoLinks p {
    color: white;
    padding: 10px;
}

.InfoLinks a {
    color: white;
    padding: 10px;
    text-decoration: none;
}