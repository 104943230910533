@import "../../../../../../../../../styles/variables/index";

.contentView {
  width: 100%;
  table {
    width: 100%;
    thead {
      tr {
        height: 0.4615rem !important;
        th {
          span {
            color: $color-label;
          }
        }
      }
    }
  }
}

.resendInvite {
  display: flex;
  justify-content: flex-end;
}

.Table {
  border-spacing: 0;
  width: 100%;
  thead {
    * {
      color: $clara-dark-grey !important;
      padding: 0;
    }
    th {
      border: unset;
      font-size: 1rem;
      font-weight: 400;
    }
  }
  tr:first-child {
    height: 3.5rem;
    padding-bottom: 1.2rem;
    padding-top: 0 !important;
    td {
      div:first-child {
        padding-bottom: 0.2rem;
      }
    }
  }
  tr {
    height: 4.7rem;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    td {
      border-bottom: 1px solid #eee;
      padding: 0.75em 0;
      font-size: 0.9231rem;
      color: $clara-dark-grey;
    }
  }
}

.Col2 {
  width: 24.6154rem;
}

.containerQuestion {
  max-width: 20.3846rem;
  display: flex;
  align-items: center;
  align-content: center;
  min-height: 3.6923rem;
}

.containerResponse {
  display: flex;
  align-items: center;
  align-content: center;
  margin-right: 1.5385rem;
  min-height: 3.6923rem;
}

.fontQuestion {
  font-size: 0.9231rem;
  line-height: 1.3;
  font-weight: 400;
  color: $clara-dark-grey;
}

.fontResponse {
  font-size: 0.9231rem;
  line-height: 1.3;
  font-weight: 600;
  color: $clara-black;
}
