.ADGMAuthorisedSignatoryCheckboxContainer {
  display: flex;
  > div {
    > div {
      width: 100%;
    }
    > div:nth-child(2){
      width: 48%;
      margin: 0 2.7px 20px 0;
    }
    > div:nth-child(3){
      width: 48%;
      margin-bottom: 20px;
    }
  }
}

.ADGMAuthorisedSignatoryCheckboxButton {
  width: 100%;
}

.ADGMAuthorisedSignatoryCheckboxButtonText{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;

  >span {
    text-align: start;
  }
}