
.icon {
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  border: 1px solid #e4e3e9;
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  &:before {
    content: " ";
    cursor: pointer;
    background-color: #a9b0bc;
    display: inline-block;
    -webkit-mask-size: 18px 6px;
    width: 18px;
    height: 6px;
    -webkit-mask: url(../../../../images/menu.svg) no-repeat center;
    display: inline-block;
    margin: 16px auto;
  }
  &:hover {
    &:before {
      background-color: #2c74ff;
    }
  }
}
.ShowModal {
  min-width: 200px; //fix revision in effect app
  height: auto;
  margin: 0;
  padding: 0;
  border: 1px solid #e4e3e9;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.TitleModal {
  
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: #848f99;
  display: inline-block;
  padding: 21px 20px 15px;
  width: 100%;
  box-sizing: border-box;
  a {
    float: right;
    width: 12px;
    height: 12px;
    cursor: pointer;
    &:before {
      content: " ";
      background-color: #848f99;
      display: inline-block;
      -webkit-mask-size: 12px 12px;
      width: 12px;
      height: 12px;
      -webkit-mask: url(../../../../images/close.svg) no-repeat center;
    }
    &:hover {
      &:before {
        background-color: #2c74ff;
      }
    }
  }
}
.BodyModal {
  width: 100%;
  margin: 0;
}
.RowModal {
  border-bottom: 1px solid #EAF1FE;
  &:last-child{
    border: unset;
  }
  a {
    
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 0px;
    padding: 15px 20px 15px 19px;
    align-items: center;
    color: #192a3e;
    cursor: pointer;
    width: 100%;
    display: block;
    &:before {
      content: " ";
      cursor: pointer;
      background-color: #a9b0bc;
      display: inline-block;
      -webkit-mask-size: 24px 24px;
      width: 24px;
      height: 24px;
      -webkit-mask: url(../../../../images/menu.svg) no-repeat center;
      display: inline-block;
      margin: 0;
    }span{
      display: inline-block;
      line-height: 24px;
      margin-left:10px;
    }
    &:hover {
      background: #f7f8f9;
      color: #2c74ff;
      &:before {
        background-color: #2c74ff;
      }
    }
  }
}
.RowIconModal {
  border-bottom: 1px solid #EAF1FE;
  &:last-child{
    border: unset;
  }
  a {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 0px;
    padding: 15px 20px 15px 19px;
    align-items: center;
    color: #192a3e;
    cursor: pointer;
    width: 100%;
    display: block;

    span{
      display: inline-block;
      line-height: 24px;
    }
    svg {
      color: #a9b0bc;
    }
    &:hover {
      background: #f7f8f9;
      color: #2c74ff;
      &:before {
        background-color: #2c74ff;
      }
    }
  }
}
.RowDelete {
  a {
    color: #ff606f;
    cursor: pointer;
    width: 100%;
    text-align: right;
    display: block;
    border-top: 1px solid #e4e3e9;
    margin: 12px 0 0 0;
    padding: 12px 20px;
    text-align: left;
    &:before {
      content: " ";
      background-color: #ff606f;
      margin: 0 0 -3px 0;
      display: inline-block;
      margin-left: 2px;
      -webkit-mask-size: 14px 14px;
      width: 14px;
      height: 14px;
      position: relative;
      -webkit-mask: url(../../../../images/form_remove.svg) no-repeat center;
      margin-right: 8px;
    }
    &:hover {
        color: #ff606f;
      }
  }
}
.WithIcon{
  a:before{
    display: none;
  }
  i{
    padding: 0px 0 0 0;
    display: inline-block;
    font-size: 21px;
    color:#a9b0bc;
    &:before{
      color:#a9b0bc;
    }
  }
}
.isDisabled{
  a,a:hover{
    color:#a9b0bc;
    cursor: no-drop;
    
  }
  
}