@import "./../../../../../styles/variables/colors.scss";

.GroupCompanyCard {
  margin-bottom: 1.8rem;
  padding: 0.9231rem;
  width: 100%;
  height: 138px;
  box-shadow: 2px 4px 12px 0px #2C74FF26;
  box-sizing: border-box;
  position: relative;
  &:hover {
    border: 2px solid $clara-blue;
  }
  &.Level_1 {
    width: 24.9rem;
  }
  &.Level_2 {
    width: 21.5rem;
    margin-left: 3.4rem;
  }
  &.Level_3 {
    width: 18.1538rem;
    margin-left: 6.7462rem;
  }
}

.borderDash {
  border: 2px dashed $clara-blue;
}
.iconFilter{
  position: absolute;
  right: 0.9231rem;
  top:0.9231rem;
  cursor: pointer;
  z-index: 2;
}