.DinamicForm {
    width:100%;
    //padding:20px 0;
    box-sizing: border-box; 
    display:block;
    text-align: left;
    padding: 0 10px;
}
.form{
    //padding: 55px;
    //height: 200px;
    box-sizing: content-box;
    //overflow: auto;
}
.title{
    
}