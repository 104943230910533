@import "./../../../../styles/variables/colors.scss";
.radioButton {
  /* left: 37.27%;
    right: 57.05%;
    top: 35.78%;
    bottom: 62.33%; */

  
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  //display: flex;
  align-items: center;

  /* Clara Black */

  color: #192a3e;
  accent-color: $clara-blue !important;
  &:focus{
    outline: 1px solid $clara-blue;
    outline-offset: initial;
  }
}
.inpuFeedback{
  color:#FF606F;
}
.containerCheckbox {
  width: 100%;
  display: block;
  text-align: left;
  input {
    line-height: 16px;
    margin-right: 5px;
    vertical-align: middle;
  }
  label {
    line-height: 16px;
    
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    vertical-align: middle;
  }
}
