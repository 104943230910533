@import "../../../styles/variables/index";
@import "~./../../../styles/extends/positions.scss";
.TextInput {
  width: 100%;
  display: block;
  position: relative;
  outline: none;
  height: 40px;
}

.TextInputSmall {
  display: block;
  position: relative;
  outline: none;
  height: 20px;
  input {
    width: 3.0769rem !important;
    height: 1.6923rem !important;
  }
}

.TextInputMedium {
  display: block;
  position: relative;
  outline: none;
  height: 40px;
  input {
    width: 35rem !important;
    height: 3rem !important;
  }
}

.TextInputDisabled {
  input {
    background-color: #e9e9e9;
  }
}

.input {
  font-size: 1rem;
  display: block;
  width: 100%;
  height: 40px;
  padding: 0.75rem 0.93rem;
  border: 1px solid #c2cfe0;
  color: $color-input;
  border-radius: 0.3846rem;
  box-sizing: border-box;
  outline: none;
  &:focus {
    border-color: $bg-primary;
  }
}

.input[type="number"]::-webkit-inner-spin-button,
.input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.borderErrorInput {
  border-color: $color-error;
  &:focus {
    border-color: $color-error !important;
  }
}
.textErrorInput {
  color: $color-error;
  &:focus {
    color: $color-error !important;
  }
}
.tooltipPlacementRight {
  background: #ffffff !important;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 13px !important;
  line-height: 17px !important;
  color: #848f99 !important;
  position: relative;
  &:before {
    content: "▲";
    width: 0;
    height: 0;
    color: #fff;
    position: absolute;
    left: -12px;
    top: 75%;
    transform: rotate(270deg) translateX(-50%);
    text-shadow: -1px -2px 4px rgba(0, 0, 0, 0.25);
  }
}
.afterComponentPadding {
  padding-right: calc(2.3077rem + 0.5rem);
}
.afterComponent {
  right: 0;
  top: 0;
  width: 2.3077rem !important;
  position: absolute;
  height: 100%;
}
.currency {
  right: 1em;
  top: calc(50% - 0.5em);
  width: max-content !important;
  position: absolute;

  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 120%;
  color: #65707e;
}

.rightLabel {
  position: absolute;
  right: 10px;
  top: 14px;

  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 120%;
  align-items: center;
  text-align: right;
  color: #65707e;
}
.leftLabel {
  position: absolute;
  left: 10px;
  top: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  align-items: center;
  text-align: right;
  height: 100%;
  display: flex;
  color: $clara-black;
}
.RightLabelPadding {
  padding: 0.75rem 4.2rem 0.75rem 0.93rem;
}
.LeftLabelPadding {
  padding: 0.75rem 0.93rem 0.75rem 3rem;
}
