.ContainerViewDocument {
  margin: 0;
  padding: 0;
  height: auto;
  width: 1000px;
  clear: both;
  position: relative;

  text-align: left;
  display: grid;
  grid-template-columns: 15.6923rem auto;
  grid-template-rows: 1.9231rem auto;
  gap: 1.5rem 0;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 40px;
  color: #65707e;
}
.ContainerSearchAndTitle {
  display: flex;
  width: 100%;
  justify-content: space-between;
  grid-column-start: 1;
  grid-column-end: 3;
}
.containerFilterBox {
  width: 223px;
  height: 30px;

  justify-self: flex-end;
}
.titleTable {
  width: 200px;
  color: #2c74ff;
  font-size: 1.3846rem;
  font-weight: 600;
}
.inputSearch {
  background: #eaf1fe;
}
.ContainerAdd {
  color: #2c74ff;
  width: 120px;
  margin-left: 25px;
  line-height: normal;
  white-space: nowrap;
  cursor: pointer;
  &:before {
    content: " ";
    background-color: #2c74ff;
    display: inline-block;
    position: relative;
    right: 7.5%;
    -webkit-mask-size: 16px 16px;
    width: 12px;
    height: 12px;
    -webkit-mask: url(../../../../../images/add_startup.svg) no-repeat center;
  }
}
.searchAndAdd {
  display: inline-flex;
  align-items: center;
}
.ContainerData {
  margin: 0;
  padding: 0;
  height: auto;
  width: 700px;
  position: relative;
  display: inline-block;
  clear: both;
}

.ContainerDataTable {
  width: 100%;
}
.header {
  width: 34px;
  height: 13px;
  left: 752px;
  top: calc(50% - 13px / 2 - 156px);

  font-style: normal !important;
  font-weight: normal !important;
  font-size: 11px !important;
  line-height: 13px; /* identical to box height */
  display: flex;
  align-items: center !important;
}

.EditIcon {
  a {
    &:before {
      -webkit-mask: url(../../../../../images/edit.svg) no-repeat center !important;
    }
  }
}
.DeleteIcon {
  a {
    &:before {
      -webkit-mask: url(../../../../../images/trash.svg) no-repeat center !important;
    }
  }
}

.DownloadIcon {
  a {
    &:before {
      -webkit-mask: url(../../../../../images/download.svg) no-repeat center !important;
    }
  }
}
.DocumentTable {
  th {
    display: flex !important;
  }
}
.uploadDocButton {
  right: 25%;
}
.listCategories {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  li {
    height: 2.2rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right:1rem ;
  }
}

.listSubCategories {
  list-style: none;
  padding-left: 1.5rem;
}

.elementList {
  cursor: pointer;
  font-family: "cl-regular";
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  font-size: 1.05rem;
  color: #192a3e;
  word-break: break-all;

 
}

.elementListSelected {
  color: #2c74ff;
}
