@import "../../../../styles/variables/index";

// position x-y-origin

.badgeClara {
    transition: opacity 225ms !important;
    opacity: 0;
    background-color: transparent !important ;
    border: 1px solid $clara-blue !important;
    color: $clara-blue !important;
}

.badgeShow {
    opacity: 1;
}

.badge-postion-center-center-in {
    transform: translate(50%, -50%) !important;
    right: 50% !important;
    top: 50% !important;
}

.badge-postion-end-top-middle {
    right: 0 !important;
}

.badge-postion-center-center-out {
    right: 0 !important;
    transform: translate(100%, -50%) !important;
    top:50% !important;
}


.badge-postion-center-center-in {
    right: 0 !important;
    transform: translate(0, -50%) !important;
    top:50% !important;
}