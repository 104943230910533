/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
.scrollbar::-webkit-scrollbar {
  background-color: transparent;
  width: 0.3846rem;
  height: 0.3846rem;
}

/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}
.scrollbar::-webkit-scrollbar-track:hover {
  background-color: transparent;
}

/* scrollbar itself */
.scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 0.3846rem;
}
.scrollbar:hover::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 0.3846rem;
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
}
/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {
  display: none;
}

/* div box */
.scrollbar {
  min-width: 150px;

  overflow-y: auto;
}
sub{
  vertical-align: baseline;
}