
.ClaraInputText {
    width:100%;
    height: ma;
}
.label{
    font-size: 13px;
    max-width: 90%;
    text-align: left;
    font-weight: bold;
    img{
        margin-left: 7px;
        margin-top: -3px;
    }
}
.input{
    font-size: 13px;
    width: 100%;
    height:57px;
    padding: 20px;
    border: 1px solid #C2CFE0;
    border-radius: 5px;
    box-sizing: border-box; 
    margin: 20px 0;
    outline: none;
    &:focus{
        border-color: #2C74FF;
    }
}

.tooltipPlacementRight{
    background: #FFFFFF!important;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25)!important;
    border-radius: 4px!important;
    
    font-style: normal!important;
    font-weight: normal!important;
    font-size: 13px!important;
    line-height: 17px!important;
    color: #848F99!important;
    position:relative;
    &:before{
        content:'▲';
        width: 0;
    height: 0;
    color:#FFF;
    position: absolute;
    left:-12px;
    top: 75%;
    transform: rotate(270deg) translateX(-50%);
    text-shadow: -1px -2px 4px rgba(0,0,0,0.25);
    }
}