@import "../../../../../styles/variables/index";
.text {
  font-weight: 700;
  font-size: 22px;
  color: $clara-black;
}
.money {
  font-weight: 700;
  font-size: 0.7692rem;
  color: $clara-black;
}
.price {
  font-weight: 800;
  font-size: 1.8462rem;
  color: $clara-black;
}

.containerCard {
  background-color: rgba(#eaf1fe, 0.5);
  border-radius: 0.3077rem !important;
  max-height: 285px !important;
  width: 539px !important;
  padding: 24px 32px !important;
  .root {
    height: 256px !important;
    width: 539px !important;
    padding: 24px 32px !important;
  }
}

.labelText {
  color: #828c98 !important;
}
