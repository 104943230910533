@import "../../../../../styles/variables/index.scss";
@import "./Styles.module.scss";

.containerForm {
    max-height: 616px;
    overflow-y: auto;
    padding-right: 2.8rem;
    padding-top: 3.6rem;
    border-bottom: 1px solid $clara-light-blue;
    padding-left: 2.8rem;
    padding-bottom: 3.8rem;
    width: calc(620px + 5.6rem);
}
.textArea{
    height: 8.615384615rem;
}
.textContainer {
    div div div div input {
        height: 3.2308rem;
    }
}

.separator {
    hr {
        height: 0.01rem !important;
    }
}

.fileUploadContainer {
    div > div > div > div > span > div > div > div {
        width: 97% !important;
        white-space: nowrap;
    }
    
}

.containerButtons {
    padding-right: 3rem;
    margin-bottom: 1.9rem;
}
.title {
    text-align: left;
    margin: 0;
    width: 100%;
    margin-bottom: 40px;
}

.isHoldingChecbox {
    div label {
    }
    color: $clara-dark-grey;
}
