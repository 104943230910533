@import "../../../../../../../styles/variables/index.scss";

.contentView {
  width: 100%;
  table {
    width: 100%;

    thead {
      tr {
        th {
          span {
            color: $color-label;
          }
        }
      }
    }
  }
}
.contentView2 {
  width: 75.3846rem;
  table {
    width: 60.6923rem;
    position: relative;
    bottom: 6.7692rem;
    margin-top: 8rem;
    thead {
      tr {
        th {
          span {
            color: $color-label;
          }
        }
      }
    }
  }
}
.avatarColumn {
  label {
    font-family: "cl-regular";
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.2308rem;
    color: $palette-clara !important;
    position: relative;
    top: 0.5385rem;
    left: 0.7692rem;
  }
  .avatarTagContainer {
    span {
      position: relative;
      top: 0.5385rem;
      left: 0.7692rem;
    }
  }
  div {
    display: inline-block !important;
    width: 2.6154rem;
    div {
      position: relative;
      top: 5px;
      div {
        top: 0px;
      }
    }
  }
  .emailLabel {
    display: block !important;
    position: relative;
    width: auto !important;
    left: 35px;
    bottom: 15px;
    label {
      color: #828c98 !important;
      font-family: "cl-regular";
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 15px;
    }
  }
}

.rolesColumn {
  min-width: 5.3846rem;
  .rolesLabelContainer {
    width: 100%;
    display: inline-block;
    a {
      color: $palette-clara !important;
    }

    a:link {
      color: $palette-clara !important;
    }

    a:visited {
      color: $palette-clara !important;
    }

    a:hover {
      color: $palette-clara !important;
    }

    a:active {
      color: $palette-clara !important;
    }

    span {
      font-family: "cl-regular";
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.2308rem;
      color: $palette-clara !important;
      // color: #2c74ff !important;
    }

    label {
      font-family: "cl-regular";
      font-style: normal;
      font-size: 0.9231rem;
      line-height: 1.2308rem;
      color: $palette-clara !important;

      // color: $palette-clara !important;
    }
  }
  .rolesIconContainer {
    width: 25%;
    display: inline-block;
    svg {
      cursor: pointer;
      color: $color-label;
      position: relative;
      right: 2.6923rem;
      top: 0.1538rem;
      width: 0.6154rem !important;
    }
  }
}
.recentActivityColumn {
  .recentActivityLabelContainer {
    display: inline-block;
    .resendInvite {
      min-width: 12.7692rem;
      text-decoration: underline $palette-clara;
      cursor: pointer;
    }
    label {
      font-family: "cl-regular";
      font-style: normal;
      font-size: 0.9231rem;
      line-height: 1.2308rem;
      color: $palette-clara !important;
    }
  }
  .recentActivityIconContainer {
    width: 25%;
    display: inline-block;
    img {
      cursor: pointer;
      position: relative;
      left: 0.7692rem;
    }
  }
}
.resendInvite {
  display: flex;
  justify-content: flex-end;
}

.shareDateColumn {
  min-width: 10.3846rem;
  label {
    font-family: "cl-regular";
    font-style: normal;
    color: $clara-dark-grey !important;
    font-size: 12px;
    line-height: 1.2308rem;
  }
}

.containerModalChangeRole {
  display: inline-flex;
  flex-direction: column;
  padding: 2rem 2.5rem;
  width: 42.3077rem;
  height: 21.5385rem;
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 4rem;
  }
}

.optionSelectorContainer {
  height: 5.7692rem;
  display: grid;
  width: 27.3077rem;
  position: relative;
  right: 0;
  top: 0;
  p {
    position: relative;
    right: 100%;
  }
  .infoLabel {
    position: absolute;
    z-index: 100;
    top: 1.9231rem;
    margin: 0.0769rem;
    left: 1.0769rem;
    color: #65707e;
    font-family: "cl-regular";
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.2308rem;
  }
  .buttonRoleToCheck {
    &:before {
      cursor: pointer;
      content: " ";
      background-color: #a9b0bc;
      display: inline-block;
      -webkit-mask-size: 16px 16px;
      width: 16px;
      height: 16px;
      -webkit-mask: url(../../../../../../../images/unborderedUncheckbox.svg)
        no-repeat center;
      margin: 11px 10px 11px 10px;
      display: inline-block;
    }
    &:after {
      content: " ";
      position: absolute;
      display: inline-block;
      -webkit-mask-size: 16px 16px;
      width: 16px;
      height: 16px;
      // -webkit-mask: url(/static/media/checked.d1f56060.svg) no-repeat center;
      margin: 11px 10px 11px 10px;
      display: inline-block;
      z-index: 100;
    }
  }

  .buttonRoleSelected {
    &:before {
      cursor: default;
      content: " ";
      background-color: #2c74ff;
      display: inline-block;
      -webkit-mask-size: 16px 16px;
      width: 16px;
      height: 16px;
      -webkit-mask: url(../../../../../../../images/uncheckbox.svg) no-repeat
        center;
      margin: 11px 10px 11px 10px;
      display: inline-block;
    }
    &:after {
      content: " ";
      position: absolute;
      background-color: #fff;
      display: inline-block;
      right: 24.0615rem;
      -webkit-mask-size: 16px 16px;
      width: 16px;
      height: 16px;
      -webkit-mask: url(../../../../../../../images/checked.svg) no-repeat center;
      margin: 11px 10px 11px 10px;
      display: inline-block;
      z-index: 100;
    }
  }
}

.deactivateModalContainer {
  .buttonDeactivateToCheck {
    width: 37.6154rem;
    &:before {
      cursor: pointer;
      content: " ";
      background-color: #a9b0bc;
      display: inline-block;
      -webkit-mask-size: 16px 16px;
      width: 16px;
      height: 16px;
      -webkit-mask: url(../../../../../../../images/unborderedUncheckbox.svg)
        no-repeat center;
      margin: 11px 10px 11px 10px;
      display: inline-block;
    }
  }
  .buttonDeactivateChecked {
    width: 37.6154rem;
    &:before {
      cursor: default;
      content: " ";
      background-color: #2c74ff;
      display: inline-block;
      -webkit-mask-size: 16px 16px;
      width: 16px;
      height: 16px;
      -webkit-mask: url(../../../../../../../images/uncheckbox.svg) no-repeat
        center;
      margin: 11px 10px 11px 10px;
      display: inline-block;
    }
    &:after {
      content: " ";
      position: absolute;
      background-color: #fff;
      display: inline-block;
      top: 0.0769rem;
      right: 24.0615rem;
      -webkit-mask-size: 16px 16px;
      width: 16px;
      height: 16px;
      -webkit-mask: url(../../../../../../../images/checked.svg) no-repeat center;
      margin: 11px 10px 11px 10px;
      display: inline-block;
      z-index: 100;
    }
  }
}

.containerButtons {
  position: relative;
  left: 18.0769rem;
  top: 2.8462rem;
  display: inline-flex;
  button {
    margin-right: 0.7692rem;
  }
}

.alertDeleteSelfMessage {
  color: #ff606f;
}

.DeleteForm {
  padding: 34px;
  min-width: 42.3077rem;
  height: 21.5385rem;
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 110.93%;
    color: #192a3e;
    margin: 0 0 10px;
  }
  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #ff606f;
  }
  .botonera {
    margin: 95px 0 0 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right;
    button {
      text-align: center;
      letter-spacing: 0.01em;
      min-width: 120px;
      margin-right: 10px;
      &.nextButton {
        background: #ff606f;
        border: 1px solid #ff606f;
        border-radius: 4px;
        color: #fff;
      }
    }
  }
}
.CheckBox {
  label {
    line-height: 38px;
  }
}
.ModalDelete {
  border-top-color: #ff606f !important;
}

.submitButton {
  width: 120px;
  height: 40px;
  background: #ff606f;
  box-sizing: border-box;
  border-radius: 4px;
  color: white;
  font-family: "cl-regular";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
}
.cancelButton {
  width: 120px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #eaf1fe;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: "cl-regular";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #828c98;
}

.ModalAdd {
  border-top-color: #2c74ff !important;
}

.alertDeleteContainer {
  width: 85%;
  padding-left: 35px;
}

.alertModalBotonera {
  margin: 67px 0 0 0 !important;
}
.Table {
  border-spacing: 0;
  width: 100%;
  thead {
    * {
      color: $clara-dark-grey !important;
      padding: 0;
    }
    th {
      border: unset;
      font-size: 1rem;
      font-weight: 400;
    }
  }
  tr:first-child {
    height: 3.5rem;
    padding-bottom: 1.2rem;
    padding-top: 0 !important;
    td {
      div:first-child {
        padding-bottom: 0.2rem;
      }
    }
  }
  tr {
    height: 4.7rem;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    td {
      border-bottom: 1px solid #eee;
      padding: 0.75em 0;
      font-size: 0.9231rem;
      color: $clara-dark-grey;
    }
  }
}
