@import "./../../../../../styles/variables/colors.scss";
.ButtonArrow {
   background: transparent;
   border: none;
   
   svg {
      color:$clara-blue;
      transition: all 0.5s;
   }
   &.bottom{
      &.close {
         svg {
            transform: rotate(180deg);
         }
      }
   }
   &.top{
      svg{
         transform: rotate(180deg);
      }
      &.close {
         svg {
            transform: rotate(0);
         }
      }
   }
   
   &:focus{
      svg{
         color:$clara-blue;
      }
   }
   &:hover{
      svg{
         color:$clara-blue;
      }
   }
}
