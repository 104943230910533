@import "./../../../../styles/variables/colors.scss";

.SubscriptionFree {
  width: 27.8462rem;
  height: 38.4615rem;
  max-height: 38.4615rem;
  margin: 0;
  padding: 0;
  border-radius: 1.25rem;
  -webkit-border-radius: 1.5385rem;
  -moz-border-radius: 1.5385rem;
  -ms-border-radius: 1.5385rem;
  -o-border-radius: 1.5385rem;
  border: 0.2308rem solid $clara-black;
  position: relative;
  background: url("../../../../../src/images/screen.svg") center bottom no-repeat;
  .cornerContainer {
    display: flex;
    position: absolute;
    right: 0;
    .cornerDetail {
      width: 6.563rem;
      height: 2.125rem;
      background-color: $clara-black;
      border-top-right-radius: 0.9231rem;
      justify-content: center;
      align-items: center;
      border-bottom-left-radius: 1.5385rem;
      display: flex;
      flex-direction: column;
      .textCorner {
        font-size: 1rem;
        font-weight: 800;
      }
    }
    .cornerRounder {
      width: 1.3846rem;
      height: 3.9231rem;
      background-color: $clara-black;
      .cornerAngle {
        width: 1.3846rem;
        height: 3.9231rem;
        background-color: white;
        border-top-right-radius: 1.0769rem;
      }
    }
  }

  .dataSpace {
    height: 100%;
    width: 100%;
    padding:0 1.063rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    .titleSpace {
      display: grid;
      flex-direction: column;
      height: auto;
      align-items: flex-start;
      padding-top: 2.1rem;
      .textTitle {
        font-size: 2.5rem;
        font-weight: 700;
        padding: 0.625rem 0;
      }
      .textSubTitle {
        padding: 0.55rem 0;
        font-size: 1rem;
        font-weight: 700;
      }
    }
    .bodyCard{
      display: grid;
      grid-template-columns: auto auto;
      padding-top: 1.438rem;
      column-gap: 0.5rem;
    }
    .tagsContainer{
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      .tagOnly{
        width: 7rem;
        border-radius: 12px;
        color: white;
        font-size: 12px;
        background: #828c98;
        text-align: center;
        margin-bottom: 2.0769rem;
        height: 1.8462rem;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        -ms-border-radius: 12px;
        -o-border-radius: 12px;
}
    }
  }
  img {
    position: absolute;
    bottom: 0;
    height: 11.509rem;
    width: 27.8462rem;
  }
}