@import "./../../../styles/variables/colors.scss";

.uploadedSelectorContainer {
    max-width: 29.2308rem;
    max-height: 16.9231rem !important;
    height: auto;
    color: $clara-dark-grey;
    display: flex;
    flex-direction: column;
    border: 1px solid $clara-light-blue;
    border-radius: 0.3077rem;
    margin-bottom: 1.2308rem;
    margin-top: 0.6154rem;
    padding: 1.2308rem 0 1rem 1.2308rem;
    span {
        margin-bottom: 0.9231rem;
    }
    .listTitle {
        font-family: "cl-regular";
        font-weight: 300;
        font-size: 0.9231rem;
        color: $clara-dark-grey;
        margin-bottom: 1rem;
        margin-left: 0.3846rem;
    }
    button {
        background: $clara-white;
        width: 2.5385rem;
        height: 18.9995px;
    }
    .checkbox {
        &:before {
            cursor: pointer;
            content: " ";
            background-color: #a9b0bc;
            display: inline-block;
            -webkit-mask-size: 1.2308rem 1.2308rem;
            width: 1.2308rem;
            height: 1.2308rem;
            -webkit-mask: url(../../../images/unborderedUncheckbox.svg) no-repeat center;
            display: inline-block;
            margin-right: 0.5385rem;
        }
        &:after {
            content: " ";
            position: relative;
            display: inline-block;
            -webkit-mask-size: 1.2308rem 1.2308rem;
            width: 1.2308rem;
            height: 1.2308rem;
            // -webkit-mask: url(/static/media/checked.d1f56060.svg) no-repeat center;
            display: inline-block;
            z-index: 100;
        }
    }

    .checkboxChecked {
        &:before {
            cursor: default;
            content: " ";
            background-color: #2c74ff;
            display: inline-block;
            -webkit-mask-size: 1.2308rem 1.2308rem;
            width: 1.2308rem;
            height: 1.2308rem;
            -webkit-mask: url(../../../images/uncheckbox.svg) no-repeat center;
            margin-right: 0.5385rem;
            display: inline-block;
        }
        &:after {
            content: " ";
            position: relative;
            background-color: #fff;
            display: inline-block;
            right: 0.2308rem;
            bottom: 1.3846rem;
            -webkit-mask-size: 1.2308rem 1.2308rem;
            width: 1.2308rem;
            height: 1.2308rem;
            -webkit-mask: url(../../../images/checked.svg) no-repeat center;
            display: inline-block;
            z-index: 100;
        }
    }
}

.selectedFileContainer {
    background: $clara-light-blue;
    max-width: 29.2308rem;
    width: 100%;
    display: flex;
    height: 3.0769rem;
    flex-direction: row;
    padding: 0.7692rem 0 0.9231rem 0.9231rem;
    border-radius: 0.3077rem;
    margin-bottom: 1.2308rem;
    span {
        align-items: center;
        display: flex;
        color: $clara-blue;
        font-weight: 300;
        width: 100%;
        font-size: 1.0769rem;
    }
    .tickIcon {
        align-self: center;
        display: flex !important;
        color: $clara-blue;
        margin-right: 0.9231rem;
    }
    .deleteIcon {
        align-self: center;
        display: flex !important;
        justify-content: flex-end;
        color: $clara-blue;
        margin-right: 1.0769rem;
        cursor: pointer;
    }
}

.listPreTitle {
    font-family: "cl-regular";
    font-weight: 400;
    font-size: 1.0769rem;
    color: $clara-dark-grey;
    margin-top: 0.2308rem;
}
