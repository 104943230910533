.PendingDraft {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .PendingDraftImg {
    position: absolute;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }
  .PendingDraftContent {
    position: absolute;
    left: 233px;
    top: 168px;
    width: 320px;
    text-align: left;
    h1 {
      
      font-style: normal;
      font-weight: normal;
      font-size: 34px;
      color: #192a3e;
      margin:11px 0 0 0 ;
    }
    h2 {
      
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #2c74ff;
      margin: 0;
    }
    p {
      
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #192a3e;
      text-align: left;
      margin: 23px 0 0 0;
    }
    .Botonera{
      margin-top:40px;
      display: flex;
      justify-content: space-between;
    }
  }
  .middleButton{
    margin: 0 24px;
  }