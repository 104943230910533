@import "./src/styles/variables/colors.scss";
.title {
  font-weight: lighter;
  color: $clara-blue;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 40rem;
  margin-left: 5rem;
}
.buttonPages {
  /* height: 100%; */
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  /* align-self: center; */
}
.ZoneTitle {
  align-self: flex-start;
  margin-top: 1.5rem !important;
  h1 {
    font-weight: lighter;
    color: $clara-dark-grey;
  }
  p {
    color: $clara-dark-grey;
    margin-bottom: 1rem;
  }
}
.titleShare {
  display: flex;
  gap: 1.5rem;
  width: 100%;
  align-items:center;
  h1 {
    color: #192a3e;
    font-size: 22px;
    font-weight: 600;
  }
}
.detailsShare {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.detailsColumn {
  display: flex;
  gap: 119px;
  align-items: center;
  justify-content: flex-start;
}
.shareLabel {
  color: #828c98;
  width: 142px;
}
.shareDetails {
  color: #192a3e;
  font-weight: 600;
}

.shareholdingTerms {
  p {
    font-weight: 600;
    font-size: 13px;
    color: #192a3e;
  }
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.labelToStakeholders {
  margin-top: 1.5rem !important;
  label {
    width: 12.5rem !important;
    color: $clara-dark-grey;
  }
}
.StakeholderName {
  label {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 120%;
    color: #192a3e !important;
  }
}
.limitSize {
  max-height: 15vh !important;
}
.limitSizeSelect {
  max-height: 13vh !important;
}
.ContainerRow {
  flex-direction: row;
  margin-top: 1.5rem !important;
  label {
    width: 14.5rem !important;
    color: $clara-dark-grey;
  }
  input {
    width: 28rem !important;
  }
  textarea {
    width: 28rem !important;
  }
}
.InputDate {
  input {
    //width: 10rem !important;
  }
}
.chechboxFix {
  width: auto;
}
.labelGroup {
  display: flex;
  margin-top: 1.5rem !important;
  flex-direction: row;
  justify-content: space-between;
  label {
    color: $clara-dark-grey;
  }
}
.checkBoxWizardFounder {
  margin-bottom: 0.5rem !important;
  width: 100%;
  label {
    // width: 30rem !important;
    margin-left: 0.2rem !important;
  }
}
.checkBoxWizardIndividual {
  margin-bottom: 0.5rem !important;
  width: 28rem;
  label {
    // width: 30rem !important;
    margin-left: 0.2rem !important;
  }
}
.checkBoxWizardIndividualInFlex {
  display: flex !important;
}
.margin {
  margin-bottom: 0px !important;
  padding: 0px !important;
  margin-top: 0px !important;
}
.RadioButtonIndividual {
  padding: 0px !important;
  margin-bottom: 0px !important;
  display: flex;
  label {
    margin-right: 0.7rem !important;
  }
  a {
    margin: 12px 0 12px 12px !important;
  }
}
.SelectedBLUE {
  &:before {
    background: #2c74ff !important;
  }
}
.RadioButtonClass {
  display: flex;
  justify-content: space-between;
  width: 29rem !important;
}
.RadioButtonClass1 {
  display: flex;
  justify-content: space-between;
  //width: 29rem !important;
}
.RadioButton {
  padding: 0px !important;
  display: flex;
  margin-bottom: 0px !important;
  flex-direction: row;
  justify-content: space-between;
  label {
    color: $clara-dark-grey;
  }
}
.checkBoxWizard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > div {
    label {
      display: inline-block;
      width: 95% !important;
      margin-top: 0 !important;
    }
  }
  & > div {
    input {
      margin: 3px 5px 0 0 !important;
    }
  }
  label {
    color: $clara-dark-grey;
    margin-top: 0.2rem;
  }
}
.checkBoxEdit {
  label {
    color: $clara-dark-grey;
    grid: 0;
    grid-gap: 0;
  }
}
.labelGroupWizard {
  display: flex;
  flex-direction: column;
  margin-left: 50%;
  margin-top: 1.5rem !important;
  label {
    color: $clara-dark-grey;
  }
}
.InputContainer {
  display: inline-flex;
  flex-direction: column !important;
  justify-content: space-between;
  width: 28rem;
  input {
    width: 20rem !important;
    //margin-top: 0.5rem;
    // height: 40px !important;
    margin-bottom: 1rem !important;
  }
  label {
    // width: 20rem !important;
    margin-bottom: 1rem !important;
  }
}
.inputCheckboxFounder {
  display: inline-flex;
  flex-direction: column !important;
  justify-content: space-between;
  width: 100%;
  input {
    //width: 20rem !important;
    //margin-top: 0.5rem;
    // height: 40px !important;
    margin-bottom: 1rem !important;
  }
  span {
    // width: 20rem !important;
    margin-bottom: 1rem !important;
  }
}
.inputCheckbox {
  display: inline-flex;
  flex-direction: column !important;
  justify-content: space-between;
  width: 28rem;
  input {
    //width: 20rem !important;
    //margin-top: 0.5rem;
    // height: 40px !important;
    margin-bottom: 1rem !important;
  }
  span {
    // width: 20rem !important;
    margin-bottom: 1rem !important;
  }
}
.SelectContainer {
  display: inline-flex;
  flex-direction: column !important;
  margin-right: 7rem;
  margin-top: 1rem !important;
  label {
    width: 25rem;
  }
  input {
    width: 20rem !important;
    //margin-top: 0.5rem;
    // height: 40px !important;
  }
  span {
    width: 20rem !important;
    margin-bottom: 0.5rem !important;
  }
}
.Select {
  > div {
    display: inline-flex;
    justify-content: space-between;
  }
  input {
    width: 28rem !important;
  }
  width: 100%;
  label {
    margin-right: 6rem !important;
  }
}
.SelectCurrency {
  margin-top: 1.5rem !important;
  div > span {
    margin-left: 2.3rem !important;
  }
}

.VerifyAssignor {
  padding: 10px;
  border-left: 3px solid $clara-blue;
  margin-top: 1.5rem !important;
  margin-bottom: 25px;
}

.ZoneTitle {
  width: 100%;
  margin-top: 1.5rem !important;
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 120%;
    color: #192a3e;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 138.05%;
    /* identical to box height, or 18px */
    color: #65707e;
    text-align: left;
    margin: 10px 0 0 0;
  }
}
.CheckboxInline {
  > div {
    display: flex;
  }
}
.CheckboxWizardInline {
  width: 4rem;
  display: block;
  margin-bottom: 5px;
  input {
    display: inline-block;
    width: auto !important;
    margin: 3px 5px 0 0 !important;
  }
  label {
    min-width: 366px !important;
    margin: 0 !important;
    line-height: 20px !important;
    font-size: 13px;
    text-align: justify;
    color: #808080;
    grid-gap: 0rem !important;
    gap: 0rem !important;
  }
}
.Calendars {
  width: 364px !important;
}
.labelCalendar {
  margin-top: 1.5rem !important;
  grid-template-columns: 20.7rem 1fr !important;
  label {
    width: 12rem !important;
  }
}
.labelCalendar1 {
  margin-top: 1.9rem !important;
  label {
    width: 12rem !important;
  }
}
.line {
  margin: 1.5rem 0 0 0 !important;
}

.componentCheckBox {
  display: block;
  width: 100%;
  margin-bottom: 30px;
}
.groupCheckboxContainer {
  left: 21rem;
}
.groupCheckbox {
  display: inline-block;
  width: 80%;
  text-align: left;
}
.groupCheckBoxWizard {
  width: 28rem !important;
  span {
    width: calc(100% - 1.4rem);
  }
}
.groupCheckBoxWizardLabel {
  width: 100%;
}
.inputDateEdit {
  div {
    > div {
      width: 364px !important;
    }
  }
}

.alignWithInputDate {
  display: inline-grid !important;
  width: 100% !important;
  height: 40px !important;
  color: #808080;
  margin: 1.5rem 0 0 0 !important;

  button {
    width: 13.5rem !important;
  }
  label {
    text-align: left !important;
    align-self: center !important;
  }
}
.alignRadioButton {
  grid-template-columns: auto 14.4rem 13.4rem !important;
  input {
    width: 13.5rem !important;
  }
  margin: 1.9rem 0 0 0 !important;
}
.alignWithInputDateButton {
  margin-top: 1.5rem !important;
  label {
    width: 12rem !important;
  }
  button {
    width: 13.5rem !important;
    justify-self: center;
    padding: 0 0.5rem 0 0.7rem !important;
  }
}

.ContainerRowAgreement {
  padding: 0;
  height: auto;
  width: 100%;
  display: inline-block;
  margin-top: 10px;
  position: relative;
  & > div {
    margin: 0;
    label {
      width: 296px !important;
      margin: 0 20px 0 0;
      display: inline-block;
    }
    input {
      width: 380px;
      margin: 0;
    }
    .containerInput {
      background: red;
    }
  }
  & > label {
    width: 180px;
    margin-right: 20px;
    display: inline-block;
  }

  & > span {
    width: 380px;
    display: inline-block;
    ul {
      margin: 0;
      list-style-type: none;
      padding: 0;
      li {
        height: 20px;
      }
    }
  }
}
.ContainerRowAgreementLabel {
  & > div {
    label {
      width: 95% !important;
    }
  }
}

.CalendarInput {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
