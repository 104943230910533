@import "../../../../../../../../styles/variables/colors.scss";

.divider {
  margin-top: 2.0769rem !important;
  margin-bottom: 2.0769rem !important;
  border-color: $border-disabled !important;
}

.dividerTop {
  margin-bottom: 2.0769rem !important;
  border-color: $border-disabled !important;
}

.containerTab {
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
}

.containerComponent {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  margin-right: 1.25rem;
  cursor: pointer;
  p {
    color: $clara-blue;
    width: 7rem;
    margin-top: 0.5rem;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.secondarySignatoryContainer {
  width: 117px;
  height: 19px;
  background-color: $clara-light-blue;
  border-radius: 10px;
  margin-top: 12px;
  display: grid;
  place-items: center;
}

.secondarySignatoryText {
  font-size: 10px;
  font-weight: 700;
}

.bankingAuthorityTitle{
  color: $clara-dark-grey;
}

.bankingAuthorityDescription{
  margin-left: 12px;
  color: $clara-black;
}

.containerSign {
  margin-bottom: 12px;
}