@import "../../../../../../styles/variables/index";

.menuItem {
  height: 52px;
  display: grid;
  place-items: center;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  padding: 0 16px;
}

.selectedTab {
  background-color: #EAF1FE;
  border-radius: 4px;
  color: $clara-blue;
}