@import "./../../../../styles/variables/colors.scss";

.CapTableZones {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  .LastUpdate {
    font-size: 10px;
    font-weight: 400;
    color: $clara-dark-grey;
    position: absolute;
    //right: 26px;
    top: -1rem;
  }
}

.CapTableTables {

}

.CapTable {
  height: 100%;
  width: 100%;
  text-align: left;
  vertical-align: top;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
 
}
.CapTableContent{
  padding: 30px 18px 30px 35px;
}

.CapTableDivSelect {
  min-width: 10.8462rem !important;
  max-width: 61.5385rem !important;
  width: auto;
  position: relative;
  margin: 0;
  padding: 0;
  height: auto;
}

.CapTableDivSelect a {
  padding: 0 1.5385rem;
  border: 1px solid #bfc9d7;
  border-radius: 30px;
  &:hover {
    border-color: $clara-blue;
  }
  svg {
    margin: 0;
  }
}
.MissingInformation{
  margin-top: 4rem !important;
  padding: 0 !important;
  height: auto !important;
}
.DivAvatar {
  position: absolute;
  right: 2rem;
  img {
    width: 2.4615rem;
    height: 2.4615rem;
  }
}

.CapTableDownload {
  position: absolute;
  left: calc(100% - 1rem);
  background-color: $clara-white !important;
}