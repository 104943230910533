@import "./../../../../styles/variables/colors.scss";

.Header {
  border-bottom: 1px solid #ebeff2;
  width: 100%;
  z-index: 2;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 24px;
}

.Filter {
  display: grid;
  grid-template-columns: 15.3rem 1fr;
  column-gap: 0.6rem;
  > div:first-child {
    display: inline-block;
  }
  > * {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.FilterIcon {
  svg {
    color: $clara-blue;
    width: 1.3rem;
    height: 1.3rem;
  }
}

.TextInput {
  svg {
    color: $clara-dark-grey;
  }
  input {
    border-color: $clara-light-blue;
    font-size: 0.9231rem;
    color: $clara-dark-grey;
  }
}

.arrowColor {
  svg {
    color: $clara-blue !important;
  }
}