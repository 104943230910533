@import "../../../../../../styles/variables/index.scss";


.Tag {
  border: 1px solid $clara-light-blue;
  border-radius: 4px;
  padding: 1.2308rem;
  justify-content: center;
  height: auto;
  align-items: center;
  display: flex;
  background-color: $clara-white;
  > * {
    justify-content: center;
    align-items: center;
  }
  svg {
    color: $clara-blue;
    cursor: pointer;
  }
}

.FounderContent {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  .TagsContent {
    display: flex;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.ButtonAdd {
  width: auto;
  justify-self: flex-end;
  align-self: flex-end;
  min-width: unset;
}

.scrollArea {
  padding-right: 12px;
  padding-left: 2px;
  padding-top: 2px;
  padding-bottom: 4px;
}
