@import "./../../../../styles/variables/colors.scss";
.ModeEdit {
  position: absolute;
  left: 0;
  top: 70px;
  background: #fff;
  width: 100%;
  height: calc(100% - 70px);
  overflow: auto;
  z-index: 13;
}
.EditForm {
  width: 100%;
  display: block;
}
.ContentEdit {
  width: 1088px;
  height: auto;
  margin: 53px 0 0 122px;
  position: relative;
  padding-bottom: 150px;
}

.icon {
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  border: 1px solid #e4e3e9;
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  &:before {
    content: " ";
    cursor: pointer;
    background-color: #a9b0bc;
    display: inline-block;
    -webkit-mask-size: 3px 13px;
    width: 25px;
    height: 25px;
    -webkit-mask: url(../../../../images/back.svg) no-repeat center;
    display: inline-block;
    margin: 7px auto;
  }
  &:hover {
    &:before {
      background-color: #2c74ff;
    }
  }
}
.optionButton {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}

.VerifyDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  .VerifyButton {
    cursor: pointer;
  }
  p {
    margin: 10px;
  }
}

.ContentView {
  display: inline-block;
  margin: 0 0 0 108px;
  h1 {
    line-height: 120%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      display: flex;
      align-items: center;
      margin-right: 30px;
      width: auto;
      div {
        display: flex;
      }
      p {
        font-size: 15px;
        margin-left: 10px;
      }
    }
  }
  h3 {
    position: absolute;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    color: #a9b0bc;
    margin: 10px 0 0 0;
  }
}
.ContentTabs {
  position: relative;
  margin: 30px 0 0 0;
  width: 100%;
}
.ContentTabsMenu {
  width: 100%;
}
.DeleteIcon {
  a {
    &:before {
      -webkit-mask: url(../../../../images/trash.svg) no-repeat center !important;
    }
  }
}
.ContainerSwitchRow {
  line-height: 24px;
  div input {
    width: auto;
    margin: 4px 10px 0 0;
    line-height: 24px;
  }
  div label {
    width: auto;
    line-height: 24px;
    display: inline-block;
  }
}
.DeleteForm {
  padding: 34px;
  min-width: 550px;
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 110.93%;
    color: #192a3e;
    margin: 0 0 10px;
  }
  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #ff606f;
  }
  .Warning {
    font-weight: 700;
    font-size: 1.0769rem;
    color: $clara-red;
    width: 409px;
    margin: 15px 0 30px 40px;
  }
  .botonera {
    margin: 40px 0 0 0;
    text-align: right;
    button {
      background: #ffffff;
      border: 1px solid #eaf1fe;
      box-sizing: border-box;
      border-radius: 4px;

      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.01em;
      padding: 16px;
      min-width: 120px;
      margin-right: 10px;
      /* Clara Dark Gray */

      color: #65707e;
      &.nextButton {
        background: #ff606f;
        border: 1px solid #ff606f;
        border-radius: 4px;
        color: #fff;
      }
    }
  }
}
.CheckBox {
  label {
    line-height: 38px;
  }
  .checkColor {
    span {
      svg {
        color: #FF606F !important;
      }
    }
    left: "0px";
  }
}
.ModalDelete {
  border-top-color: #ff606f !important;
}


