@import "../../../../../styles/variables/index.scss";

.containerForm {
    max-height: 41.5rem;
    overflow-y: auto;
    padding-right: 2.8rem;
    padding-top: 3.6rem;
    border-bottom: 1px solid $clara-light-blue;
    padding-left: 2.8rem;
    padding-bottom: 3.8rem;
    min-width: 53.3rem;
}

.textContainer {
    div div div div input {
        height: 3.2308rem;
    }
}

.separator {
    hr {
        height: 0.01rem !important;
    }
}

.containerButtons {
    padding-right: 3rem;
    padding-top: 1.5rem;
}

.title {
    
    text-align: left;
    margin: 0;
    width: 100%;
    margin-bottom: 40px;
    
}

.fileUploader {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    div label {
        position: relative;
        right: 50px;
        top: 4px;
    }
}
