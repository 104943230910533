.buttonCheckBoxSize {
  width: 12rem;
  height: 10rem;
}

.CheckboxButton {
  [data-contentButton="true"] {
    width: 146px;
  }
}

.buttonCheckBox2 {
  width: 12rem;
  height: 9rem;
}

.center {
  justify-content: center;
  align-items: center;
}

.buttonCheckBoxFlag {
  padding-right: 1.5385rem;
  padding-top: 1.5385rem;
  padding-bottom: 1.5385rem;
  .AddStartup {
    padding: 47px 24px 24px 24px !important;
  }
}

.smallSelect {
  button {
    width: 12.3077rem !important;
    padding-left: 1.5385rem !important;
    min-height: 11.8462rem !important;
  }
}

.newButtonJurisdiction {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inactiveTitle {
  font-weight: 800;
  text-align: center;
}

.textComing {
  font-size: 0.9231rem;
}

.ViewCompanyFomation {
  width: 100%;
  height: auto;
  object-fit: contain;
}


.rightContentImage{
  width: calc(100% - 4.9231rem);
  position: relative;
  height: 100%;
  img{
    position: absolute;
    left: 8.4615rem;
    top: 3.0769rem;
  }
}
.cartTitle {
  font-size: 3.6923rem;
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
}

//TechStartupEligibilityFormStep
.tsefsContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
  .tsefsColumns{
    margin-top: 2rem;
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 1fr;
  }
}

.tsefsButtonCheckBoxSize {
  width: 14.5385rem !important;
  height: 9.8462rem;
}

.tsefsCheckboxButton{
  margin-bottom: 1rem;
  display:flex;
  justify-content: center;
  font-weight: 700;
  font-size: 20px;

}

.tsefsbuttons{
  div{
    div{
      justify-content: space-evenly;
    }
  }
}

//ClaraInTouchFormStep
.rightContentImage{
  width: calc(100% - 4.9231rem);
  position: relative;
  height: 100%;
  img{
    position: absolute;
    left: 8.4615rem;
    top: 3.0769rem;
  }
}
.cartTitle {
  font-size: 3.6923rem;
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
}


//TechStartupConciergeFormStep
.tscfsButtonCheckBoxSize{
  width: 24.3077rem !important;
  height: 9.4615rem;
}

//WhatsIsYourStartup

.wiysButtonCheckBoxSize{
  width: 24.3077rem !important;
  height: 13.3077rem;
}

.wiysButtonText{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.wiysList{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}


//ADGMTechStartupAuthorisedSignatory

.adgmtsasButtonCheckBoxSize{
  width: 24.3077rem !important;
  height: auto;
  display: flex;
}


.adgmtsasText{
  text-align: left;
  margin-bottom: 1rem;
}

.adgmtsasCheckboxButton{
  padding-bottom: 1rem;
}

.bottomSpace{
  padding: 0.5rem;
}