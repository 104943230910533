.ZoneGraph {
  width: 98%;
  display: grid;
  grid-template-rows: repeat(2, 20rem);
  grid-template-columns: auto;
  gap: 2rem;
}

.Class {
  width: 100%;
  height: 170px;
  text-align: left;
  display: flex;
  flex-direction: column;
}
.Graph {
  padding-top: 1rem;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 95rem !important;
}
.ClassGraph {
  display: flex;
  align-items: center;
}
.ClassLegend {
  padding-left: 2rem;
  width: 100%;
  height: auto;
  display: flex;
  box-sizing: content-box;
  align-items: center;
  overflow-x: hidden;
  position: relative;
}

.containerZoneLegends {
  width: calc(100% - 470px);
  display: flex;
  align-items: center;
  position: relative;
}

.prevButton {
  position: absolute;
  left: 1rem;
  z-index: 3;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.nextButton {
  position: absolute;
  right: -1rem;
  z-index: 3;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
