.ChatAssistant-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  max-height: 100%;
  box-sizing: content-box;
}

.container-bubbles {
  padding-top: 10px;
  padding-bottom: 10px;
  height: 668px;
  // height: 100%;
  width: 100%;
}
