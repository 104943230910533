html {
  height: 100%;
}


body {
  background: #fff;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  min-height: 100%;
  min-width: 1440px;
  overflow: auto;
  font-family: "cl-regular";
  font-size: 13px;
  text-align: left;
  @media (min-width: 1440px) {
    overflow-x: hidden;
  }

  * {
    vertical-align: top;
  }
  sup{
    vertical-align: baseline;
  }
}
h1 {
  font-size: 24pt;
  color: #192a3e;
  margin: 0 58px;
  position: relative;
  text-align: left;
}

a,
button {
  cursor: pointer;
}
a {
  &:hover {
    color: #2c74ff;
  }
}
button {
  &:disabled {
    opacity: 0.3;
    cursor: no-drop;
  }
}
