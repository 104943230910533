@import "../../../../styles/variables/index";
@import "~./../../../../styles/extends/positions.scss";
.FormAdd {
    @extend .flex-top;
    @extend .flex-column;
    
    align-items: flex-start;
    margin: 0;
    width:100%;
    max-height: 42rem;
    font-weight:normal;
    overflow-x: hidden;
    padding: 2rem 0rem 0 2rem;   
    h1 {
        font-size: 1.3846rem;
        font-weight:normal;
        margin:0.7692rem 0;
        text-align: left;
        width:100%;
    }
    h2 {
        color: $color-label;
        font-weight:normal;
        text-align: left;
        margin:0;
        width:100%;
        font-size:1rem;
    }
    .FormChildren{
        padding: 0 2rem 0 1rem;
        display: inline-flex;
        flex-direction: column;
        width:100%;
        height: auto;
        overflow-y: auto ;
        margin-bottom: 1.5rem;
        

    }
    p {
        color: $color-label;

    }
}
