*{
  box-sizing: border-box!important;
}
/* Mixing */
@mixin transform-translateY($top){
  transform: translateY($top);
  -webkit-transform: translateY($top);
  -ms-transform: translateY($top);
}

/* Box */
  .centerY{
    position: absolute;
    top: 50%;
    @include transform-translateY(-50%);
  }
/* Link */
  /*Mask*/
  a.mask{
    background-color: #a9b0bc;
    display: inline-block;
    &:hover{
      background-color: #2c74ff;
    }
  }
  a.maskBefore{
    display: inline-block;
    &:before{
      background-color: #a9b0bc;
      display: inline-block;
    }
    &:hover{
      &:before{
        background-color: #2c74ff;
      }
    }
  }
  a.maskAfter{
    display: inline-block;
    &:after{
      display: inline-block;
      background-color: #a9b0bc;
      -webkit-transition: transform 0.5s; 
      transition: transform 0.5s;
    }
    &:hover{
      &:after{
        background-color: #2c74ff;
      }
    }
  }
  