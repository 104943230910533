@import "./../../../../styles/variables/colors.scss";

.tagsOnly {
  width: 7.3077rem;
  height: 24px;
  border-radius: 12px;
  color: white;
  padding: 0.3rem 0.5rem;
  background: #E96199;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9231rem;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  span {
    padding-left: 0.3rem;
    color: $clara-white;
  }
}

.tagsOnlyText {
  justify-content: center;
  align-items: center;
  width: 7.3077rem;
  height: 1.8462rem;
  border-radius: 12px;
  color: white;
  font-size: 0.9231rem;
  padding: 0.3rem 0.5rem;
  text-align: center;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  display: flex;
  span {
    padding-left: 0rem;
    color: $clara-white;
  }
}
