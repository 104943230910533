.IconComment{
    width: auto;
    height: auto;
    display: inline-block;
    position: relative;
    i{
        font-size: 18px;
        margin-right:10px;
    }
    label{
        
        font-size: 14px;
        width: 20px;
        text-align: left;
        display: inline-block;
        line-height: 19px;
    }
    span{
        background: #FF606F;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        display: inline-block;
        position: absolute;
        left: 12px;
        top: -1px;
    }
}