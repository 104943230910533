@import "../../../styles/variables/colors.scss";

.fieldContainer {
    display: inline-flex;
    margin-top: 20px;
    .fieldLabel {
        min-width: 105px;
        font-family: "cl-regular";
        font-weight: 400;
        font-size: 14px;
        color: $clara-dark-grey;
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 15px;
    }
    .inputContainer {
        width: 380px;
        height: 40px;
        margin-left: 15px !important;
    }
}