@import "../../../../../../../styles/variables/index.scss";

.ModalDecline {
    padding: 34px;
    width: 48rem;
    h1 {
        
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 110.93%;
        color: #192a3e;
        margin: 0 0 10px;
    }
    .botonera {
        display: flex;
        align-content: flex-end;
        justify-content: flex-end;
        margin: 20px 0 0 0;
        button {
            margin-left: 20px;
        }
    }
    .TableDecline {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        div {
            margin-top: 0 !important;
        }
        div.botonera {
            margin-top: 20px !important;
        }
        textarea {
            height: 200px !important;
        }
    }
}

.titlePaymentOptions {
  color: $clara-blue !important;
}

.containerPaymentMethod {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.containerOnline {
  margin-top: 1.5rem;
  padding-right: 0.5rem;
  padding-left: 1rem;
  border-left: 3px solid $clara-blue;
}

.containerBankTransfer {
  padding-right: 0.5rem;
}
