@import "../../../../../../../styles/variables/index.scss";

.modalBankTransfer {
  padding: 34px;
  width: 50rem;
  height: auto;
  z-index: 199999; // I dont like this setting

  h1 {
        
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 110.93%;
        color: #192a3e;
        margin: 0 0 10px;
    }
    .botonera {
        display: flex;
        align-content: flex-end;
        justify-content: flex-end;
        margin: 20px 0 0 0;
        button {
            margin-left: 20px;
        }
    }
}

.modalTitle {
  color: $clara-blue !important;
}

.containerInvoice {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.containerInvoiceText {
  margin-top: 1.5rem;
  padding-right: 0.5rem;
  padding-left: 1rem;
  border-left: 3px solid $clara-blue;
}

.containerBankTransfer {
  padding-right: 0.5rem;
}

.containerAccountInfo {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.containerCurrencyPayment {
  display: flex;
  flex-direction: column;

  h4 {
    font-size: 12px;
  }

  label {
    font-size: 12px;
    color: $clara-dark-grey;
    width: 8.5rem;
  }

  p {
    font-size: 12px;
  }
}

.containerTable {
  display: inline-flex;
  margin-bottom: 0.6154rem;
}
