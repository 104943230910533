@import "../../../../../styles/";

$width-modal-container: 61.5385rem;
$width-modal-assistant: 24.9231rem;
$border-radius-modal: 5px !default;
$border-with-modal: 0.5rem !default;
$max-height-modal: 51.9rem !default;

.MuiDialog-paperWidthMd {
    background: transparent !important;
    display: grid !important;
    grid-template-columns: $width-modal-container $width-modal-assistant;
    box-sizing: content-box !important;
    column-gap: 1rem;
    max-width: $width-modal-container + $width-modal-assistant + 1rem !important;
    height: $max-height-modal !important;
}

.MuiDialog-paperWidthMdFull {
    background: transparent !important;
    display: grid !important;
    grid-template-columns: $width-modal-container + $width-modal-assistant + 1rem !important;
    box-sizing: content-box !important;
    column-gap: 0rem;
    max-width: $width-modal-container + $width-modal-assistant + 1rem !important;
    height: $max-height-modal !important;
}

.MuiDialog-paperWidthLgFull {
    background: transparent !important;
    display: grid !important;
    grid-template-columns: $width-modal-container + $width-modal-assistant + 6rem !important;
    box-sizing: content-box !important;
    column-gap: 0rem;
    max-width: $width-modal-container + $width-modal-assistant + 6rem !important;
    height: $max-height-modal !important;
}

.MuiDialog-paper {
    overflow: hidden !important;
    border-top: hidden !important;
}

.container-modal {
    background: #fff;
    border-top: $border-with-modal solid $clara-blue;
    border-radius: $border-radius-modal;
    height: 100%;
    display: grid;
    grid-template-rows: auto;
    overflow: hidden;
    form{
        overflow: hidden;
    }
}

.container-modal-pay {
    background: #fff;
    border-top: $border-with-modal solid $clara-red;
    border-radius: $border-radius-modal;
    height: 100%;
    display: grid;
    grid-template-rows: auto;
    overflow: hidden;
    form{
        overflow: hidden;
    }
}

.Container-assistant {
    background: #eaf1fe;
    border-top: $border-with-modal solid $clara-academy;
    border-radius: $border-radius-modal;
    // padding-top: 10px;
    // padding-bottom: 10px;
    padding-left: 4px;
    padding-right: 0px;
    box-sizing: border-box;
    height: 100%;
}

.containerButtons {
    display: grid;
    flex-direction: row;
    height: 80px;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 2.6rem;
    padding-right: 3.6rem;
    width: 100%;
    grid-template-columns: auto 9.2308rem 11.0769rem;
    border-top: 1px solid $color-border-line;

    > button {
        width: 9.2308rem;
    }

    button:last-child {
        justify-self: flex-end;
    }
}
