.ModalLoading {
    width: 100%;
    padding: 30px;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: auto auto;
    column-gap: 30px;
    min-width:300px;
    :first-child{
        grid-row: 1/3;
        
    }
}

