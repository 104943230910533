.buttonCheckBox{
  min-width: 12rem;
  min-height:10rem;
}

.questionFont {
  font-size: 1.2308rem;
  font-weight: 400;
}

.fileLoad {
  span > div > div > div{
    width: 33.6923rem !important;
  }
}