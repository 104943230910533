
.cancel{
    background: #FFFFFF;
    border-radius: 4px;
    
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    height: 41px;
    width: 110px;
    display: inline-block;
    align-items: center;
    text-align: center;
    letter-spacing: 0.01em;
    color: #A9B0BC;
    display: 'inline-flex';
    cursor: pointer;
}
.save{
    background: #2C74FF;
    border-radius: 4px;
    
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    height: 41px;
    width: 110px;
    display: inline-block;
    align-items: center;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    display: 'inline-flex';
    margin-right:17px;
    cursor: pointer;
    //background: #FFFFFF;
  
}
.containerBotonera{
    background: #FFFFFF;
    box-shadow: 0px -10px 20px rgba(190, 192, 197, 0.35);
    border-radius: 4px;
    //transform: rotate(-180deg);
    border-radius: 4px;
}
.containerBotoneraUpload{
  background: #FFFFFF;
  box-shadow: 0px -10px 20px rgba(190, 192, 197, 0.35);
  display: inline-flex;
  position: absolute;
  width: 577px;
  bottom: 0px;
  left: 0px;
  border: unset;
  border-radius: 0;
  padding: 20px;
  height: auto;
  box-sizing: border-box;
}

.filterIcon {
    display: inline-block;
    margin: 0;
    padding: 0;
    width: 20px;
    margin-left: 20px;
    i {
      cursor: pointer;
      background-color: #a9b0bc;
      display: inline-block;
      -webkit-mask-size: 19px 14px;
      width: 19px;
      height: 14px;
      -webkit-mask: url(../../../images/filterDot.svg) no-repeat center;
      display: inline-block;
      &:hover {
        background-color: #2c74ff;
      }
    }
  }
  .tripleDot{
    //todo a la derecha
    position: absolute;
    right: 20px;
  }