.ClaraScoreForm {
  width: 350px;
  height: auto;
  margin: 0;
  box-sizing: border-box;
  &>div{
    padding: 0 ;
    margin: 0;
    form{
      padding: 0 20px;
    }
  }
}
.BotoneraContainer{
  background: #FFFFFF;
  display: inline-block;
  position: relative;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  border-top:1px solid #E4E3E9;
  padding:20px 0;
}