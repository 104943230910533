.AuditLogs {
  width: 100%;
  height: 100%;
}
.ZoneMenu {
  background: #fff;
  margin-top: 55px;
  padding-right: 65px;
  height: 100%;
  text-align: left;
  // border-right: 1px solid #e4e3e9;
  overflow: auto;
  height: 100%;

  font-style: normal;
  font-weight: normal;
  color: #192a3e;
  
  h1 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 0;
    color: #2c74ff;
  }
  h2 {
    font-size: 16px;
    margin: 0 0 20px 0;
    padding: 0;
  }
  p {
    font-family: 'cl-regular';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    margin-bottom: 1.3077rem;
    text-align: left;
  }
}
.ZoneTimeLine {
  width: 100%;
  background: #fff !important;
  box-shadow: unset;
  padding-top: 117px;
  padding-right: 158px;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  text-align: left;
  .InfiniteScroll::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .InfiniteScroll {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .InfiniteScroll {
    height: 100%;
    width: auto;
    overflow-y: auto;
    overflow-x: hidden;
    span {
      margin-left: 20px;
      margin-top: 8px;
      display: inline-block;
    }
  }
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 120%;
    margin: 0 0 20px 0;
    padding: 0;
    color: #192a3e;
  }
}

.icon {
  width: 3.0769rem;
  height: 3.0769rem;
  border: 1px solid #e4e3e9;
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  display: flex;  
  cursor: pointer;
  &:before {
    content: " ";
    cursor: pointer;
    background-color: #2c74ff;
    display: inline-block;
    -webkit-mask-size: 3px 13px;
    width: 100%;
    height: 100%;
    align-content: center;
    justify-content: center;
    -webkit-mask: url(../../../../../images/back.svg) no-repeat center;
  }
  &:hover {
    &:before {
      background-color: #2c74ff;
    }
  }
}

.AuditLogs2{
  width: 100%;
  height: 85%;
  gap: 23px;
  display: grid;
  grid-template-columns: 196px 351px auto;
  box-sizing: border-box;
  >div{
    align-content: flex-start;
    justify-content: flex-start;
  }
}

.arrowContainer{
  padding-left: 130px;
  margin-top: 55px;
}