@import "../../../styles/variables/index";
@import "~./../../../styles/extends/positions.scss";

.ImageUpload {
  width: 2.6923rem;
  height: 2.6923rem;
  position: relative;
}

.ContentCircularButton {
  position: absolute;
  // right: -0.5rem;
  // top: -0.5rem;
  // background: #fff;
  // border-radius: 50%;
  // padding: 0.1538rem;
  // width: auto;
  // height: auto;
  // margin: 0 !important;
  // > div {
  //   width: auto !important;
  //   height: auto !important;
  //   margin: 0 !important;
  //   border: unset !important;
  //   padding: 0 !important;
  // }
  width: 100%;
  height: 100%;
  // display: grid;
  // grid-template-columns: 3.3rem 1fr;
  // column-gap: 1.2rem;
  row-gap: 0;
  position: relative;
  & > div:first-child {
    width: 3.3rem;
    height: 3.3rem;
  }
  // grid-template-columns: 3.3rem 1fr;
}

.CircularButton {
  font-style: normal !important;
  font-weight: 600 !important;
  color: $palette-clara !important;
  background: $bg-secondary !important;
  border-radius: 50% !important;
  border: 0.0769rem solid $palette-clara !important;
  min-width: 1rem !important;
  width: 1rem!important;
  height: 1rem !important;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 1rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  &:hover {
    background: $bg-primary-hover !important;
    color: $clara-white !important;
    opacity: 0.8 !important;
  }
  &:disabled {
    background: $clara-white !important;
    color: $clara-dark-grey !important;
    border-color: $clara-dark-grey !important;
    opacity: 0.3 !important;
    &.loading {
      background: $bg-secondary !important;
      opacity: 1 !important;
      &:hover {
        background: $bg-primary !important;
        opacity: 1 !important;
        color: $clara-white !important;
      }
    }
    &:active {
      background: $clara-dark-blue !important;
      color: $bg-secondary !important;
      opacity: 1 !important;
      &:hover {
        background: $clara-dark-blue !important;
        color: $clara-white !important;
        opacity: 1 !important;
      }
    }
  }
}

.Avatar {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
  > div {
    width: 35px;
    height: 35px;
  }
  span {
    font-size: 1rem !important;
    width: 100% !important;
    height: 35px;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}

.ModalUpload {
  align-items: flex-start;
  margin: 0;
  width: 100%;
  font-weight: normal;
  background: transparent;
  padding: 0 3.1rem 1.9rem 3.1rem;
  min-width: 47.1rem;
}

.CircularButtonBadge {
  // border: 0.0769rem solid $palette-clara !important;
  min-width: 1.6923rem !important;
  min-height: 1.6923rem !important;
  width: 1.6923rem !important;
  height: 1.6923rem !important;

  position: absolute;
  top: -50px;
  left: -8px;
}
