$Clara-Light-Blue: #eaf1fe;
$color-blue-clara: #2c74ff;
$Clara-Light-red: #ff606f;
$Clara-red: #FF7E8B;
.progressContainer {
    background: $Clara-Light-Blue;
    height: 3.0769rem;
}

.container {
    display: flex;
    width: 100%;
    position: relative;
    border-radius: 0.3846rem;
    border: 1px solid $Clara-Light-Blue;
    margin-bottom: 1rem;
}

.loadingContainer {
    width: 100%;
    position: absolute;
    left: 0px;
    padding-top: 0.7692rem;
    padding-bottom: 0.7692rem;
    padding-left: 1.0769rem;
    padding-right: 1.0769rem;
    height: 3.0769rem;
    color: $color-blue-clara;
    display: inline-flex;
    align-items: center;
    font-size: 0.7692rem;
}
.loading {
    color: $color-blue-clara !important;
}
.nameFile {
    margin: 0 1.2308rem 0 1rem;
    width: 11.3846rem;
}

.button {
    padding: 0px !important;
    color: $color-blue-clara !important;
    font-weight: bold !important;
    text-transform: none !important;
    min-width: 10px !important;
    font-size: 0.7692rem !important;

}

.errorUploadedContainer {
    background: rgba($Clara-red, 0.15);
    color: $Clara-Light-red;
    position: relative;

}
