@import "./../../../../styles/variables/colors.scss";

.Table {
  thead {
    tr {
      height: 1.8462rem;
      margin: 1.3846rem 0;
    }
    tr:first-child {
      display: none;
    }
  }
}

.tableHeader {
  font-size: 0.9231rem;
  font-weight: 400;
  color: $clara-dark-grey !important;
}

.headerColumnTable {
  padding: 0.75rem 0;
  color: $clara-dark-grey;
  font-size: 0.9231rem;
  font-weight: 400;
  padding-bottom: 1.9rem;
  padding-top: 0;
}

.viewContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;  
}