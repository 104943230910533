.MissingInformation {
  width: 100%;
  margin: auto;
  padding: 230px 20px 20px 20px;
  box-sizing: content-box;
  height: 100%;
  background: #FFF;
}

.Icon {
  margin-left: 65px;
}

.Title {
  font-size: 24px;
  color: darkgray;
  font-weight: bold;
}

.SubTitle {
  font-size: 15px;
  color: gray;
}

