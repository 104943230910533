.form{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content:flex-start;
    margin-bottom: 2rem;
}

.container{
    padding: 1.1rem 3.846153846rem 1rem 2.846153846rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    grid-gap: 2rem;
    column-gap: 2rem;
    width: 53.2315rem !important;
}

.header{
    margin-top: 1rem;
}

.buttons{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    grid-gap: 1rem;
}

h2 {
    font-weight:300;
}