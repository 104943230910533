.ClaraInputText {
  width: 100%;
  display: block;
}

.label {
  font-size: 13px;
  max-width: 90%;
  text-align: left;
  font-weight: bold;
  img {
    margin-left: 7px;
    margin-top: -3px;
  }
}

.input {
  font-size: 13px;
  width: 100%;
  height: 57px;
  padding: 20px;
  border: 1px solid #c2cfe0;
  border-radius: 5px;
  box-sizing: border-box;
  margin: 20px 0;
  outline: none;

  &:focus {
    border-color: #2c74ff;
  }
}

.tooltipPlacementRight {
  background: #ffffff !important;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;

  font-style: normal !important;
  font-weight: normal !important;
  font-size: 13px !important;
  line-height: 17px !important;
  color: #848f99 !important;
  position: relative;

  &:before {
    content: "▲";
    width: 0;
    height: 0;
    color: #fff;
    position: absolute;
    left: -12px;
    top: 75%;
    transform: rotate(270deg) translateX(-50%);
    text-shadow: -1px -2px 4px rgba(0, 0, 0, 0.25);
  }
}
.Select {
  div {
    margin: 3px 0 0 0 !important;

    label {
      width: 262px !important;
      margin-right: 20px !important;
      display: inline-block !important;
    }
  }
  &.add {
    div label {
      width: 296px !important;
    }
  }
  &.chatSelector {
    div {
      span {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  &.addKyc {
    div {
      label {
        width: 174px !important;
      }
      span:nth-last-child(2) {
        width: 380px;
      }
    }
  }
  &.addKycCompany {
    div {
      label {
        width: 200px !important;
      }
      span:nth-last-child(2) {
        width: 380px;
      }
    }
  }

}

.Input {
  div {
    margin: 3px 0 0 0 !important;
    label {
      width: 260px !important;
      margin-right: 20px !important;
      display: inline-block !important;
    }
  }
  &.addKyc {
    div {
      label {
        width: 174px !important;
      }
    }
  }
  &.chatSelector {
    div {
      left: 70px !important;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &.otherJurisdiction {
    display: flex;
    left: auto !important;
    width: 100% !important;
    justify-content: center;
    align-items: flex-start;
    margin: 3px 0 0 0 !important;
    padding: 0 !important;
    div div label{
      display: none!important;
    }
    div {
      display: flex!important;
      left: auto !important;
      width: 100% !important;
      justify-content: center;
      align-items: flex-start;
      margin: 0 !important;
      padding: 0 !important;
    }
  }
}

.ClaraCountrySelectorWizard {
  div {
    margin: 3px 0 0 0 !important;

    label {
      width: 296px !important;
      margin-right: 20px !important;
      display: inline-block !important;
    }
  }
}

.ClaraCountrySelectorModal {
  div {
    margin: 3px 0 0 0 !important;

    label {
      width: 249px !important;
      margin-right: 20px !important;
      display: inline-block !important;
    }
  }
  > div {
    margin-top: 1.5rem !important;
  }

  > div > div {
    width: 100% !important;
  }
}

