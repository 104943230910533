.SessionManagerContentPage{
  width: 100%;
  height: 100%;
}
.SessionManager {
  z-index: 100000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .7);

  .SessionManagerModal {
    background: #ffffff;
    border-radius: 4px;
    width: 559px;
    margin: 155px auto 0;
    position: relative;
    display: block;
    padding: 59px;
    text-align: left;
    z-index: 3;

    .Buttons {
      display: flex;
      justify-content: flex-end;

      button {
        background: blue;
        border: 1px solid #eaf1fe;
        box-sizing: border-box;
        border-radius: 4px;

        
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.01em;
        padding: 16px;
        min-width: 120px;
        margin-right: 10px;
        color: white;
      }

      .LogOutButton {
        color: gray;
        background: white;
      }
    }

    h1 {
      
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 120%;
      color: #192a3e;
      margin: 0 0 13px 0;
      padding: 0;
      text-align: left;
    }
    p {
      
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 167.58%;
      color: #65707e;
      text-align: left;
      margin: 0 0 30px 0;
      padding: 0;

      span {
        color: red;
        font-weight: bold;
      }
    }
  }
}
