@import "../../../../../styles/variables/index.scss";

$border-radius-modal: 5px !default;
$border-with-modal: 0.5rem !default;
$max-height-modal: 51.9rem !default;
$height-modal-tour: 51.9rem !default;
$size-with: calc(86.9231rem + 3.0769rem);

.container-tour {
  border-top: $border-with-modal solid $clara-blue;
  border-radius: $border-radius-modal;
  box-sizing: content-box;
  max-width: 86.9231rem;
  background: #fff;
  box-shadow: $box-shadow !important;
  height: $height-modal-tour;
}

.container-buttons-modal-tour {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 2.4rem;
  padding-right: 3.6rem;
  padding-top: 0.2308rem;
  height: 6.1538rem;
  border-top: 1px solid #eaf1fe;
  // overflow: hidden;container-blocks-modal-tour
  .container-steps-dots {
    max-height: -webkit-max-content !important;
    max-height: -moz-max-content !important;
    max-height: max-content !important;
    
  }
}

.container-blocks-modal-tour {
  height: $height-modal-tour - 0.9rem - 2.6rem - 2.6rem - $border-with-modal;
  box-sizing: border-box !important;
  width: 86.9231rem;
  display: grid;
  grid-template-columns: 1fr 572px;
  grid-template-rows: 100%;
  overflow: visible;
  &.same{
    grid-template-columns: 1fr 1fr;
  }
}


.leftContentBlockModalTour {
  padding-top: 6.6rem;
  padding-left: 4.9rem;
  padding-bottom: 1.2rem;
  padding-right: 2.4rem;
  height: 100%;
  box-sizing: border-box;
  align-items: center;

  
}


.right-content-block-modal-tour {
  box-sizing: border-box;
  padding-top: 4.2rem;
  padding-bottom: 1.2rem;
  padding-left: 2.4rem;
  padding-right: 3.6rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  align-items: flex-start;
  overflow: visible;
}

.container-RightContentOnboarding-card {
  padding-right: calc(6.6rem);
  > button {
    margin-bottom: 1rem;
  }
}

.container-RightContentOnboarding-card-top {
  display: flex;
  flex-direction: row;
  padding-top: 94px;
  padding-right: calc(6.6rem);
  > button {
    margin-bottom: 1rem;
  }
}
$step-dot-size: 0.9rem;
$step-dot-disable-color: #D5E4FF; 
$step-dot-enable-color: #EAF1FE;

.container-step-dots {
    display: inline-flex;
    button {
        margin-left: 0.9rem;
    }
    button:first-child {
        margin-left: 0px !important;
    }
}

.step-dot {
    padding: 0;
    width: $step-dot-size;
    height: $step-dot-size;
    background-color: $step-dot-enable-color;
    border-radius: 50%;
    border: none;

    &:disabled {
        background: $step-dot-disable-color;
        cursor: not-allowed;
        opacity: 0.3;
    }
}

.step-dot-selected {
    background-color: $clara-blue;
}
$step-dot-size: 0.9rem;
$step-dot-disable-color: #D5E4FF; 
$step-dot-enable-color: #EAF1FE;

.container-step-dots {
    display: inline-flex;
    button {
        margin-left: 0.9rem;
    }
    button:first-child {
        margin-left: 0px !important;
    }
}

.step-dot {
    padding: 0;
    width: $step-dot-size;
    height: $step-dot-size;
    background-color: $step-dot-enable-color;
    border-radius: 50%;
    border: none;

    &:disabled {
        background: $step-dot-disable-color;
        cursor: not-allowed;
        opacity: 0.3;
    }
}

.step-dot-selected {
    background-color: $clara-blue;
}
.container-steps-dots-button {
  
  button{
    cursor: auto!important;
  }
}