.DropDownMenu {
  float: right;
  height: 100%;
  
  div {
    z-index: 25;
  }
  button {
    height: 100%;
    min-width: 115px;
    img {
      margin: 0;
      height: 32px;
      border-radius: 50%;
    }
    div {
      span {
        margin-left: 0;
      }
    }
    span {
      margin-left: 10px;
    }
  }
}
.menuList {
  margin-right: 5px;
  min-width: 150px;
  z-index: 10;
  img {
    margin: 0 10px 0 0;
  }
  label {
    margin-left: 40px;
  }
}
.Avatar {
  border-radius: 5px;
}

.DropDownMenuServiceAgentContainer {
  height: 100%;
  display: inline-block;
  position: absolute;
  right: 25px;
  top: 18px;
}
