@import "../../../styles/variables/index.scss";
.container {
    border: 1px $clara-blue dashed;
    border-radius: 15px;
    width: 23rem;
    height: 15.5rem;
    padding: 3.6rem 2.4rem 2.5rem 1.9rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div svg {
        color: $clara-blue;
    }
}
.containerError{
    border: 1px $clara-red dashed ;
}
.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    span {
        text-align: center;
        font-family: 'cl-regular';
        font-size: 16px;
        font-weight: 300;
        padding-top: 10px;
        color: $clara-dark-grey;
        margin-bottom: 5px;
    }
}

.browseButton{
    color: $clara-blue !important;
    cursor: pointer;
}

.hiddenContainer{
    visibility: hidden;
    height: 1px;
    width: 1px;
    position: absolute;
}
.MessageError {
    display: block;
    width: 23rem;
    margin-top: 0.2rem;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    text-align: right;
    color: $clara-red;
}