@import "../../../../../styles/variables/index.scss";

.TemplateLabelColumn {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 16.4615rem 1fr;
  grid-column-gap: 1.5385rem;
  > * {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: auto;
  }
  > label {
  }
  > div {
  }
  &.top {
    > label {
      align-items: flex-start;
    }
  }
  &.banner {
    > label {
      align-items: flex-start;
      margin-top: 12px;
    }
  }
}

.TemplateLabelRow {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-row: 16.4615rem 1fr;
  grid-row-gap: 1.5385rem;
  > * {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: auto;
  }
  > label {
  }
  > div {
  }
  &.top {
    > label {
      align-items: flex-start;
    }
  }
  &.banner {
    > label {
      align-items: flex-start;
      margin-top: 12px;
    }
  }
}

.required {
  color: $clara-red;
  margin-left: 0.5rem;
}

.isColumn {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.multilineLabel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.multilineLabelCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.multilineLabelCenterBlack {
  color: $clara-black !important;
}
