.containerHeader{
    width: 800px;
    display: inline-flex;
    flex-direction: column ;

}
.title{
    
    font-weight: 400;
    line-height : 30px;
    font-size: 30px;
    

}

.containerUsers{

    display:inline-flex;
    flex-direction: row ;
    
    margin-top: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    font-size: 14px;

    p:nth-child( 2 ){
        border-right: solid 1px #848f99;
        min-width: 120px;
        text-align: start;
        
    }

    label{
        display: inline-block;
        color: #848f99;       
    }


    p{
        color: #2c74ff;
        margin-right: 20px;
        margin-left: 5px;

    }
}