@import "../../../../../styles/variables/index.scss";

.containerLabelText {
    margin-bottom: 1.6rem;
    label {
        color: $color-primary;
    }
    span {
        color: $clara-dark-grey;
    }

    font-weight: 400;

    width: 100%;
    display: grid;
    grid-template-columns: 9.0769rem auto;
}

.containerCategories {
    background: #fff;
    margin-bottom: 2.5rem;
}

.containerDates {
    display: inline-flex;
    width: 100%;
    margin: 0.25rem 0;
}

.containerPanel {
    padding-right: 1.5rem;
}

.rootTabs {
    border-bottom: 1px solid $color-border-line;
}

.tabElement {
    padding-bottom: 0.5rem !important;
    margin-right: 1rem !important;
}
.containerTabsMenu {
    margin-top: 2rem;
}

.containerTab {
   
    height: 49vh;

    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 1rem;
    @media (max-height: 768px) {
     
        height: 30vh;
        overflow-y: auto;
        overflow-x: hidden;

    }
   
}
