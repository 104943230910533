.ClaraInputRadioButton {
  width: 100%;
  display: block;
  margin-bottom: 20px;

  button {
    border: 1px solid #a9b0bc;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    cursor: pointer;
    margin: 12px 5px 12px 0;
    line-height: 0;
    padding: 0;
    background: #fff;
    &:focus{
      border-color: #2c74ff;
    }
    &:before {
      content: ' ';
      //background: #2c74ff;
      background: transparent;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      margin: 3px;
    }
  }
}

.SelectedBLUE {
  &:before {
    background: #2c74ff !important;
  }

}

.CircleRED {
  background: #ff606f;
}

.CircleGREEN {
  background: #71e096;
}

.CircleAMBER {
  background: #fbbd2c;
}

.RadioButton {
  width: auto;
  padding: 10px;
  box-sizing: border-box;
  display: inline-block;

  input {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  label {
    width: auto !important;
    padding: 10px;
    box-sizing: border-box;
    display: inline-block;
  }
}
.MessageError {
  display: block;
  width: 100%;
  
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  text-align: right;
  color: #ff606f;
  max-width: 380px;
}