@import "./../../../../styles/variables/colors.scss";
.Contet2Row {
    width: 100%;
    display: grid;
    grid-template-columns: 12rem 1fr;
    gap: 1.5385rem;
    margin-bottom: 1.1538rem;
    color: $clara-black;
    :first-child {
        color: $clara-dark-grey;
    }
}
.containerAvatar {
    label {
        font-weight: 900;
        color: $clara-black !important;
    }
}
.containerActivity {
    color: $clara-black;
}
.Table {
    width: 100%;
    font-family: "cl-regular";
    font-weight: 400;
    font-size: 12px;
    
    thead tr:first-child {
        display: none !important;
    }
    thead tr:last-child {
        height: auto !important;
        th {
            font-family: "cl-regular";
            font-weight: 400;
            font-size: 12px;
            
        }
    }
    td {
        vertical-align: middle;
    }
    th {
        height: 2.3846rem;
    }
    tr:first-child td {
        border-top: unset;
    }
}

.stateCell {
    span {
        text-transform: capitalize;
    }
    svg{
        color: $clara-blue;
        margin-left: 1.875rem;
        cursor: pointer;
    }
}

.circle {
    -webkit-mask: url(../../../../images/ellipse.svg) no-repeat center !important;
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-right: 5px;
    position: relative;
    top: 2px;
}

.DEAUTHORISED {
    background-color: #ff606f !important;
    box-sizing: border-box;
}

.AUTHORISED {
    background: #71e096;
}


.containerMenuOptionState{
    padding: 0.75rem;
}

.containerOption {
    padding: 0.5rem;
    cursor: pointer;
    color: $clara-black;
    font-size: 0.9231rem;
    font-weight: 400;
    &:hover{
        background: $clara-light-blue;
    }
}

.dropdownContainer{
    display: flex;
    justify-content: space-between;
    width: 140px;
}