@import "../../../../styles/variables/index";
.LinkButton{
    width: auto;
    height: auto;
    text-align: left;
    color: $palette-clara;
    display: inline-block;
    background: transparent;
    border: unset;
    &:hover{
        color: $color-link;
    }
}