@import "./../../../../styles/variables/colors.scss";

.Bubble {
  background-color: $clara-white;
  border-radius: 1.8462rem 1.8462rem 1.8462rem 0;
  padding: 1.1538rem 1.4615rem 1.2308rem 1.1538rem;
  display: flex;
  line-height: 1.34;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  .Content {
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
    color: $color-primary;
    p:last-child {
      margin-bottom: unset;
    }
    p:first-child {
      margin-top: unset;
    }
  }
}

.disabled {
  border: 0.063rem solid $border-disabled;
  .Content {
    color: $clara-dark-grey;
  }
}

.academy {
  // border: 0.063rem solid $clara-academy;
  .Content {
    color: $clara-dark-grey;
  }
}

.chat {

  background-color: $clara-chat;
  .Content {
    color: $clara-white;
  }
}

.success {
  // border: 0.063rem solid $clara-green;
  .Content {
    color: $clara-green;
  }
}

.pay {
  border: 1px solid $clara-red;
  .Content {
    color: $clara-pay;
  }
}

.error {
  border: 0.063rem solid $clara-red;
  .Content {
    color: $clara-black;
  }
}

.containImage {
  padding: 0 !important;
}

.headerAnimation {
  height: 12.4rem;
  background-color: $clara-white;
  border-radius: 1.8462rem 1.8462rem 0 0;
  overflow: hidden;
}

.headerPercent {
  height: 12.4rem;
  background-color: $clara-percent-bg;
  border-radius: 1.8462rem 1.8462rem 0 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgText {
  padding: 1.1538rem 1.4615rem 1.2308rem 1.1538rem;
}

.linkSpace {
  margin-top: 1.1538rem;
}

.singleChart {
  width: 60%;
  justify-content: space-around ;
}

.circularChart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}

.circularChart .circle {
  stroke: $clara-percent-circle;
}

.circleBg {
  fill: none;
  stroke: $clara-white;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.isNext{
  border: 1px solid $clara-academy;
}

.percentage {
  fill: $clara-percent-circle;
  font-size: 0.5em;
  text-anchor: middle;
}

.imgForming {
  position: absolute;
}

.close {
  width: .8654rem;
  height: .8654rem;
  position: relative;
  right: 14px;
  top: 12px;
}