.containerZoneMessagesPanel {
  width: 100%;
  height: 100%;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.containerNotMessages {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  span {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
  }
  label {
    margin-top: 0.7692rem;
    color: #828c98;
    font-size: 1rem;
  }
}
