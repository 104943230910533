.buttonCheckBoxSize {
  width: 12rem;
  height: 10rem;
}

.CheckboxButton {
  [data-contentButton="true"] {
    width: 146px;
  }
}

.buttonCheckBox2 {
  width: 12rem;
  height: 9rem;
}

.center {
  justify-content: center;
  align-items: center;
}

.buttonCheckBoxFlag {
  padding-right: 1.5385rem;
  padding-top: 1.5385rem;
  padding-bottom: 1.5385rem;
  .AddStartup {
    padding: 47px 24px 24px 24px !important;
  }
}

.smallSelect {
  button {
    width: 12.3077rem !important;
    padding-left: 1.5385rem !important;
    min-height: 11.8462rem !important;
  }
}

.newButtonJurisdiction {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inactiveTitle {
  font-weight: 800;
  text-align: center;
}

.textComing {
  font-size: 0.9231rem;
}

.ViewCompanyFomation {
  width: 100%;
  height: auto;
  object-fit: contain;
}
