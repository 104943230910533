.containerData {
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: 15px;
    text-align: left;
    justify-content: space-between;
}

.OptionButtonZone {
    margin-top: 0.8rem;
    width: 34.5%;
    display: inline-block;
    position: relative;
    left: 5rem;

    button {
        width: 180px;
        margin: 5px 0px 5px 0px;
        overflow: hidden;
        padding: 4px;
    }
}

.labelOptionButton {
    
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    position: relative;
    top: 2rem;
    text-align: left;
    color: #65707e;
}
.containerButton {
    justify-content: flex-start !important ;

    label {
        width: 185px;
    }
}

.containerButtons {
    justify-self: end;
    display: inline-flex;
    width: max-content;
    margin-top: 20px;

    button:first-child {
        background: #fff;
        color: #2c74ff;
        margin-right: 15px;
        border: solid 1px #e5e5e5;
    }
}

.fileUpload {
    display: inline-grid;
    justify-content: space-between;
    grid-template-columns: 192px 385px;
    justify-items: end;
    margin: 1rem 0 1.2rem 0;

    label {
        max-width: 200px;
        line-height: 16px;
        text-align: left;
        padding-left: 10px;
        padding-right: 15px;
    }
}
.containerButtonsOptions {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    > button {
        margin-right: 3rem !important;
    }
    > label {
        
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        text-align: left;
        color: #65707e;
    }
}

.ChatInputTextarea {
    width: 100% !important;
    > div {
        display: inline-flex;
        justify-content: space-between;
        width: 100% !important;
    }
    label {
        line-height: 1rem !important;
        max-width: 200px !important;
        align-self: flex-start;
    }
}

.showOptionAssetsHeld {
    width: 100%;
}
.contentField {
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 25px;
    justify-items: flex-start;
    width: 580px;
    > div {
        width: 100%;
    }

    label {
        margin: 10px 0;
        border: 1px solid #fff;
        padding: 0;
        
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        display: inline-block;
        text-align: left;
        color: #65707e;

        width: 210px;
    }
}

.horizontalOptionsButton {
    flex-wrap: nowrap !important;
    margin: 0 !important;
    justify-content: flex-start !important ;

    button {
        min-width: 125px !important;
        margin-left: 0.3rem !important;
        margin-right: 0.3rem !important;
    }
}

.buttonOption {
    width: auto;
    padding: 0 1rem;
    margin: 10px 0;
    height: 40px;
    
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #2c74ff;
    border-radius: 4px;
    border: 1px solid #2c74ff;
    background: #fff;
    display: block;
    &.buttonOptionSelected,
    &:hover {
        color: #fff !important;
        border: 1px solid #2c74ff !important;
        background: #2c74ff !important;
    }
    &.buttonWithAutomatic {
        width: auto !important;
        padding: 0 15px;
    }
    &.buttonAdd {
        width: 300px;
    }
}

.ContentAvatar {
    width: 80px !important;
    margin: 20px 5px;
}
.assetBlock {
    height: 42px;
    display: inline-flex;
    label {
        margin: 10px 0;
        border: 1px solid #fff;
        padding: 0;
        
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        display: inline-block;
        text-align: left;
        color: #65707e;
        width: 210px;
    }
}
.intellectualPropBlock {
    background: #2c74ff;
    border: 1px solid #2c74ff;
    box-sizing: border-box;
    border-radius: 4px;
    height: 40px;
    position: relative;
    width: 336px !important;
    p {
        width: auto;
        height: 17px;
        
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 40px;
        align-items: center;
        text-align: center;
        letter-spacing: 0.01em;
        margin: auto;
        color: #ffffff;
    }
}

.sharesBlock {
    background: #fff;
    p {
        color: #2c74ff;
    }
}

.plusButtonContainer {
    width: 32px;
    background: white;
    position: relative;
    right: 10px;
}
.plusButton {
    color: #65707e;
    border: 1px solid #eaf1fe;
    box-sizing: border-box;
    border-radius: 50%;
    padding: 14px;
}

.selectorContainer {
    width: 60%;
    display: inline-block;
    position: relative;
    left: 35%;
    div {
        display: inline-block;
        width: 20%;
    }
}

.FullAvatarContainer {
    display: inline-block;
    width: 25% !important;
}

.Avatar {
    span {
        line-height: 50px !important;
        font-size: 14px !important;
    }
}

.editRemoveButtons {
    display: inline-block !important;
    width: 90% !important;
    position: relative;
    right: 10px;
    span {
        height: 19px;
        cursor: pointer;
        
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 385%;
        display: inline;
        align-items: center;
        text-align: center;
        color: #2c74ff;
    }
}

.titleRow {
    
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    position: relative;
    top: 2rem;
    text-align: left;
    color: #65707e;
}
