@import "./../../../../../styles/variables/colors.scss";

.assistanceBlock {
  border-top: 0.0769rem solid $clara-light-blue;
}

.blockSpace {
  margin-top: 1rem;
}

.addPadding {
  padding-top: 0.9rem !important;
}