.containerData {
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: 5px;
    text-align: left;
    justify-content: space-between;

    > div {
        > div {
            > label {
                padding-right: 15px !important;
                width: 100% !important;
            }
        }
    }
    article {
        color: #828c98;
        font-size: 13px;
        
    }

    h1 {
        
        font-size: 18px !important;
        font-weight: normal !important;
    }

    h2 {
        
        font-size: 16px;
        margin-bottom: 0px;
        font-weight: 300;
    }

    hr {
        margin: 0 0 15px 0 !important;
    }
}

.claraSelect {
    display: inline-grid;
    grid-template-columns: 200px auto;
    margin: 0.2rem 0 1.2rem 0;

    label {
        width: 200px !important;
        text-align: left;
    }
}

.fileUpload {
    display: inline-grid;
    justify-content: space-between;
    grid-template-columns: 192px 385px;
    justify-items: end;
    margin: 0.2rem 0 1.2rem 0;

    label {
        max-width: 200px;
        text-align: left;
        padding-left: 10px;
        padding-right: 15px;
    }
}
.containerButtons {
    justify-self: end;
    display: inline-flex;
    width: max-content;
    margin-top: 20px;

    button:first-child {
        background: #fff;
        color: #2c74ff;
        margin-right: 15px;
        border: solid 1px #e5e5e5;
    }
}

.modal {
    border-top-style: hidden !important;
    padding: 3.5rem 3rem;
}
.containerModal {
    display: inline-flex;
    flex-direction: column;
    width: 482px;

    h1 {
        margin-top: 0;
        
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 110.93%;
        color: #192a3e;
    }
    span {
        
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        padding-right: 3rem;
        display: flex;
        align-items: center;
        color: #828c98;
    }
}
.modalContainerButton {
    display: inline-flex;
    justify-content: space-between;
    margin-top: 1.8rem;

    button {
        background: #fff;
        color: #2c74ff;
        border: solid 1px #e5e5e5;
        width: 154px;
    }
}

.containerInputText {
    display: inline-flex;
    flex-direction: column;

    article {
        margin-top: 10px;
        width: 380px;
        align-self: flex-end;
       
            }
}
