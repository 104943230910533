.contentData {
  width: 100%;
  justify-content: center;
  display: flex;
  .centerFrame {
    width: 60.5385rem;
    .claraTitle {
      height: 2.1538rem;
      margin-top: 3.6923rem;
      margin-bottom: 1.9231rem;
      justify-content: center;
      align-items: center;
      display: flex;
    }
    .buttonsContainer {
      display: flex;
      justify-content: center;
      margin-bottom: 1.2308rem;
      .btnSpace {
        margin-left: 1.2308rem;
      }
      span {
        text-align: center;
      }
    }
  }
}

.bottomDisclaimer {
  font-size: 0.7692rem;
  font-weight: 400;
  line-height: 1.5;
}

.flowTitle {
  font-size: 0.9231rem;
  font-weight: 700;
}