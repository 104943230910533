@import "../../../styles/variables/index";
@import "~./../../../styles/extends/positions.scss";
.AddGroupInput {
    width: 100%;
    margin: 1.5385rem 0 0 0;
}
.AddGroupFieldInput {
    width: 100%;
    justify-content: flex-end;
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    text-align: right;  
}
.LinkRemove {
    color: $palette-error!important;
    text-align: right!important;
    margin:0 0 0.3846rem 0;
    font-size: 0.7692rem;
    path {
        color: $palette-error;
    }
    &:hover {
        color: $palette-error!important;
        path {
            color: $palette-error;
        }
    }
}
.LinkAdd {
    color: $bg-primary;
    path {
        color: $bg-primary;
    }
    &:hover {
        color: $color-link;
        path {
            color: $color-link;
        }
    }
}
