.Title {
    font-size: 1.8462rem;
    font-weight: bold;
}

.SubTitle {
    font-size: 1.1538rem;
}
.MissingView{
    text-align: center;
    img{
        width: 11.7692rem;
    }
    *{
        margin: 1.1538rem 0;
    }
}