.generalContainer {
    width: 100%;
    height: 100vh;
    background-color: white;
    button {
        width: 180px;
        height: 40px;
        border-radius: 4px;
    }
    margin: auto;
    
    .contentContainer {
        width: 70%;
        height: 100%;
        position: absolute;
        overflow-y: auto; 
        right: 0;
        background-color: white;
        padding-bottom: 10rem ;
    }
}


.leftEducation {
    width: 30%;
    height: 100%;
    background-color: #2c74ff;
    left: 0;
    top: 0;
    color: white;
    display: block;
    position: fixed;
    overflow: hidden;

    >img {
        position: absolute;
        left: 0;
        bottom: 0;
    }

    header {
        padding-left: 1.5rem ;
        padding-top: 1.5rem ;
        text-align: left;
        display: inline-flex;
        align-items: center;
        width: 100%;
        margin-bottom: 2.5rem;
        img {
            margin-right: 1.5rem;
        }
        label{
            font-weight: 600;
            font-size: 1.25rem;
        }
    }
}


.textContainer {
    z-index: 999;
    position: relative;
    .upperTitle {
        margin: 20px 114px 0px 96px;
        
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        text-align: left;
        line-height: 120%;
        color: #ffffff;
        span {
            
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 120%;
            color: #ffffff;
            text-align: left;
            display: inline-block;
        }
    }
}
.Separator {
    border: unset;
    height: 0.0769rem;
    background: #eaf1fe;
    margin: 15px 0;
}

.title {
    margin-top: 117px;
    
    font-style: normal;
    text-align: left;
    font-weight: normal;
    font-size: 18px;
    line-height: 120%;
    color: #192a3e;
}

.subTitle {
    margin-top: 10px;
    
    font-style: normal;
    font-weight: normal;

    text-align: left;
    font-size: 13px;
    line-height: 138.05%;
    align-items: center;
    color: #828c98;
}

.docName {
    
    font-style: normal;
    font-weight: normal;
    position: relative;
    left: 10px;
    font-size: 13px;
    line-height: 18px;
    display: inline-block;
    align-items: center;
    color: #2c74ff;
}
.container {
    margin-left: 99px;
    margin-right: 332px;
    text-align: left;
}

.greenCheck {
    display: inline-block;
    position: relative;
}

.approvalTitle {
    
    font-style: normal;
    font-weight: normal;
    text-align: left;
    font-size: 16px;
    line-height: 20px;
    color: #192a3e;
}

.labelText{
    max-height: 100%;
    margin-top: 0.5rem;
}

.FieldValueContainer {
    margin: 0.5rem 0;
    padding: 0;
    height: auto;
    width: 100%;
    display: inline-block;
    line-height: 18px;
    label {
        min-width: 180px;
        display: inline-block;
        margin-right: 45px;
        
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #828c98;
    }

    span {
        max-width: 355px;
        display: inline-block;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 138.05%;
        color: #192a3e;
        ul {
            margin: 0;
            list-style-type: none;
            padding: 0;
            li {
                height: 40px;
            }
        }
    }
}
.Avatar {
    display: inline-block;
    position: relative;
    align-items: center;
    margin-right: 10px;
    
    div {
        span {
            color: white !important;
            position: relative;
            top: 4px;
            left: 0px;
        }
    }
    label{
        position: absolute;
        top:50%;
        transform: translateY(-50%);
        color: #192a3e !important;
    }
}

.buttonBlue {
    background: #2c74ff;
    color: #fff;
}

.buttonCancel {
    color: #2c74ff;
    background: #fff;
    border: 1px solid #eaf1fe;
    box-sizing: border-box;
    margin-right: 10px;
}

.botonera {
    text-align: right;
    margin-top: 2.5rem;
    margin-bottom: 5px;
    background-color: white;
}
.modal {
    border-top-style: solid !important;
    padding: 3.5rem 3rem;
}
.containerModal {
    display: inline-flex;
    flex-direction: column;
    width: 400px;

    h1 {
        margin-top: 0;
        
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 110.93%;
        color: #192a3e;
    }
    span {
        position: relative;
        
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 120%;
        color: #192a3e;
        text-align: left;
        bottom: 23px;
    }
    .buttonModal {
        text-align: right;
        position: relative;
        left: 58%;
        top: 20px;
        padding: 0 1rem;
        height: 40px;
        border-radius: 4px;
    }
}

.docNames {
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    color: #192a3e;
}

.containerDocumentName{
    display: inline-flex;
    width: 100%;
    justify-items: center;
    align-items: center;
    margin: 0.5rem 0;
}

.containerDocuments{
    margin-top: 1.5rem;
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.75rem 1rem  ;

    width: 100%;
}