.contentData {
  width: 100%;
  justify-content: center;
  display: flex;
  .centerFrame {
    width: 43.4615rem;
    .claraTitle {
      height: 2.1538rem;
      margin-top: 3.6923rem;
      justify-content: center;
      align-items: center;
      display: flex;
    }
    .claraQuestion {
      height: 2.6154rem;
      justify-content: center;
      align-items: center;
      display: flex;
    }
    .claraInfo {
      height: 2.6154rem;
      justify-content: center;
      align-items: center;
      display: flex;
      margin-top: 3.6923rem;
      margin-bottom: 5.6154rem;
    }
    .buttonsContainer {
      display: flex;
      justify-content: center;
      margin-bottom: 1.2308rem;
    }
  }
}

.flowTitle {
  font-size: 0.9231rem;
  font-weight: 700;
}
