@import "../../../../../../../../styles/variables/index.scss";

.buttonsContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  grid-gap: 0.9231rem;
  gap: 0.9231rem;
}

.cancelButton{
  width: auto;
  height: 3.0769rem;

  font-style: normal;
  font-weight: 600;

  line-height: 1.2308rem;
  text-align: center;
  color: $clara-dark-grey;
  display: inline-block;
  background: $bg-secondary;
  border-radius: 0.3077rem;
  border: 0.0769rem solid $clara-light-grey;
  min-width: 9.2308rem;
  &:hover,
  &:focus-visible {
    background: $bg-secondary;
    color: $clara-dark-grey;
    opacity: 1;
  }
}

.container{
  height: 25.3846rem;
}

.cannotDeleteDescription {
  p {
    font-size: 14px;
    padding-bottom: 0.5rem;
  }

  li {
    padding: 0.8rem 0 0 1rem;
    font-weight: 700;
  }
}