@import "../../styles/variables/index";

.ClaraLayout {
  background: $bg-white;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .content {
    width: 100%;
    height: calc(100% - 5.39em - 3.3rem);
    overflow: hidden;
    overflow-x: hidden!important;
    position: relative;  
    &.contentWithBreadCrumbs {
      height: calc(100% - 5.39em - 3.3rem - 3.1rem);
    }
  }  
  .contentWithAssistance4 {
    width: calc(100% - 24.8rem) !important;
  }
}

.Assistance4 {
  position: absolute;
  z-index: 12;
  top: 70px;
  height: calc(100% - 70px);
  width: 24.8rem;
  transition-property: all;
  transition-duration: 1s;
  transition-delay: 0s;
  overflow: visible;
  right: 0;
}

.AssistanceView{
  border-left: 1px solid #ebeff2;
  box-shadow: 10px 17px 40px 0px rgba(0, 0, 0, 0.04);
  // padding-top: 5.8462rem;
  background-color: #FFF;
}