@import "./../../../../styles/variables/colors.scss";

.separation {
  margin-bottom: 1.2308rem;
}
.TemplateLabel{   
    grid-template-columns: 17.3846rem 1fr !important;
}
.TextArea{
  height:10rem;
}
.checkboxLabel{
    
  >label{
    align-items: flex-start !important;
  }  
}
.TemplateLabelColumn{
  grid-template-columns: auto !important;
  label{
    margin-bottom: 1rem;
  }
}
.describePEPContainer{
  display: flex;
  flex-direction: column;
  color:$clara-dark-grey;
  margin-top: 1rem;
}

.ContainerForm{
  display: grid;
  flex-direction: row !important;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}

.CurriculumVitae {
  background: url('../../../../images/CV.svg') no-repeat;
  width: 100%;
  height: 100%;
}

.buttonCheckBox {
  width: 12rem;
  height: 10rem;
}

.CheckboxButton {
  [data-contentButton="true"] {
    width: 146px;
  }
}