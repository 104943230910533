.ProgressBar {
  width: 100%;
  height: 8px;
  background-color: rgb(183, 155, 229);
  border-radius: 5px;
}

.ProgressPercent {
  background: #eaf1fe;
  position: absolute;
  left: 0;
  top: 0;
  height: 30px;
  border-radius: 6px;
}

.ProgressData {
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  height: 30px;
  width: 100%;
}

.Progress {
  width: 100%;
  height: 30px;
  margin: 5px 0px;
  background: #fbfcff;
  border: 1px solid rgba(234, 241, 254, 0.5);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 3px 0 3px 35px;
  position: relative;
  .fileName {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 30px;
    color: #2c74ff;
    width: 180px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 33px;
  }
  .percent {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 138.05%;
    color: #65707e;
    position: absolute;
    right: 5px;
    top: 6px;
    display: inline-block;
  }
  .icon {
    position: absolute;
    left: 5px;
    top: 2px;
    display: inline-block;
  }
}

.ProgressError {
  background: rgba(255, 99, 111, 0.2);
  .fileName {
    color: rgba(255, 99, 111, 1);
  }
  .tryAgain {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 138.05%;
    color: #2c74ff;
    position: absolute;
    right: 5px;
    top: 6px;
    display: inline-block;
  }
}

.remove {
  right: 6px;
  top: 8px;
  position: absolute !important;
  width: auto !important;
  &:before {
    -webkit-mask: url(../../../../images/close.svg) no-repeat center !important;
  }
}
