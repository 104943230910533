.Header {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  grid-gap: 0.5rem;
  cursor: pointer;
}
.IsOpen {
  transform: rotate(180deg);
  transition: transform 200ms ease;
}
.IsNotOpen {
  transform: rotate(0deg);
  transition: transform 200ms ease;
}
