@import "../../../../../../../styles/variables/colors.scss";

.containerTab {
  border: 2px solid #eaf1fe;
  border-radius: 10px;
  margin-bottom: 1.84612rem;
  padding-bottom: 26px;
}

.tabTitle{
  font-size: 18px;
  font-weight: 400;
  margin: 15px 16px 18px 16px;
}

.stakeholdersContainer {
  margin-left: 1rem;
  display: flex;
  column-gap: 1.2308rem;
  flex-wrap: wrap;
  row-gap: 2rem;

  .stakeholder {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    cursor: pointer;

    p {
      color: $clara-blue;
      font-size: 12px;
      line-height: 16px;
      width: 4.9235rem;
      margin-top: 0.5rem;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.documentTabMargin {
  > div:first-of-type {
    margin-left: 16px;
  }
}

.questionnaireTab {
  > div:first-of-type {
    margin: 0 16px;
    padding-right: 32px;
  }
}

.questionContainer {
  margin: 0 1.2308rem;
  border-bottom: 1px solid $clara-light-blue;
  font-size: 12px;
  padding: 15.5px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .questionTitle {
    font-weight: 300;
    color: $clara-dark-grey;
    min-width: 360px;
    width: 360px;
    margin-right: 1.2308rem;
  }

  .questionDescription {
    font-weight: 600;
    color: $clara-black;
  }
}