@import "../../../../../styles/variables/index.scss";

.InputContainer {
  position: relative;
  text-align: left;

  .TextInput {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    align-items: center;
    width: 48.6923rem;

    .TextInputChildren {
      margin-left: 10px;
    }

    label {
      color: gray;
      line-height:17px;
      display: inline;
      align-items: center;
    }

    input {
      font-size: 1rem;
      display: block;
      width: 100%;
      height: 3.0769rem;
      padding: 0.75rem 0.93rem;
      border-left: 1px solid #c2cfe0;
      color: $color-input;
      box-sizing: border-box;
      outline: none;
      &:focus {
        border-color: $bg-primary;
      }
      &::placeholder {
        color: black;
        opacity: 0.5;
      } 
    }
    .Error {
      border: 1px solid  #ff606f !important;
    }
  }
}

.contentOnlyInput {
  position: relative;

}

.MessageError {
  display: block;
  width: 100%;
  
  font-style: normal;
  font-weight: normal;
  font-size: .7692rem;
  line-height: 1rem;
  text-align: right;
  color: #ff606f;
  max-width: 29.2308rem;
}

.Error {
  border-radius: 0.3846rem;
  border: 1px solid red !important;
  
  width: 28rem;
  &>div{
    padding-left: 0 !important;
    &>input{
      padding-left: 0.5769rem !important;
    }
  }
}


.dropdown {
  margin-left: .4615rem !important;
  div:nth-of-type(3) {
    border-radius: .4615rem;
    font-family: "cl-regular" !important;
    -webkit-border-radius: .4615rem;
    -moz-border-radius: .4615rem;
    -ms-border-radius: .4615rem;
    -o-border-radius: .4615rem;
    box-shadow: 0px 0.3077rem 0.7692rem rgba(0, 0, 0,0.1)!important;
    border: 1px solid #eaf1fe;
  }
}

.containerDropdownOn{
  position: relative;

  button{
    position: relative;
    left: 0;
    height: 3.0769rem;
    width: 3.0769rem;
    padding-right: .4615rem;
    box-sizing: content-box;
  }
  &>div> div> div{
    margin: 0!important;
  }
  &::before{
    content: ' ';
    position: absolute;
    left: 2.25rem;
    top: 1.3677rem;
    z-index: 2;
    width: 0; 
    height: 0; 
    border-left: .3077rem solid transparent;
    border-right: .3077rem solid transparent;
    border-top: .3846rem solid #000;
  }
}

.containerDropdownOff{
  position: relative;
}

.NoError{
  width: 28rem;
  &>div{
    padding-left: 0 !important;
    &>input{
      padding-left: 0.5769rem !important;
    }
  }
}