@import "../../../../../../styles/variables/index.scss";

.text_input_no_outline {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 0.3rem solid $clara-blue;
  font-size: 2.4615rem;
  line-height: 1;
  padding-bottom: 0.3rem;
  background: transparent;
  font-family: "cl-bold";
  font-weight: normal;
  color: $clara-blue;
  margin-bottom: 2.3077rem;
  &::placeholder {
    color: #bec9d7;
    font-size: 1.84615rem;
  }
  width: 28.7692rem;
}