@import "./../../../styles/variables/index.scss";

$border-radius-modal: 5px !default;
$border-with-modal: 0.5rem !default;
$max-height-modal: 51.9rem !default;
$height-modal-tour: 51.9rem !default;
$height-modal-title: 50rem !default;
$size-with: calc(86.9231rem + 3.0769rem);

.Subscriptions {
  padding: 3.8rem 3.6rem 3.6rem 2.8rem;
  width: 46.7rem;
  display: flex;
  flex-direction: column;
  position: relative;
  > div {
    z-index: 1;
    > * {
      //display: flex;
      //width: 100%;
    }
    > svg {
      margin-bottom: 1.9rem;
      color: $clara-blue;
    }
    > h1 {
      font-size: 1.6923rem;
      line-height: 1.7rem;
      margin-bottom: 2.3rem;
    }
  }

  .ContentText {
    width: 100%;
    flex-direction: column;
    p {
      font-size: 1.0769rem;
      width: 100%;
      margin-bottom: 1.9231rem;
      b {
        font-weight: 700;
      }
    }
  }
}

.ContentBotonera {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > * {
    margin-left: 1.2rem;
    margin-bottom: 0;
  }
}

.ModalContainer {
  border-top: $border-with-modal solid $clara-blue;
  border-radius: $border-radius-modal;
  box-sizing: content-box;
  max-width: 86rem;
  background: #fff;
  box-shadow: $box-shadow !important;
  height: 60rem;
}

.ModalTitle {
  padding: 6.6rem 2.4rem 0 4.9rem;
  height: 100%;
  box-sizing: border-box;
  align-items: center;

  > h1 {
    font-family: "cl-bold";
    margin: 0;
    color: $clara-black;
    font-size: 3rem !important;
    line-height: 5rem;
    > strong {
      font-family: "cl-extrabold";
    }
  }
  > h2 {
    margin: 0;
    color: $clara-blue;
    padding-bottom: 1.2rem;
    line-height: 1rem;
    font-size: 1rem;
  }
}

.ModalContent {
  box-sizing: border-box !important;
  display: grid;
  grid-template-columns: 30.308rem 1fr;
  grid-template-rows: 100%;
  overflow: visible;
  height: 100%;

  > p {
    font-family: "cl-regular";
    font-weight: normal;
    color: $clara-black;
    padding-bottom: 3rem;
    font-size: 2.5rem !important;
    font-weight: 400 !important;
    line-height: 3.6rem;
  }
}

.RightContent {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: flex-end;
}

.ContentText {
  width: 100%;
  height: 100%;

  >p {
    font-family: "cl-regular";
    font-weight: normal;
    color: $clara-black;
    padding-bottom: 3rem;
    font-size: 14px;
    font-weight: 400 !important;
    line-height: 1.714rem;
  }
}

.NoPadding {
  padding-bottom: 0 !important;
}

.NoGap {
  grid-gap: 0 !important;
}