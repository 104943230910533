@import "../../../styles/variables/colors.scss";

.SnackBar {
  padding: 0rem 0.7692rem;
  border-radius: 0.9231rem;
  text-align: left;
  font-weight: bold;
  position: relative;
  min-height: 3.6923rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  -webkit-border-radius: 0.9231rem;
  -moz-border-radius: 0.9231rem;
  -ms-border-radius: 0.9231rem;
  -o-border-radius: 0.9231rem;
}

.error {
  background: $clara-snackbar-error;
  font-weight: normal;
  padding-left: 30px;
  padding-right: 30px;
  min-height: calc(3.3rem - 1px) !important;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 0px;
}

.success {
  background: $clara-success-background;
  font-weight: normal;
  padding-left: 30px;
  padding-right: 30px;
  min-height: calc(3.3rem - 1px) !important;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 0px;
}
