@import "./../../../../styles/variables/colors.scss";

.SubscriptionDetails {
  width: 816px;
  min-width: 816px;
  height: 500px;
  border-radius: 1.25rem;
  -webkit-border-radius: 1.25rem;
  -moz-border-radius: 1.25rem;
  -ms-border-radius: 1.25rem;
  -o-border-radius: 1.25rem;
  border: 0.2308rem solid $clara-upgrade-color;
  position: relative;
  .cornerContainer {
    display: flex;
    position: absolute;
    right: 0;
    .cornerDetail {
      width: 13.625rem;
      height: 3.188rem;
      background-color: #E96199;
      border-top-right-radius: 0.8462rem;
      justify-content: center;
      align-items: center;
      border-bottom-left-radius: 1.3846rem;
      display: flex;
      flex-direction: column;
      .textCorner {
        font-size: 1rem;
        font-weight: 800;
        line-height: 1.5rem;
      }
      .textSubTitleCorner{
        font-size: 0.813rem;
        font-weight: 400;
        line-height: 1.5rem;
      }
    }
    .cornerRounder {
      width: 1.3846rem;
      height: 3.9231rem;
      background-color: $clara-upgrade-color;
      .cornerAngle {
        width: 1.3846rem;
        height: 3.9231rem;
        background-color: white;
        border-top-right-radius: 1.3846rem;
      }
    }
  }
  .dataSpace {
    height: 100%;
    width: 100%;
    padding: 0rem 2.375rem;
    padding-bottom: 3rem;
    position: absolute;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1.5rem;
    .dataLeft{
      justify-items: flex-start;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      .titleSpace {
        display: flex;
        flex-direction: column;
        height: 8.5rem;
        align-items: flex-start;
        .textTitle {
          padding: 0.9rem 0rem;
          padding-top: 3rem;
          font-size: 2.5rem;
          font-weight: 700;
        }
        .textSubTitle {
          font-size: 1rem;
          font-weight: bold;
          padding: 0.375rem 0rem;
          padding-bottom: 0.4rem;
        }
      }
      .containerListData{
        margin-bottom: -1.5rem;
        width: 100%;
      }
      .listItems{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        .tagOnly{
          width: 5.938rem;
          height: 24px;
          border-radius: 11px;
          color: white;
          font-size: 0.75rem;
          padding: 0.3rem 0.5rem;
          background: #E96199;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

    }
    .dataRight{
      align-items: flex-end;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      button {
        margin-top: 0.7rem;
        width: 23.7692rem;
      }
      img {
        width: 100%;
        height: 18.4615rem;
        margin-bottom: 1.7692rem;
      }
    }
  }
}