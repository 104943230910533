@import "../../../../../../../styles/variables/index.scss";

.containerForm {
  max-height: 616px;
  overflow-y: auto;
  padding-right: 2.8rem;
  padding-top: 3.6rem;
  border-bottom: 1px solid $clara-light-blue;
  padding-left: 2.8rem;
  padding-bottom: 3.8rem;
  width: calc(620px + 5.6rem);
}

.textContainer {
  div div div div input {
    height: 3.2308rem;
  }
}

.separator {
  hr {
    height: 0.01rem !important;
  }
}

.fileUploadContainer {
  div > div > div > div > span > div > div > div {
    width: 97% !important;
    white-space: nowrap;
  }
}

.containerButtons {
  padding-right: 3rem;
  margin-bottom: 1.9rem;
}

.title {
  text-align: left;
  margin: 0;
  width: 100%;
  margin-bottom: 40px;
}

.isHoldingChecbox {
  color: $clara-dark-grey;
}

.containerTable {
  table thead {
    tr:first-child {
      display: none;
      th {
        display: none;
      }
    }
    tr {
      height: 0px !important;
      th {
        position: relative;
        height: 0px !important;
      }
    }
  }
  table tbody tr:first-child td:first-child {
    div span {
      bottom: 3px;
      right: 219px;
    }
  }
}

.headerColumnTable {
  color: $clara-black;
  font-size: 0.9231rem;
  font-weight: 400;
}

.headerColumnTableCenter {
  font-size: 0.9231rem;
  font-weight: 400;
  display: flex;
  justify-content: center;
  color: $clara-black;
}

.headerColumnTableCenterSpace {
  font-size: 0.9231rem;
  font-weight: 400;
  display: flex;
  justify-content: center;
  margin-bottom: 2.3077rem;
}

.headerColumnTableCenterSpaceHeader {
padding-top: 2rem;
  font-size: 0.9231rem;
  font-weight: 400;
  display: flex;
  justify-content: center;
  margin-bottom: 2.3077rem;
}

.centerValues {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  color: $clara-dark-grey;
}

.containerInnerTable {
  background: #f6faff;
  animation-name: collapseInnerTableOpen;
  animation-duration: 5s;
  table {
    border-spacing: 0;
    width: 100%;
    tr:first-child {
      height: 3.7rem !important;
    }
    tbody tr td {
      border-bottom: none;
    }
    tr {
      height: 3.7rem !important;
    }
    tr td {
      height: 3.7rem !important;
    }
  }
}

@keyframes collapseInnerTableOpen {
  0% {
    max-height: 0%;
  }
  25% {
    max-height: 25%;
  }
  50% {
    max-height: 50%;
  }
  75% {
    max-height: 75%;
  }
  100% {
    max-height: 100%;
  }
}

.containerInnerNoHeaderTable {
  padding-top: 15px;
  table thead tr th {
    top: 0px !important;
  }
}

.circle {
  -webkit-mask: url(../../../../../../../images/ellipse.svg) no-repeat center !important;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 6px;
}

.MISSING {
  background-color: $clara-red !important;
}

.Signed {
  background-color: $status-green !important;
}

.Pending {
  background-color: $clara-yellow !important;
}
.UNSIGNED {
  background-color: $clara-yellow !important;
}

.headerTitle {
  padding-bottom: 0px !important;
  margin-bottom: 0 !important;
}

.dropdownIcon {
  width: auto;
  height: auto;
  position: absolute;
  left: 200px;
  bottom: -2px !important;
  color: $clara-blue;
  cursor: pointer;
  svg {
    transition: all 0.5s;
  }
}

.nameText {
  color: $clara-blue;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 150px;
}

.containerNoHeaderTable {
  position: relative;
  table thead {
    height: 0px !important;
    tr {
      height: 0px !important;
    }
  }
}

.hiddenHeader {
  visibility: hidden;
  height: 0;
}

.containerMenuOptionDocument {
  padding: 0.75rem;
}

.containerOption {
  padding: 0.5rem;
  cursor: pointer;
  color: $clara-black;
  font-size: 0.9231rem;
  font-weight: 400;
  &:hover {
    background: $clara-light-blue;
  }
}

.li {
  position: relative !important;
  transform: translateX(-50%);
}

.vestedIconContainer {
  position: absolute;
  right: 219px;
  bottom: -1px;
}

.popoverContainer {
  position: relative;
}

.nameTextStakeholder {
  display: block;
  position: relative;
  top: 0px;
  color: #a8a8a8;
  font-family: "cl-regular";
  font-size: 12px;
  font-weight: 400;
}

.nameCollapseContainer {
  position: relative;
  width: 135px;
  margin-bottom: 10px;
}

.dropdownRowContainer {
  position: relative;
}

.scrollbarModal {
  min-width: 819px;
}

.dropdownIconOpen {
  svg {
    transform: rotate(180deg);
  }
}

.clickable {
  cursor: pointer;
  color: $clara-blue;
}