@import "./../../../../styles/variables/colors.scss";

.TypeSubscriptionHeader {
  display: grid;
  grid-template-rows: 100%;
  width: 100%;
  grid-template-columns: auto 10.025rem 10.025rem;
  padding: 0.5rem 0;
  align-items: center;
  .containerTypeProduct {
    width: 5.7rem;
    text-align: center;
    padding-left: 0.875rem;
  }
  .StartSub {
    font-size: 0.875rem;
    font-weight: 600;
    color: $clara-black;
  }
  .ScaleSub {
    font-size: 0.875rem;
    font-weight: 600;
    color: $clara-upgrade-color;
  }
}
