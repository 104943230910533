.containerRow {
  margin: 0 0 10px 0;
  padding: 0;
  height: auto;
  width: 100%;
  display: inline-block;
  & > div {
    margin: 0;
    label {
      width: 296px !important;
      margin: 0 20px 0 0;
      display: inline-block;
      //line-height: 40px;
    }
    input {
      width: 380px;
      margin: 0;
    }
  }
  & > label {
    width: 180px;
    margin-right: 20px;
    line-height: 40px;
    display: inline-block;
  }

  & > span {
    width: 380px;
    display: inline-block;
    text-align: center;
    ul {
      margin: 0;
      list-style-type: none;
      padding: 0;
      li {
        height: 20px;
      }
    }
  }
}
.ContainerRow {
  margin: 0 0 10px 0;
  padding: 0;
  height: auto;
  width: 100%;
  display: inline-block;
  & > div {
    margin: 0;
    label {
      width: 296px !important;
      margin: 0 20px 0 0;
      display: inline-block;
      //line-height: 40px;
    }
    input {
      width: 380px;
      margin: 0;
    }
  }
  & > label {
    width: 180px;
    margin-right: 20px;
    line-height: 40px;
    display: inline-block;
  }

  & > span {
    width: 380px;
    display: inline-block;
    text-align: center;
    ul {
      margin: 0;
      list-style-type: none;
      padding: 0;
      li {
        height: 20px;
      }
    }
  }
}
.containerEquity{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: flex-end;
  margin-bottom: 3rem;
  label{
    color: grey;
  }
}
.select {
  > div {
    display: inline-flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-left: -6px;
  }
  input {
    width: 29.5rem !important;
  }
  width: 100%;
  label {
    margin-right: 6rem !important;
  }
}
.Select {
  > div {
    display: inline-flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-left: -6px;
  }
  input {
    width: 29.5rem !important;
  }
  width: 100%;
  label {
    margin-right: 6rem !important;
  }
}
.inputShareModal{
  input{
    width: 12rem !important;
  }
}
.inputEquityModal{
  width: 12rem;
  word-break: break-word;
  white-space: pre-wrap;
  input{
    width: 12rem !important;
  }
}
.inputEquity{
  width: 195px;
  word-break: break-word;
  white-space: pre-wrap;
  input{
    width: 15rem !important;
  }
}
.inputCodeModal {
  input {
    width: 12rem !important;
  }
  > div {
    margin: 0.75rem 0 0 0;
  }

}
.inputCode {
  input {
    width: 15rem !important;
  }
  > div {
    margin: 0.75rem 0 0 0;
  }

}
.InputContainer {
  display: inline-block;
  // position: relative;
  width: 100% !important;
  margin-bottom: 10px;
  // text-align: left;

  .TextInput {
    // display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
    width: 83%;
    margin-left: 45px;

    .TextInputChildren {
      margin-left: 10px;
    }

    label {
      color: gray;
      font-size: 13px;
      margin-bottom: -5px;
      // margin-top: 30px;
    }

    input {
      font-size: 13px;
      width: 105%;
      height: 40px;
      padding: 0 60px 0 20px;
      border: 1px solid #c2cfe0;
      border-radius: 5px;
      // margin: 20px 0;
      outline: none;
      display: block;

      &:focus {
        border-color: #2c74ff;
      }
    }
    .ChatTextInput {
      position: relative;
      left: calc(50% - 90px);
    }
    .Error {
      border: 1px solid red !important;
    }
  }
}
.Separator {
  width: 100%;
  height: 1px;
  border: unset;
  height: 0.0769rem;
  background: #eaf1fe;
  margin: 10px 0 20px 0;
}
.containerFounderName {
  margin-bottom: 3.69rem;
  margin-top:4.7rem;
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #192a3e;
    width: auto;
    padding: 0.5rem 0.5rem 0.5rem 0;
  }
  p{
    color: #828C98;
font-size: 14px;
font-weight: 400;
  }
}
.titleShare {
  display: flex;
  gap: 1.5rem;
  align-items:center;
  h1 {
    color: #192a3e;
    font-size: 22px;
    font-weight: 600;
  }
}
.detailsShare {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.detailsColumn {
  display: flex;
  gap: 119px;
  align-items: center;
  justify-content: flex-start;
}
.shareLabel {
  color: #828c98;
  width:142px;
}
.shareDetails {
  color: #192a3e;
  font-weight: 600;
}

.shareholdingTerms{
  p{
  font-weight: 600;
  font-size: 13px;
  color: #192a3e;
  }
  display:flex;
  flex-direction:column;
  gap:2rem;
}
