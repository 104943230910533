@import "../../../../../../styles/variables/index.scss";

.checkboxMultiple div button div{
  height: 5.849rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 0.6154rem;
  padding-top: 1.0769rem;
  span {
    color: $clara-black !important;
  }
}

.spaceData {
  margin-top: 1.5385rem;
}