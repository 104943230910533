@import "../../../../../styles/variables/index.scss";

$border-radius-modal: 5px !default;
$border-with-modal: 0.5rem !default;
$max-height-modal: 51.9rem !default;
$height-modal-tour: 51.9rem !default;
$size-with: calc(86.9231rem + 3.0769rem);

.container-tour {
  border-top: $border-with-modal solid $clara-blue;
  border-radius: $border-radius-modal;
  box-sizing: content-box;
  max-width: 86.9231rem;
  background: #fff;
  box-shadow: $box-shadow !important;
  height: $height-modal-tour;
}

.container-buttons-modal-tour {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 2.4rem;
  padding-right: 3.6rem;
  padding-top: 0.2308rem;
  height: 6.1538rem;
  border-top: 1px solid #eaf1fe;
  // overflow: hidden;container-blocks-modal-tour
  .container-steps-dots {
    max-height: -webkit-max-content !important;
    max-height: -moz-max-content !important;
    max-height: max-content !important;
  }
}

.container-blocks-modal-tour {
  height: $height-modal-tour - 0.9rem - 2.6rem - 2.6rem - $border-with-modal;
  box-sizing: border-box !important;
  width: 86.9231rem;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 26px;
  overflow: visible;
  & > div:first-child{
    width: 100%;
  }
  & > div:last-child{
    display: grid;
    grid-template-columns: 751px 250px;
    height: 100%;
    margin: 0 64px;
  }
}
.container-blocks-warning{
  margin: 68px 62px 0;
  box-sizing: border-box;
  border: 1px solid $clara-red;
  line-height: 1.7692rem;
  h5{
    color: $clara-red;
    margin:0;
  }
  color: $clara-black;
  font-size: 14px;
  font-weight: 700;
  padding: 0.9231rem;
  border-radius: 0.3846rem;
  
}


