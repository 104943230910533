@import "src/styles/variables/index.scss";

.divider {
  margin-top: 2.0769rem !important;
  margin-bottom: 2.0769rem !important;
  border-color: $border-disabled !important;
}

.sourceContainer {
  display: flex;
  flex-direction: column;
  .row {
    margin-bottom: 1.7692rem;
    display: flex;
    .titleArea {
      width: 18.0769rem;
    }
  }
}

.title {
  font-size: 1.2308rem;
  font-weight: 600;
  color: $clara-black;
  margin-bottom: 1.1538rem;
}

.text {
  font-size: 0.9231rem;
  font-weight: 300;
  color: $clara-dark-grey;
}

.contain {
  color: $clara-black;
}

.containerCompany {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  margin-right: 1.25rem;
  cursor: pointer;
  p {
    color: $clara-black;
    width: 7rem;
    margin-top: 0.5rem;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.opaque {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  margin-right: 1.25rem;
  opacity: 0.2;
  cursor: pointer;
  p {
    color: $clara-black;
    width: 7rem;
    margin-top: 0.5rem;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.locked{
  cursor: no-drop;
}

.containerSkeleton {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  margin-right: 1.25rem;
  p {
    width: 7rem;
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
  }
}

.SkeletonRounder {
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}