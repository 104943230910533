@import "./../../../../../styles/variables/index";
@import "./../../../../../styles/variables/colors";


$height-button: 2.92307rem !default;
$width-border-button: 0.0769rem !default;

.Button {
  outline: none;
  width: auto;
  min-width: 9.2308rem;
  height: $height-button + $width-border-button * 2;
  font-style: normal;
  font-weight: normal;
  line-height: 1.2308rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.3077rem;
  font-size: 1.0769rem;
  position: relative;
  padding: 0 1.9231rem;
  border: unset;
  font-family: $font-family;
  cursor: pointer;
  //box-sizing: border-box;
  &:active {
    outline: none;
  }
}

.primary {
  color: $clara-white;
  background: $bg-primary;
  font-family: $font-family;
  &:hover {
    background: $bg-primary-hover;
    opacity: 0.8;
  }
  &:disabled {
    background: $clara-blue-disable;
    // opacity: 0.3;
    &:hover {
      background: $clara-blue-disable;
    }
    &.loading {
      background: $bg-primary;
      opacity: 1;
      &:hover {
        background: $bg-primary;
        opacity: 1;
      }
    }
  }
  &:active {
    background: $clara-dark-blue;
    opacity: 1;
    &:hover {
      background: $clara-dark-blue;
      opacity: 1;
    }
  }
}

.error{
  color: $clara-white;
  background: $clara-red;
  font-family: $font-family;

  &:hover {
    background: $clara-red;
    opacity: 0.8;
  }
  &:disabled {
    background: $clara-blue-disable;
    // opacity: 0.3;
    &:hover {
      background: $clara-blue-disable;
    }
    &.loading {
      background: $bg-primary;
      opacity: 1;
      &:hover {
        background: $bg-primary;
        opacity: 1;
      }
    }
  }
  &:active {
    background: $clara-pay !important;
    opacity: 1;
    &:hover {
      background: $clara-pay !important;
      opacity: 1;
    }
  }

}

.secondary {
  color: $palette-clara;
  background: $bg-secondary;
  font-family: $cl-semibold;
  border: $width-border-button solid $palette-clara;
  &:hover,
  &:focus-visible {
    background: $bg-primary-hover;
    color: $clara-white;
    opacity: 0.8;
  }
  &:disabled {
    background: $clara-white;
    color: $clara-dark-grey;
    border-color: $clara-dark-grey;
    opacity: 0.3;
    &.loading {
      background: $bg-secondary;
      opacity: 1;
      &:hover,
      &:focus {
        background: $bg-primary;
        opacity: 1;
        color: $clara-white;
      }
    }
  }
}

.transparent {
  color: $palette-clara;
  background: transparent;
  border: 0;
  border-radius: 0;
  &.EnabledActive {
    color: $clara-dark-grey;
    &[data-active="true"] {
      color: $palette-clara;
    }
  }
  &:hover,
  &:focus-visible {
    background: transparent;
    opacity: 0.8;
  }
  &:disabled {
    background: transparent;
    color: $clara-dark-grey;
    opacity: 0.3;
    &.loading {
      background: transparent;
      opacity: 1;
      &:hover,
      &:focus {
        background: transparent;
        opacity: 1;
        color: $clara-light-blue;
      }
    }
  }
}

.none {
  color: $palette-clara;
  background: transparent;
  border: 0;
  border-radius: 0;
  padding: 0px;
  min-width: 0px;
  &.EnabledActive {
    color: $clara-dark-grey;
    &[data-active="true"] {
      color: $palette-clara;
    }
  }
  &:hover,
  &:focus-visible {
    background: transparent;
    opacity: 0.8;
  }
  &:disabled {
    background: transparent;
    color: $clara-dark-grey;
    opacity: 0.3;
    &.loading {
      background: transparent;
      opacity: 1;
      &:hover,
      &:focus {
        background: transparent;
        opacity: 1;
        color: $clara-light-blue;
      }
    }
  }
}

.white {
  color: $clara-white;
  background: transparent;
  border: 0;
  border-radius: 0;
  &.EnabledActive {
    color: $clara-dark-grey;
    &[data-active="true"] {
      color: $palette-clara;
    }
  }
  &:hover,
  &:focus-visible {
    background: transparent;
    opacity: 0.8;
  }
  &:disabled {
    background: transparent;
    color: $clara-dark-grey;
    opacity: 0.3;
    &.loading {
      background: transparent;
      opacity: 1;
      &:hover,
      &:focus {
        background: transparent;
        opacity: 1;
        color: $clara-white;
      }
    }
  }
}


.primaryCard{
  @extend .primary;
  font-size: 0.7692rem;
  height: 1.8462rem;
  border-radius: 0.9231rem;
  width: auto;
  min-width: auto;
  padding: 0 0.9231rem;
  display: inline-block;
  border: 1px solid $clara-white;
}

.cancelDelete {
  width: auto;
  height: 3.0769rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2308rem;
  text-align: center;
  color: $clara-dark-grey;
  display: inline-block;
  background: $bg-secondary;
  border-radius: 0.3077rem;
  border: 0.0769rem solid $clara-light-grey;
  min-width: 9.2308rem;
  &:hover,
  &:focus-visible {
    background: $bg-secondary;
    color: $clara-dark-grey;
    opacity: 1;
  }
}

.submitDelete {
  width: auto;
  min-width: 9.2308rem;
  height: 3.0769rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2308rem;
  text-align: center;
  color: $clara-white;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $clara-red;
  border-radius: 0.3077rem;
  font-size: 1.0769rem;
  position: relative;
  padding: 0 1.9231rem;
  &:hover {
    background: $clara-red;
    opacity: 0.8;
  }
  &:disabled {
    background: $clara-dark-grey;
    opacity: 0.3;
    &:hover {
      background: $clara-dark-grey;
    }
    &.loading {
      background: $bg-primary;
      opacity: 1;
      &:hover {
        background: $bg-primary;
        opacity: 1;
      }
    }
  }
  &:active {
    background: $clara-red;
    opacity: 1;
    &:hover {
      background: $clara-red;
      opacity: 1;
    }
  }
}

.card {
  @extend .secondary;
  font-size: 0.7692rem;
  height: 1.8462rem;
  border-radius: 0.9231rem;
  width: auto;
  min-width: auto;
  padding: 0 0.9231rem;
  line-height: 1;
}

.dashed {
  color: $palette-clara;
  background: linear-gradient(to right, $clara-light-blue 50%, transparent 0%)
      top/10px 1px repeat-x,
    /* top */ linear-gradient($clara-light-blue 50%, transparent 0%) right/1px
      10px repeat-y,
    /* right */ linear-gradient(to right, $clara-light-blue 50%, transparent 0%)
      bottom/10px 1px repeat-x,
    /* bottom */ linear-gradient($clara-light-blue 50%, transparent 0%) left/1px
      10px repeat-y; /* left */
  &:hover,
  &:focus-visible {
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.3;
    &.loading {
      opacity: 1;
      &:hover,
      &:focus {
        opacity: 1;
        color: $clara-light-blue;
      }
    }
  }
}

.success {
  color: $clara-white;
  background: $clara-green;
  &:hover {
    background: $clara-green-dark;
    opacity: 0.8;
  }
  &:disabled {
    background: $clara-dark-grey;
    opacity: 0.3;
    &:hover {
      background: $clara-dark-grey;
    }
    &.loading {
      background: $clara-green;
      opacity: 1;
      &:hover {
        background: $clara-green;
        opacity: 1;
      }
    }
  }
  &:active {
    background: $clara-green-dark;
    opacity: 1;
    &:hover {
      background: $clara-green-dark;
      opacity: 1;
    }
  }
}

.flat {
  color: $clara-blue;
  background: $clara-white;
  &:hover {
    background: $clara-white;
    opacity: 0.8;
  }
  &:disabled {
    background: $clara-dark-grey;
    opacity: 0.3;
    &:hover {
      background: $clara-dark-grey;
    }
    &.loading {
      background: $clara-white;
      opacity: 1;
      &:hover {
        background: $clara-white;
        opacity: 1;
      }
    }
  }
  &:active {
    background: $clara-light-grey;
    opacity: 1;
    &:hover {
      background: $clara-light-grey;
      opacity: 1;
    }
  }
}

.upgrade {
  color: $clara-white;
  background: $clara-upgrade-color;
  &:hover {
    background: $clara-upgrade-color-dark;
    opacity: 0.8;
  }
  &:disabled {
    background: $clara-dark-grey;
    opacity: 0.3;
    &:hover {
      background: $clara-dark-grey;
    }
    &.loading {
      background: $clara-upgrade-color;
      opacity: 1;
      &:hover {
        background: $clara-upgrade-color;
        opacity: 1;
      }
    }
  }
  &:active {
    background: $clara-upgrade-color-dark;
    opacity: 1;
    &:hover {
      background: $clara-upgrade-color-dark;
      opacity: 1;
    }
  }
}

.pay {
  color: $clara-white;
  background: $clara-pay;
  &:hover {
    background: $clara-pay-dark;
    opacity: 0.8;
  }
  &:disabled {
    background: $clara-dark-grey;
    opacity: 0.3;
    &:hover {
      background: $clara-dark-grey;
    }
    &.loading {
      background: $clara-pay;
      opacity: 1;
      &:hover {
        background: $clara-pay;
        opacity: 1;
      }
    }
  }
  &:active {
    background: $clara-pay-dark;
    opacity: 1;
    &:hover {
      background: $clara-pay-dark;
      opacity: 1;
    }
  }
}

.paySecondary {
  color: $clara-pay;
  background: $bg-secondary;
  font-family: $cl-semibold;
  border: $width-border-button solid $clara-pay;
  &:hover,
  &:focus-visible {
    background: $clara-pay;
    color: $clara-white;
    opacity: 0.8;
  }
  &:disabled {
    background: $clara-white;
    color: $clara-dark-grey;
    border-color: $clara-dark-grey;
    opacity: 0.3;
    &.loading {
      background: $bg-secondary;
      opacity: 1;
      &:hover,
      &:focus {
        background: $bg-primary;
        opacity: 1;
        color: $clara-white;
      }
    }
  }
}

.warning {
  @extend.secondary;
  color: $clara-pay;
  border: $width-border-button solid $clara-pay;
  &:hover {
    background-color: $clara-pay;
  }
}

.upgradeOutlined {
  @extend.secondary;
  color: $clara-upgrade-color;
  border: $width-border-button solid $clara-upgrade-color;
  &:hover {
    background-color: $clara-upgrade-color;
  }
}

.cardWarning {
  @extend.secondary;
  font-size: 0.7692rem;
  height: 1.8462rem;
  border-radius: 0.9615rem;
  width: auto;
  min-width: auto;
  padding: 0 0.9615rem;
  line-height: 1;
  -webkit-border-radius: 0.9615rem;
  -moz-border-radius: 0.9615rem;
  -ms-border-radius: 0.9615rem;
  -o-border-radius: 0.9615rem;
  color: $clara-pay;
  border: $width-border-button solid $clara-pay;
  &:hover {
    background-color: $clara-pay;
  }
}

.small{
  font-size: 0.9rem;
  height: 1.8462rem;
  min-width: 2.5rem;
}

.expanded{
  width: 100% !important;
}