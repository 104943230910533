@import "../../../../styles/variables/index";
@import "~./../../../../styles/extends/positions.scss";
.Footer {
  @extend .flex;
  @extend .flex-start;
  @extend .flex-middle;
  background: $bg-white;
  width: 100%;
  height: 3.3rem;
  border-top: 1px solid $bg-border;
  box-shadow: 10px 17px 40px 0px rgba(0, 0, 0, 0.04);
  z-index: 11;

  img {
    margin-left:2.3077rem;
    margin-right:4.41em;
    height:1.6923rem;
  }
  a{
    text-decoration: unset;
    line-height: 2rem;
  }
}
