.page {
  width: 100%;
  height: 100%;
  min-height: 800px;
  overflow: hidden;
  text-align: center;
}

@media only screen and (max-height: 600px) {
  .page {
    overflow: scroll;
  }
}
.whiteZone {
  background: #ffffff;
  border-radius: 4px;
  width: 559px;
  margin: 155px auto 0;
  position: relative;
  display: block;
  padding: 59px;
  text-align: left;
  z-index: 3;
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 120%;
    color: #192a3e;
    margin: 0 0 13px 0;
    padding: 0;
    text-align: left;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 167.58%;
    color: #65707e;
    text-align: left;
    margin: 0 0 30px 0;
    padding: 0;
  }
}
.botonera {
  margin: 40px 0 0 0;
  button {
    background: #ffffff;
    border: 1px solid #eaf1fe;
    box-sizing: border-box;
    border-radius: 4px;

    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.01em;
    padding: 16px;
    min-width: 120px;
    margin-right: 10px;
    /* Clara Dark Gray */

    color: #65707e;
    &.nextButton {
      background: #2c74ff;
      border: 1px solid #2c74ff;
      border-radius: 4px;
      color: #fff;
    }
  }
}
.eye1 {
  background-color: #848f99;
  display: inline-block;
  -webkit-mask-size: 13px 13px;
  width: 19px;
  height: 13px;
  position: relative;
  -webkit-mask: url(./images/eye1.svg) no-repeat center;
  cursor: pointer;
}
.eye2 {
  background-color: #848f99;
  display: inline-block;
  -webkit-mask-size: 13px 13px;
  width: 19px;
  height: 13px;
  position: relative;
  cursor: pointer;
  -webkit-mask: url(./images/eye2.svg) no-repeat center;
}
.forGot {
  width: 100%;
  margin: 10px 0;
  display: block;
  text-align: right;
}
.forGotLink{
  width: auto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #2c74ff;
  text-align: right;
}
.background {
  background: #276bee;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  min-height: 800px;
  overflow: hidden;
}

.triangle {
  background-image: linear-gradient(to right top, #276bee 0%, #276bee 50%, transparent 50%);
  position: absolute;
  width: 100vh;
  height: 100vh;
  top: 0;
  left: calc(50% - 155px);
  min-height: 800px;
  z-index: 2;
}
.color1 {
  background: #276bee;
  width: 50%;
  height: 100%;
  position: absolute;
  min-height: 800px;
  left: 0;
  top: 0;
  z-index: 1;
}
.color2 {
  background: #2c74ff;
  width: calc(50% + 155px);
  height: 100%;
  position: absolute;
  left: calc(50% - 155px);
  min-height: 800px;
  top: 0;
  z-index: 1;
}
.switchTriangle {
  background-image: linear-gradient(to right top, #2c74ff 0%, #2c74ff 50%, transparent 50%);
}
.switchColor1 {
  background: #2c74ff;
}
.switchColor2 {
  background: #276bee;
}
.textP {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 167.58%;
  text-align: center;
  color: #ffffff;
  margin-top: 20px;
  z-index: 3;
  position: relative;
  display: block;
  a {
    text-decoration: underline;
    color: #ffffff;
  }
}

.logo {
  &:before {
    content: " ";
    width: 22px;
    height: 22px;
    display: inline-block;
    position: relative;
    top: 3px;
    margin-right: 7px;
    -webkit-mask: url(./images/claraIconLogin.svg) no-repeat center;
    -webkit-mask-size: 22px 22px;

    background: #276bee;
  }
}

.linkClass {
  line-height: 1.4em !important;
}