.buttonCheckBox {
    width: 12rem;
    height: 10rem;
  }
  
  .searchSize {
    width: 30.2308rem;
    margin: 0;
  }
  .checkboxButton {
    [data-contentButton="true"] {
      width: 146px;
    }
  }