@import "./../../../../styles/variables/colors.scss";

.ScaleItem {
  display: flex;
  align-items: center;
  margin-bottom: 2.0769rem;
  .iconColor {
    color: $clara-black !important;
  }
  .textItem {
    margin-left: 0.6rem;
    font-size: 1.3846rem;
    font-weight: 400;
    color: $clara-black;
  }
}
