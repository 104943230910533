@import "./../../../../../styles/variables/colors.scss";

.checkContainer {
  display: grid;
  width: 100%;
  grid-template-columns: 1.2rem 1fr;
  grid-template-rows: 1fr;
  margin: 0;
  padding-top: 1.8rem;
  align-items: flex-start;
  justify-items: flex-start;
  align-content: center;
  justify-content: flex-start;
  color: $clara-blue;
  .titleContents {
    margin-left: 0.9rem;
    display: flex;
    align-items: center;
    min-height: 1.3rem;    
  }
  .iconAling {
    align-self: center;
  }
  .iconAlingAction {
    align-self: center;
    cursor: pointer;
  }
}

.withDelete {
  grid-template-columns: 1.2rem 1fr 1.2rem !important;
}

.iconGrey {
  color: $clara-grey !important;
}

.cursorHand {
  cursor: pointer;
}

.changeFont {
  font-weight: bold !important;
}

.checkText {
  font-family: "cl-regular";
  font-size: 1.0769rem;
  font-weight: 400;
}

.noTopMargin {
  padding-top: 0rem !important;
}