@import "../../../../../../../../styles/variables/index.scss";

.containerParties {
  display: inline-flex;
  p {
    margin-top: 8px;
    height: auto;
    margin-left: 5px;
  }
}

//----------DocumentName
.containerDocumentName {
  display: inline-flex;
  align-items: center;
  color: $clara-blue;
  label {
    font-size: 0.9231rem;
    font-weight: 400;
    cursor: pointer;
    color: $clara-dark-grey;
  }
}

//-------------------

//----------Status--------
.circle {
  -webkit-mask: url(../../../../../../../../images/ellipse.svg) no-repeat center !important;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 5px;
  position: relative;
}

.containerStatus {
  display: inline-flex;
  align-items: center;
}

.Missing {
  background-color: #ff606f !important;
}

.Signed {
  background-color: $status-green!important;
}

.Unsigned {
  background-color: #ffdec0 !important;
}

.Expired {
  background-color: #ff606f !important;
  box-sizing: border-box;
}
.Terminated {
  background: $clara-dark-grey;
}
.Executed {
  background:$status-green;
}

.Pending {
  background: #fbbd2c;
}

.Draft {
  background: #848f99;
}

.Valid {
  background-color: #71e096 !important;
}

.Uploaded {
  background-color: #71e096 !important;
}

//----------------------------------------------

//------------MenuOptionsDocuments -------

.containerMenuOptionDocument {
  padding: 0.75rem;
}

.containerOption {
  padding: 0.5rem;
  cursor: pointer;
  color: $clara-black;
  font-size: 0.9231rem;
  font-weight: 400;
  &:hover {
    background: $clara-light-blue;
  }
}

//----------------------------------------
.li {
  margin-left: -15px;
  position: relative;
  display: inline-block;

  overflow: hidden;
  width: 32px;
  height: 32px;
  z-index: 1;
  white-space: nowrap;
  overflow: hidden;
  background: #fff;
  border-radius: 50%;
  &:first-child {
    margin-left: 0;
  }
}

.documentTabFilterHeader {
  cursor: default;
  color: $clara-black;
  svg {
    cursor: pointer;
    color: $clara-blue;
    position: relative;
    left: 5px;
  }
}

.notClickable {
  span label {
    cursor: default !important;
  }
}

.labelDocument {
  cursor: pointer !important;
  position: relative;
  top: 0.1538rem;
  left: 0.7692rem;
  color: $clara-blue !important;
}
