@import "../../../../../../../styles/variables/index.scss";

.deleteInfo {
    font-family: "cl-regular";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.textArea {
    textarea {
        position: relative;
        top: 20px;
    }
    padding-bottom: 20px;
}

.textCheckbox {
    font-family: "cl-regular";
    font-size: 12px;
    font-weight: 400;
    color: $clara-dark-grey;
    position: relative;
    top: 2px;
}

.errorCheckboxLabel {
    transform: translateY(100%);
    left: 0px;
    width: max-content !important;
    position: absolute;
}

.Checkbox div div label {
    grid-gap: 0 !important;
    gap: 0 !important;
}
