@import "./../../../../../styles/variables/colors.scss";

.Subscriptions {
  padding-left: 3.1538rem;
  padding-right: 3.1538rem;
  padding-top: 3.9231rem;
  display: flex;
  flex-direction: column;
  .title {
    display: flex;
    justify-content: space-between;
  }
  .titleColor {
    color: $clara-blue;
  }
  .buttons{
    display: flex;
    flex-direction: row;
    button {
      margin-left: 0.9rem;
    }
  }
  .subtitle {
    margin-top: 2.1538rem;
    .intro {
      font-size: 1.8462rem;
      margin-right: 0.4615rem;
    }
    .typePlan {
      font-size: 1.8462rem;
      font-weight: bold;
    }
    .typePlanResalt {
      font-size: 1.8462rem;
      font-weight: bold;
      color: $clara-upgrade-color;
    }
  }
  .subtitleType {
    margin-top: 0.7692rem;
  }
  .squeareContainer {
    width: 100%;
    margin-top: 0.9231rem;
    display: flex;
    flex-direction: row;
    gap: 1.8462rem;
  }
  .detailsContainer {
    margin-top: 2.1538rem;
  }
  // .buttonBenefits{
  //   // color: blue;
  //   padding-top: 2rem;
  //   position: ;
  // }
}
