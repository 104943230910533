.InputContainer {
  display: flex;
  position: relative;
  width: 120%!important;  

  .DocumentInput {
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    width: 82%;

    label {
      color: gray;
      margin-top: 30px;
      display: inline;
    }
  }

  .TooltipContainer {
    color: gray;
    display: flex;
    position: absolute;
    right: -80px;
    top: 10px;

    .Tooltip {
      width: 144px;
      margin-left: 10px !important;
    }
  }
}
