@import "./../../../../styles/variables/colors";

.healthCheckAreaContainer {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
  background-color: $clara-white;
}

.TabArea {
  bottom: 0;
  transition: all 1.5s ease 0s;
  position: relative;
  height: 3.1rem;
  width: 100%;
  display: flex;
  > div {
    width: 100%;
  }
}

.skelTabs {
  display: flex;
  flex-direction: row;
  height: 2.3077rem;
  width: 100%;
  margin-top: 1rem !important;
  .skelTabsSpace {
    padding-right: 2.7692rem;
  }
}

.Overview {
  display: inline-block;
  width: 100%;
  height: calc(100% - 4.6154rem);
  overflow: hidden;
}

.ZoneGraph {
  width: 353px;
  background: $clara-white;
  height: 100%;
  border-right: 0.0769rem solid #e4e3e9;
  overflow: auto;
  display: inline-block;
}

.ZoneLink {
  width: calc(100% - 27.2308rem);
  background: $clara-white !important;
  box-shadow: unset;
  height: 100%;
  overflow: hidden;
  display: inline-block;
  box-sizing: border-box;
}

.ZoneChartContent {
  width: 100%;
  height: 100%;
  padding: 3.0769rem 0 3.0769rem 0;
  box-sizing: border-box;
  overflow: hidden;
}

.ZoneChartTitle {
  margin-bottom: 0rem;
  padding: 0 2rem 0 3.8462rem;
  box-sizing: border-box;
  h1 {
    line-height: 2.0769rem;
    margin: 0 0 0.7692rem 0;
    padding: 0;
  }
  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.1538rem;
    display: flex;
    align-items: center;
    margin: 0 0 0 0;
    padding: 0;
  }
}

.ZoneChartCircles {
  overflow: hidden;
  text-align: center;
  display: inline-block;
  width: 100%;
  padding: 0 2rem 0 3.0769rem;
  box-sizing: border-box;
  margin-top: 7.6923rem;
  margin-bottom: 7.6923rem;
  span {
    width: 3.6154rem;
    height: 3.6154rem;
    border-radius: 50%;
    display: inline-block;
    margin: 0 0.7692rem;
    vertical-align: middle;
    text-align: center;
    padding: 1.1538rem 0;
    box-sizing: border-box;
  }
}

.Botonera {
  width: 100%;
  text-align: center;
}

.TextLastRun {
  width: 100%;
  font-size: 1.0769rem;
  margin-top: 0.7692rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Category {
  display: inline-block;
  width: 24.3846rem;
  height: max-content;
  overflow: hidden;
  text-align: left;
  margin: 1.5385rem 2.3077rem;
  .title {
    padding: 1.6923rem 1.5385rem;
    margin: 0;
    border-bottom: 0.0769rem solid #e4e3e9;
    display: flex;
    justify-content: space-between;
  }
}

.Row {
  width: 100%;
  margin-bottom: 1.1538rem;
  a {
    width: 15.8462rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.SubCategories {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 1.6923rem 1.5385rem;
}

.ZoneIssues {
  width: calc(100% - 27.3077rem);
  display: inline-block;
  overflow: auto;
  height: 100%;
}

.headerIssues {
  margin-top: 3.8462rem;
  margin-left: 1.5385rem;
  width: 49.7692rem;
  text-align: left;
  box-shadow: 0rem 0.1538rem 0.7692rem rgba(190, 192, 197, 0.35);
  border-radius: 0.3077rem;
  background: $clara-white;
  border-bottom: 0.0769rem solid #e4e3e9;
  -webkit-border-radius: 0.3077rem;
  -moz-border-radius: 0.3077rem;
  -ms-border-radius: 0.3077rem;
  -o-border-radius: 0.3077rem;
}

.title {
  display: inline-block;
  padding: 1.6923rem 1.2308rem 1.6923rem 1.2308rem;
  margin: 0;
  width: 100%;
  overflow: hidden;
  border-bottom: 0.0769rem solid #e4e3e9;
  box-sizing: border-box;
  position: relative;
}

.h5 {
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 36.1538rem;
}

.issuesTotal {
  display: flex;
  align-items: center;
  text-align: right;
  display: inline-block;
  width: 7.6923rem;
  padding: 0.4615rem 0;
}

.Circles {
  overflow: hidden;
  width: 5.4615rem;
  text-align: right;
  display: inline-block;
  height: 1.1538rem;
  align-items: flex-start;
  span {
    width: 0.4615rem;
    height: 0.4615rem;
    border-radius: 50%;
    display: inline-block;
    margin-left: 0.2308rem;
    vertical-align: middle;
  }
}
