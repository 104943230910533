@import "../../../../styles/variables/colors.scss";

.fullContainer {
    display: flex;
    justify-content: space-evenly; 
    align-items: center;
}

.container {
    max-width: 37.8rem;
    padding-top: 5.2rem;
    padding-bottom: 1.6rem;
    height: 98%;
    display: flex;
    flex-direction: column;
    .formContainer {
        max-width: 37.8rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        div {
            max-width: 37.8rem;
            margin: 0;
        }
    }
    .loginTitle {
        display: flex;
        font-family: "cl-regular";
        font-weight: 400;
        font-size: 22px;
        color: $clara-blue;
        flex-direction: column;
        align-items: center;
        margin-bottom: 8px;
    }
    .fieldContainer {
        margin-bottom: 30px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .forgotPasswordLink {
        a {
            cursor: pointer;
            color: $clara-blue;
        }
        display: flex;
        font-family: "cl-regular";
        font-weight: 400;
        font-size: 14px;
        color: $clara-blue;
        position: relative;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 25px !important;
        margin-bottom: 43px !important;
    }

    .submitButton {
        margin-top: 36px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 515px;
        div {
            justify-content: center !important;
        }
        button {
            width: 200px;
            background-color: $clara-blue;
            font-family: "cl-regular";
            font-weight: 600;
            font-size: 14px;
        }
    }
    .textBetweenButtons {
        display: block;
        width: 37.8rem;
        position: relative;
        justify-self: center;
        margin-top: 18px;
        position: relative;
        left: 20px;
        align-self: center;
        .text {
            margin: 20px;
        }
        .line {
            border-bottom: 1px $clara-light-blue solid;
            display: inline-block;
            width: calc(50% - 28px);
            margin-top: 8px;
        }
        span {
            margin-top: 5px;
            font-family: "cl-regular";
            font-weight: 300;
            font-size: 14px;
            color: $clara-black;
        }
    }
    .linkedInButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 2rem;
        div {
            margin-top: 18px;
        }
        button {
            background-color: #3079b1;
            width: 200px;
            font-family: "cl-regular";
            font-weight: 500;
            font-size: 14px;
            color: $clara-white;
            border-radius: 4px;
            height: 40px;
        }
        .signUpLink {
            font-family: "cl-regular";
            font-weight: 300;
            margin-top: 5px;
            font-size: 14px;
            color: $clara-black;
            a {
                
                margin-left: 0.5rem;
                font-weight: 600;
                color: $clara-blue;
            }
        }
    }
    .footerLinks {
        flex-direction: row;
        align-items: flex-end;
        display: flex;
        justify-content: center;
        margin-top: auto;
        position: relative;
        left: 15px;
        white-space: nowrap;
        .links {
            text-decoration: none;
            font-family: "cl-regular";
            font-weight: 300;
            font-size: 12px;
            margin-right: 30px;
            color: $clara-blue;
        }
    }
}

.footerSeparator {
    position: relative;
    right: 15px;
    color: $clara-light-blue;
}
