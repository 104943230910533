@import "./../../../../styles/variables/colors.scss";

.nextContainer {
  display: flex;
  padding-bottom: 0.5385rem;
  .iconSpace {
    width: 4.7692rem !important;
    height: 5.0769rem !important;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .imageDetail {
      width: 4.7692rem !important;
      height: 5.0769rem !important;
    }
  }
  .textSpace {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .titleFontStyle {
      font-size: 1.5385rem;
      color: $clara-black;
      font-weight: 800;
      line-height: 1.3;
    }
    .subtitleFontStyle {
      font-size: 0.9231rem;
      color: $clara-black;
      font-weight: 400;
      margin-top: 6px;
    }
  }

  .textChat {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .titleFontStyle {
      color: $clara-white;
      font-size: 1.5385rem;
      font-weight: 700;
      line-height: 1.3;
    }
    .subtitleFontStyle {
      font-size: 0.9231rem;
      color: $clara-black;
      font-weight: 400;
      margin-top: 6px;
    }
  }
}

.hitSpace {
  padding-top: 1.2308rem;
  .helpFontStyle {
    font-size: 0.9231rem;
    color: $clara-black;
    font-weight: 400;
  }
}

.addBorder {
  padding-bottom: 0.5385rem;
  border-bottom: 1px solid $clara-light-blue;
}