@import "../../../styles/variables/index.scss";

.root {
    //position: relative;
    color: red;
}
.arrow {
    content: " ";
    position: absolute;
    background: #fff;
    top: 0;
    width: 1.5rem;
    height: 1.5rem;
    display: block;
    transform: translateY(calc(-50% - 1px)) rotate(45deg);
    z-index: 2;
    border-top: 2px solid $clara-grey;
    border-left: 2px solid $clara-grey;
}
.dropdown {
    position: absolute;
    z-index: 1;
    width: 10rem;
    border: 1px solid $clara-grey;
    border-top: 2px solid $clara-grey;
    
    padding: 1rem;
    background: #fff;
}
