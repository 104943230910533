@import "./../../../../styles/variables/colors.scss";
@import "./../../../../styles/variables/index.scss";
.MapInteraction {
   width: 100%;
   height: 100%;
   background:  #fbfcff;
   position: relative;
   overflow: hidden;
}
.ZoomZone{
   transition: transform 0.3s ease 0s;
}
.Botoneras{
   position: absolute;
   right: 1.2308rem;
   z-index: 1;
   transition: all $default-transition-time;
   >div{
      margin-top: 0.6rem;
   }
}

.BotonerasDown {
   bottom: 1.2308rem;
}
.BotonerasUp {
   bottom: 8.3077rem;
}

