@import "./../../../../styles/variables/colors.scss";
.cardProductContainer {
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.headerContainer {
  display: inline-flex;
  align-items: center;
  margin-bottom: 0.8rem;
  h3 {
    font-size: 2.4615rem;
    font-family: "cl-regular";
    font-weight: 300;
    margin: 0;
  }
}

.containerBodyCard {
  height: 7.2rem;
  margin-bottom: 2.3rem;
  font-size: 1.0769rem;
}

.priceContainer {
  display: inline-flex;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-items: center;
  font-size: 1.3846rem;
  font-family: "cl-regular";
  margin-bottom: 2.3rem;
  max-height: 3.4rem;

  p {
    font-size: 2.4615rem;
    line-height: 2.2rem;
    margin: 0 0.4rem;
  }

  label {
    margin-top: 0.3846rem;
    width: 100%;
    font-size: 0.9231rem;
    font-weight: 600;
  }
}

.cardFooterContainer {
  margin-top: 0.5385rem;
  height: 3.1rem;
  width: 100%;
  padding-right: 2.9231rem;
  justify-content: center;
  display: flex;
}

.button {
  margin: 0;
  height: 100%;
  width: 100%;
  color: #fff;
  font-size: 1.5rem;
  border-radius: 0.3846rem;
}
.containerUpgradeScale {
  *,
  h2 {
    color: $clara-upgrade-color;
  }
  button {
    color: $clara-upgrade-color;
    background: $clara-white;
  }
}
.containerUpgradeStart {
  *,
  h2 {
    color: $clara-dark-grey;
  }
}
.ContinerRowSwitch {
  width: 100%;
  padding: 0 49px;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    display: grid;
    width: 100%;
    height: auto;
    grid-template-columns: 1fr 60px 1fr;
    gap: 7px;
    > div {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
  }
}
.SWITHC_root {
  background: $clara-black !important;
  padding: 2px !important;
  border-radius: 13px !important;
  height: 24px !important;
  width: 44px !important;
}
.SWITHC_checked {
  color: #0400e9 !important;
  transform: translateX(20px) !important;
  // background-color: #0400e9;
}

.SWITHC_track {
  background-color: #ffffff !important;
  border-radius: 13px !important;
  // height: 22px;
  // width: 22px;
  opacity: 1 !important;
}
.SWITHC_thumb {
  background: $clara-upgrade-color!important;
  border: 1px solid #ffffff;
  width: 18px !important;
  height: 18px !important;
}
.SWITHC_switchBase {
  padding: 3px !important;
}
.PriceScale {
  display: flex;
  text-align: center;
  flex-direction: column;
  opacity: 0.4;
  transition: all 0.5s;
  &.select {
    opacity: 1;
  }
  h2 {
    font-size: 1.8462rem;
    line-height: 2.2rem;
    margin-bottom: 1.3846rem;
    font-weight: 700;
  }
  p {
    font-size: 0.9231rem;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
    b {
      font-size: 1.8462rem;
      font-weight: 700;
      margin: 0 2px;
      line-height: 21px;
    }
  }
}
.PriceStart {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 1.3846rem;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: center;
      font-weight: 600;
      b {
        font-size: 2.4615rem;
        font-weight: 600;
        margin: 0 2px;
        line-height: 21px;
      }
    }
  }
  > p {
    font-weight: 600;
    margin-top: 62px;
    font-family: 14px;
  }
}
.ContinerRowSwitchV0 {
  > div {
    grid-template-columns: 1fr !important;
  }
}
