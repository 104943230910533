.ClaraScore {
    position: relative;
    display: inline-block;
    width: 100%;
    height: auto;
    vertical-align: top;
    
}
.ClaraContent{
    display: inline-block;
    width: 40px;
    background: #FFF;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    top: 10px;
    right: 0;
}
.ClaraContentPercent{
    display: inline-block;
    width:40px;
    margin: 0px;
    cursor:pointer;
}
.ClaraText{
    width: 103px;
    color: #97a7bd;
    font-size: 13px;
    margin: 20px auto;
    text-align: left;
    display: inline-block;
}
.ClaraScoreHoverOn{
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(44, 116, 255, 0.9);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    cursor:pointer;
    &:before{
        content: " ";
        background-color: #FFF;
        border: 1px solid #fff;
        display: inline-block;
        -webkit-mask-size: 16px 16px;
        width: 16px;
        height: 16px;
        position: relative;
        -webkit-mask: url(../../../../images/hover_plus.svg) no-repeat center;
        margin:11px
    }
}
.ClaraScoreHoverOff{
    display:none;
}