@import "../../../../../styles/variables/index";

.text {
  font-weight: 700;
  font-size: 1.3846rem;
  color: $clara-black;
}
.moneyScratch {
  font-weight: 700;
  font-size: 0.7692rem;
  color: $clara-black;
  text-decoration:line-through;
}
.priceScratch {
  font-weight: 700;
  color: $clara-black;
  text-decoration:line-through;
}
.money {
  font-weight: 700;
  font-size: 0.7692rem;
  color: $clara-black;
}
.price {
  font-weight: 800;
  font-size: 1.8462rem;
  color: $clara-black;
}
.cornerContainer {
  display: flex;
  position: absolute;
  right: -0.4px;
  top: -0.4px;
  .cornerDetail {
    width: 10.3846rem;
    height: 2.125rem;
    background-color: $clara-blue;
    border-top-right-radius: 0;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 1.5385rem;
    display: flex;
    flex-direction: column;
    color: $clara-white;
    .textCorner {
      font-size: 1rem;
      font-weight: 800;
    }
  }
  .cornerRounder {
    width: 1.3846rem;
    height: 3.9231rem;
    background-color: $clara-blue;
    .cornerAngle {
      width: 1.3846rem;
      height: 3.9231rem;
      background-color: white;
      border-top-right-radius: 1.0769rem;
    }
  }
}
