@import "./../../../../styles/variables/colors.scss";

.TemplateLabel {
  grid-template-columns: 17.3846rem 1fr !important;
}

.buttonCheckBox {
  width: 12rem;
  height: 10rem;
}

.CheckboxButton {
  [data-contentButton="true"] {
    width: 146px;
  }
}
