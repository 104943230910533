@import "./../../../../styles/variables/colors.scss";
.CheckboxButtonSimple{
  width: 100%!important;
  margin-bottom: 16px!important;
  >div{
    width: 100%!important;;
    >div{
      width: 100%!important;
    }
  }
}

.CheckboxButtonSimpleButton{
  width: 100%!important;
  text-align: left;
  padding: 1.9231rem 4.3846rem;
}
.Input{
   width: 100%;
   
}
.CheckboxButton{
    [data-contentButton='true']{
        width: 146px;
    }
}
.TemplateForm {
    position: relative;
    width: 100%;
    height: calc(100% - 50px)!important;
    >div {
      display: grid;
      grid-template-rows: 1fr 6.0769rem;
      grid-template-columns: 1fr;
      width: 100%;
      height: 100%;
    }
    .Scrollbar {
      margin-top: 2.6154rem;
      width: 100%;
      height: 100%;
      display: block;
    }
    .Content {
      padding: 0.9231rem 8.6923rem;
      width: 100%;
      height: 100%;
      .Spacer {
        margin-bottom: 1.8462rem
      }
      .Form {
        width: 100%;
        height: 100%;
        display: flex;
        gap: 1.8462rem;
        flex-direction: column;
      }
    }
  }
  
  .Botonera {
    display: flex;
    width: 100%;
    padding: 0.9231rem 8.6923rem ;
    height: auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    button{
      width: 100%!important;
      min-height: 3.0769rem!important;
      margin-bottom: 0.7692rem!important;
    }
  }
  
  .skeletonContainer {
    display: flex;
    flex-direction: row;
    grid-gap: 1.8462rem;
    gap: 1.8462rem;
  }
  
  .TemplateLabel {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 16.4615rem 1fr;
    grid-column-gap: 1.5385rem;
    .skeletonList {
      display: flex;
      flex-direction: column;
      gap: 1.8462rem;
      align-items: flex-start;
    }
    > * {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }
  
  .showSkeleton {
    position: absolute;
    width: 100%;
    background-color: $clara-white;
    height: 51.4rem !important;
    z-index: 2;
  }
  