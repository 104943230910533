@import "./../../../../../styles/variables/colors.scss";

.CheckboxButton {
  width: 100% !important;
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 2.4615rem !important;
  padding: 1.4615rem 2rem 1.4615rem 4.1538rem !important;
}
.PaymentTotalContent {
  border-radius: 15px;
  background-color: $clara-light-blue;
  width: 35.2rem;
  height: 39rem;
  padding: 24px;
  overflow: hidden;
  display: flex;
  justify-self: flex-end;
  align-items: flex-start;
}
