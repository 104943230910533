.tabArea {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  .tabContents {
    height: calc(100% - 3.4615rem);
    width: 100%;
    >div{
      height: 100%;
      width: 100%;
      &.hidden{
        display: none;
      }
    }
  }
}

.tabAreaHeight {
  height: unset !important;
}