.SPVEligibilityCheckboxContainer {

}

.SPVEligibilityCheckboxButton {
  padding: 42px;
}

.secondQuestionContainer {
  margin-top: 20px;
  > h4 {
    margin-bottom: 20px;
  }
}