@import "../../../styles/variables/index";
@import "~./../../../styles/extends/positions.scss";
.PasswordInput {
    width: 100%;
    display: block;
    position: relative;
    outline: none;
}
.PasswordStrength {
    margin: 0.1538rem 0;
    path:first-child {
        stroke: $clara-grey !important;
    }
}
.StrongBad {
    path {
        stroke: $clara-red;
    }
}
.StrongWarning {
    path {
        stroke: $clara-yellow;
    }
}
.StrongStrong {
    path {
        stroke: $clara-green-password;
    }
}

.input {
    font-size: 1rem;
    display: block;
    width: 100%;
    padding: 0.75rem 2.5rem 0.75rem 0.93rem;
    border: 1px solid #c2cfe0;
    color: $color-input;
    border-radius: 0.3846rem;
    box-sizing: border-box;
    outline: none;

    &:focus {
        border-color: $bg-primary;
    }
}

.input[type="number"]::-webkit-inner-spin-button,
.input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.borderErrorInput {
    border-color: $color-error;
    &:focus {
        border-color: $color-error !important;
    }
}

.tooltipPlacementRight {
    background: #ffffff !important;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25) !important;
    border-radius: 4px !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 13px !important;
    line-height: 17px !important;
    color: #848f99 !important;
    position: relative;
    &:before {
        content: "▲";
        width: 0;
        height: 0;
        color: #fff;
        position: absolute;
        left: -12px;
        top: 75%;
        transform: rotate(270deg) translateX(-50%);
        text-shadow: -1px -2px 4px rgba(0, 0, 0, 0.25);
    }
}

.iconEye {
    position: absolute;
    right: 0.7692rem;
    top: 0.5385rem;
    color: $clara-blue;
    cursor: pointer;
}

.errorContainer {
    div svg {
        position: relative;
        top: 1px;
    }
    position: relative;
}
