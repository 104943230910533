.skelTabs {
  display: flex;
  flex-direction: row;
  height: 30px;
  width: 100%;
  .skelTabsSpace {
    padding-right: 2.7692rem;
  }
}
.DetailsTabTitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 0 19px 0;
  .Botonera{
      display: flex;
      justify-content: flex-end;
      gap: 12px;
  }
}