.flexOptionLocation {
  > div {
    flex-wrap: wrap !important;
  }
}

.center {
  justify-content: center;
  align-items: center;
}

.buttonShare {
  width: 100% !important;
  height: auto !important;
}

.buttonLocation {
  width: 11rem !important;
}

.buttonOwnershipContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.ownershipTexts {
  margin-left: 1.5rem;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.buttonTextListContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  padding-left: 1.6rem;
}

.containerColumns {
  display: flex;
  flex-direction: row;
  text-align: left;
  margin-top: 0.7692rem;
}

.containerColumnsNoMargin {
  display: flex;
  flex-direction: row;
  text-align: left;
}

.containerButtonFlag {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containerList {
  li {
    padding: 0.5rem 0rem;
  }
}

.separator {
  margin-left: 3.8462rem;
}

.midleSize {
  width: 50%;
}

.specialSize {
  width: 268px;
}

.searchSize {
  width: 30.2308rem;
}

.sizeTwoLines {
  height: 2.4615rem;
  line-height: 1.2;
}

.sizeList {
  padding: 0.3077rem !important;
}

.sizeListSmall {
  padding: 0 !important;
}

.sizeU {
  margin: 0;
  list-style: none;
  li::before {
    content: "\2022";
    color: black;
    font-weight: bold;
    display: inline-block;
    width: 2em;
    margin-left: -2.6em;
    margin-top: 0.3077rem;
  }
}

.specialLineHeight {
  line-height: 1.4;
}

.buttonContainer {
  display: grid;
  grid-template-columns: 4.5385rem 1fr;
  grid-template-rows: 1fr;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 1.2308rem;
}

.buttonCheckBox {
  // padding-left: 3.0769rem;
  padding-right: 1.5385rem;
  padding-top: 1.5385rem;
  padding-bottom: 1.5385rem;
  .AddStartup{
    padding: 47px 24px 24px 24px!important;
  }
}

.textComing {
  font-size: 0.9231rem;
}

.inactiveTitle {
  font-weight: 800;
  text-align: center;
}

.newButtonJurisdiction {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.smallSelect {
  button {
    width: 12.3077rem !important;
    padding-left: 1.5385rem !important;
    min-height: 11.8462rem !important;
  };
  // div > div:nth-child(1) > button, div > div:nth-child(2) > button {
  //   width: 24.3077rem !important;
  //   padding-left: 3.0769rem !important;
  // }
}

.buttonCheckBoxSize {
  width: 12rem;
  height: 10rem;
}