
.errorClaraInput{
    color: #b91616;
    font-size: 11px;
    position: relative;
    margin-top: -15px;
    margin-bottom: 15px;
}
.required{
    color: #ff6f7c;
    margin-left:3px;
    display: inline-block;
}
.inputEmpty{
    background: transparent;
}
.inputFill{
    background: rgba(44, 116, 255, 0.1);
}
