@import "./../../../../styles/variables/index.scss";
.ClaraSelectContainer {
    width: 100%;
    display: block;
    text-align: left;

    span {
        position: relative;
        display: inline-block;
        text-align: left;
        width: 100%;
        hr {
            &:last-child {
                display: none;
            }
        }
    }
}
.ClaraSelectZoneInput {
    display: inline-block;
}
.borderErrorInput {
    border-color: $color-error !important;
    &:focus {
        border-color: $color-error !important;
    }
}
.claraCountryInputSelected {
    width: 100%;
    height: 3.0769rem;
    padding: 0 4.6154rem 0 4.6154rem;
    border: 0.0769rem solid #c2cfe0;
    border-radius: 5px;
    outline: none;
    position: relative;
    &:focus {
        border-color: #2c74ff;
    }
    &:disabled {
        background: rgba(212, 212, 212, 0.5);
    }
}
.claraSelectInput {
    width: 100%;
    height: 3.0769rem;
    padding: 0 4.6154rem 0 1rem;
    border: 1px solid #c2cfe0;
    border-radius: 0.3846rem;
    // margin: 20px 0;
    outline: none;
    position: relative;
    &:focus {
        border-color: #2c74ff;
    }
    &:disabled {
        background: rgba(212, 212, 212, 0.5);
    }
}
.claraSelectSelected {
    background: rgba(234, 241, 254, 0.5);
}

.claraSelectArrow {
    content: " ";
    width: 0.625rem;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #bec9d7;
    position: absolute;
    right: 1.4375rem;
    top: 1.3625rem;
}
.Modal {
    width: 100%;
    position: absolute;
    background: #ffffff;
    box-shadow: 0px 0.3077rem 0.7692rem rgba(0, 0, 0, 0.1);
    border-radius: 0.3077rem;
    padding: 0.625rem 0;
    z-index: 1;
    max-height: 31.25rem;
    opacity: 0;
    transition: all 0.5s;
    display: hidden;
}

.ModalDouble {
    width: 200% !important;
}

.zoneAdd {
    width: 100%;
    padding: 0;
    a {
        cursor: pointer;
        padding: 0.7692rem 1.9231rem 0.7692rem 3.8462rem;
        &:before {
            left: 1.9231rem;
            top: 0.9231rem;
        }
        &:hover {
            background: #eee;
        }
    }
}
.multiselectLink {
    padding: 0.7692rem 1.9231rem 0.7692rem 4.6154rem;
    input {
        margin-right: 0.7692rem !important;
        width: auto !important;
    }
}
.multiselectTitle {
    padding: 0.7692rem 1.9231rem 0.7692rem 1.8462rem;
    margin: 0;
}
.zoneList {
    width: 100%;
    z-index: 1000;
    > div {
        img {
            position: relative;
            bottom: 0.3846rem;
        }
        cursor: pointer;
        display: flex;
        width: 100%;
        text-align: left;
        color: #192a3e;
        padding: 0 1.9231rem;
        min-height: 30px;
        align-items: center;

        &:hover {
            background: #eee;
        }
    }
}
.zoneNewData {
    display: block;
    width: 100%;
    margin: 1.5385rem 0;
    position: relative;
}
.Separator {
    border: unset;
    height: 0.0769rem;
    background: #eaf1fe;
    margin: 0;
}
.inputLabel {
    width: 23.3077rem !important;
    display: inline-block;
    margin-right: 10px !important;
}
.buttonInputLabel {
    width: 5.1538rem !important;
    min-width: 5.1538rem !important;
    height: 3.0769rem;
    position: absolute;
    right: 0;
    top: 0;
}
.Error {
    border: 1px solid #ff606f;
}
.MessageError {
    display: block;
    width: 100%;

    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    text-align: right;
    color: #ff606f;
}
.Show {
    display: block;
}
.Hide {
    display: none;
}
.Selected {
    background: #eee !important;
}
.Loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: rgba(226, 226, 226, 0.5);
    height: 100%;
    div {
        zoom: 0.5;
        top: 20px;
        position: absolute;
        left: 10px;
    }
}
.selectedKey {
    background: #eee;
}
.claraButtonClearValue {
    width: 15px;
    height: 15px;
    background: $input-complete;
    color: $color-link;
    position: absolute;
    right: 19px;
    top: 15px;
    text-align: center;
    line-height: 15px;
    cursor: pointer;
    &:hover {
        color: $palette-clara;
    }
}
.Poppper {
    background: red;
    z-index: 2;
}

.openModal {
    opacity: 1;
    display: block;
}

.optionsSeparator {
    width: 90%;
    border: 0.0769rem solid #eaf1fe;
    border-radius: 4px;
}

.fixedBlueOption {
    color: #2c74ff !important;
}

.skeleton {
    border-radius: 5px;
}

.customMessage {
    width: 100%;
    min-height: 2.4615rem;
    display: flex;
    color: #2c74ff;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0.6154rem;
    padding-right: 0.6154rem;
    gap: 0.7692rem;
}
