@import "./../../../../styles/variables/colors.scss";

.ContentAssitance {
  height: 100%;
  background-color: $clara-light-blue;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-left: 1px solid #ebeff2;
  box-shadow: 10px 17px 40px 0px rgba(0, 0, 0, 0.04);
  .bubbleSpace {
    flex: 1;
    height: calc(100% -  3.4615rem);
  }
}

.tabsArea {
  height: 3.4615rem !important;
  background-color: $clara-white;
}

.singleTab {
  padding-top: 1.7rem !important;
  margin: 0rem !important;
}

.flexTabsContainer {
  justify-content: space-around !important;
  height: 3.4615rem !important;
  background-color: $clara-white;
}

.notVisible {
  display: none !important;
}