@import "../../../../styles/variables/colors.scss";

.allContainer {
  height: 100%;
  width: 100%;
  background-color: $clara-light-blue;
  .AssistanceBubbleContainer {
    height: calc(100% - 4.8rem) !important;
  }
  .AssistanceBubbleSpace {
    height: 100%;
  }
}