@import "../../../../styles/variables/index";
@import "~./../../../../styles/extends/positions.scss";
.FormAdd {
    @extend .flex-top;
    @extend .flex-column;
    align-items: flex-start;
    margin: 9.3077rem 0 0 7.6923rem;
    width:53.8462rem;
    font-weight:normal;
    h1 {
        font-size: 1.3846rem;
        font-weight:normal;
        margin:0.7692rem 0;
        text-align: left;
        width:100%;
    }
    h2 {
        color: $color-label;
        font-weight:normal;
        text-align: left;
        margin:0;
        width:100%;
        font-size:1rem;
    }
    .FormChildren{
        width:100%;
    }
    p {
        color: $color-label;

    }
}
