@import "./../../../../../styles/variables/colors.scss";

.blockContainer {
  width: 100%;
  border: 0.0769rem solid $clara-light-blue;
  border-radius: 0.3077rem;
  margin-bottom: 2.6923rem;
  padding: 2.3077rem;
  .titleMargin {
    margin-bottom: 3.0769rem;
  }
}

// .flowQuestion {
//     font-size: 1.3846rem;
//     font-weight: 400;
// }
