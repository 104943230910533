@import "./../../../../../styles/variables/colors";

.messagesAreaContainer {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
  background-color: $clara-white;
}

.containterPanelMessages {
  width: 100%;
  height: 100%;
  padding-left: 2.3077rem;
}

.containerSearchAndButtonFilter {
  display: inline-flex;
  width: 100%;
  align-items: center;
}

.divsearch {
  display: flex;
  flex-direction: row;
  height: 4.6154rem;
  width: 100%;
  padding-left: 2.3077rem;
  align-items: flex-end;
  .iconContainer {
    margin-left: 0.7692rem;
    top: -0.4615rem;
    position: relative;
  }
}

.TabArea {
  padding-left: 2.3077rem;
  bottom: 0;
  transition: all 1.5s ease 0s;
  position: relative;
  height: 3.1rem;
  width: 100%;
  display: flex;
  > div {
    width: 100%;
  }
}

.skelTabs {
  display: flex;
  flex-direction: row;
  height: 2.3077rem;
  width: 100%;
  margin-top: 1rem !important;
  .skelTabsSpace {
    padding-right: 2.7692rem;
  }
}

.containerZonaMessages {
  width: 100%;
  position: relative;
  height: calc(100% - 7.5rem);
}

.containerThreadMessage {
  height: 100%;
  width: max-content;
  padding: 3rem 2rem;
  display: inline-flex;
  flex-direction: column;
}

.containerButtonEvent {
  width: 43.2308rem;
  padding-left: 2.7692rem;
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  button {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.containerMessageTemplateWithIcon {
  width: max-content;
  position: relative;
  padding-left: 2.7692rem;
  padding-bottom: 1rem;
  display: inline-flex;
  flex-direction: column;
}

.containerIcon {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(50%);
}

.containerMessageTemplate {
  max-width: 65.3846rem;
  min-width: 38.4615rem;
  padding: 1rem 1.5rem;
  box-sizing: border-box;
  border: 0.0769rem solid $color-border-line;
  border-radius: 1.8462rem 1.8462rem 1.8462rem 0;
}

.containerStakeholderTemplate {
  ul {
    padding-inline-start: 0;
    list-style-type: none;
  }
}

.containerZoneMessagesPanel {
  width: 100%;
  height: 100%;
  padding-left: 2.3077rem;
}

.containerMessage {
  width: 100%;
  padding: 1rem;
  position: relative;
  display: block;
  padding-bottom: 0;
  margin-top: 1rem;
  .iconMessage {
    position: absolute;
    top: 0.1154rem;
    left: 0.1538rem;
  }
}

.containerCardMessage {
  width: 27.6154rem;
  border: 0.0769rem solid $color-border-line;
  box-sizing: border-box;
  border-radius: 0.3846rem;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1.1538rem auto 1.1538rem;
  grid-template-rows: 1.1538rem minmax(2.3077rem, auto) 1.1538rem;
  gap: 1rem 1rem;
}

.textMessage {
  order: 4;
  grid-column-start: 1;
  grid-column-end: 3;
}

.dateMessage {
  order: 7;
  grid-column-start: 1;
  grid-column-end: 3;
}

