@import "../../../../../../styles/variables/colors.scss";

.graphsContainer {
  width: 100%;
  border: 2px solid $clara-light-blue;
  border-radius: 0.7692rem;
  -webkit-border-radius: 0.7692rem;
  -moz-border-radius: 0.7692rem;
  -ms-border-radius: 0.7692rem;
  -o-border-radius: 0.7692rem;
  padding: 1.7692rem;
  display: flex;
  gap: 1.2308rem;
}

.Class {
  height: 11.5385rem;
  display: flex;
  .graphContainer {
    height: 11.5385rem;
    width: 11.5385rem;
    margin-right: 3.8462rem;
  }
}

.legendContainer {
  width: 15.3846rem !important;
  margin-top: 0.6154rem !important;
  height: calc(100% - 2.3846rem) !important;
}

.legendContainer::-webkit-scrollbar-thumb {
  background-color: $clara-grey;
  border-radius: 0.3846rem;
}