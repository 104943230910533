// @import "../../../../../styles/variables/index.scss";

@import "./../../../styles/variables/index.scss";

$border-radius-modal: 5px !default;
$border-with-modal: 0.5rem !default;
$max-height-modal: 51.9rem !default;
$height-modal-tour: 51.9rem !default;
// $size-with: calc(86.9231rem + 3.0769rem);

.containerButtonsModalTour {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 2.4rem;
    padding-right: 3.6rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    height: 6.1538rem;
    border-top: 1px solid #eaf1fe;
    // overflow: hidden;container-blocks-modal-tour

    .containerStepsDots {
        max-height: max-content !important;
    }
}

.container_modal_tour {
    .MuiDialog-paper {
        margin-left: 0px !important;
        margin-right: 0px !important;
        padding-right: 3.0769rem;
        box-sizing: border-box !important;
        border-top: none;
        position: relative;
        box-shadow: none;
        background: transparent;
        overflow: hidden;
    }
    .MuiDialog-paperWidthMd {
        // max-width: $size-with;
        max-width: none !important;

        box-sizing: border-box;
        overflow: visible;
    }
}
.containerTour {
    border-top: $border-with-modal solid $clara-blue;
    border-radius: $border-radius-modal;
    box-sizing: content-box;
    max-width: 86.9231rem;
    background: #fff;
    box-shadow: $box-shadow !important;
    height: $height-modal-tour;
}

.containerBlocksModalTour {
    height: $height-modal-tour - 0.9rem - 2.6rem - 2.6rem - $border-with-modal;
    box-sizing: border-box !important;
    width: 86.9231rem;
    display: grid;
    grid-template-columns: 39rem 1fr;
    grid-template-rows: 100%;
    overflow: visible;
}

.rightContentBlockModalTour {
    box-sizing: border-box;
    padding-top: 4.2rem;
    padding-bottom: 1.2rem;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    align-items: flex-end;
    overflow: visible;
    padding-right: 4.9rem;

}

.leftContentBlockModalTour {
    padding-top: 6.6rem;
    padding-left: 4.9rem;
    padding-bottom: 1.2rem;
    // padding-right: 2.4rem;
    height: 100%;
    box-sizing: border-box;
    align-items: center;

    > div {
        padding-bottom: 1.8462rem;
    }
}

.containerButtons{
    display: flex;
    >div{
        padding-left: 2.0769rem;
    }
}