@import "../../../../../../../styles/variables/colors.scss";

.LetsPreApproveCheckboxButton {
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.LetsPreApproveCheckboxOption {
    width: 100px;
    min-width: 100px;
}

.fakeLink {
    color: $clara-blue;
    cursor: pointer;
}