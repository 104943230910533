$zIndexLeft: 50;
$zIndexRight: 100;
$zIndexCircle: 75;
$circleSize: 350px;
$circleSizeSmall: 15px;
.selectGenericForm {
  background: #fff;
}
.genericForm {
  width: 100%;
  height: 100%;
  margin: auto;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  .genericFormExit {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: $zIndexRight * 2;
    -webkit-mask-size: 12px 12px;
    width: 12px;
    height: 12px;
    -webkit-mask: url(../../../images/close.svg) no-repeat center;
  }
}
.contentStakeHolder {
  margin: auto;
  background: #fff;
  h1 {
    
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    text-align: center;
    color: #192a3e;
    width: 614px;
    margin: 130px auto 50px;
  }
}

.EquityTooltipContainer {
  position: absolute;
  right: 20px;
  top: 20px;

  &:hover {
    cursor: pointer;
  }

  .EquityTooltip {
    position: absolute;
    background-color: white;
    padding: 20px;
    z-index: 10;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    top: 2px;
    left: 30px;
    width: 221px;
  }
}

.docTypeCard {
  padding: 15px;
  position: relative;
  width: 280px;
  height: 357px;
  background: #ffffff;
  border: 1px solid #eaf1fe;
  box-sizing: border-box;
  display: inline-block;
  margin: 20px;
  border-radius: 4px;
  i{
    font-size: 16px;
    position: relative;
    left: 150px;
    top: 15px;
    visibility: hidden;
    
  }
  span {
    color: #2c74ff;
    font-size: 96px;
    &::before {
      color: #2c74ff !important;
    }
  }
  p {
    margin-top: 0;
    margin-bottom: 10px;
    height: 85px;
  }
  button {
    background: #fff;
    border: 1px solid #2c74ff;
    border-radius: 4px;
    width: 118px;
    line-height: 29px;
    height: 35px;
    font-size: 13px;
    color: #2c74ff;
    margin: 35px auto 0;
    &:hover {
      background: #2c74ff;
      border-radius: 4px;
      color: #fff;
    }
  }

  &:hover {
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
}

.docTypeCard:hover i{
  visibility: visible;
}

.docTypeCardsContainer {
  position: relative;
  max-width: 840px;
  left: calc(50% - 420px);
}
.docTypeCardSelected {
  position: relative;
  width: 280px;
  height: 357px;
  background: #ffffff;
  border: 1px solid #eaf1fe;
  box-sizing: border-box;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  button {
    background: #2c74ff;
    border-radius: 4px;
    width: 118px;
    line-height: 29px;
    height: 30px;
    font-size: 13px;
    color: #fff;
    margin: 35px auto 0;
  }
}

.card {
  position: relative;
  width: 320px;
  height: 424px;
  margin: 0 15px 0;
  background: #ffffff;
  border: 1px solid #eaf1fe;
  box-sizing: border-box;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  padding: 61px 26px;
  box-sizing: border-box;
  
  font-style: normal;
  font-weight: normal;
  text-align: center;
  color: #65707e;
  font-size: 11px;
  h2 {
    font-size: 18px;
    line-height: 23px;
    margin: 25px 0 18px 0;
    color: #192a3e;
  }
  button {
    background: #2c74ff;
    border-radius: 4px;
    width: 118px;
    line-height: 29px;
    height: 30px;
    font-size: 13px;
    color: #fff;
    margin: 35px auto 0;
  }
}
