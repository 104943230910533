.Legends {
    display: flex;
    height: 170px;

    label{
        display: inline-block;
        margin-bottom: 8px;
        font-size: 12px;
        span{
            display: inline-block;
            margin-right: 25px;
            border-radius: 50%;
            width: 8px;
            height: 8px;

        }
    }
}

.FiveLegendsGroup {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-left: 30px;
    .LegendDiv {
        display: flex;
        align-items: center;
        width: 200px;
    }
}
