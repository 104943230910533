@import "./../../../../../../../styles/variables/colors.scss";
.TitleDocumentUploaded {
    display: flex;
    width: 100%;
    padding: 32px 25px  32px 35px;
    justify-content: space-between;
    border-bottom: 1px solid $clara-light-blue;
    div{
        gap: 12px;
        display: flex;
    }
    
}