.containerEquity{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
 // align-items: flex-end;
  padding-top:3rem ;
  padding-bottom: 1rem;
  label{
    color: grey;
  }
}
.inputShareModal{
  textarea{
    width: 14rem !important;
  }
}
.inputModal{
  word-break: break-word;
  white-space: pre-wrap;
  textarea{
    width: 11rem !important;
  }
}
.inputEquity{
  width: 195px;
  word-break: break-word;
  white-space: pre-wrap;
  textarea{
    width: 14rem !important;
  }
}
.inputCodeModal {
  margin-bottom: auto;
  input {
    width: 20rem !important;
  }
  > div {
    margin: 0.75rem 0 0 0;
  }

}
.inputCode {
  margin-bottom: auto;
  input {
    width: 20rem !important;
  }
  > div {
    margin: 0.75rem 0 0 0;
  }

}
.inputCodeModalOther {
  margin-bottom: auto;
  input {
    width: 10rem !important;
  }
  > div {
    margin: 0.75rem 0 0 0;
  }

}
.inputCodeOtherSelect {
  margin-bottom: auto;
  input {
    width: 10rem !important;
  }
  > div {
    margin: 0.75rem 0 0 0;
  }

}
.inputOtherModal{
  word-break: break-word;
  white-space: pre-wrap;
  input{
    width: 9.5rem !important;
  }
}
.InputContainer {
  display: inline-block;
  // position: relative;
  width: 100% !important;
  margin-bottom: 10px;
  // text-align: left;

  .TextInput {
    // display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
    width: 83%;
    margin-left: 45px;

    .TextInputChildren {
      margin-left: 10px;
    }

    label {
      color: gray;
      font-size: 13px;
      margin-bottom: -5px;
      // margin-top: 30px;
    }

    input {
      font-size: 13px;
      width: 105%;
      height: 40px;
      padding: 0 60px 0 20px;
      border: 1px solid #c2cfe0;
      border-radius: 5px;
      // margin: 20px 0;
      outline: none;
      display: block;

      &:focus {
        border-color: #2c74ff;
      }
    }
    .ChatTextInput {
      position: relative;
      left: calc(50% - 90px);
    }
    .Error {
      border: 1px solid red !important;
    }
  }
}
.containerFounderName {
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 120%;
    color: #192a3e;
    width: auto;
    padding: 0.5rem 0.5rem 0.5rem 0;
    margin-bottom: 1rem;
  }
}
.textArea{
  width: 100% !important;
  > div {
    width:auto !important;
  }
}
.deleteButton{
  margin-bottom: auto;
  &:hover {
    cursor: pointer;
  }
}