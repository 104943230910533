.TitleHealthCheck {
    display: flex;
    width: 100%;
    padding: 32px 25px  0 35px;
    justify-content: space-between;
    div{
        gap: 12px;
        display: flex;
    }
    
}