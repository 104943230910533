@import "./../../../../styles/variables/index.scss";
.ClaraSelectContainer {
  width: 100%;
  display: block;
  text-align: left;
  flex-wrap: wrap;
  label {
    color: gray;
    text-align: left;
    line-height: 40px;
    width: 296px;
    display: inline-block;
  }
  span {
    position: relative;
    display: inline-block;
    text-align: left;
    max-width: 380px;
    hr {
      &:last-child {
        display: none;
      }
    }
  }
}
.ClaraSelectZoneInput {
  display: inline-block;
}
.flag {
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  img {
    margin-left: 5px;
  }
}

.ImageFlag {
  position: absolute;
  margin-top: 5px;
  margin-left: 20px;
}
.CountrySelected {
  position: absolute;
  left: 10px;
  top: 8px;
  z-index: 2;
}
.claraCountryInputSelected {
  font-size: 13px;
  width: 100%;
  height: 40px;
  padding: 0 60px 0 60px;
  border: 1px solid #c2cfe0;
  border-radius: 5px;
  // margin: 20px 0;
  outline: none;
  position: relative;
  &:focus {
    border-color: #2c74ff;
  }
  &:disabled {
    background: rgba(212, 212, 212, 0.5);
  }
}
.claraSelectInput {
  font-size: 13px;
  width: 380px;
  height: 40px;
  padding: 0 60px 0 20px;
  border: 1px solid #c2cfe0;
  border-radius: 5px;
  // margin: 20px 0;
  outline: none;
  position: relative;
  &:focus {
    border-color: #2c74ff;
  }
  &:disabled {
    background: rgba(212, 212, 212, 0.5);
  }
}
.claraSelectSelected {
  background: rgba(234, 241, 254, 0.5);
}

.claraSelectArrow {
  content: " ";
  width: 10px;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #bec9d7;
  position: absolute;
  right: calc(0% + 15px); // 23px
  top: 17px;
}
.Modal {
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 10px 0;
  z-index: 1;
  max-height: 500px;
}

.limitedModalSize{
  max-height: 25vh !important;
}
.zoneAdd {
  width: 100%;
  padding: 0;
  a {
    cursor: pointer;
    padding: 10px 25px 10px 50px;
    &:before {
      left: 25px;
      top: 12px;
    }
    &:hover {
      background: #eee;
    }
  }
}
.multiselectLink {
  padding: 10px 25px 10px 60px;
  input {
    margin-right: 10px !important;
    width: auto !important;
  }
}
.multiselectTitle {
  padding: 10px 25px 10px 24px;
  margin: 0;
}
.zoneList {
  width: 100%;
  a {
    cursor: pointer;
    display: block;
    width: 100%;
    text-align: left;

    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 13px;
    color: #192a3e;
    padding: 10px 25px;
    &:hover {
      background: #eee;
    }
  }
}
.zoneNewData {
  display: block;
  width: 100%;
  margin-top: 1rem !important;
  position: relative;
}
.Separator {
  border: unset;
  height: 0.0769rem;
  background: #eaf1fe;
  margin: 0;
}
.inputLabel {
  width: 303px !important;
  display: inline-block;
  //margin-right: 10px !important;
}
.buttonInputLabel {
  width: 67px !important;
  min-width: 67px !important;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
}
.Error {
  border: 1px solid #ff606f!important;
}
.MessageError {
  display: block;
  width: 100%;

  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  text-align: right;
  color: #ff606f;
}
.Show {
  display: block;
}
.Hide {
  display: none;
}
.Selected {
  background: #eee !important;
}
.Select{
  color: #2c74ff;
  display: inline-block;
  line-height: 17px;
  width: 100%;
  text-align: left;
  position: relative;
  padding-left: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;

  /* identical to box height */

  display: flex;
  align-items: center;
}
.Loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: rgba(226, 226, 226, 0.5);
  height: 100%;
  div {
    zoom: 0.5;
    top: 20px;
    position: absolute;
    left: 10px;
  }
}
.claraButtonClearValue {
  width: 15px;
  height: 15px;
  background: $input-complete;
  color: $color-link;
  position: absolute;
  right: calc(0% + 15px); // 19px;
  top: 15px;
  text-align: center;
  line-height: 15px;
  cursor: pointer;
  &:hover {
    color: $palette-clara;
  }
}
.Poppper {
  background: red;
  z-index: 2;
}
