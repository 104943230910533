.container {
  display: flex;
  flex-direction: column;
  width: 29rem;
  height: 100%;
}

.containerIlustration {
  padding-top: 0.9231rem;
  width: 29rem;
  display: block;
}
