.radio {
  flex-basis: auto;
}
.radioContainer {
  gap: 1rem;
}
.text {
  width: 25%;
  input {
    width: 25%;
  }
}
