.AddButton {
  color: blue;
  text-align: left;
  font-size: 15px;
  margin-top: 30px;
  margin-bottom: 30px;

  &:hover {
    cursor: pointer;
  }

  p {
    
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #2c74ff;
    margin: 0 0 0 10px;
    display: inline;
    line-height: 25px;
  }
}
.AddInputFull{
  widows: 100%;
  height:auto;
  position: relative
}
.Remove {
  color: #65707E;
  cursor: pointer;
  width: 100%;
  text-align: right;
  display: block;
  &:before {
    content: " ";
    background-color: #FF606F;
    margin: 0 0 -3px 0;
    display: inline-block;
    margin-left: 2px;
    -webkit-mask-size: 14px 14px;
    width: 14px;
    height: 14px;
    position: relative;
    -webkit-mask: url(../../../../images/form_remove.svg) no-repeat center;
    margin-right: 8px;
  }
}
.AddButtonFull {
  color: blue;
  text-align: left;
  font-size: 15px;
  

  &:hover {
    cursor: pointer;
  }

  p {
    
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #2c74ff;
    margin: 0 0 0 10px;
    display: inline;
    line-height: 25px;
  }
}
