@mixin transform-translateY($top){
    transform: translateY($top);
    -webkit-transform: translateY($top);
    -ms-transform: translateY($top);
}
.centerY{
    position: absolute;
    top: 50%;
    // @include transform-translateY(-50%);
}
.LoadingInline{
    width: 100%;
    height: auto;
    text-align: left;
    display: block;
}
.Loading{
    width: 100%;
    height: 100%;
    text-align: center;
    // background: rgba(0,0,0,0.1);
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    div{
        @extend .centerY;
        margin: auto;
    }
    span {
        position: absolute;
        top: 60%;
        right: 25%;
        width: 300px
    }
}
.ModalLoading{
    background: #ffffff;
    border-radius: 4px;
    width: auto;
    margin: 30px ;
    position: relative;
    display: block;
    padding: 0;
    text-align: left;
    z-index: 3;
    div{
        display: inline-block;
        margin: 0 10px;
    }
    h1{
        margin: 0  auto 5px;
        font-size: 24px;
        text-align: left;
        color:#2c74ff;
    }
    p{
        font-size: 12;
        margin: 0 auto 0;
    }
}
