.containerModal {
  display: inline-flex;
  flex-direction: column;
  padding: 2rem 2.5rem;
  width: 38.25rem;
  min-height: 12.19rem;

  h2 {

    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 2rem;
  }
}

.containerButtons {
  margin-top: 1rem;
  display: inline-flex;
  justify-content: flex-end;

  button:first-child {
    margin-right: 1rem;
  }
}
