.Notification{
  width: 100%;
  height: auto;
  min-height: 60px;
  box-sizing: border-box;
  border-left:2px solid;
  padding: 15px 40px;
  margin: 20px 0;
  text-align: left;
}
.success{
  background: rgba(24,197,168,0.1);
  border-color: #18C5A8;
}
.warning{
  background: #FF606F1A;
  border-color: #FF606F;
}