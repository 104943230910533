@import "./../../../../styles/variables/colors.scss";

.bubbleArea {
  width: 100%;
  height: 100%;
  padding-top: 0.9231rem;
}

.bubblePaddingBottom {
  padding-bottom: 7.0769rem;
}