.CompanyCheckboxButton {
  margin-bottom: 1rem;
}

.CheckboxButtonSimple {
  [data-contentButton="true"] {
    width: 146px;
  }
}

.ButtonCheckbox {
  width: 12rem;
  height: 9rem;
}