@import "./../../../../src/styles/variables/colors.scss";
$zIndexLeft: 50;
$zIndexRight: 100;
$zIndexCircle: 75;
$circleSize: 350px;
$circleSizeSmall: 15px;

.genericForm {
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  display: grid;
  grid-template-areas: "form menu";
  grid-template-columns: 1fr 411px;

  .genericFormUpdateExit {
    width: 30px;
    height: 30px;
    //position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    z-index: $zIndexRight * 2;
    color: black;
  }
  .genericFormExit:hover {
    color: rgba(0, 0, 0, 0.5);
  }

  .genericFormLeft {
    grid-area: menu;
    width: 411px;
    background-color: $clara-light-blue;
    height: 100%;
    color: white;
    z-index: $zIndexLeft;
    display: inline-block;
    position: relative;
    overflow: hidden;
    header {
      text-align: left;
      padding: 5%;

      .genericFormIcon {
        width: $circleSizeSmall * 2;
        height: $circleSizeSmall;
        background-color: white;
        border-radius: $circleSizeSmall * 2 $circleSizeSmall * 2 0 0;
        z-index: $zIndexCircle;
        transform: rotate(-90deg);
        margin: 10px;
      }

      .titleIcon {
        margin-right: 30px;
        padding: 10px;
        border: 1px solid white;
        width: 30px;
        text-align: center;
        font-weight: bold;
      }
    }

    .titles {
      display: flex;
      flex-direction: column;
      text-align: left;
      padding: 39px 48px;
      margin-top: 0;
      height: 100%;
      overflow: hidden;
      box-sizing: border-box;
    }

    .littleLineContainer {
      box-sizing: border-box;
      padding-right: 200px;

      .littleLine {
        margin-top: 150px;
        color: white;
        width: 30px;
        display: inline-block;
      }
    }

    .title {
      font-size: 25px;
      font-weight: bold;
      z-index: $zIndexCircle * 2;
      text-align: left;
    }

    .subTitle {
      font-size: 15px;
      z-index: $zIndexCircle * 2;
      text-align: left;
      font-weight: bold;
    }

    .text {
      font-size: 14px;
      z-index: $zIndexCircle * 2;
      text-align: left;
    }

    .miniBorder {
      width: 30px;
      height: 30px;
      border-top: 1px solid white;
      z-index: $zIndexCircle * 2;
    }

    footer {
      display: flex;
      justify-content: left;
      position: absolute;
      bottom: 5px;
      left: 5px;

      p {
        padding: 7px;
        font-weight: bold;
        z-index: $zIndexRight;

        a {
          color: black;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
  .CheckboxInline {
    > div {
      display: flex;
    }
  }

  .BackButton {
    color: white;
    z-index: 1000;
    display: flex;
    align-items: center;
    position: absolute;
    left: 40px;
    top: 55px;

    p {
      font-size: 16px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .genericFormRight {
    grid-area: form;
    width: 100%;
    text-align: left;
    margin: 0;
    box-sizing: border-box;
    display: inline-block;
    height: 100%;
    padding: 32px 100px 150px;
    position: relative;
  }

  .formRightClassOnStep3 {
    width: calc(100% - 411px);
    text-align: left;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: inline-block;
    z-index: $zIndexRight;
    height: 100%;
    overflow: hidden;
  }

  .genericFormKnowHow {
    position: absolute;
    bottom: 30px;
    right: 30px;
    z-index: $zIndexRight;
  }
}
.Grant,
.Document {
  padding: 30px;
  border: 1px solid lightgray;
  margin-top: 30px;

  .CardHeader {
    position: relative;

    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;

      color: #192a3e;
    }

    .RemoveButton {
      position: absolute;
      right: 0;
      top: 0;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.formSubTitle {
  text-align: left;
  color: grey;
}

.formTitle {
  font-size: 25px;
  text-align: left;
}

.AddButtonsGroup {
  display: flex;
  justify-content: space-between;
}

.modal {
  width: auto;
  position: relative;
  height: 600px;
  overflow: hidden;
  display: block;
}

.titleUpdate {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 110.93%;
  text-align: left;
  padding: 0 5% 5% 5%;
}
.genericFormUpdate {
  margin: 55px 55px 55px 55px;
  & > div {
    margin: 0 auto;
    padding: 0;
    h1 {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      color: #192a3e;
      width: 100%;
      margin: 0 0 42px 0;
    }
  }
}
.CircularProgress,
.ErrorProgress {
  width: 100%;
  text-align: center;
}
.CircularProgress * {
  display: inline;
}
.ZonePreview {
  display: inline-block;
  width: 501px;
  background: #848f99;
  padding: 40px;
  box-sizing: border-box;
  height: 100%;
  max-height: 650px;
  overflow: auto;
}
.ZonePreviewWork {
}
.ZoneForm {
  width: 577px;
  overflow: auto;
  height: 459px;
  padding: 0px;
  margin: 0px;
  padding-bottom: 60px;
  display: inline-block;
}

.Page {
  margin-bottom: 10px;
}

.continueButton {
  background: #ffffff;
  /* Clara Blue */
  margin-top: 15px;
  border: 1px solid #2c74ff;
  box-sizing: border-box;
  border-radius: 4px;
  width: 118px;
  height: 40px;

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  /* Clara Black */
  color: #192a3e;
}

.NoGroupCompanies {
  border: 1px solid lightgray;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .Right {
    position: relative;
    width: 252px;
    margin-right: 30px;

    svg:first-child {
      position: absolute;
    }
  }

  .Left {
    width: 261px;
    .noGcTitle {
      font-weight: lighter;
      font-size: 24px !important;
      text-align: left;
      width: 220px;
      height: 61px;
      color: gray;
    }
    .noGcText {
      font-weight: lighter;
      font-size: 14px !important;
      text-align: left;
      width: 220px;
      height: 83px;
      color: gray;
      margin-left: 20px;
    }

    .onboardGroupCompany {
      font-size: 14px !important;
      text-align: left;
      width: 190px;
      height: 17px;
      color: blue;
      margin-left: 20px;
      margin-top: 40px;
      font-weight: bold;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.Footer {
  z-index: 10000;
  position: absolute;
  bottom: 5px;
  left: 410px;
  right: 0;
  padding-top: 20px;
  overflow-x: hidden;
}

.AgreementModal {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.78);

  .ModalCard {
    width: 480px;
    height: 172px;
    margin: auto;
    margin-top: 220px;
    background-color: white;
    padding: 50px;

    .ModalLabel {
      display: flex;
      justify-content: flex-start;
      button:first-child {
        margin-right: 10px;
      }
      margin-bottom: 20px;
    }

    .ModalButtons {
      display: flex;
      justify-content: flex-end;
      div:first-child {
        margin-right: 10px;
      }
    }
  }
}
.Header {
  width: 100%;
  height: 80px;
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(125, 139, 156, 0.2);
  z-index: 600;
  position: absolute;
}
.Preview {
  background-color: rgba(0, 0, 0, 0.78);
  z-index: 1000;
  position: absolute;
  bottom: 0;
  top: 0;
  width: 100%;
  left: 0;

  .PreviewAgreement {
    width: 100%;
    background-color: #d1d1d1;
    margin: auto;
    margin-top: 0;
    padding: 20px;
    position: relative;
    iframe {
      width: 100%;
      height: 100%;
      border: unset;
    }
    div {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: calc(100% - 40vw);
      font-size: 12vw;
      font-weight: 600;
      opacity: 0.1;
      background: transparent;
      pointer-events: none;
      text-align: center;
      top: calc(50% - 10vw);
    }
  }
}

.TitleSignatures {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .LinkAgreement {
    color: blue;

    &:hover {
      cursor: pointer;
    }
  }
}

.FlexFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .TitleAgreement {
    display: flex;
    margin-left: 20px;
    align-items: center;
  }

  .LinkAgreement {
    color: blue;
    width: 220px;

    &:hover {
      cursor: pointer;
    }
  }

  .ButtonsAgreement {
    display: flex;
    justify-content: space-evenly;
    margin-right: 0;
    margin-bottom: 0;
    align-items: center;
  }
}

.Button2 {
  margin-top: 0;
  background: none;
  color: inherit;
  border: none;
  padding: 0 0 0 10px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.Button {
  margin-top: 15px;
  background: none;
  color: inherit;
  border: none;
  padding: 0 0 0 10px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.GoToDocumentsButton {
  background-color: #2c74ff;
  border-radius: 5px;
  padding: 12px;
  margin-left: 5px;
  color: white;
  margin-top: 15px;
  height: 39px;
  border: none;
}

.previousButton {
  background-color: white;
  /* Clara Light Blue */
  color: #2c74ff;
  border: 1px solid lightgray;
  border-radius: 4px;
  margin-top: 15px;
}

.FirstPageButtons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 35px;
}

.NotFirstPageButtons {
  display: flex;
  justify-content: space-between;
}
.labelToStakeholders {
  position: unset;
  left: 11.14%;
  right: 80.57%;
  top: 35.78%;
  bottom: 62.33%;
  display: flex;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: right;
  /* Clara Dark Gray */
  color: #7d8b9c;
}
.labelGroup {
  position: unset;
  left: 11.14%;
  right: 80.57%;
  top: 35.78%;
  bottom: 62.33%;
  display: flex;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: right;
  /* Clara Dark Gray */
  color: #7d8b9c;
}
.labelGroupWizard {
  width: 296px !important;
  margin: 0 20px 0 0;
  font-size: 14px;
  color: gray;
}
.container {
  margin-left: 0;
}
.componentCheckBox {
  display: block;
  width: 100%;
  margin-bottom: 30px;
}
.groupCheckbox {
  display: inline-block;
  width: 80%;
  text-align: left;
}
.groupCheckBoxWizard {
  width: 384px;
  span {
    width: calc(100% - 1.4rem);
  }
}
.groupCheckBoxWizardLabel {
  width: 100%;
}
.groupCheckBoxOnLeftWizard {
  margin-left: 320px;
}
.checkBoxWizard {
  width: 100%;
  display: block;
  margin-bottom: 5px;
  input {
    display: inline-block;
    width: auto !important;
    margin: 3px 5px 0 0 !important;
  }
  label {
    // display: inline-block;
    min-width: 366px !important;
    margin: 0 !important;
    line-height: 20px !important;
    font-size: 13px;
    text-align: justify;
    color: #808080;
  }
}
.CheckboxWizardInline {
  width: 4rem;
  display: block;
  margin-bottom: 5px;
  input {
    display: inline-block;
    width: auto !important;
    margin: 3px 5px 0 0 !important;
  }
  label {
    min-width: 366px !important;
    margin: 0 !important;
    line-height: 20px !important;
    font-size: 13px;
    text-align: justify;
    color: #808080;
    grid-gap: 0rem !important;
    gap: 0rem !important;
  }
}

.labelGroup {
  display: inline-block;
  width: 20%;
  text-align: left;
}
.radioButtonWizard {
  width: 100%;
  display: block;
  margin-bottom: -10px;
  input {
    display: inline-block;
    width: auto !important;
    margin: 3px 5px 0 0 !important;
  }
  label {
    display: inline-block;
    min-width: 360px !important;
    margin: 0 !important;
    font-size: 13px;
    text-align: justify;
    color: #808080;
    line-height: 40px;
  }
}
.SelectContainer {
  display: flex;
  flex-direction: column;
}
.SelectedBLUE {
  &:before {
    background: #2c74ff !important;
  }
}
.containerAvatar {
  width: 100%;
  text-align: left;
  margin: 20px 0;
}

.Separator {
  width: 100%;
  height: 1px;
  border: unset;
  height: 0.0769rem;
  background: #eaf1fe;
  margin: 10px 0 20px 0;
}

.SeparatorTransparent {
  width: 100%;
  height: 1px;
  margin: 10px 0 20px 0;
  background-color: transparent;
  border: 1px solid transparent;
}

.StepFormZone {
  display: inline-block;
  height: auto;
  margin: 54px 0 0;
  text-align: left;
  max-width: 700px;
  .TitleScreen {
    margin-bottom: 35px;
  }
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    color: #192a3e;
    width: 100%;
    margin: 0;
  }
}
.step {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  border-inline-end-color: #65707e;
  margin: 0 0 20px 0;
  text-align: left;
}
.ContainerRowAvatar {
  height: 50px;
  & > div {
    & > div {
      position: relative;
      width: 380px !important;
      height: 50px !important;
      left: 0;
      margin: 0 !important;
      top: 0;
      text-align: left;
      display: inline-block !important;
      border: unset !important;
      & > div {
        position: relative;
        margin: auto;
        text-align: left;
        left: 0;
        height: 50px !important;
        width: 50px !important;
        display: inline-block;
        img {
          width: 50px !important;
          height: 50px !important;
        }
      }
    }
  }
  button {
    border-radius: 50% !important;
    width: 50px !important;
    height: 50px !important;
    font-size: 30px !important;
    color: blue !important;
    background: transparent !important;
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    margin: 0 !important;
    text-align: center !important;
    padding: 0 !important;
    line-height: 50px !important;
  }
}
.containerButton {
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 10px;
  }
}

.SelectedDiv {
  width: 15px;
  height: 15px;
  border: 1px solid gray;
  box-shadow: inset 0px 0px 0px 2px #fff;
  background-color: blue;
  border-radius: 50%;
  margin-right: 5px;
}

.RadioContainer {
  display: flex;
  margin-bottom: 10px;
  margin-right: 10px;
  align-items: center;
  z-index: 1000;
  &:hover {
    cursor: pointer;
  }
}

.NotSelectedDiv {
  width: 15px;
  height: 15px;
  border: 1px solid gray;
  border-radius: 50%;
  background-color: white;
  margin-right: 5px;
}

.DeleteAuthorized {
  position: absolute;
  top: 0;
  right: -35px;

  &:hover {
    cursor: pointer;
  }
}

.ContainerRowAgreement {
  padding: 0;
  height: auto;
  width: 100%;
  display: inline-block;
  margin-top: 10px;
  position: relative;
  & > div {
    margin: 0;
    label {
      width: 296px !important;
      margin: 0 20px 0 0;
      display: inline-block;
    }
    input {
      width: 380px;
      margin: 0;
    }
    .containerInput {
      background: red;
    }
  }
  & > label {
    width: 180px;
    margin-right: 20px;
    display: inline-block;
  }

  & > span {
    width: 380px;
    display: inline-block;
    ul {
      margin: 0;
      list-style-type: none;
      padding: 0;
      li {
        height: 20px;
      }
    }
  }
}
.ContainerRowAgreementLabel {
  & > div {
    label {
      width: 95% !important;
    }
  }
}

.ClaraInputSelect {
  & > div {
    margin: 0;
    label {
      width: 296px !important;
      margin: 0 20px 0 0;
    }
    input {
      width: 380px;
      margin: 0;
    }
  }
}
.formCompanySelect {
  div > div > label {
    margin-top: 0 !important;
  }
}
.issuesContainer {
  white-space: nowrap;
  div div label {
    width: 100% !important;
  }
}
.ContainerRow {
  margin: 0 0 10px 0;
  padding: 0;
  height: auto;
  width: 100%;
  display: inline-block;
  & > div {
    margin: 0;
    label {
      width: 298px !important;
      margin: 0 20px 0 0;
      display: inline-block;
      //line-height: 40px;
    }
    input {
      width: 380px;
      margin: 0;
    }
  }
  & > label {
    width: 180px;
    margin-right: 20px;
    line-height: 40px;
    display: inline-block;
  }

  & > span {
    width: 380px;
    display: inline-block;
    text-align: center;
    ul {
      margin: 0;
      list-style-type: none;
      padding: 0;
      li {
        height: 20px;
      }
    }
  }
}
.SubFormContainerRow {
  margin-bottom: 10px !important;
}
.Select {
  div {
    margin: 0;
    &.SubFormContainerRow {
      margin-bottom: 10px !important;
    }
    label {
      width: 296px !important;
      margin-right: 20px !important;
      display: inline-block !important;
    }
  }
  a input {
    width: 28rem !important;
  }
}

.VerifyAssignor {
  padding: 10px;
  border-left: 3px solid blue;
  margin-top: 25px;
  margin-bottom: 25px;
}

.VerifyStakeholder {
  padding: 10px 10px 10px 0;
  margin-top: 25px;
  margin-bottom: 25px;
}
.StakeholderName {
  label {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 120%;
    color: #192a3e !important;
  }
}
.ZoneTitle {
  width: 100%;
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 120%;
    color: #192a3e;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 138.05%;
    /* identical to box height, or 18px */
    color: #65707e;
    text-align: left;
    margin: 10px 0 0 0;
  }
}
.ContainerRowAvatar {
  height: 50px;
  label {
    line-height: 50px !important;
  }
  & > div {
    & > div {
      position: relative;
      width: 380px !important;
      height: 50px !important;
      left: 0;
      margin: 0 !important;
      top: 0;
      text-align: left;
      display: inline-block !important;
      border: unset !important;
      & > div {
        position: relative;
        margin: auto;
        text-align: left;
        left: 0;
        height: 50px !important;
        width: 50px !important;
        display: inline-block;
        img {
          width: 50px !important;
          height: 50px !important;
        }
      }
    }
  }
}
.CardHeader {
  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin: 10px 0 23px 0;
    color: #192a3e;
  }
}
.Remove {
  color: #65707e;
  cursor: pointer;
  width: 100%;
  text-align: right;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  &:before {
    content: " ";
    background-color: #ff606f;
    margin: 0 0 -3px 0;
    display: inline-block;
    margin-left: 2px;
    -webkit-mask-size: 14px 14px;
    width: 14px;
    height: 14px;
    position: relative;
    -webkit-mask: url(../../../images/form_remove.svg) no-repeat center;
    margin-right: 8px;
  }
}
.RemoveParty {
  color: #65707e;
  cursor: pointer;
  width: 100%;
  text-align: right;
  display: block;
  margin: 10px 2px -10px -8px;
  &:before {
    content: " ";
    background-color: #ff606f;
    margin: 0 3px -3px 0;
    display: inline-block;
    // margin-left: 2px;
    -webkit-mask-size: 14px 14px;
    width: 14px;
    height: 14px;
    position: relative;
    -webkit-mask: url(../../../images/form_remove.svg) no-repeat center;
    // margin-right: 8px;
  }
}

.ContainerAdd {
  position: relative;
  width: 100%;
  height: auto;
}
.ContainerAddGrant {
  border: 1px solid #eaf1fe;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
}
.ContainerSwitchRow {
  line-height: 24px;
  div input {
    width: auto;
    margin: 4px 10px 0 0;
    line-height: 24px;
  }
  div label {
    width: 90% !important;
    line-height: 24px;
    display: inline-block;
  }
}
.RemoveGrant {
  top: 20px;
  right: 20px;
}

.SelectedRED {
  &:before {
    background: #ff606f !important;
  }
}
.SelectedAMBER {
  &:before {
    background: #fbbd2c !important;
  }
}
.SelectedGREEN {
  &:before {
    background: #71e096 !important;
  }
}
.SelectedUNKNOWN {
  &:before {
    background: grey !important;
  }
}
.RED {
  a {
    position: relative;
    top: 10px;
    border: 1px solid #ff606f !important;
  }
}
.AMBER {
  a {
    position: relative;
    top: 10px;
    border: 1px solid #fbbd2c !important;
  }
}
.GREEN {
  a {
    position: relative;
    top: 10px;
    border: 1px solid #71e096 !important;
  }
}
.UNKNOWN {
  a {
    position: relative;
    top: 10px;
    border: 1px solid grey !important;
  }
}
// temporale

.partiesContainer {
  margin-top: 18px;
  display: grid;
  width: 800px;
  grid-template-columns: 90% 20%;
  input {
    position: relative;
    left: 20px;
  }
}

.Remove2 {
  color: #65707e;
  cursor: pointer;
  width: 100%;
  text-align: left;
  align-self: center;
  &:before {
    content: " ";
    background-color: #ff606f;
    margin-right: 5px;
    display: inline-block;
    -webkit-mask-size: 14px 14px;
    width: 14px;
    height: 14px;
    position: relative;
    top: 1px;
    -webkit-mask: url(../../../images/form_remove.svg) no-repeat center;
  }
}
.SubFormContainerButton {
  width: 100%;
  text-align: right;
}
.SubFormButton {
  min-width: 100px;
  margin-bottom: 20px;
}

.sameDataCheckbox {
  width: 702px;
  height: 54px;
  background: rgba(24, 197, 168, 0.1);
  input {
    position: relative;
    margin: 20px 10px 22px 20px;
  }
  label {
    position: relative;
    top: 18px;

    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #192a3e;
  }
  span {
    position: relative;
    top: 18px;
    margin: 1px;
  }
  border-radius: 4px;
}

.editDocUploader {
  position: relative;
  left: 24px;
}
.formRightClassModified {
  position: relative !important;
  bottom: 30px;
  width: calc(100% - 411px);
  text-align: left;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: inline-block;
  z-index: $zIndexRight;
  height: 100%;
  overflow-y: auto;
}

.editDocUploaderOnSignature {
  position: relative;
  left: 27px;
  div label {
    position: relative;
    right: 28px;
  }
}

.uploaderOnFounder {
  position: relative;
  div span {
    position: relative;
    left: 21px;
  }
  div label span {
    position: relative;
    left: 0px !important;
  }
}
.MessageError {
  display: block;
  width: 100%;

  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  text-align: right;
  color: #ff606f;
  margin: 0 0 0 10px;
  text-align: left;
}
.asteriskRequired {
  color: #ff606f;
  font-size: 10px;
}

.containerInputText {
  height: 40px;
  display: grid;
  grid-template-columns: auto 75px 226px 75px;
  color: #808080;

  margin: 0.5rem 0 0.2rem 0;

  label {
    text-align: left;
    align-self: center;
  }
}

.InputContainer {
  display: inline-block;
  // position: relative;
  width: 100% !important;
  margin-bottom: 10px;
  // text-align: left;

  .TextInput {
    // display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
    width: 83%;
    margin-left: 45px;

    .TextInputChildren {
      margin-left: 10px;
    }

    label {
      color: gray;
      font-size: 13px;
      margin-bottom: -5px;
      // margin-top: 30px;
    }

    input {
      font-size: 13px;
      width: 105%;
      height: 40px;
      padding: 0 60px 0 20px;
      border: 1px solid #c2cfe0;
      border-radius: 5px;
      // margin: 20px 0;
      outline: none;
      display: block;
      &:focus {
        border-color: #2c74ff;
      }
    }
    .ChatTextInput {
      position: relative;
      left: calc(50% - 90px);
    }
    .Error {
      border: 1px solid red !important;
    }
  }
}
.InputContainerRightLabel {
  display: inline-block;
  // position: relative;
  width: 100% !important;
  margin-bottom: 10px;
  // text-align: left;

  .TextInputRightLabel {
    // display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
    width: 83%;
    margin-left: 45px;

    .TextInputChildren {
      margin-left: 10px;
    }

    label {
      color: gray;
      font-size: 13px;
      margin-bottom: -5px;
      // margin-top: 30px;
    }

    input {
      font-size: 13px;
      width: 105%;
      height: 40px;
      padding: 0 60px 0 34px;
      border: 1px solid #c2cfe0;
      border-radius: 5px;
      // margin: 20px 0;
      outline: none;
      display: block;
      &:focus {
        border-color: #2c74ff;
      }
    }
    .ChatTextInput {
      position: relative;
      left: calc(50% - 90px);
    }
    .Error {
      border: 1px solid red !important;
    }
  }
}
.LeftLabel {
  left: 5px !important;
  top: 13px !important;
}
.TextInputInfo {
  // display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
  width: 83%;
  margin-left: 45px;

  .TextInputChildren {
    margin-left: 10px;
  }

  label {
    color: gray;
    font-size: 13px;
    margin-bottom: -5px;
    // margin-top: 30px;
  }

  input {
    font-size: 13px;
    width: 100%;
    height: 40px;
    padding: 0 60px 0 20px;
    border: 1px solid #c2cfe0;
    border-radius: 5px;
    // margin: 20px 0;
    outline: none;
    display: block;

    &:focus {
      border-color: #2c74ff;
    }
  }
  .ChatTextInput {
    position: relative;
    left: calc(50% - 90px);
  }
  .Error {
    border: 1px solid red !important;
  }
}
.ApproveDocument {
  position: relative;
  height: 100%;
  padding: 0 !important;
  .ApproveDocumentPreview {
    // background-color: rgba(0, 0, 0, 0.78);
    background-color: $clara-preview-background;
    z-index: 1000;
    position: relative;
    height: calc(100% - 80px);
    iframe {
      width: 100%;
      height: 100%;
      border: unset;
    }
  }
}
.ApproveDocumentBotonera {
  width: 100%;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px -4px 10px rgba(125, 139, 156, 0.2);
  z-index: 199999;
  position: absolute;
  padding: 20px 130px;
  text-align: right;
  button {
    margin-left: 10px;
  }
}

.ModalBox {
  padding: 34px;
  min-width: 550px;
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 110.93%;
    color: #192a3e;
    margin: 0 0 10px;
  }
  .botonera {
    display: flex;
    align-content: flex-end;
    justify-content: flex-end;
    margin: 20px 0 0 0;
    button {
      margin-left: 20px;
    }
  }
}
.TableDecline {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  div {
    margin-top: 0 !important;
  }
  div.botonera {
    margin-top: 20px !important;
  }
  textarea {
    height: 200px !important;
  }
}

.fileUploaderAndSelectorContainer {
  display: inline-block;
  position: relative;
  bottom: 40px;
  left: 316px;
}

.fileUploaderAndSelectorLabel {
  display: inline-block;
  label {
    width: 263px !important;
    margin: 0 20px 0 0;
    display: inline-block;
    color: gray;
    text-align: left;
    line-height: 40px;
  }
}
.IFrame {
  margin-top: 80px;
}
.alignWithInputDate {
  display: inline-grid !important;
  width: 100% !important;
  height: 40px !important;
  color: #808080;

  margin: 0.2rem 0 1.2rem 0;

  button {
    width: 185px !important;
  }
  label {
    text-align: left !important;
    align-self: center !important;
    width: 23rem;
  }
}
.alignWithInputGrantee {
  display: inline-grid !important;
  width: 100% !important;
  height: 40px !important;
  color: #808080;

  margin: 0.2rem 0 1.2rem 0;

  button {
    width: 165px !important;
  }
  label {
    text-align: left !important;
    align-self: center !important;
  }
}
.alignWithInputDateButton {
  button {
    width: 150px !important;
    justify-self: center;
    padding: 0 0.5rem 0 0.7rem !important;
  }
}
.alignWithInputButton {
  button {
    width: 130px !important;
    justify-self: center;
    padding: 0 0.5rem 0 0.7rem !important;
  }
}
.rolesSelector {
  span div span div div a input {
    position: relative;
    // top: 12px;
  }
}
.buttonsContainers {
  margin-top: 1rem;
  display: flex;
  margin-bottom: 2rem;
  justify-content: flex-end;
}
.Botonera {
  padding-top: 25px;
  margin-top: 25px;
  border-top: 1px solid $clara-light-blue;
}
.titleShare {
  display: flex;
  gap: 1.5rem;
  align-items:center;
  h1 {
    color: #192a3e;
    font-size: 22px;
    font-weight: 600;
  }
}
.detailsShare {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.detailsColumn {
  display: flex;
  gap: 119px;
  align-items: center;
  justify-content: flex-start;
}
.shareLabel {
  color: #828c98;
  width:142px;
}
.shareDetails {
  color: #192a3e;
  font-weight: 600;
}

.shareholdingTerms{
  p{
  font-weight: 600;
  font-size: 13px;
  color: #192a3e;
  }
  display:flex;
  flex-direction:column;
  gap:2rem;
}
