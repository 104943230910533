@import "../../sass/base";

.zoom-scale {
  position: relative;
  width: 90%;
  margin: 10px auto;
  &__handler {
    -webkit-appearance: none;
    display: block;
    margin: 0 auto;
    width: calc(100% - 40px);
    height: 18px;
    background: transparent;

    &:focus {
      &::-ms-thumb {
        border-color: color(primary);
        box-shadow: 0 0 1px 0px color(primary);
      }
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
    }

    &::-moz-range-thumb {
      border-color: color(primary);
      box-shadow: 0 0 1px 0px color(primary);
    }

    &::-webkit-slider-thumb {
      border-color: color(primary);
      box-shadow: 0 0 1px 0px color(primary);
      -webkit-appearance: none;
      margin-top: -9px;
      box-sizing: border-box;
      cursor: pointer;
      width: 18px;
      height: 18px;
      display: block;
      border-radius: 50%;
      background: #eee;
      border: 1px solid color(primary);

      &:hover {
        border-color: lighten(color(primary), 20%);
      }
    }

    &::-ms-track {
      width: 100%;
      cursor: pointer;
      background: transparent;
      border-color: transparent;
      color: transparent;
    }

    &::-ms-thumb {
      margin-top: 0;
      box-sizing: border-box;
      cursor: pointer;
      width: 18px;
      height: 18px;
      display: block;
      border-radius: 50%;
      background: #eee;
      border: 1px solid color(primary);

      &:hover {
        border-color: darken(color(primary), 20%);
      }
    }

    &::-moz-range-thumb {
      margin-top: 0;
      box-sizing: border-box;
      cursor: pointer;
      width: 18px;
      height: 18px;
      display: block;
      border-radius: 50%;
      background: #eee;
      border: 1px solid color(primary);
      &::-moz-range-thumb:hover {
        border-color: darken(color(primary), 20%);
      }
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 1px;
      cursor: pointer;
      background: #eee;
      border: 0;
    }

    &::-moz-range-track {
      width: 100%;
      height: 1px;
      cursor: pointer;
      background: #eee;
      border: 0;
    }
    &::-ms-track {
      width: 100%;
      height: 1px;
      cursor: pointer;
      background: #eee;
      border: 0;
    }
  }

  &__min,
  &__max {
    position: absolute;
    transition: color 0.3s ease;
    fill: color(primary);
  }

  &__min {
    left: -12px;
    top: 0px;
  }

  &__max {
    right: -13px;
    top: -1px;
  }

  &__icon {
    &--disabled {
      fill: lighten(color(primary), 50%);
    }
  }
}
