@import "../../styles/variables/colors.scss";

.table {
  border-spacing: 0;
  width: 100%;
  // tr:first-child {
  //   height: 3.5rem;
  //   padding-bottom: 1.2rem;
  //   padding-top: 0 !important;
  //   td {
  //     div:first-child {
  //       padding-bottom: 0.2rem;
  //     }
  //   }
  // }
  tr {
    height: 4.7rem;
    // padding-top: 1.2rem;
    // padding-bottom: 1.2rem;
    td {
      border-bottom: 1px solid #eee;
      // padding-top: 1rem;
      font-size: 0.9231rem;
      color: $clara-black;
    }
  }
}

.expandedRow {
  td {
    border-bottom-color: #fff !important;
  }
}

.containerExtraRow {
  width: 100%;

  transition: height 1s;
  td {
    transition: height 2s;
  }
}

.openRow {
  td {
    height: 15px;
  }
}

.closeRow {
  height: 0px;
  td {
    height: 0;
  }
}

.highlightItem {
  background: #eaf1fe !important;
}

.dataContainer {
  width: 100%;
  height: 4.7rem;
  display: flex;
  align-items: center;
}
