@import "../../.././../../styles/variables/index.scss";

$border-radius-modal: 5px !default;
$border-with-modal: 0.5rem !default;
$max-height-modal: 51.9rem !default;

.Modal-clara-generic {
  .MuiDialog-paper {
    border-radius: $border-radius-modal;
    box-sizing: border-box;
  }
}
