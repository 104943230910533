.containerInputDate{
    display: inline-grid;
    width: 100%;
    height: 40px;
    grid-template-columns: auto 195px 185px ;
    color: #808080;

    margin: .2rem 0 1.2rem 0;

    input{
        width: 185px;
    }
    label{
        text-align: left;
        align-self: center;
    }
}
.inputCode {
    input {
        width: 150px ;
        justify-self: center;
        padding: 0 0.5rem 0 0.7rem;
    }
    > div {
        margin: 0 ;
    }

}

.inputExt{
    height: 40px ;
}
.inputNumber {
    input {
        width: 150px;
        justify-self: center;
        padding: 0 0.5rem 0 0.7rem;
    }
    > div {
        margin: 0 ;
    }
}
.ErrorMessage{
    grid-column: 3/3;
}
.limitSize{
    max-height: 15vh !important;
}