@import "../../../../../../../styles/variables/index.scss";

.tagContainer {
  display: flex;
  gap: 0.9231rem;
  padding-bottom: 0rem !important;
  div {
    padding-bottom: 0rem !important;
    display: flex;
    align-items: center;
  }
  .dataContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    .separe {
      margin-top: 0.3077rem;
      width: 100%;
    }
    .nameContainer {
      display: flex;
      flex-direction: row;
      width: 100%;
      .name {
        font-family: "cl-regular";
        line-height: 1.2308rem;
        color: $clara-black !important;
      }
    }
    .email {
      color: #828c98 !important;
      line-height: 1.1538rem;
    }
  }
}
