.ZoneTable{
    padding: 0;
    box-sizing: border-box;
    margin-bottom: 30px;
}
.Botonera{
    width:100%;
    text-align: lefT;
}
.ZoneTableHeader{
    width: 100%;
    display: flex;
}
.CapTableDivSelect {
    min-width: 10.8462rem !important;
    max-width: 61.5385rem !important;
    width: auto;
    position: relative;
    margin: 0;
    padding: 0;
    height: auto;
}

.ZoneTableTabs{
    width: calc(100% - 20rem);
    display: flex;
}
.Tabs{
    width: 100%;
}
.DivAvatar {
    img {
        width: 2.4615rem;
        height: 2.4615rem;
    }
}
.Button,.ButtonSelect{
    border-radius: unset!important;
    box-shadow: unset!important;
    text-transform: unset!important;
    width: 90px!important;
    height: 32px!important;
    vertical-align: text-bottom!important;
    padding: 0!important;
    background:#f5f6f8!important;
    color:#a7b3c6!important;
}
.ButtonSelect{
    background: #0071bc!important;
    color: #FFF!important;
    
}
