.ModalDecline {
  padding: 34px;
  min-width: 550px;
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 110.93%;
    color: #192a3e;
    margin: 0 0 10px;
  }
  .botonera {
    display: flex;
    align-content: flex-end;
    justify-content: flex-end;
    margin: 20px 0 0 0;
    button {
      margin-left: 20px;
    }
  }
  .TableDecline {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    div {
      margin-top: 0 !important;
    }
    div.botonera {
      margin-top: 20px !important;
    }
    textarea {
      height: 200px !important;
    }
  }
}
