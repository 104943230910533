[class*="Mui"]{
  font-family: cl-regular!important;
}
.MuiPaper-root {
  min-height: auto;
}
.MuiPaper-elevation1 {
  box-shadow: unset !important;
  background: transparent;
}
.MuiPaper-elevation8 {
  box-shadow: unset !important;
}
.MuiPaper-rounded {
  border-radius: 8px;
}

.MuiTableCell-root {
  border-bottom: 1px solid #eaf1fe !important;
}

.MuiTabs-root {
  height: 100%;
  align-items: center;
  min-height: auto!important;
}
.MuiTabs-fixed {
  height: 100%;
}
.MuiTabs-flexContainer {
  height: 100%;
}
.MuiTabs-indicator {
  height: 1px!important;
  transition: none;
  background-color: #2c74ff!important;
}
.MuiTab-textColorPrimary.Mui-selected{
  color: #2c74ff!important;
}
.MuiTab-root {
  min-width: auto !important;
  min-height: auto!important;
  padding: 0 0 1rem 0!important;
  margin: 0 2.5rem 0 0!important;
  line-height: normal!important;
}
.MuiTab-root.Mui-disabled {
  color: #828c9866 !important;
  opacity: 1;
}
.MuiTab-wrapper {
  text-transform: none;
  font-size: 1.0769rem;
  font-weight: normal;
}

.MuiPickersDay-daySelected {
  background-color: #2c74ff !important;
}
.MuiPickersYear-yearSelected {
  font-weight: 700 !important;
}
.MuiPickersMonth-monthSelected {
  font-weight: 700 !important;
}
.MuiTypography-h1 {
  font-size: 24px;
  color: #2c74ff;
  font-weight: normal;
  font-family: cl-regular;
  line-height: 19px;
}
.MuiTypography-h4 {
  font-family: cl-regular;
}
.MuiTypography-h5 {
  font-family: cl-regular;
}
.MuiTypography-subtitle1 {
  font-family: cl-regular;
}
.MuiTypography-caption {
  font-family: cl-regular;
}
.MuiTypography-body1 {
  font-family: cl-regular;
}
.MuiTypography-colorPrimary {
  color: #2c74ff !important;
}



.MuiButton-root {
  font-family: cl-regular;
}
/* .MuiButton-root:hover {
  background-color: unset!important;
} */
.MuiButton-textPrimary {
  color: #2c74ff !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #2c74ff !important;
}

.MuiOutlinedInput-root {
  border-width: 1px !important;
  border-color: yellow !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #2c74ff !important;
}
.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #ff606f !important;
}
.MuiOutlinedInput-notchedOutline {
  border-color: #c2cfe0 !important;
  border-width: 1px !important;
}
.MuiOutlinedInput-rootnotchedOutline {
  border-width: 1px !important;
  border-color: #c2cfe0 !important;
}

.MuiOutlinedInput-rootinput {
  padding: 11px 14px;
  height: 18px;
  font-size: 13px;
}

.MuiTextField-root {
  background: #fff;
}

.MuiIconButton-root {
  padding: 5px;
  color: #2c74ff !important;
}
.MuiPickersDay-day{
  color: rgba(0, 0, 0, 0.87)!important;
}
.MuiPickersDay-daySelected{
  color: #FFF!important
}
.MuiOutlinedInput-inputAdornedStart {
    padding-left: 0;
}
.MuiOutlinedInput-input{
    height: 40px!important;
    padding: 0!important;
}

.MuiSnackbar-anchorOriginBottomCenter {
  bottom: 0rem !important;
  height: 3.3rem !important;
}