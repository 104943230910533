@import "../../../../../../styles/variables/index.scss";

.containerForm {
  width: 45.8rem;
  height: 44rem;
  padding-top: 3.6rem;
  padding-left: 2.4rem;
  padding-bottom: 1.2rem;
  min-width: 53.2308rem;
}
.title {
  color: $clara-blue;
  font-weight: normal;
  text-align: left;
  margin: 0;
  width: 100%;
  margin-bottom: 20px;
}

.containerButtons {
  padding-right: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  width: 54%;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.fileUploader {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1rem;
  div label {
    position: relative;
    right: 50px;
    top: 4px;
  }
}
.Calendars {
  div {
    margin-left: 0.5rem;
    width: 13.5rem !important;
  }
}
.labelCalendar {
  margin-top: 1.5rem !important;
  label {
    width: 12rem !important;
  }
}
.MessageErrorDate {
  max-width: 10.5rem;
  margin-left: 0;
}
.SelectCountry {
  > div {
    display: inline-flex;
    justify-content: space-between;
  }
  input {
    margin-left: 0.5rem;
    width: 28rem !important;
  }
  width: 100%;
  label {
    //margin-right: 4.5rem !important;
  }
}
.Select {
  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  input {
    width: 28rem !important;
  }
  width: 100%;
  label {
    //margin-right: 4.5rem !important;
  }
}
.Input {
  width: 100%!important;
  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%!important;
    > div{
      width:587px!important
    }
  }
  
}
.row {
  flex-direction: row;
  margin-top: 1.5rem !important;
  label {
    width: 12.5rem !important;
    color: $clara-dark-grey;
  }
  input {
    width: 28rem !important;
  }
  textarea {
    width: 28rem !important;
  }
}
.drop {
  width: 28rem !important;
}
.upload {
  flex-direction: row;
  margin-top: 1.5rem !important;
  display: flex;
  justify-content: space-between;
  label {
    width: 12.5rem !important;
    color: $clara-dark-grey;
  }
}
.Separator {
  margin: 1.5rem 0 0 0 !important;
}

.partiesSelectSeparator {
  margin: 2.5rem 0 0 0 !important;
}
.remove {
  color: #65707e;
  width: max-content;
  cursor: pointer;
  text-align: right;
  display: inline-flex;
  align-items: center;
  position: relative;
  top: 82px;
  margin-left: 88%;

  &:before {
    content: " ";
    background-color: #ff606f;
    margin-right: 5px;
    display: inline-block;
    -webkit-mask-size: 14px 14px;
    width: 14px;
    height: 14px;
    position: relative;
    -webkit-mask: url(../../../../../../images/form_remove.svg) no-repeat center;
  }
}

.gridContainer {
  display: grid;
  grid-template-columns: 1fr 1.25fr 0.75fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas:
    "leftArea leftArea rightArea rightArea"
    "leftArea leftArea rightArea rightArea"
    "leftArea leftArea rightArea rightArea";
}
.leftArea {
  grid-area: leftArea;
}
.rightArea {
  grid-area: rightArea;
  border-top: 5px solid #2c74ff !important;
  padding-right: 2.4rem;
  padding-top: 3.6rem;
  padding-bottom: 2.5rem;
  width: 43.1rem;
}

.rightAreaContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  .titleContent {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .deleteButton {
      svg {
        color: $clara-blue;
        font-weight: normal;
        cursor: pointer;
        position: relative;
      }
    }
    h1 {
      font-size: 16px !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.previewContentContainer {
  padding: 20px 20px 0 0;
}
.checkBoxWizard {
  color: $clara-dark-grey;
}

.documentSpecialHeight {
  height: 500px !important;
}

.dropCenter {
  display: flex;
  justify-content: center;
  height: 496px;
  flex-direction: column;
  align-items: center;
}
