.claraLink {
  height: 1.2308rem;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 0.4615rem;
  margin-bottom: 2.1538rem;
  .linkIcon {
    margin-right: 0.5385rem;
  }
  .alink {
    text-decoration: none !important;
  }
}

.flowLink {
    font-size: 0.9231rem;
    font-weight: 300;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
        text-decoration: none !important;
    }
}