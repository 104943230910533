@import "../../../../../styles/variables/index.scss";

$with-border-card: 2px;
$padding-card-x: 1.8462em;

$padding-card-y: 2.4615em;
$padding-card-y-special: 2.2615em;
$size-icon-card: 2.4615rem;

.container_button_card {
  background: transparent;
  border: $with-border-card solid $clara-gray-border;
  border-radius: 0.5rem;
  position: relative;
  box-sizing: border-box;
  transition: all 0.25s;
  padding: $padding-card-y-special $padding-card-x;

  .icon_card {
    width: $size-icon-card;
    height: $size-icon-card;
    position: absolute;
    content: " ";
    background: $clara-gray-border;
    top: -$with-border-card;
    left: -$with-border-card;
    border-radius: 0.615rem 0rem;
    transition: inherit;
  }

  .container_icon_card {
    background: #fff;
    width: 0.9231rem;
    height: 0.923rem;
    padding: 0.5rem;
    display: block;
    position: absolute;
    border-radius: 50%;
    box-sizing: border-box;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $clara-blue;
    }
  }

  .icon_card_selected {
    background: $clara-blue;
  }

  .button_card_text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: cl-regular;
    font-weight: 600;
    font-size: 0.9231rem;
  }

  &:hover {
    background: $clara-blue-over;
  }
  &:disabled {
    background-color: #f9f9f9;
    cursor: not-allowed;
    opacity: 0.46;
  }
}

.container_button_card_select {
  border-color: $clara-blue;
  background: $clara-blue-over;
}

.specialWith {
  width: 11.2308rem !important;
}
.default {
  width: 100%;
}
