@import "../../../styles/variables/colors";
.Checkbox {
    display: block;
    align-items: center;
    width: 100%;
    height: auto;
    input {
        cursor: pointer;
        display: block;
        width: 1.4rem !important;
        height: 1.4rem;
        border: 1px solid $clara-grey;
        border-radius: 5px;
        padding: 0;
        margin: 0;
        background: $clara-white;
    }
    input:focus {
        outline: 1px solid $clara-blue;
        outline-offset: initial;
    }

    label {
        display: flex;
        align-items: center;
        width: 100%;
        padding-left: 0;
        position: relative;
        justify-content: flex-start;
        gap: 1rem;
    }
}

.Checkbox input[type="checkbox"] {
    background: url("./uncheck.svg");
}
.Checkbox input[type="checkbox"]:checked + i {
    background: url("./check.svg") center no-repeat;
}
.containerCheckbox {
    display: inline-flex;
    flex-direction: column;
    transition-delay: display 5s linear;
    position: relative;
}

.buttonToCheck {
    &:before {
        cursor: pointer;
        content: " ";
        background-color: #a9b0bc;
        display: inline-block;
        -webkit-mask-size: 16px 16px;
        width: 16px;
        height: 16px;
        -webkit-mask: url(../../../images/unborderedUncheckbox.svg) no-repeat center;
        display: inline-block;
        position: relative;
        top: 0;
    }
}
.paragraph{
    color: #808080;
    font-size:0.9rem;
    padding:1rem;
}
.buttonCheckedEmule {
    background: white;
    padding: 0px;
    margin: 0;
    display: block;
    width: 16px;
    height: 16px;
    margin-right: 0.6062rem;
    &:focus {
      //  outline: 1px solid #2c74ff;
        outline-offset: initial;
    }
}
.buttonChecked {
    position: relative;
    &:before {
        cursor: default;
        content: " ";
        background-color: #2c74ff;
        display: inline-block;
        -webkit-mask-size: 16px 16px;
        width: 16px;
        height: 16px;
        -webkit-mask: url(../../../images/uncheckbox.svg) no-repeat center;
        position: relative;
        top: 0;
        margin-right: 8px;
        display: inline-block;
    }
    &:after {
        content: " ";
        position: absolute;
        background-color: #fff;
        display: inline-flex;
        -webkit-mask-size: 16px 16px;
        width: 16px;
        height: 16px;
        -webkit-mask: url(../../../images/checked.svg) no-repeat center;
        position: absolute;
        margin-right: 8px;
        left: 0px;
        top: 0px;
        z-index: 100;
    }
}
