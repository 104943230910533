.containerForm {
  margin-top: 1.5rem ;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 120%;
    color: #192a3e;
    width: auto;
  }
}
.input{
  padding-left: 3rem;
}
.inputModal{
  padding-left: 0.8rem;
}
.containerButtons{
  width: 100%;
  display: inline-flex;
  justify-content: flex-end;
}
.errorForm {
  padding-right: 1rem;
}

.deleteButton{
  &:hover {
    cursor: pointer;
  }
}
.ContainerRow {
  //margin: 0 0 10px 0;
  padding: 0;
  height: auto;
  width: 100%;
  display: inline-block;

}
