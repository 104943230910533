.App {
  height: 100%;
  width: 100%;
  text-align: left;
  vertical-align: top;
}

.Router {
  height: 100%;
  width: 100%;
  text-align: left;
  vertical-align: top;
  position: relative;
}

.Playground {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  background: #fff;
  .zoneMenu {
    width: 100%;
    height: 3.4615rem;
    background: #fff;
    border-bottom: 1px solid #666;
    position: fixed;
    z-index: 2;
    .logoClara {
      height: 2.6923rem;
      padding: 0.3846rem;
      .imgLogo {
        height: 100%;
      }
      .spanLogo {
        vertical-align: middle;
        margin: 0 0 1.5385rem 0.7692rem;
        display: inline-block;
        font-size: 1.3846rem;
      }
    }
  }

  .zoneFlow {
    width: 19.2308rem;
    height: 100%;
    vertical-align: top;
    background: #fff;
    display: inline-block;
    border-right: 1px solid #666;
    position: fixed;
    z-index: 1;
    padding-top: 3.5385rem;
  }
  .zoneJson {
    height: 38.4615rem;
    background: #272822;
    border-radius: 0.7692rem;
    width: 19.2308rem;
    vertical-align: top;
    display: inline-block;
    position: absolute;
    right: 1.5385rem;
    top: 5.3846rem;
    z-index: 3;
  }
  .zoneMachine {
    width: calc(100% - 40.3846rem);
    background: white;
    display: inline-block;
    height: calc(100% - 3.1538rem);
    position: fixed;
    z-index: 2;
    top: 3.5385rem;
    left: 19.3846rem;
    overflow: auto;
    min-height: calc(100% - 3.5385rem);
  }
}
.FlowContent {
  width: 100%;
  padding: 0.7692rem;
  height: 100%;
  position: relative;
}
.FlowContent_title {
  font-size: 1.2308rem;
  width: 100%;
}
.FlowContent_fabAdd {
  position: absolute !important;
  left: 15.3846rem;
  top: 1.9231rem;
  width: 3.0769rem !important;
  height: 3.0769rem !important;
  background: #0097ab !important;
}
.FlowContent_link {
  width: 100%;
  display: block;
  padding: 0.2308rem;
  font-size: 0.9231rem;
  cursor: pointer;
  color: #1414f1;
}
.FlowContent_link:hover {
  color: #0097ab;
}
.FlowContent_link_icon {
  font-size: 1.0769rem !important;
  vertical-align: middle;
}
.FlowContent_link_span {
  font-size: 0.9231rem !important;
  vertical-align: middle;
  height: auto;
  display: inline-block;
  margin-left: 0.3846rem;
}

.BraceEditor {
  text-align: center;
}
.BraceEditor_title {
  font-size: 1.2308rem;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid rgba(255, 152, 0, 1);
  color: #fff;
}
.BraceEditor_button {
  border: 1px solid rgba(255, 152, 0, 1) !important;
  color: #fff !important;
  text-align: center;
  margin: 0.7692rem auto !important;
}
.BraceEditor_help {
  position: absolute !important;
  right: -0.3077rem;
  top: -0.2308rem;
  width: 3.0769rem !important;
  height: 3.0769rem !important;
  background: transparent !important;
  color: #fff !important;
  border: none !important;
  box-shadow: none !important;
  cursor: pointer !important;
}

.rootContentStyle {
  width: 100%;
  height: 100%;
  & > div {
    width: 100%;
    height: 100%;
  }
}

.detect-device-tablet {
  font-size: 0.8462rem !important ;
}
.MeetUri {
  height: 400px;
  padding: 0;
  position: absolute;
  left: 20px;
  bottom: 20px;
  transform: unset!important;
  iframe {
    border-radius: 1.8462rem 1.8462rem 1.8462rem 0;
  }
}
.MeetUriOpen {
  height: auto;
}
