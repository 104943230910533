.buttonOption {
  width: 200px;
  margin: 20px 0;
  height: 40px;
  
  text-align: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #2c74ff;
  border-radius: 4px;
  border: 1px solid #2c74ff;
  background: #fff;
  display: block;
  &.buttonOptionSelected,
  &:hover {
    color: #fff !important;
    border: 1px solid #2c74ff !important;
    background: #2c74ff !important;
  }
  &.buttonWithAutomatic {
    width: auto !important;
    padding: 0 15px;
  }
}

.horizontalOptionsButton {
  flex-wrap: nowrap !important;
  margin: 0 !important;
  justify-content: flex-start !important ;
  >label{
    width: 200px;
  }
  > div {
    display: inline-flex;
    button {
      min-width: 125px !important;
      margin-left: 0.3rem !important;
      margin-right: 0.3rem !important;
    }
  }
}
.contentField{
  width: 580px;
  display: inline-flex;
  align-items: center;
  >label{
    width: 200px;
    color: gray;
  } 
}
.ContentAvatar{
  width: 80px !important;
  margin: 20px 5px;
}