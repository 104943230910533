.IconButton {
  padding: 0;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 40px;
  height: 40px;
  margin: 10px;
  padding: 8px;
  text-align: left;
  &:before {
    content: " ";
    background-color: #192a3e;
    width: 24px;
    height: 24px;
    display: inline-block;
    display: inline-block;
    margin: 15px;
    -webkit-mask-size: 24px 24px;
    -webkit-mask: url(../../../../images/arrow_next.svg) no-repeat center !important;
  }
}
