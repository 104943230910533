
.skeletonForm {
  width: 100%;
  display: flex;
  gap: 1.8462rem;
  flex-direction: column;
  .skeletonCardsContainer {
    display: flex;
    flex-direction: row;
    grid-gap: 1.8462rem;
    gap: 1.8462rem;
  }
}