.containerTable{
    width: 100%;
    margin-top:10px; 
    
    font-style: normal;
    font-weight: normal;
    border: 1px solid #EAF1FE ;
    border-radius:5px; 
    
   
    Table {
      thead {
        th {
          
        }
      }
  
      tbody {
        tr {
    
          &:hover{
            background: #E5E5E5;
          }
  
        }
      }
    }
   

}
.containerTables{
    width: 100%;
    display: inline-block;
    top: 220px;
    position: absolute;
    align-self: center;
    right: 2px;
    
    .shareTable{
      border: hidden;
    }
       
}



.tableHead{
    width: 100%;
    height: 50px;
    background: #EAF1FE;
    display: inline-flex;
    justify-content: space-between;
    border-radius:5px 0px; 
   
    button{
        
        width: 280px;
        font-size: 16px;
        border: hidden ;
        background: #EAF1FE;
        text-align: left;
        padding: 0;
       
        
        &:before { 
            content: " ";
            width: 24px;
            height: 24px;
            display: inline-block;
            margin: 0px 10px;
            -webkit-mask :url(../../../../../../images/arrow_down.svg) no-repeat center;
            background-size: 24px; 
            background: #2C74FF;
            position: relative;
            top:5px;
            transform: rotate(0deg);
            transition: all 0.5s;
            
        }
      
    }
    
    .groupClose{
        &:before{
        transform: rotate(180deg);
    }

    }
    
}

.help{
    
        width: 24px;
        height: 24px;
        background: url(../../../../../../images/clara_academy.png) no-repeat center;
        align-self: center;
        margin-right: 15px;
        display: none;//provitional
}


.EditIcon {
    a {
      &:before {
        -webkit-mask: url(../../../../../../images/edit.svg) no-repeat center !important;
      }
    }
  }
.DeleteIcon {
    a {
      &:before {
        -webkit-mask: url(../../../../../../images/trash.svg) no-repeat center !important;
      }
    }
  }
  
.ViewIcon {
    a {
      &:before {
        -webkit-mask: url(../../../../../../images/viewEquity.svg) no-repeat center !important;
      }
    }
  }


  //Table//
  .claraTable{


  }
 .containerHolder{
   min-width: 120px;
   margin-left: 24px;

   &:hover{
    
       color: #2C74FF;
     
   }
 }

  //row Table element
  .containerButtonModal{
    display: inline-flex;
    align-items: center;
    .default{
      position: absolute;
      right: 40px;
    }
    

  }

  .buttomModal{
    position: relative;
    
    display: flex;
    align-content: flex-end;
    span{
      align-items: flex-end;
    div{
      margin-left: 20px;
      align-self: flex-end;
      justify-self: flex-end;
     
    }
  }
  }

  .letterDisable{
    color: #A9B0BC;
    font-weight: 400;
    font-size: 13px;
    margin-right:29px;
    
  }
  .letterTables{
    color: #192a3e;
    font-weight: 500;
    font-size: 13px;
    margin-right:29px;
  }


  // share tables columns

  .Col1 {
    min-width: 25%;
    max-width: 25%;
    width:  25%;
  }
  .Col2 {
    min-width: 25%;
    max-width: 25%;
    width: 25%;
  }
  .Col3 {
    min-width: 20px;
    max-width: 20px;
    width: 20px;
    
  }