@import "./../../../../styles/variables/colors.scss";
.PaymentSuccessMessageGlobal{
    position: relative;
    height: 100%;
    width: 100%;
}
.PaymentSuccessMessage {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2.1rem;
    padding-top: 2.1rem;
    flex-direction: column;
    >div{
        width: 38.1rem;
        display: inline-block;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        
        h1,div,p{
            width:100%;
            margin-bottom: 1rem!important;
            text-align: left;
            margin-bottom: 1.5385rem!important;
        }
        h1{
            font-size: 2.7692rem;
            margin-bottom: 2.7692rem!important;
        }
        p{
            font-size: 1.3846rem;
        }
    }
    .Button{
        width: auto;
        margin-top:2.7692rem!important;
    }
    .Confirm{
        color:$clara-dark-grey;
        font-size: 0.7692rem;
        text-align: center;
        padding: 0  20px;
    }
}

.normalText {
    font-size: 1.2308rem;
    font-weight: 400;
    line-height: 1.4;
}

.title {
    font-size: 2.4615rem !important;
    line-height: 1.4; 
}