@import "./../../../../styles/variables/colors.scss";

.contentsTitle {
  display: grid;
  width: 100%;
  grid-template-columns: 1.8462rem 1fr;
  grid-template-rows: 1fr;
  margin: 0;
  margin-bottom: 1rem;
  align-items: flex-start;
  justify-items: flex-start;
  align-content: center;
  justify-content: flex-start;
  color: $clara-blue;
  .titleContents {
    margin-left: 0.4615rem;
    display: flex;
    align-items: center;
    min-height: 1.8462rem;
    margin-right: 0.4615rem;
  }
  .iconAling {
    align-self: flex-start;
  }
}

.titleNoMargin {
  margin-bottom: 0 !important;
}

.iconBlack {
  color: $clara-black !important;
}

.titleFontStyle {
  font-size: 1.0769rem;
  font-weight: 600;
  line-height: 1.35rem;
}