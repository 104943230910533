.containerServiceForm {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: auto 8.75rem;
    grid-template-rows: 1fr 1fr;

    grid-gap: 1rem 1rem;

    input {
        align-self: start;
    }

    button {
        align-self: end;
    }
   
}

.containerMenuListService {
    margin-top: 1rem;
    overflow-y: auto;
    max-height: 155px;
    width: 100%;
    > div:first-child {
        color: #2c74ff;
    }
    > div {
        cursor: pointer;
        width: 100%;
        text-align: left;
        
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 13px;
        color: #192a3e;
        padding: 0.625rem 1.5625rem;

        &:hover {
            background: #eee;
        }
    }
}



.MenuServices {
    width: 38.3125rem;
    max-height: 18.75rem;
    padding: 1rem;
    > div:first-child {
        padding-right: 1.5625rem ;
    }
}


.addService{
    
}

.itemService {
    display: inline-flex;
    justify-content: space-between;
}


.services_component_container {
    display: grid;
    grid-template-columns: 34.6154rem 8.6154rem;
    grid-template-rows: 1fr 1fr;
    row-gap: 1rem;
    column-gap: 1rem;
    margin: 1rem 0;
    >button{
        align-self: end;
    }
}
