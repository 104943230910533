.SizeTwoLines {
  height: 2.4615rem;
  line-height: 1.2;
  // color: #828C98 !important;
}
.CheckboxButtonIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btnDetail {
  width: 14.6154rem;
  padding: 0.7692rem;
}
