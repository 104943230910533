.IntroSubmitFlows {
  height: 100%;
  width: 100%;
  position: relative;
}
.scopingIntroContainer {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: minmax(7.6923rem, 17.9231rem) 320px 1fr;
  align-items: center;
  justify-content: center;

  .tellmeMoreDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    .tellmeMoreDivContent {
      display: inline-block;
      width: auto;
    }
    a {
      margin-left: 10px;
      color: gray;
      text-align: left;
    }
  }
  .introScoping {
    .titleScoping {
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      width: 100%;
      color: #192a3e;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 23px;
      text-align: center;
      margin: 41px 0 11px 0;
      color: #192a3e;
    }
    p.footerScoping {
      font-style: normal;
      vertical-align: initial !important;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      margin: 0;
      color: #192a3e;
    }
    button {
      margin: 32px auto;
      display: block;
    }
    hr {
      color: #bec9d7;
      background-color: #bec9d7;
      height: 1px;
      border-width: 0;
    }
  }
  .containerLeft {
    z-index: 200;
    .botonera {
      width: 115%;
      .buttonContainer {
        position: relative;
        display: inline-block;
        text-align: left;
        background: #2c74ff;
        border: 1px solid #2c74ff;
        box-sizing: border-box;
        border-radius: 4px;

        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        align-items: center;
        text-align: center;
        letter-spacing: 0.01em;
        padding: 11px 40px 12px 40px;
        cursor: pointer !important;
        color: #ffffff;
        margin-top: 2.6923rem;
      }
      .buttonCloseContainer {
        margin-right: 32px;
        border: 1px solid #796efc;
        color: #796efc;
        background: #ffffff;
      }
    }
  }
  .containerRight {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    img {
      z-index: 100;
      height: 446.54px;
      position: absolute;
      bottom:0px;
      right:0px;
    }
    .ellipse {
      width: 446px;
      height: 446px;
      background: rgba(234, 241, 254, 0.5);
      border-radius: 50%;
      display: flex;
      position: absolute;
      justify-content: flex-end;
      align-items: flex-start;
    }
  }

  .pretitleContainer {
    position: relative;
    display: inline-block;
    text-align: left;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #2c74ff;
    p {
      text-align: left;
    }
  }
  .titleContainer {
    position: relative;
    display: inline-block;
    text-align: left;
    font-family: "cl-regular";
    font-size: 33px;
    font-style: normal;
    font-weight: normal;
    line-height: 130%;
    align-items: center;
    color: #192a3e;
    margin: 11px 0 23px;
    p {
      text-align: left;
    }
  }
  .subTitleContainer {
    position: relative;
    display: inline-block;
    text-align: left !important;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #192a3e;
    p {
      text-align: left;
    }
  }
  .subTitleContainerList {
    padding-left: 0;
  }

}
.CloseChat {
  position: absolute;
  right: 50px;
  top: 65px;
}

.introImg {
  // top: 90px !important;
  // left: 555px !important;
}
