@import "../../../../../../styles/variables/index.scss";

.textContainer {
  div div div div input {
    height: 3.2308rem;
  }
}

.firstInputField {
  margin-top: 1.6875rem;
}

.separator {
  hr {
    height: 0.01rem !important;
  }
}

.hiddenField {
  div div {
    display: none !important;
  }
}

.containerButtons {
  padding-right: 3rem;
  margin-bottom: 1.9rem;
}
.title {
  text-align: left;
  margin: 0;
  width: 100%;
  margin-bottom: 0.6538rem !important;
}

.rolesText {
  font-family: "cl-regular";
  font-size: 12px;
  font-weight: 400;
  color: $clara-dark-grey;
}

.checkboxRolesContainer {
  margin-top: 1.6875rem;
  margin-bottom: 2.2308rem;
}

.checkboxPositionContainer {
  margin-top: 0;
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-rows: 100%;

  .responsibilityLabel {
    position: relative;
    color: $clara-dark-grey;
    top: 4px;
  }
}

.companyName {
  display: flex;
  margin-top: 2.3077rem;
  margin-bottom: 2.2692rem;
  .companyNameLabel {
    font-family: "cl-regular";
    font-size: 16px;
    font-weight: 400;
    color: $clara-dark-grey;
    position: relative;
    top: 6px;
    margin-left: 20px;
  }
}

.errorInvestorMessage {
  font-family: "cl-regular";
  font-weight: 300;
  font-size: 12px;
  color: $color-error;
}

.container {
  display: flex;
  flex-direction: column;
}

.checkboxResponsibility {
  margin: 0.5rem 0;
  label{
      gap:0.5rem;
      font-size: 12px;
      font-weight: 400;
      font-family: 'cl-regular';
      color: $clara-dark-grey;
  }
}
.checkboxPositionContainer {
  margin-top: 0;
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-rows: 100%;

  .responsibilityLabel {
    position: relative;
    color: $clara-dark-grey;
    top: 4px;
  }
}

.rootCheckbox {
  margin: 1rem 0;
  
  div:first-child {
      margin-top: 0rem;
  }

  div:last-child {
      margin-bottom: 0.5rem;
  }
}
