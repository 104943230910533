
@import "./../../../../../../styles/variables/colors.scss";

.HeaderTitle {
   display: flex;
   height: 100%;
   justify-content: space-between;
   align-items: flex-start;
   width: 100%;
   flex-direction: column;
}
.Botonera{
   width: 100%;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   position: relative;
   
}
.Icon {
   font-size: 0.7692rem;
   font-weight: 300;
   margin-right: 0.6154rem;
   color:$clara-black;
   justify-content: flex-end;
   display: flex;
   align-items: center;
   b{
      font-weight: 700;
   }
   svg{
      color:$clara-grey;
      path{
         color:$clara-grey;
         fill: $clara-grey;
      }
      height: 16px;
      width:16px;
      margin-right: 7px;
   }
}