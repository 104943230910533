@import "../../../../../../../styles/variables/index.scss";
.headerColumnTable {
  padding: 0.75rem 0;
  color: $clara-dark-grey;
  font-size: 0.9231rem;
  font-weight: 400;
  padding-bottom: 1.9rem;
  padding-top: 0;
}


.containerTable {
  // margin-top: 1rem;
  padding-top: 1rem;
  table thead {
    tr:first-child {
      display: none;
      th {
        display: none;
      }
    }
    tr {
      height: 10px;
      th {
        position: relative;
        top: 22px;
      }
    }
  }
}

.coltext {
  color: $clara-black;
}
.coltextLink{
  color: $clara-link;
  cursor: pointer;
}