.Paragraph {
  p {
    margin-bottom: 20px !important;
  }
}

.list {
  li {
    margin-bottom: 8px;
  }
}