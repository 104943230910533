body {
  background: #fff;
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  overflow: auto;
}

@media (-webkit-device-pixel-ratio: 1.25) {
  html{
    zoom: 0.8;
  }
}
@media (-webkit-device-pixel-ratio: 1.5) {
  html{
    zoom: 0.8;
  }
}
a:hover {
  text-decoration: none !important;
}
a.linkPlus {
  color: #2c74ff;
  display: inline-block;
  line-height: 17px;
  width: 100%;
  text-align: left;
  position: relative;
  padding-left: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;

  /* identical to box height */

  display: flex;
  align-items: center;
  &:before {
    display: inline-block;
    background-color: #2c74ff;
    -webkit-transition: transform 0.5s;
    transition: transform 0.5s;
    content: " ";
    -webkit-mask-size: 16px 16px;
    width: 12px;
    height: 12px;
    -webkit-mask: url(images/add_startup.svg) no-repeat center;
    line-height: 17px;
    top: 1px;
    left: 0;
    position: absolute;
  }
  &:hover {
    color: #0a57ea;
    &:before {
      background-color: #0a57ea;
    }
  }
}
.botoneraButtons {
  width: 100%;
  text-align: right;
}
button.genericBlue {
  width: auto;
  height: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  display: inline-block;
  background: #2c74ff;
  border-radius: 4px;
  min-width: 120px;
}
button.genericWhite {
  width: auto;
  height: 40px;
  
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #2c74ff;
  display: inline-block;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #eaf1fe;
  min-width: 120px;
}
button.errorButton {
  width: auto;
  height: 40px;

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  border: 1px solid  #ff606f;
  color: #ff606f;;
  display: inline-block;
  background: #fff;
  border-radius: 4px;
  min-width: 120px;
}

button.genericWhiteBorder {
  width: auto;
  height: 40px;
  
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #2c74ff;
  display: inline-block;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #2c74ff;
  min-width: 120px;
}
button.genericPurpleBorder {
  width: auto;
  height: 40px;
  
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #796EFC;
  display: inline-block;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #796EFC;
  min-width: 120px;
}
button.genericWhiteBorderScoping {
  width: auto;
  height: 50px;
  
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #2C74FF;
  display: inline-block;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #2C74FF;
  min-width: 161px;
}

button.genericWhiteScoping {
  width: auto;
  height: 40px;
  
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #2c74ff;
  display: inline-block;
  background: #fff;
  border-radius: 4px;
  border: 0px solid #eaf1fe;
  min-width: 120px;
}

input.inputGeneric {
  height: 100%;
  width: auto;
  border-radius: 5px;
  padding: 1rem;
  font-size: 13px;
  border: 1px solid #c2cfe0;
  &:focus {
    border: 1px solid #2c74ff;
  }
}

input.InputGeneric {
  padding: 0.75rem 0.93rem;
  border-radius: 5px;
  font-size: 13px;
  border: 1px solid #c2cfe0;
  &:focus {
    border: 1px solid #2c74ff;
  }
}
textarea.TextAreaGeneric {
  padding: 0.75rem 0.93rem;
  border-radius: 5px;
  font-size: 13px;
  border: 1px solid #c2cfe0;
  resize: none;
  &:focus {
    border: 1px solid #2c74ff;
  }
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
.scrollbar::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
}

/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}
.scrollbar::-webkit-scrollbar-track:hover {
  background-color: transparent;
}

/* scrollbar itself */
.scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 4px;
}
.scrollbar:hover::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 4px;
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
}
/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {
  display: none;
}

/* div box */
.scrollbar {
  min-width: 150px;

  overflow-y: auto;
}
sub{
  vertical-align: baseline;
}

.Separator {
  border: unset;
  height: 0.0769rem;
  background: #eaf1fe;
  margin: 0;
}