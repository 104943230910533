@import "../../../styles/variables/colors.scss";
.SliderContainer{
    display: flex;
   // margin-top: 75px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.Container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 41.9rem;
    margin:  0 100px;
    z-index: 200;
    font-family: "cl-regular";
    .SliderTitle {
        font-size: 28px;
        display: flex;
        margin-top: 16px;
        flex-direction: column;
        margin-bottom: 30px;
        height: auto;
    }
    .SliderText {
        font-size: 18px;
        height: auto;

        div {
            margin-bottom: 10px;
        }
    }
    font-size: 28px;
    color: white;
    .contentIcon{
        display: flex;
        margin-bottom: 67px;
    }
}
.SliderButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: auto;
    margin-bottom: 10px;
    div {
        width: 10px;
        cursor: pointer;
        height: 10px;
        display: inline-block;
        border-radius: 50%;
        border-style: solid;
        border-width: 1px;
        margin-right: 5px;
        border-color: $clara-white;
        background-color: rgba(0, 0, 0, 0);
    }
    .selectedSliderButton {
        background-color: $clara-white;
    }
}
