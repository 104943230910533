.rightContentImage{
  width: calc(100% - 4.9231rem);
  position: relative;
  height: 100%;
  img{
    position: absolute;
    left: 8.4615rem;
    top: 3.0769rem;
  }
}

.cartTitle {
  font-size: 3.6923rem;
  font-weight: 700;
  line-height: 1.2;
}