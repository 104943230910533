@import "../../../../styles/variables/index.scss";

.tooltipModal {
    content: " ";
    width: 10px;
    height: 10px;
    background: red;
    position: absolute;
    display: block;
    top: 0;
    transform: translate(20%, -50%);
}

.arrow {
    content: " ";
    width: 10px;
    height: 10px;
    background: red;
    position: fixed;
    display: block;
    transform: translate(20%, -50%);
}
.papper {
    padding: 1rem;
    box-shadow: none;
    box-sizing: border-box;
    outline: 1px solid $color-border-line !important;

    outline-offset: -1rem;
    background: rgba(0, 0, 0, 0) !important;
    display: block;

    &:before {
        content: " ";
        transform: rotate(45deg) translate(calc(50% - 1px));
        width: 1rem;
        top: 0;
        left: 15%;
        height: 1rem;
        border-top: 1.6px solid $color-border-line;
        border-left: 1.6px solid $color-border-line;
        z-index: 2;
        background: #fff;
        display: block;
        position: absolute;
    }
}

.classicPapper {
    padding: 1rem;
    box-shadow: none !important;
    box-sizing: border-box;
    outline: 1px solid $color-border-line !important;

    outline-offset: -1rem;
    background: rgba(0, 0, 0, 0) !important;
    display: block;
}

.containerElement {
    padding-top: 0.5rem;
    background: #fff;
}
