@import "../../../../../../styles/variables/index.scss";

.containerTabPartiesUploaded {
    display: flex;
    flex-direction: column;

    > div:nth-child(2) {
        padding-top: 2rem;
    }
}

.containerFileName {
    display: inline-flex;
    position: relative;
    align-items: flex-end;
    margin-left: 2.5px;
}

.textFileName {
    color: $clara-blue;
    width: 100%;
    display: inline-flex;
    align-items: center;
    padding-left: 10px;
}

.initialIcon {
    margin-top: 1rem;
    li {
        color: $clara-blue;
        width: 100%;
        display: inline-flex;
        padding-left: 10px;
    }
    li::before {
        content: "•";
        color: $clara-blue;
        display: inline-block;
        width: 1em;
    }
}
.containerAvatar {
    padding: 1rem 0;
    border-left: 1px solid $color-border-line;
    margin-left: 2.5px;
    display: inline-grid;
    grid-template-columns: 1.05rem 2.7692rem auto;
    width: 100%;
    align-items: center;
    > i {
        width: 100%;
        height: 1px;
        background: $color-border-line;
    }
    position: relative;
}

.containerText {
    //height: 100%;
    cursor: pointer;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > label {
        cursor: pointer;

        color: $clara-blue;
        width: 100%;
        display: inline-flex;
        align-items: center;
        padding-left: 10px;
        font-size: 0.9231rem;
        font-weight: 400;
    }
    > span {
        color: $clara-grey;
        width: 100%;
        display: inline-flex;
        align-items: center;
        padding-left: 10px;
    }
}

.lastParty {
    &::before {
        content: " ";
        width: 1px;
        height: 100%;
        top: 15%;

        transform: translateX(-1px);
        background: #fff;
        position: absolute;
    }
}

.containerTabHistory {
    display: flex;
    flex-direction: column;
}

.containerHistoryText {
    border-left: 1px solid $color-border-line;
    margin-left: 2.5px;
    padding: 0.5rem 0 0.5rem 0.5rem;
}

.containerHistoryText {
    label {
        color: $clara-dark-grey;
        font-weight: 400;
    }
}

.containerAuthorized {
    grid-column: 3/4;
    width: 100%;
    margin-top: 0.5rem;
}

.AuthorizedSignatoriesContainer {
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
}
.AuthorizedSignatoriesItem {
    margin: 0.5rem 0;
    display: inline-flex;
    margin-right: 0rem;
}
.StatusTitle {
    font-size: 0.7692rem;

    color: $clara-dark-grey;
}

.StatusValue {
    font-size: 0.7692rem;
    margin-right: 15px;
    color: $clara-dark-grey;
}

.circle {
    -webkit-mask: url(../../../../../../images/ellipse.svg) no-repeat center !important;
    width: 10px;
    height: 10px;
    display: inline-block;
    margin: 0 2px 0 0 !important;
}

.userNameRecipientNotice {
    color: $clara-blue;
    padding-left: 10px;
    font-size: 0.9231rem;
    font-weight: 400;
    margin-bottom: 1rem;
    cursor: pointer;
}
.Sent {
    background: #fbbd2c;
}

.Delivered {
    background: #fbbd2c;
}

.Pending {
    background: #fbbd2c;
}

.Completed {
    background: #71e096 !important;
}

.docuSign{
    position: absolute;
    font-size: 8px;
    font-weight: bold;
    transform: translateX(calc(-100% - 3px)) translateY(1px);
}
