.ClaraInputTextArea {
    width:100%;
    display:block;
}
.label{
    font-size: 13px;
    max-width: 90%;
    text-align: left;
    font-weight: bold;
    img{
        margin-left: 7px;
        margin-top: -3px;
    }
}
.input{
    font-size: 13px;
    width: 100%;
    height:57px;
    padding: 20px;
    border: 1px solid #C2CFE0;
    border-radius: 5px;
    box-sizing: border-box; 
    margin: 20px 0;
    outline: none;
    &:focus{
        border-color: #2C74FF;
    }
}
.CheckBox{
    width:50%;
    padding: 10px;
    box-sizing: border-box;
    padding: 10px;
    box-sizing: border-box;
    display: inline-block;
    input{
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }
}
.contentCheckBox{
    width: 100%;
    display: block;

}
.tooltipPlacementRight{
    background: #FFFFFF!important;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25)!important;
    border-radius: 4px!important;
    
    font-style: normal!important;
    font-weight: normal!important;
    font-size: 13px!important;
    line-height: 17px!important;
    color: #848F99!important;
    &:before{
        content:'';
        width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 9px solid #FFFFFF!important;
    position: absolute;
    left: -9px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    }
}