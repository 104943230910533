@import "../../../../../styles//variables/index.scss";
//psition    x-y
.badge-position-center-center {
    // translate: ;
}

.inputText {
    font-size: 1rem;
    display: block;
    width: 100%;
    height: 40px;
    padding: 0.75rem 0.93rem;
    border: 1px solid #c2cfe0;
    color: $color-input;
    border-radius: 0.3846rem;
    box-sizing: border-box;
    outline: none;

    &:focus {
        border-color: $bg-primary;
    }
}

.containerInput {
    width: 100%;
}

.containerBadge{
    width:  100%;
}

.input-error{
    border-color:  $clara-red !important;
}
div.MuiAutocompletePaper{
    box-shadow: 0px 0.3077rem 0.7692rem rgba(0, 0, 0,0.1)!important;
}