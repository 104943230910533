.fileUploader {
    width: 70%;
    margin-right: 65px;
}

.fileUploader .fileContainer {
    color: red;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: 1px solid lightgrey;
}

.fileUploader .fileContainer p, .fileUploader .fileContainer img, .fileUploader .fileContainer .errorsContainers {
    display: none;
}

.fileUploader .fileContainer .chooseFileButton {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    font-size: 30px;
    color: blue;
    background: transparent;
}

.fileUploader .fileContainer:active {
    border: 1px solid blue;
}


