@import "../../../../../../../styles/variables/index.scss";

.containerSearch {
  background-color: $clara-white;
  width: 100%;
  height: 3.0769rem;
  display: grid;
  grid-template-columns: 16.2308rem auto;
  grid-template-rows: 100%;
  align-items: center;
  gap: 1.5rem;
  position: relative;
  top: 1.5rem;
  svg {
    color: $clara-blue;
    width: 1rem !important;
    height: 1rem !important;
  }
}
.containerDocumentTab {
  width: 98%;
  height: 100%;
  position: relative;
  bottom: 1.7692rem;
}
.containerTable {
  // margin-top: 1rem;
  padding-top: 1rem;
  table thead {
    tr:first-child {
      display: none;
      th {
        display: none;
      }
    }
    tr {
      height: 10px;
      th {
        position: relative;
        top: 22px;
      }
    }
  }
}
.headerColumnTable {
  padding: 0.75rem 0;
  color: $clara-dark-grey;
  font-size: 0.9231rem;
  font-weight: 400;
  padding-bottom: 1.9rem;
  padding-top: 0;
}
.coltext {
  color: $clara-black;
}

.viewContainer {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  align-content: center;  
}

.statusText {
  color: $clara-dark-grey;
}
