.container {
  display: flex;
  flex-direction: column;
  width: 28.6;
}

.containerIlustration {
  padding-top: 0.9231rem;
  width: 20.6rem;
  height: 28.6rem;
}
