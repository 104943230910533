.buttonCheckBox{
  width: 12rem;
  height:10rem;
}

.typoResalt {
  font-size: 1.2308rem;
  font-weight: 700;
}

.typoNormal {
  font-size: 1.2308rem;
  font-weight: 400;
  line-height: 1.3;
}