@import "../../../../../../../styles/variables/index";

.buttonContainer {
  display: flex;
  flex-direction: column;
}

.buttonCheckBox{
  display: flex;
  width: 11.2308rem !important;
  height: 7.6154rem !important;
  align-items: center;
}

.confirmation{
  display: flex;
  padding-bottom: 1.2rem;
}

.stakeholderTitles{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 1.2rem;
  gap: 1.2rem;
}

.stakeholdersRoles{
  border-top: 1px solid $clara-input-outline-grey;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 1.2rem;
  column-gap: 1.8rem;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
}

.stakeholder{
  display: flex;
  flex-direction: column;
}

.form{
  gap: 0 !important;
  grid-gap: 0 !important;   
}

.description{
  font-size: 1.2308rem;
  margin-bottom: 1.8rem;
}

.scroll{
  padding: 0 2rem 0 1rem;
  display: inline-flex;
  flex-direction: column;
  width:100%;
  height: 119px;
  overflow-y: auto ;
  margin-bottom: 1.5rem;
}

.confirmationText{
  width: 37rem;
}

.confirmationOptions{
  width: 100%;
  // font-size: 12px;
  // height: 7.6154rem;
}

.founderTag{
  width: 65px;
  height: 24px;
  > span {
    margin-left: 0 !important;
  }
  margin-top: 0.6rem;
}