.ContainerEdit {
  margin: 0;
  padding: 0;
  height: auto;
  width: 978px;
  clear: both;
  position: relative;
  display: block;

  /*
  
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 40px;
  color: #65707e;
  */
}
.ContainerRow {
  margin: 5px 0 0 0;
  padding: 0;
  height: auto;
  width: 100%;
  display: inline-block;
  & > div {
    margin: 0;
    label {
      width: 190px;
      margin: 0 20px 0 0;
      display: inline-block;
    }
    input {
      width: 380px;
      margin: 0;
    }
  }
  & > label {
    width: 180px;
    margin-right: 20px;
    display: inline-block;
  }

  & > span {
    width: 380px;
    display: inline-block;
    ul {
      margin: 0;
      list-style-type: none;
      padding: 0;
      li {
        height: 20px;
      }
    }
  }
}

.ContainerRowAgreement {
  padding: 0;
  height: auto;
  width: 100%;
  display: inline-block;
  margin-top: 10px;
  & > div {
    margin: 0;
    label {
      width: 263px !important;
      margin: 0 20px 0 0;
      display: inline-block;
    }
    input {
      width: 380px;
      margin: 0;
    }
  }
  & > label {
    width: 180px;
    margin-right: 20px;
    display: inline-block;
  }

  & > span {
    width: 380px;
    display: inline-block;
    ul {
      margin: 0;
      list-style-type: none;
      padding: 0;
      li {
        height: 20px;
      }
    }
  }
}

.Select {
  div {
    margin: 0 !important;
    label {
      width: 260px !important;
      margin-right: 20px !important;
      display: inline-block !important;
    }
  }
}
.TitleWithAvatar {
  line-height: 30px !important;
  font-size: 16px !important;
  margin: 0 0 30px 0 !important;
  padding: 0;
  display: block;
  width: 100%;
  margin-bottom: 1.5385rem !important;
  label {
    margin: 0 0 0 20px;
    line-height: 30px;
  }
}

.Grant {
  padding: 30px;
  border: 1px solid lightgray;
  margin-top: 30px;

  .CardHeader {
    display: flex;
    justify-content: space-between;
    justify-items: center;

    p {
      text-align: left;
      font-size: 20px;
    }
  }
}

.Form {
  width: 665px;
}

.FormAgr {
  margin-bottom: 20px;
  width: 665px;
}

.Separator {
  border: unset;
  height: 0.0769rem;
  background: #eaf1fe;
  margin: 15px 0 20px 0;
}
h2 {
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 20px 0;
  color: #192a3e;
}
.Botonera {
  position: absolute;
  right: 0;
  top: 0;
  button {
    width: 118px;
    height: 40px;
    text-align: center;
    margin-left: 10px;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #65707e;
    border-radius: 4px;
    background: transparent;
    &:first-child {
      background: #2c74ff;
      color: #fff;
    }
  }
}
.groupCheckbox {
  label {
    line-height: initial;
  }
}
.ContainerRowAvatar {
  height: 50px;
  margin-bottom: 10px;
  label {
    line-height: 50px;
  }
  & > div {
    & > div {
      position: relative;
      width: 380px !important;
      height: 50px !important;
      left: 0;
      margin: 0 !important;
      top: 0;
      text-align: left;
      display: inline-block !important;
      border: unset !important;
      & > div {
        position: relative;
        margin: auto;
        text-align: left;
        left: 0;
        height: 50px !important;
        width: 50px !important;
        display: inline-block;
        img {
          width: 50px !important;
          height: 50px !important;
        }
      }
    }
  }
  button {
    border-radius: 50% !important;
    width: 50px !important;
    height: 50px !important;
    font-size: 30px !important;
    color: blue !important;
    background: transparent !important;
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    margin: 0 !important;
    text-align: center !important;
    padding: 0 !important;
    line-height: 50px !important;
  }
}
.ContainerRowRoles {
  input {
    line-height: 16px;
    margin-right: 5px;
    vertical-align: middle;
  }
  label {
    line-height: 16px;

    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    vertical-align: middle;
    text-align: left;
  }
}
.ContainerSwitchRow {
  line-height: 24px;
  div input {
    width: auto;
    margin: 0 10px 0 0;
    line-height: 24px;
  }
  div label {
    width: auto;
    line-height: 24px;
    display: inline-block;
  }
}
.SelectedRED {
  &:before {
    background: #ff606f !important;
  }
}
.SelectedAMBER {
  &:before {
    background: #fbbd2c !important;
  }
}
.SelectedGREEN {
  &:before {
    background: #71e096 !important;
  }
}
.SelectedUNKNOWN {
  &:before {
    background: grey !important;
  }
}
.RED {
  a {
    border: 1px solid #ff606f !important;
  }
}
.AMBER {
  a {
    border: 1px solid #fbbd2c !important;
  }
}
.GREEN {
  a {
    border: 1px solid #71e096 !important;
  }
}
.UNKNOWN {
  a {
    border: 1px solid grey !important;
  }
}

.partiesContainer {
  display: grid;
  width: 742px;
  grid-template-columns: 90% 10%;
}
.Remove {
  color: #65707e;
  cursor: pointer;
  width: 100%;
  text-align: right;
  align-self: center;
  &:before {
    content: " ";
    background-color: #ff606f;
    margin-right: 5px;
    display: inline-block;
    -webkit-mask-size: 14px 14px;
    width: 14px;
    height: 14px;
    position: relative;
    top: 1px;
    -webkit-mask: url(../../../../images/form_remove.svg) no-repeat center;
  }
}
.position {
  grid-column: 1/2;
  grid-row: 2/3;
}

.issuesContainerLabel {
  div {
    position: relative;
    text-align: left;
  }
}
.MessageError {
  display: block;
  width: 100%;

  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  text-align: right;
  color: #ff606f;
  margin: 0 0 0 10px;
  text-align: left;
}
.asteriskRequired {
  color: #ff606f;
  font-size: 10px;
}

.ClaraInputSelect {
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin: 0.5rem 0 !important;
  justify-content: space-between;
  label {
    width: 450px !important;
    color: #65707e;
  }
}

//-----Roles-------
.containerRoles {
  display: inline-flex;
  width: 100%;
  padding-right: 3rem;
}

.containerComponent {
  display: grid;
  grid-template-rows: 100%;
  padding-right: 3rem;
  grid-template-columns: auto 29.2308rem;
  align-items: center;
  margin-top: 1.5rem;
  label {
    color: #65707e;
  }
}

.columRoles {
  display: inline-flex;
  flex-direction: column;
  border-right: 1px solid #fff;
  width: 100%;
  div:nth-child(even) {
    background: #fff;
  }
  div:nth-child(odd) {
    background: #eaf1fe;
  }
}
.headerColum {
  color: #828c98;
  border-bottom: 1px solid #eaf1fe;
  width: 100%;
  padding: 1.02rem 1rem 1.02rem 1.7rem;
}

.containerCheckbox {
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding: 1.02rem 1rem 1.02rem 0.5rem;
  color: #192a3e;
  label {
    padding-left: 0.5rem;
  }
}

.FormRoles {
  width: 56.3rem;
}

.blockBlank {
  width: 100%;
  padding: 1.02rem 1rem 1.02rem 0.5rem;
  color: rgba(0, 0, 0, 0);
}
.labelResponsibility {
  align-self: flex-start;
  margin-top: 1rem;
}

.checkboxResponsibility {
  margin: 0.5rem 0;
  i {
    margin: 0rem 1rem 0rem 0rem;
  }
}
.roorCheckbox {
  margin: 1rem 0;
  div:first-child {
    margin-top: 0rem;
  }

  div:last-child {
    margin-bottom: 0rem;
  }
}

.containerOtherText{
  padding-left: 2rem;
}

.fileUploaderAndSelectorContainer {
  display: inline-block;
  position: relative;
  bottom: 40px;
  left: 283px;
}

.fileUploaderAndSelectorLabel {
  display: inline-block;
  label {
    width: 263px !important;
    margin: 0 20px 0 0;
    display: inline-block;
    color: gray;
    text-align: left;
    line-height: 40px;
  }
}
