.InputTextClara {
    width: 100%;
    display: block;
    position: relative;
}
.label {
    font-size: 13px;
    max-width: 90%;
    text-align: left;
    font-weight: bold;
    img {
        margin-left: 7px;
        margin-top: -3px;
    }
}
.input {
    font-size: 13px;
    display: block;
    width: 100%;
    padding: 0.75rem 0.93rem;
    border: 1px solid #c2cfe0;
    color: #192a3e;
    border-radius: 5px;
    box-sizing: border-box;
    outline: none;
    &:focus {
        border-color: #2c74ff;
    }
   
}

.input[type=number]::-webkit-inner-spin-button, 
.input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.borderErrorInput {
    border-color: #ff606f;
    &:focus {
        border-color: #ff606f !important;
    }
}

.tooltipPlacementRight {
    background: #ffffff !important;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25) !important;
    border-radius: 4px !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 13px !important;
    line-height: 17px !important;
    color: #848f99 !important;
    position: relative;
    &:before {
        content: "▲";
        width: 0;
        height: 0;
        color: #fff;
        position: absolute;
        left: -12px;
        top: 75%;
        transform: rotate(270deg) translateX(-50%);
        text-shadow: -1px -2px 4px rgba(0, 0, 0, 0.25);
    }
}
.errorMessage {
    width: 100%;
    
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    text-align: right;
    color: #ff606f;
}

.currency {
    right: 1em;
    top: calc(50% - 0.5em);
    width: max-content !important;
    position: absolute;
    
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 120%;
    color: #65707e;
    
}
