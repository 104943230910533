@import "../../../../../../styles/variables/index";

.DropDownMenu {
  width: auto;
  height: 100%;
}

.Popover {
  & > div {
    min-width: 8.8462rem !important;
  }
  ul {
    li {
      width: 100%;
      text-align: left;
      svg,
      img,
      i {
        margin-right: 1.2308rem;
      }
    }
  }
}

.ListMenu {
  min-width: 16.2308rem;
  border-radius: 0.6154rem;
  border: 0.0769rem solid $clara-light-blue;
  color: $clara-grey;
  margin: 0 !important;
  padding: 0 !important;
  -webkit-border-radius: 0.6154rem;
  -moz-border-radius: 0.6154rem;
  -ms-border-radius: 0.6154rem;
  -o-border-radius: 0.6154rem;
  li {
    border-bottom: 0.0769rem solid $clara-light-blue;
    display: grid;
    grid-template-columns: 3.9231rem auto;
    grid-template-rows: 4.3846rem;
    align-items: center;
    justify-content: start;
    padding: 0;
    *:first-child {
      display: flex;
      justify-self: center;
      align-self: center;
      margin: 0;
    }
  }
  li:last-child {
    border-bottom: unset;
  }
}

.arrow {
  position: absolute;
  &:after {
    content: " ";
    cursor: pointer;
    -webkit-mask-size: 1.2308rem 8.9231rem;
    -webkit-mask: url(../../../../../../images/arrow_down.svg) no-repeat center;
    width: 1.2308rem;
    height: 1.2308rem;
    background: $clara-blue;
    transform: rotate(180deg);
  }
}

.open {
  &:after {
    transform: rotate(0deg);
  }
}

.burgerIcon{
  color: $clara-black !important;
}

.Button {
  width: 60px;
  min-width: 60px !important;
  max-width: 60px;
  height: 52px;
  min-height: 52px;
  max-height: 52px;
  > span {
    > svg {
      margin: 0;
    }
  }
}

.ButtonSelected {
  background-color: #EAF1FE !important;
  > span {
    > svg {
      transform: rotate(0deg);
      color: $clara-blue !important;
    }
  }
}

.paper {
  top: 4.4615rem !important;
}

.user {
  grid-area: user;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 0.9231rem;
  > div {
    display: flex;
    align-items: center;
  }
}

.disabledState {
  color: $clara-grey !important;
}

.avatarSpace {
  margin-right: 3.0769rem;
}