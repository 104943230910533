@import "./../../../styles/variables/colors.scss";
.AuditLogs {
  width: 100%;
  height: 100%;
}
.ZoneMenu {
  padding: 50px 50px 50px 0;
  margin-left: 3.846153846rem;
  //width: 358px;
  background: #fff;
  height: 100%;
  border-top:1px solid $clara-light-blue ;
  text-align: left;
  // border-right: 1px solid #e4e3e9;
  overflow: auto;
  display: inline-block;
  height: 100%;
  font-family: 'cl-regular';
  font-style: normal;
  font-weight: normal;
  color: #192a3e;
  h1 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 0;
  }
  h2 {
    font-size:1.538461538rem;
    margin: 0 0 20px 0;
    padding: 0;
    font-weight: 600;
    color: $clara-black;
  }
  p {
    font-size: 14px;
    text-align: left;
    font-weight: 300;
    color: $clara-black;
  }
}
.ZoneTimeLine {
  border:1px solid $clara-light-blue ;
  width: calc(100% - 435px);
  padding: 50px 50px 50px 50px;
  background: #fff !important;
  box-shadow: unset;
  height: 100%;
  overflow: hidden;
  display: inline-block;
  box-sizing: border-box;
  text-align: left;
  .InfiniteScroll {
    height: 100%;
    width: auto;
    overflow-y: auto;
    overflow-x: hidden;
    span{
      margin-left: 20px;
      margin-top: 8px;
      display: inline-block;
    }
  }
  h1 {
    
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 120%;
    margin: 0 0 20px 0;
    padding: 0;
    color: #192a3e;
  }
}
.header{
  font-family: 'cl-regular';
  font-weight: 400;
  font-size:1.846153846rem ;
  color:$clara-blue;
  margin-left:3.846153846rem;
  padding-bottom: 1rem;
}
.container{
  display: flex;
  flex-direction: row;
  grid-template-rows: 0.28fr 0.72fr;
}