@import "../../../styles/variables/index";
@import "~./../../../styles/extends/positions.scss";

.RadioInput {
  width: 100%;
  .Options {
    @extend .flex;
    width: 100%;
    gap: 1rem;
  }
  .Option {
    @extend .flex;
    flex-basis: 100%;
    width: auto;
  }
  button {
    border: 1px solid $clara-dark-grey;
    width: 1.2308rem;
    height: 1.2308rem;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    cursor: pointer;
    margin: 0 0.7692rem 0 0;
    padding: 0;
    background: transparent;
    outline: unset;
    &:focus {
    }
    &:before {
      content: " ";
      @extend .flex;
      background: transparent;
      width: 0.6154rem;
      height: 0.6154rem;
      border-radius: 50%;
      box-sizing: border-box;
      margin: 0.2308rem;
    }
    &.selected {
      border: 1px solid $bg-primary;
      &:before {
        background: $bg-primary;
      }
    }
  }
}
.Checkbox {
  .Option {
    @extend .flex;
    flex-basis: 100%;
    width: auto;
  }
}
.Horizontal {
  flex-direction: row;
}
.Vertical {
  flex-direction: column;
  gap: 1rem;
}
