@import "./../../../../styles/variables/colors.scss";

.ReadMore {
  color: $clara-academy !important;
  cursor: pointer;
}

.ReadMoreBlue {
  color: $clara-star !important;
  cursor: pointer;
}