@import "../../../../styles/variables/index.scss";
@import "../../../../styles/variables/colors.scss";

.containerPreview {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: .1469rem solid $clara-preview-background;
  border-radius: 6px;
  background-color: $clara-preview-background;
  padding: 4px;
  .docSkelPreview {
    overflow: hidden !important;
  }
  .containerPreviewFile {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: auto;
    iframe {
      width: calc(100% + 1px);
      height: calc(100% + 22px);
      border: transparent;
      margin-top: -1px;
      margin-left: -1px;
    }
    img {
      width: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.containerWhite {
  background-color: $clara-white;
}

.allPages {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}

.loadingPreview {
  width: 100% !important;
  height: 100% !important;
  text-align: center !important;
  /* background: rgba(0, 0, 0, 0.1) !important; */
  display: block !important;
  position: absolute !important;
  left: 0px;
  top: 0;
  z-index: 1 !important;
}

.containerNotPreview {
  height: 100%;
  width: 100%; // calc(100% - 22rem);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Reload {
  position: absolute;
  left: 10px;
  top: 5px;
  background: transparent;
  color: $clara-blue;
  font-size: 10px;
  cursor: pointer;
  width: auto !important;
  height: auto !important;
}
