.CheckboxButton {
  [data-contentButton="true"] {
    width: 146px;
  }
}

.buttonCheckBox2 {
  width: 12rem;
  height: 9rem;
}
