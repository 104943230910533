.containerShareholder {
    width: 580px;
}

.contentField {
    display: inline-flex;
    margin-bottom:25px;
    width: 580px;
    >div {
        width: 100%;
    }
    h4{
        span{
            background: #fff;
            border: 1px solid #fff;
            display: inline-block;
            padding: 0;
            
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            display: inline-block;
            align-items: center;
            color: #65707e;
            margin: auto;
            width: 125px;
        }
    }
}

.contentBotonera {
    width: 100%;
    text-align: center;
    button {
        display: inline-block !important;
        margin: auto 50px;
    }
}

.buttonOption {
    width: 336px;
    margin: 20px auto;
    height: 40px;
    
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #2c74ff;
    border-radius: 4px;
    border: 1px solid #2c74ff;
    background: #fff;
    display: block;
    &.buttonOptionSelected,
    &:hover {
        color: #fff !important;
        border: 1px solid #2c74ff !important;
        background: #2c74ff !important;
    }
    &.buttonWithAutomatic {
        width: auto !important;
        padding: 0 15px;
    }
}
