@import "../../../../../../styles/variables/index.scss";

.checkbox {
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  button {
    padding: 3.6154rem 1.8462rem 1.8462rem 1.8462rem !important ;
    width: 235px !important;
    justify-content: flex-start;
    align-self: flex-start;
    background-color: $clara-white !important;
    height: 100%;
    > div{
      height: 100%;
    }
    h1 {
      margin-bottom: 0.6154rem;
    }
  }
}
