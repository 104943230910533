.ClaraButton {
  min-width: 146px;
  min-height: 40px;
  display: inline-block;
  background: #2c74ff;
  
  border-radius: 5px;
  padding:  11px 37px;
  cursor: pointer;
  position: relative;
  outline: none;

  
  
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  i{
    margin: 2px 5px 0 0;
    display: inline-block;
   &:before{
    color: #ffffff;
   } 
  }
}
