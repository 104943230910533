@import "./../../../styles/variables/colors.scss";

.skeletonContainerText {
  display: flex;
  flex-direction: row;
}

.skeletonTextPadding {
  padding-right: 1.5385rem;
}

.skeletonContainerStartup {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 40% 15% 40% 5%;
  align-items: center;
  border: 2px solid $clara-light-blue;
  border-radius: 0.7692rem;
  margin: 0 332px 11px 0;
  width: 90%;
  height: 70px;
  position: relative;
  text-align: left;
  -webkit-border-radius: 0.7692rem;
  -moz-border-radius: 0.7692rem;
  -ms-border-radius: 0.7692rem;
  -o-border-radius: 0.7692rem;
}

.skeletonContainerStartup {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 40% 15% 41% 4%;
  align-items: center;
  border: 2px solid $clara-light-blue;
  border-radius: 0.7692rem;
  margin: 0 332px 11px 0;
  width: 99%;
  height: 70px;
  position: relative;
  text-align: left;
  -webkit-border-radius: 0.7692rem;
  -moz-border-radius: 0.7692rem;
  -ms-border-radius: 0.7692rem;
  -o-border-radius: 0.7692rem;
}

.containerView {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: $clara-white;
}

.addStartupSkeleton {
  position: relative !important;
  margin-left: 5px;
}

.arrowColor {
  color: $clara-grey !important;
}