@import "./../../../styles/variables/colors";

.TabArea {
  bottom: 0;
  position: relative;
  height: 3.1rem;
  width: 100%;
  margin: 0;
  display: flex;
  padding-bottom: 0.3846rem;
  > div {
    border-bottom: 1px solid $clara-light-blue;
    width: 100%;
  }
  button {
    padding: 0 0 1rem 0;
    margin-right: 2.5rem;
    cursor: pointer;
    * {
      cursor: pointer;
    }
    span label {
      font-weight: 400;
    }
  }
}

.collapseIcon {
  display: block;
  transition: all 1.5s ease 0s;
  justify-content: flex-end !important;
  align-items: flex-end !important;
  width: auto !important;
  height: 25px;
  position: absolute;
  bottom: 5px;
  right: 0;
  z-index: 1;
  svg {
    cursor: pointer;
    z-index: 100;
    color: $clara-blue;
  }
}

.notCollapsedTabArea {
  // bottom: 320px;
  // position: relative;
  // top: 0;
  // position: absolute;
  transition: all 1500ms;
}

// .notCollapsedContentTab {
//     // bottom: 320px;
//     // top: 50px;
//     // position: absolute;
// }

.notCollapsedContentBlocks {
  opacity: 0;
}

.notCollapsedIconBlocks {
  opacity: 0;
}

// .notCollapsedCollapseIcon {
//     // bottom: 330px;
// }

.MuiTabRoot {
  span label {
    color: $clara-blue !important;
    font-weight: 400 !important;
    font-family: "cl-regular" !important;
    font-size: 14px !important;
  }
}

.ContentContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: min-content;
}

.DetailContainer {
  display: flex;
  flex-direction: column;
}

.transition {
  transition: all 1500ms;
}

.Details {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}
.Header {
  width: 100%;
  height: 35px;
  margin: 32px 0;
  padding: 0 35px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 19px;
  .Info {
    grid-template-columns: 35px 1fr;
    gap: 19px;
    display: grid;
    .HeaderData {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      .tagContainer {
        display: flex;
        align-items: center;
        gap: 0.9231rem;
        h5 {
          font-size: 22px;
        }
      }
    }
  }
  .Botonera {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
}
.ContentTabs {
  width: 100%;
  height: calc(100% - 120px);
  padding: 0 35px;
  margin: 0 0 32px 0;
  display: flex;
  flex-direction: column;
}
.ContentTab {
  border: 2px solid $clara-light-blue;
  border-radius: 10px;
  padding: 27px;
  height: auto;
  display: flex;
  width: 100%;
  margin-top: 12px;
  flex-direction: column;
  box-sizing: border-box;
  max-height: calc(100% - 42px);
}

.NoTitle {
  height: calc(100% - 54px) !important;
  max-height: calc(100% - 54px) !important;
}

.noBottonSpace {
  max-height: max-content !important;
}

.headerDescriptionContainer {
  display: flex;
  gap: .6rem;
  margin-top: .4rem;
}

.fitContent {
  max-height: fit-content;
}