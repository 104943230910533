
.Input {
  width: 100%;
}
.CheckboxButton {
  [data-contentButton="true"] {
    width: 146px;
  }
}

.buttonCheckBox {
  width: 12rem;
  height: 10rem;
}