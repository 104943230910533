@import "./../../../styles/variables/colors.scss";

.SwitchStartups {
  margin-top: 60px;
  margin-bottom: 100px;
  margin-left: 160px;
  margin-right: 160px;
}

.missingAndAdder {
  display: inline-block;
  text-align: left;
  width: 100%;
  div {
    display: inline;
  }
  .addStartup {
    cursor: pointer;
    font-size: 14px !important;
    position: relative !important;
    top: 4px !important;
    margin-left: 5px;
    &:before {
      position: relative;
      top: 2px;
    }
  }
}

.containerSearch {
  width: 150px;
  height: 25px;
  top: 1px;
  margin-right: 30px;
}

.addStartupAndSearch {
  width: 350px;
  height: 30px;
  margin-right: 10%;
  justify-self: flex-end;
  display: inline-flex;
  justify-items: center;
  align-items: center;
  align-content: space-between;
}

.containerTitleAndSearch {
  width: 99%;
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 16px;
}

.listhr {
  width: 90%;
  margin: 20px 0px 20px 0px;
  height: 2px;
  background-color: $clara-light-blue;
  border: 0px;
}

.containerStartup {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 40% 15% 41% 4%;
  align-items: center;
  &:hover {
    border-color: $clara-blue;
    .selectMatter {
      &:before {
        background: $clara-blue !important;
      }
    }
  }
  border: 2px solid $clara-light-blue;
  border-radius: 0.7692rem;
  margin: 0 332px 11px 0;
  width: 99%;
  height: 70px;
  position: relative;
  text-align: left;
  cursor: pointer;
  -webkit-border-radius: 0.7692rem;
  -moz-border-radius: 0.7692rem;
  -ms-border-radius: 0.7692rem;
  -o-border-radius: 0.7692rem;
}

.addStartup {
  width: 200px;
  margin-right: 1.5%;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.containerAvatar {
  width: 220px;
  position: relative;
  margin-left: 25px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  div label {
    overflow: hidden;
    max-width: 300px;
    text-overflow: ellipsis;
  }
}

.containerAvatarMessage {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 15px;
  .LabelName {
    font-weight: 700;
    font-size: 13px;
    color: #000;
  }
  .LastUpdate {
    font-weight: 400;
    font-size: 8px;
    color: $clara-dark-grey;
    line-height: 16px;
    b {
      color: $clara-black;
    }
  }
  & > *:first-child {
    grid-row: 1/3;
  }
}

.countNotification {
  position: absolute;
  font-size: 0.6154rem;
  height: 1.0769rem;
  justify-content: center;
  background: $clara-red;
  border-radius: 50%;
  font-weight: bold;
  color: $clara-white;
  border: 1px solid $clara-white;
  top: -4px;
  left: 1.8rem;
  z-index: 2;
  align-items: center;
  display: flex;
  align-content: center;
  padding-top: 1px;
}

.selected {
  &:before {
    background: url("../../../images/ellipsis3.1.svg") center no-repeat;
    background-size: 8px;
  }
}

.containerIssues {
  width: 110px;
  height: 20px;
  display: inline-flex;
  // border-right: solid #e5e5e5 1px;
  justify-content: space-around;
  padding-right: 10px;
}

.issues {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  font-style: normal;
  font-weight: 600;
  font-size: 12.5px;
  text-align: center;
  color: $clara-white;
  line-height: 19px;
}

.elements {
  width: 90px;
  height: 20px;
  margin-right: 10px;
  color: $status-unknown;
  margin-right: 20px;
  cursor: pointer;
}

.empty {
  color: $clara-grey;
}

.RED {
  background: $clara-red;
}
.GREEN {
  background: $status-green;
}
.AMBER {
  background: $status-amber;
}
.UNKNOWN {
  background: $color-disabled;
}

.containerMattersTable {
  width: 78%;
  height: calc(100% - 70px - 163px);
  margin-left: 131px;
}

.containerMatters {
  &:hover {
    border-color: $clara-blue;
    .selectMatter {
      &:before {
        background: $clara-blue !important;
      }
    }
  }
  border: 1px solid $clara-light-blue;
  border-radius: 4px;
  margin: 0 332px 11px 0;
  width: 90%;
  height: 70px;
  position: relative;
  text-align: left;
  cursor: pointer;
}

.tagsContainer {
  position: relative;
  display: inline-block;
  margin: 26px 0 22px 30px;
  span {
    color: black;
    padding: 1px 9px;
    border-radius: 10px;
  }
}

.matterStateTitle {
  width: 58px;
  height: 16px;
  text-align: left;
  white-space: nowrap;

  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 120%;
  color: $color-disabled;
}

.matterAvatar {
  margin: 22px 15px 18px 32px;
  display: inline-block;
}

.matterTextContainer {
  font-weight: 600;
  line-height: 16px;
  align-items: center;
  color: $clara-black;
  display: inline-block;
  position: relative;
  h3 {
    margin: 20px 0 0 0;
    font-weight: 600;
    font-size: 1.1538rem;
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    opacity: 0.6;
    font-weight: 500;
  }
}

.containerMatterTitleAndSearch {
  max-width: 90%;
  width: 90%;
  height: 70px;
  text-align: left;
  position: relative;
}

.matterSectionTitle {
  position: absolute;
  bottom: 0;
  width: 40%;
  h1 {
    line-height: 120%;
    padding: 0;
    margin: 0;
  }
}

.addMatter {
  color: $clara-blue;
  font-size: 15.5px;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  &:before {
    content: " ";
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url("../../../images/plus.svg") no-repeat center 5px;
    background: $clara-blue !important;
    margin-right: 5px;
    -webkit-mask-size: 24px 24px;
  }
}

.containerMatterSearch {
  width: 150px;
  height: 25px;
  right: 150px;
  margin-right: 5px;
  bottom: 0;
  position: absolute;
  input {
    border: 0px solid $clara-black;
    background: $clara-white;
  }
}

.selectMatter {
  display: inline-block;
  top: 22px;
  position: absolute;
  right: 0;
  &:before {
    content: " ";
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: inline-block;
    -webkit-mask: url("../../../images/arrow_down.svg") no-repeat center 5px;
    transform: rotate(90deg);
    background: #bec9d7 !important;
    margin-right: 5px;
    -webkit-mask-size: 24px 24px;
  }
}

.missingMatters {
  margin-top: 30px;
  text-align: left;
  color: $clara-dark-grey;
  p {
    display: inline;
    font-size: 14px;
    padding: 10px;
    line-height: 25px;
  }
  &:before {
    content: " ";
    width: 24px;
    height: 29px;
    display: inline-block;
    background: $clara-dark-grey !important;
    margin-right: 5px;
    position: relative;
    top: 5px;
    -webkit-mask-size: 24px 24px;
  }
}

.containerMatter {
  i {
    text-align: right;
  }
}

.selectorContainer {
  width: 30%;
  position: relative;
  left: 32%;
  top: 3.1538rem;
  div {
    div {
      span {
        span {
          input {
            border: none;
            background: $clara-white;
          }
        }
      }
    }
  }
}

.DeleteForm {
  padding: 34px;
  min-width: 42.3077rem;
  height: 21.5385rem;
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 110.93%;
    color: $clara-black;
    margin: 0 0 10px;
  }
  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: $clara-red;
  }
  .botonera {
    margin: 6.1154rem 0 0 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right;
    button {
      text-align: center;
      letter-spacing: 0.01em;
      min-width: 120px;
      margin-right: 10px;
      &.nextButton {
        background: $clara-red;
        border: 1px solid $clara-red;
        border-radius: 4px;
        color: $clara-white;
      }
    }
  }
}
.CheckBox {
  padding-top: 3.0769rem;
  label {
    line-height: 38px;
  }
  span {
    width: 413px;
    height: 33px;
    font-family: "cl-regular";
    font-size: 13px;
    line-height: 15px;
    color: $clara-dark-grey;
  }
}
.ModalDelete {
  border-top-color: $clara-red !important;
}

.submitButton {
  width: 120px;
  height: 40px;
  background: $clara-red;
  box-sizing: border-box;
  border-radius: 4px;
  color: $clara-white;
  font-family: "cl-regular";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
}
.cancelButton {
  width: 120px;
  height: 40px;
  background: $clara-white;
  border: 1px solid $clara-light-blue;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: "cl-regular";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: $clara-dark-grey;
}

.ModalAdd {
  border-top-color: $clara-blue !important;
}

.isRounder {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.arrowColor {
  color: $clara-blue !important;
}

.searchColor {
  svg {
    color: $clara-blue !important;
  }
}

.titleAndAddContainer {
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.TextInput {
  svg {
    color: $clara-dark-grey;
  }
  input {
    border-color: $clara-light-blue;
    font-size: 0.9231rem;
    color: $clara-dark-grey;
  }
}