.Paragraph {
  p {
    margin-bottom: 16px !important;
  }
}

.lastInput {
  padding-bottom: 20px;
}

.disabled {
  background-color: #EAEAEA !important;
}