@import "./../../../../../styles/variables/colors.scss";

.TemplateWizard {
  position: relative;
  width: 100%;
  height: 100% !important;
  > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
  .Scrollbar {
    width: 100%;
    height: calc(100% - 6.08rem);
    display: block;
    max-height: calc( 100vh - 146px);
  }
  .Content {
    width: 100%;
    height: 100%;
    .Header {
      width: 100%;
      .Spacer {
        margin-bottom: 1.2308rem;
        margin-top: 1.2308rem;
      }
    }
    .Form {
      width: 100%;
      height: 100%;
      display: flex;
      gap: 1.8462rem;
      flex-direction: column;
    }
  }
}

.Botonera {
  display: grid;
  width: 100%;
  height: 6.08rem;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  padding: 1.5rem 3.6rem 1.5rem 2.4rem;
  border-top: 1px solid $clara-light-grey;
  box-sizing: border-box;
  .LeftButtons {
    display: flex;
    gap: 1.8462rem;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .RightButtons {
    display: flex;
    gap: 1.8462rem;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}