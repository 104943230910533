@import "../../../styles/variables/colors.scss";

.container {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  input {
    width: 3.8462rem;
    height: 3.8462rem;
    border-radius: 0.3077rem;
    margin-right: 0.7692rem;
    border: 0.0769rem solid #C2CFE0;
    font-family: "cl-regular";
    font-weight: 300;
    font-size: 1.3846rem;
    color: $clara-black;
    padding: 1.3846rem;
  }

  .thirdInput {
    margin-right: 2.3077rem;
  }
}
