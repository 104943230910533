@import "./../../../../styles/variables/colors.scss";

.SVGZone {
  // width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  opacity: 1;
  transition: all 0.2s;
  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
    pointer-events: none;
    path {
      fill: transparent;
      z-index: 999;
      pointer-events: none;
      transition: all 0.5s;
    }
    polyline {
      fill: $clara-blue;
    }
    text {
      font-size: 0.6154rem;
      fill: $clara-white;
    }
  }
  &.invisible {
    opacity: 0;
  }
}
.defaultLine {
  stroke: $clara-chat;
  stroke-width: 2;
}
.line_groupCompany {
  stroke: $clara-blue;
  stroke-width: 2;
}
.line_FOUNDER {
  stroke: $clara-role-founder;
  stroke-width: 2;
}
.line_DIRECTOR {
  stroke: $clara-role-directors;
  stroke-width: 2;
}
.line_ADVISOR {
  stroke: $clara-role-advisors;
  stroke-width: 2;
}
.line_EMPLOYEE {
  stroke: $clara-role-employees;
  stroke-width: 2;
}
.line_CONSULTANT {
  stroke: $clara-role-consultants;
  stroke-width: 2;
}
.line_INVESTOR {
  stroke: $clara-role-investors;
  stroke-width: 2;
}
.line_SHAREHOLDER {
  stroke: $clara-role-shareholders;
  stroke-width: 2;
}
.line_CONVERTIBLEHOLDER {
  stroke: $clara-role-convertibleholders;
  stroke-width: 2;
}
.line_WARRANTHOLDER {
  stroke: $clara-role-warrantholders;
  stroke-width: 2;
}
.line_OPTIONHOLDER {
  stroke: $clara-role-optionholder;
  stroke-width: 2;
}
.line_IPASSIGNOR {
  stroke: $clara-role-ip;
  stroke-width: 2;
}
.line_THIRDPARTY {
  stroke: $clara-role-thirdparty;
  stroke-width: 2;
}
.line_AUTHORIZEDSIGNATORY {
  stroke: $clara-role-documents;
  stroke-width: 2;
}
.line_UBO {
  stroke: $clara-role-ubo;
  stroke-width: 2;
}
