@import "./../../../../../styles/variables/colors.scss";

.listItemContainer {
  display: flex;
  margin-bottom: 1.3846rem;
  .textContainer {
    width: 100%;
  }
  .dotContainer {
    width: 1.2308rem;
  }
  .blue{
    color:$clara-blue
  }
  .red{
    color:$clara-red
  }
}

.listItemContainerNoMargin {
  display: flex;
  .textContainer {
    width: 100%;
  }
    .dotContainer {
      width: 1.2308rem;
    }
    .blue{
      color:$clara-blue
    }
    .red{
      color:$clara-red
    }
}

.listItem {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
}