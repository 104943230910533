@import "../../../../../../styles/variables/index.scss";

.containerFilterMenuMessages {
  padding: 1rem 0.75rem;
  min-height: 5rem;
  // div:nth-child(2){
  //     margin-top: 0.5rem;
  // }
}

.titleFilterMenuMessages {
  color: $palette-clara;
  font-size: 0.8rem;
  padding: 0 0.5rem;
}
.itemFilterMenuMessages {
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #eaf1fe;
  }
}
