@import "./../../../../../styles/variables/colors.scss";

.TemplateWizard {
  position: relative;
  width: 100%;
  height: 100% !important;
  > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
  .Scrollbar {
    width: 100%;
    height: calc(100% - 6.08rem);
    display: block;
    max-height: calc( 100vh - 146px);
  }
  .Content {
    width: 100%;
    padding-left: 4.9231rem;
    padding-right: 4.9231rem;
    // padding-left: 2.4rem;
    // padding-right: 2.4rem;
    // padding-top: 48px;
    // padding-bottom: 48px;
    padding-top: 4.9231rem;
    padding-bottom: 3.8462rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .Header {
      width: 100%;
      .Spacer {
        margin-bottom: 2.462rem;
        margin-top: 1.2308rem;
        font-weight: normal;
        font-family: 'cl-bold';
        font-size: 2.923rem;
      }
    }
    .Form {
      width: 100%;
      height: 100%;
      display: flex;
      gap: 1.8462rem;
      flex-direction: column;
    }
  }
  .infoContent {
    padding-left: 4.9231rem;
    padding-right: 4.9231rem;
    padding-top: 4.9231rem;
    padding-bottom: 0 !important;
    width: 100%;
    .Header {
      width: 100%;
      .Spacer {
        margin-bottom: 2.462rem;
        margin-top: 1.2308rem;
        font-weight: normal;
        font-family: 'cl-bold';
        font-size: 2.923rem;
      }
    }
    .Form {
      width: 100%;
      height: 100%;
      display: flex;
      gap: 1.8462rem;
      flex-direction: column;
    }
  }
}

.Botonera {
  display: grid;
  width: 100%;
  height: 6.08rem;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  // padding: 1.5rem 3.6rem 1.5rem 2.4rem;
  padding: 1.5rem 2.4rem 1.5rem 2.4rem;
  border-top: 1px solid $clara-light-grey;
  box-sizing: border-box;
  .LeftButtons {
    display: flex;
    gap: 1.8462rem;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .RightButtons {
    display: flex;
    gap: 1.8462rem;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-right: 0!important;
  }
}

.skeletonContainer {
  display: flex;
  flex-direction: row;
  grid-gap: 1.8462rem;
  gap: 1.8462rem;
}

.TemplateLabel {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 16.4615rem 1fr;
  grid-column-gap: 1.5385rem;
  .skeletonList {
    display: flex;
    flex-direction: column;
    gap: 1.8462rem;
    align-items: flex-start;
  }
  > * {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

.showSkeleton {
  position: absolute;
  width: 100%;
  background-color: $clara-white;
  height: 51.4rem !important;
  z-index: 2;
}
.GridTemplateWizardPreview {
  display: grid;
  grid-template-columns: 308px 340px;
  grid-gap: 21px;
  > div:first-child {
    justify-content: flex-start;
    align-items: flex-start;
  }
  > div:last-child {
    justify-content: flex-start;
    align-items: flex-end;
  }
}
.ClassFileUpload {
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 31rem !important;
}

// scss for frame_no_company
.GridTemplateWizardInfoPreview{
  display: grid;
  grid-template-columns: 370px 320px;
  grid-template-rows: minmax(0px, auto);
  grid-column-gap: 30px;
  > div:first-child {
    grid-column: 1;
    grid-row: 1 / 3;
    justify-content: flex-start;
    align-items: flex-start;
  }
  > div:last-child {
    grid-column: 2;
    grid-row: 2;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

.detailImage {
  width: 100%;
  height: auto;
  object-fit: contain;
}


// scss for frame_yes_company
.GridTemplateWizardInfoPreviewCompany{
  display: grid;
  grid-template-columns: 466px 267px;
  grid-template-rows: minmax(120px, auto);
  grid-gap: 0px;
  > div:first-child {
    grid-column: 1;
    grid-row: 1 / 3;
    justify-content: flex-start;
    align-items: flex-start;
  }
  > div:last-child {
    grid-column: 2;
    grid-row: 2;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

.ContentNoPadding {
  width: 100%;
  padding-top: 4.9231rem;
  padding-left: 4.9231rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  .Header {
    width: 100%;
    .Spacer {
      margin-bottom: 2.462rem;
      margin-top: 1.2308rem;
      font-weight: normal;
      font-family: 'cl-bold';
      font-size: 2.923rem;
    }
  }
  .Form {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 0;
    flex-direction: column;
  }
}

.previewFull {
  width: 100%;
  min-height: 300px;
}

.ClassFileUpload2 {
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 31.5385rem !important;
}

.TemplateWizardExtend {
  position: relative;
  width: 100%;
  height: 100% !important;
  > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
  .Scrollbar {
    width: 100%;
    height: calc(100% - 6.08rem);
    display: block;
    max-height: calc( 100vh - 146px);
  }
  .Content {
    width: 100%;
    padding-left: 4.9231rem;
    padding-right: 2.1538rem;
    padding-top: 4.9231rem;
    padding-bottom: 3.8462rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    .Header {
      width: 100%;
      .Spacer {
        margin-bottom: 2.462rem;
        margin-top: 1.2308rem;
        font-weight: normal;
        font-family: 'cl-bold';
        font-size: 2.923rem;
      }
    }
    .Form {
      width: 100%;
      height: 100%;
      display: flex;
      gap: 1.8462rem;
      flex-direction: column;
    }
  }
  .infoContent {
    padding-left: 4.9231rem;
    padding-right: 4.9231rem;
    padding-top: 4.9231rem;
    padding-bottom: 0 !important;
    width: 100%;
    .Header {
      width: 100%;
      .Spacer {
        margin-bottom: 2.462rem;
        margin-top: 1.2308rem;
        font-weight: normal;
        font-family: 'cl-bold';
        font-size: 2.923rem;
      }
    }
    .Form {
      width: 100%;
      height: 100%;
      display: flex;
      gap: 1.8462rem;
      flex-direction: column;
    }
  }
}

.GridTemplateWizardPreviewExpended {
  display: grid;
  grid-template-columns: 308px 380px;
  grid-gap: 21px;
  > div:first-child {
    justify-content: flex-start;
    align-items: flex-start;
  }
  > div:last-child {
    justify-content: flex-start;
    align-items: flex-end;
  }
}

.allContainer {
  width: 100%;
  height: calc(100% - 6.08rem);
  display: block;
  max-height: calc( 100vh - 146px);
  padding-left: 4.9231rem;
  padding-top: 4.9231rem;
  padding-bottom: 0 !important;
  .headerTitle {
    width: 100%;
    .SpacerTitle {
      margin-bottom: 2.462rem;
      margin-top: 1.2308rem;
      font-weight: normal;
      font-family: 'cl-bold';
      font-size: 2.923rem;
    } 
  }
  .scrollSection {
    width: 100%;
    height: calc(100% - 112px);
    display: block;
    .Form {
      width: 100%;
      height: 100%;
      display: flex;
      gap: 1.8462rem;
      flex-direction: column;
    }
  }
  .scrollSectionExtended {
    width: 100%;
    height: 100%;
    display: block;
    .Form {
      width: 100%;
      height: 100%;
      display: flex;
      gap: 1.8462rem;
      flex-direction: column;
    }   
  }
}

.uploadTitleSpace {
  display: block;
  padding-bottom: 0.3077rem;
}