@import "../../../../../styles/variables/index.scss";
@import "../../../../../styles/variables/colors.scss";

.pageTextContent {
  top: 47% !important;
  border-radius: 5px;
  canvas{
    margin:auto;
  }
}

.pageTextNoSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.clickable {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  color: lightgray;
}

.containerPreview {
  width: 100%;
  height: 100%;
  overflow: scroll;
  .containerPreviewFile {
    width: 100%;
    height: 100%;
    position: relative;
    iframe {
      width: calc(100% + 1px);
      height: calc(100% + 22px);
      border: transparent;
      margin-top: -1px;
      margin-left: -1px;
    }
    img {
      width: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
}
.allPages {
  height: 100%;
  max-height: 500px;
}
.loadingPreview {
  width: 100% !important;
  height: 100% !important;
  text-align: center !important;
  display: block !important;
  position: absolute !important;
  left: 0px;
  top: 0;
  z-index: 1 !important;
}

.containerNotPreview {
  height: 100%;
  width: 100%; // calc(100% - 22rem);
  display: flex;
  justify-content: center;
  align-items: center;
}
.Reload {
  position: absolute;
  left: 10px;
  top: 5px;
  background: transparent;
  color: $clara-blue;
  font-size: 10px;
  cursor: pointer;
  width: auto !important;
  height: auto !important;
}

.Toolbar {
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
}

.scrollContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.PDFViewer {
  height: 100%;
  width: 100%;
  position: relative;
}

.PDFContainerAllPages {
  width: 100%;
  height: 100%;
}

.pageSeparator {
  margin-top: 1rem;
}

.pageScrollPadding {
  display: flex;
  justify-content: center;
}

.divSpacer {
  width: 100%;
  height: 1rem;
}

.errorWarning {
  display: flex;
  justify-content: center;
  align-items: center;
}

.document{
  text-align: center;
  display: block;
  align-content: space-around;
  justify-content: space-around;

}

.scroll{
  padding-top: 60px;
  padding-bottom: 60px;
}