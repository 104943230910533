@import "../../../../../styles/";

$border-with-modal: 0.5rem !default;
$border-radius-modal: 5px !default;
$width-modal-container: 110.769230769rem;
$max-height-modal: 276.666666667rem !default;

.MuiDialog-paperWidthMd {
  background: transparent !important;
  display: grid !important;
  grid-template-columns: $width-modal-container ;
  box-sizing: content-box !important;
  column-gap: 1rem;
  height: $max-height-modal !important;
}

.MuiDialogPaper {
  overflow: hidden !important;
  border-top: hidden !important;
}

.containerModal {
  background: #fff;
  border-top: $border-with-modal solid $clara-red;
  border-radius: $border-radius-modal;
  height: 100%;
  display: grid;
  grid-template-rows: auto;
}