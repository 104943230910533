@import "./../../../../../styles/variables/colors.scss";
.CardContent{
   width: 100%;
   height: auto;
   outline: none !important;
   
}
.Card {
   height: auto;
   outline: none !important;
   background-color: $clara-white;
   border: 2px solid $clara-white;
   border-radius: 0.9231rem;
   -webkit-border-radius: 0.9231rem;
   -moz-border-radius: 0.9231rem;
   -ms-border-radius: 0.9231rem;
   -o-border-radius: 0.9231rem;
   // padding: 0.9rem 1.3rem 1.1rem 0.9rem;
   // padding: 11.7px 1rem 1.1rem 1rem;
   padding-top: 16px;
   padding-left: 16px;
   padding-bottom: 16px;
   padding-right: 16px;
   box-sizing: border-box;
   &[data-hidden='true'] {
      margin: 0;
      padding: 0;
   }
   &[data-show_hover_effect='true'] { 
      &:hover {
         border-color: $clara-blue;
      }
   }
   &[data-suggested='true'] {
      border: 2px solid $clara-blue;
   }
   &[data-selected='true'] {
      background-color: $clara-blue-over;
      border: 2px solid $clara-blue;
   }
   &[data-innactive='true'] {
      background: $clara-white;
      border-color: $clara-white !important;
      opacity: 0.6;
      pointer-events: none;
      cursor: no-drop;
   }
}
