.Title {
    font-size: 1.8462rem;
    font-weight: bold;
}

.SubTitle {
    font-size: 1.1538rem;
}
.ForbiddenView{
    width:100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img{
        width: 11.7692rem;
    }
    *{
        margin: 0.5rem 0;
    }
}