@import "../../../../../../styles/variables/index.scss";

.containerTabPartiesUploaded {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden ;
    display: block;
    position: relative;
    height: 24rem ;
}

.containerFileName {
    display: inline-flex;
    position: relative;
    align-items: flex-end;
    margin-left: 2.5px;
}

.textFileName {
    color: $clara-blue;
    width: 100%;
    display: inline-flex;
    align-items: center;
    padding-left: 10px;
}

.initialIcon {
    margin-top: 1rem;
    li {
        color: $clara-blue;
        width: 100%;
        display: inline-flex;
        padding-left: 10px;
    }
    li::before {
        content: "•";
        color: $clara-blue;
        display: inline-block;
        width: 1em;
    }
}
.containerAvatar {
    padding: 1rem 0;
    border-left: 1px solid $color-border-line;
    margin-left: 2.5px;
    display: inline-grid;
    grid-template-columns: 1.5rem 2.7692rem auto;
    width: 100%;
    align-items: center;
   > i {
        width: 100%;
        height: 1px;
        background: $color-border-line;
    }
    position: relative;
}

.containerText {
    height: 100%;
    display: inline-flex;
    cursor: pointer;
    flex-direction: column;
    >label {
        color: $clara-blue;
        width: 100%;
        display: inline-flex;
        align-items: center;
        padding-left: 10px;
        cursor: pointer;
    }
    >span {
        color: $clara-grey;
        width: 100%;
        display: inline-flex;
        align-items: center;
        padding-left: 10px;
    }
}

.lastParty {
    &::before{
        content: ' ';
        width: 1px;
        height: 50%;
        top:50%;
        transform: translateX(-1px);
        background: #fff;
        position: absolute;
    }

}


.containerTabHistory {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
}

.containerHistoryText{
    border-left: 1px solid $color-border-line;
    margin-left: 2.5px ;
    padding: 0.5rem 0 0.5rem 0.5rem;

}

.containerHistoryText{
    label{
        color:   $clara-dark-grey;
        font-weight: 400;
    }
}

.containerAuthorized{
    order: 5;
    grid-column: 2/4;
}

.AuthorizedSignatoriesContainer{
    display: inline-flex;
    flex-wrap: wrap;
    
    
}
.AuthorizedSignatoriesItem{
    margin:0.5rem 0 ;
    display: inline-flex;
    margin-right: 0.75rem;
}
.StatusTitle{
    font-size: 0.7692rem ;
}

.StatusValue{
    font-size: 0.7692rem ;
}
