.ChatShareCaptial {
    button {
        margin-left: 0 !important;
    }
}
.inputContainer {
    width: 155px !important;
}
.containerRow {
    display: inline-flex;
    width: 100%;
    align-items: center;
    margin-top: 20px;
    > div {
        width: max-content;
        height: auto;
        text-align: center;
        margin-right: 20px ;
    }

    input {
        width: 7.25rem;
        padding-left: 10px;
    }
    > div:nth-child(3) {
        height: 40px;
        width: 65px;
        line-height: 2.5rem;
    }

    div:nth-child(2) {
        div {
            width: 100px !important;
            margin: 0;
        }
        input {
            width: 9.625rem !important;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
            
        }
    }
}

.containerTotals{
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
     
    p{
        margin: 8px 0px 8px 0 ;
        span:nth-child(2) {
            font-weight: 500;
        }
    }
}
.columntitleNumShares{
    font-size: 14px;
    margin-left: 70px;
}
.columntitlePaidUnpaid{
    font-size: 14px;
    margin-left: 170px;
}