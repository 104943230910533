@import "../../../styles/variables/colors";

.Scrollbar {
  scrollbar-width: thin;
  position: relative;
}

.overflow-y {
  overflow-y: auto !important;
  overflow-x: hidden;
}

.paddingAdd {
  padding: 0.5rem;
}

.overflow-x {
  overflow-x: auto !important;
  overflow-y: hidden;
}

.Scrollbar::-webkit-scrollbar {
  background-color: transparent;
  width: 0.3846rem;
  height: 0.3846rem;
}

.Scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.Scrollbar::-webkit-scrollbar-track:hover {
  background-color: transparent;
}

.Scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: $clara-grey;
  border-radius: 0.3846rem;
}

.Scrollbar:hover::-webkit-scrollbar-thumb:hover {
  background-color: $clara-grey;
}

.Scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 0.3846rem;
}

.Scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: $clara-grey;
}

.Scrollbar::-webkit-scrollbar-button {
  display: none;
}

.Scrollbar {
  height: 100%;
  width: 100%;
  max-height: inherit;
}
