@import "./../styles/variables/colors.scss";
$zIndexLeft: 50;
$zIndexRight: 100;
$zIndexCircle: 75;
$circleSize: 350px;
$circleSizeSmall: 15px;
$widthLeftEducation: 324px;

.Loader {
  position: relative;
  width: 50px;
  height: 50px;

  & > :first-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-top-color: #2c74ff;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
