@import "../../../../../styles/variables/index";
button.ButtonIcon{
    border-radius: 50%;
    min-width: 1.8462rem;
    min-height: 1.8462rem;
    width: 1.8462rem;
    height: 1.8462rem;
    padding: 0;
}
.ButtonLoading{
    background-color: transparent!important;
    circle {
        color: $clara-blue;
    }   
}