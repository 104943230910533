@import "../../../../styles/variables/colors.scss";

.fullContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.container {
    max-width: 38.2rem;
    padding: 5.2rem 5.7rem 1.6rem 5.7rem;
    height: 98%;
    display: flex;
    flex-direction: column;
    .formContainer {
        max-width: 38.2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        div {
            max-width: 38.2rem;
            margin: 0;
        }
    }
    .loginTitle {
        display: flex;
        font-family: "cl-regular";
        font-weight: 400;
        font-size: 22px;
        color: $clara-blue;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
    }
    .fieldContainer {
        margin-bottom: 30px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 3.1rem;
    }
    .termsAndConditions {
        display: flex;
        font-family: "cl-regular";
        font-weight: 300;
        font-size: 14px;
        color: $clara-dark-grey;
        flex-direction: column;
        margin-bottom: 18px !important;
    }
    .submitButton {
        margin-top: 28px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 515px;
        div {
            justify-content: center !important;
        }
        button {
            width: 200px;
            background-color: $clara-blue;
            font-family: "cl-regular";
            font-weight: 600;
            font-size: 14px;
        }
    }
    .logInButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: "cl-regular";
        font-weight: 300;
        font-size: 12px;
        color: $clara-black;
        a {
            font-weight: 600;
            color: $clara-blue;
        }
    }
    .linkedInButton {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        button {
            background-color: #3079b1;
            width: 200px;
            font-family: "cl-regular";
            font-weight: 500;
            font-size: 14px;
            color: $clara-white;
            border-radius: 4px;
            height: 40px;
        }

        .signUpLink {
            font-family: "cl-regular";
            font-weight: 300;
            font-size: 12px;
            width: 75%;
            color: $clara-black;
            text-align: center;
            a {
                font-weight: 300;
                color: $clara-blue;
            }
        }
    }
    .footerLinks {
        flex-direction: row;
        align-items: flex-end;
        display: flex;
        justify-content: center;
        margin-top: auto;
        position: relative;
        left: 15px;
        white-space: nowrap;
        .links {
            text-decoration: none;
            font-family: "cl-regular";
            font-weight: 300;
            font-size: 12px;
            margin-right: 30px;
            color: $clara-blue;
        }
    }
    .firstTerms {
        margin-top: 43px;
    }
    .subTitleTextContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        .firstText {
            margin-top: 10px;
        }
        span {
            font-family: "cl-regular";
            font-weight: 300;
            font-size: 14px;
            color: $clara-black;
            text-align: center;
            margin-bottom: 11px;
        }
    }
    .forgotPasswordText {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 15px !important;
        span {
            font-family: "cl-regular";
            font-weight: 300;
            font-size: 14px;
            color: $clara-black;
            a {
                color: $clara-blue;
            }
        }
    }
    .resendButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 15px !important;
        button {
            width: 200px;
            background-color: $clara-blue;
            font-family: "cl-regular";
            font-weight: 600;
            font-size: 14px;
        }
    }
    .verifyButton {
        margin-top: 40px !important;
        margin-bottom: 54px !important;
    }
    .forgotPasswordTextSubtitle {
        margin-bottom: 60px;
    }
    .textBetweenButtons {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 5px;
        font-family: "cl-regular";
        font-weight: 300;
        font-size: 14px;
        color: $clara-black;
        .text {
            margin: 20px;
        }
        .line {
            border-bottom: 1px $clara-light-blue solid;
            display: inline-block;
            width: 17.2308rem;
            margin-top: 8px;
        }
    }
}

.footerSeparator{
    position: relative;
    right: 15px;
    color: $clara-light-blue;
}
