@font-face {
  font-family: 'Clara-set';
  src:  url('fonts/Clara-set.eot?z2c5tn');
  src:  url('fonts/Clara-set.eot?z2c5tn#iefix') format('embedded-opentype'),
    url('fonts/Clara-set.ttf?z2c5tn') format('truetype'),
    url('fonts/Clara-set.woff?z2c5tn') format('woff'),
    url('fonts/Clara-set.svg?z2c5tn#Clara-set') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="clara-"], [class*=" clara-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Clara-set' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.clara-Scenario-modelling:before {
  content: "\e9c2";
}
.clara-COLUMN:before {
  content: "\e9c0";
}
.clara-TABLE:before {
  content: "\e9c1";
}
.clara-List:before {
  content: "\e9be";
}
.clara-Paragraph:before {
  content: "\e9bf";
}
.clara-Edit:before {
  content: "\e9bc";
}
.clara-Comments:before {
  content: "\e9bd";
}
.clara-Add-company:before {
  content: "\e9ba";
}
.clara-Menu:before {
  content: "\e9bb";
}
.clara-Location-pin:before {
  content: "\e9b9";
}
.clara-Upload:before {
  content: "\e9b8";
}
.clara-Not-Visible:before {
  content: "\e9b7";
}
.clara-Edit1:before {
  content: "\e9b6";
}
.clara-Thumbs-up:before {
  content: "\e9a0";
}
.clara-Home:before {
  content: "\e9a1";
}
.clara-Scales:before {
  content: "\e9a2";
}
.clara-Unlocked-lrg:before {
  content: "\e9a3";
}
.clara-Settings-cog:before {
  content: "\e9a4";
}
.clara-Light-bulb:before {
  content: "\e9a5";
}
.clara-Timer:before {
  content: "\e9a6";
}
.clara-Bookmark:before {
  content: "\e9a7";
}
.clara-Forward-arrow:before {
  content: "\e9a8";
}
.clara-Bank:before {
  content: "\e9a9";
}
.clara-Notepad:before {
  content: "\e9aa";
}
.clara-Target:before {
  content: "\e9ab";
}
.clara-Academy-lrg:before {
  content: "\e9ac";
}
.clara-Alarm-clock:before {
  content: "\e9ad";
}
.clara-Envelope:before {
  content: "\e9ae";
}
.clara-Annoucement:before {
  content: "\e9af";
}
.clara-Messages:before {
  content: "\e9b0";
}
.clara-London:before {
  content: "\e9b1";
}
.clara-Crown:before {
  content: "\e9b2";
}
.clara-Dubai:before {
  content: "\e9b3";
}
.clara-Abu-Dhabi:before {
  content: "\e9b4";
}
.clara-UAE:before {
  content: "\e9b5";
}
.clara-Locked:before {
  content: "\e99f";
}
.clara-Warrant:before {
  content: "\e99d";
}
.clara-Pie-chart:before {
  content: "\e99e";
}
.clara-Bullseye:before {
  content: "\e99c";
}
.clara-Heart-lcon:before {
  content: "\e999";
}
.clara-Profile-share:before {
  content: "\e99a";
}
.clara-Upload-doc:before {
  content: "\e99b";
}
.clara-Dollar:before {
  content: "\e997";
}
.clara-Clock1:before {
  content: "\e998";
}
.clara-In-progress .path1:before {
  content: "\e995";
  color: rgb(234, 241, 254);
}
.clara-In-progress .path2:before {
  content: "\e996";
  margin-left: -1em;
  color: rgb(252, 199, 45);
}
.clara-Tick-coloured .path1:before {
  content: "\e993";
  color: rgb(234, 241, 254);
}
.clara-Tick-coloured .path2:before {
  content: "\e994";
  margin-left: -1em;
  color: rgb(24, 197, 168);
}
.clara-Doc-edit:before {
  content: "\e992";
}
.clara-Stakeholder-icon:before {
  content: "\e991";
}
.clara-Next-circle:before {
  content: "\e98f";
}
.clara-Previous-circle:before {
  content: "\e990";
}
.clara-Globe:before {
  content: "\ea54";
}
.clara-UAE-outline:before {
  content: "\ea55";
}
.clara-Pause:before {
  content: "\e98d";
}
.clara-Play:before {
  content: "\e98e";
}
.clara-DOC:before {
  content: "\e988";
}
.clara-JPG:before {
  content: "\e989";
}
.clara-PDF:before {
  content: "\e98a";
}
.clara-PNG:before {
  content: "\e98b";
}
.clara-XLXS:before {
  content: "\e98c";
}
.clara-DocuSign-Glyph:before {
  content: "\e985";
}
.clara-Maps-Icon:before {
  content: "\e986";
}
.clara-Legal:before {
  content: "\e987";
}
.clara-Expand-card:before {
  content: "\e984";
}
.clara-stakeholders:before {
  content: "\e983";
}
.clara-Mountains-with-flag:before {
  content: "\e982";
}
.clara-Essentials:before {
  content: "\e974";
}
.clara-stakeholders-quantity:before {
  content: "\e975";
}
.clara-plus-icon-filters:before {
  content: "\e976";
}
.clara-complete-and-incomplete:before {
  content: "\e977";
}
.clara-pending:before {
  content: "\e978";
}
.clara-Prepare-for-due-diligence:before {
  content: "\e97e";
}
.clara-Task:before {
  content: "\e97f";
}
.clara-Prepare-for-Due-diligence:before {
  content: "\e980";
}
.clara-Create-and-manage-cap-tables:before {
  content: "\e981";
}
.clara-Map-lines:before {
  content: "\e973";
}
.clara-Filter-dropdown:before {
  content: "\e972";
}
.clara-no-comp:before {
  content: "\e97d";
}
.clara-minitick:before {
  content: "\e97c";
}
.clara-Graph:before {
  content: "\e970";
}
.clara-Invoice:before {
  content: "\e971";
}
.clara-Organisation:before {
  content: "\e979";
}
.clara-Pen-paper:before {
  content: "\e97a";
}
.clara-Security-doc:before {
  content: "\e97b";
}
.clara-Upload-Document:before {
  content: "\e96f";
}
.clara-LinkedIn-mini-icon .path1:before {
  content: "\e96a";
  color: rgb(0, 0, 0);
}
.clara-LinkedIn-mini-icon .path2:before {
  content: "\e96b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.clara-CV-mini-icon .path1:before {
  content: "\e96c";
  color: rgb(0, 0, 0);
}
.clara-CV-mini-icon .path2:before {
  content: "\e96d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.clara-CV-mini-icon .path3:before {
  content: "\e96e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.clara-List-Plan:before {
  content: "\e969";
}
.clara-Glyph-Tick-2:before {
  content: "\e968";
}
.clara-Switch-around:before {
  content: "\e967";
}
.clara-DocuSign:before {
  content: "\e966";
}
.clara-Xlsx:before {
  content: "\e961";
}
.clara-Generic:before {
  content: "\e962";
}
.clara-Zip:before {
  content: "\e963";
}
.clara-Pptx:before {
  content: "\e964";
}
.clara-Edit-Signatories:before {
  content: "\e965";
}
.clara-Add-card:before {
  content: "\e94f";
}
.clara-Coins:before {
  content: "\e950";
}
.clara-contact-card:before {
  content: "\e951";
}
.clara-Dcument-Money:before {
  content: "\e952";
}
.clara-Delete-bin:before {
  content: "\e953";
}
.clara-Document-personal:before {
  content: "\e954";
}
.clara-Document-tick:before {
  content: "\e955";
}
.clara-Document-convertible:before {
  content: "\e956";
}
.clara-Document-download:before {
  content: "\e957";
}
.clara-Document-edit:before {
  content: "\e958";
}
.clara-document-generate:before {
  content: "\e959";
}
.clara-Document-Share-option:before {
  content: "\e95a";
}
.clara-document-stack:before {
  content: "\e95b";
}
.clara-Document-warrant:before {
  content: "\e95c";
}
.clara-Edit-card:before {
  content: "\e95d";
}
.clara-investor:before {
  content: "\e95e";
}
.clara-Website:before {
  content: "\e95f";
}
.clara-Plane:before {
  content: "\e960";
}
.clara-Rocket-Icon:before {
  content: "\e94e";
}
.clara-error:before {
  content: "\e94c";
}
.clara-linkedin-Logo:before {
  content: "\e94d";
}
.clara-Signed:before {
  content: "\e949";
}
.clara-Download:before {
  content: "\e94a";
}
.clara-Remark:before {
  content: "\e94b";
}
.clara-Restrictive-Covenant:before {
  content: "\e948";
}
.clara-Advisor-Agreement:before {
  content: "\e93e";
}
.clara-Consutancy-Agreement:before {
  content: "\e93f";
}
.clara-Convertible-Loan-Note:before {
  content: "\e940";
}
.clara-Employment-Agreement:before {
  content: "\e941";
}
.clara-Founders-Agreement:before {
  content: "\e942";
}
.clara-Founders-Service-Agreement:before {
  content: "\e943";
}
.clara-IP-Assignment:before {
  content: "\e944";
}
.clara-NDA:before {
  content: "\e945";
}
.clara-SAFE:before {
  content: "\e946";
}
.clara-SIP-Grant:before {
  content: "\e947";
}
.clara-No-Messages:before {
  content: "\e928";
}
.clara-Assistant:before {
  content: "\e93d";
}
.clara-Unlocked:before {
  content: "\e90f";
}
.clara-Triangle:before {
  content: "\e910";
}
.clara-Ticks:before {
  content: "\e911";
}
.clara-Tick:before {
  content: "\e912";
}
.clara-Star:before {
  content: "\e913";
}
.clara-Share:before {
  content: "\e914";
}
.clara-Settings:before {
  content: "\e915";
}
.clara-Search:before {
  content: "\e916";
}
.clara-Right:before {
  content: "\e917";
}
.clara-Question:before {
  content: "\e918";
}
.clara-Plus:before {
  content: "\e919";
}
.clara-Pie:before {
  content: "\e91a";
}
.clara-Person:before {
  content: "\e91b";
}
.clara-New:before {
  content: "\e91c";
}
.clara-More-Verticle:before {
  content: "\e91d";
}
.clara-More-Horizontal:before {
  content: "\e91e";
}
.clara-Minus:before {
  content: "\e91f";
}
.clara-Mail:before {
  content: "\e920";
}
.clara-Lock:before {
  content: "\e921";
}
.clara-Left:before {
  content: "\e922";
}
.clara-Investor:before {
  content: "\e923";
}
.clara-Inside:before {
  content: "\e924";
}
.clara-Info:before {
  content: "\e925";
}
.clara-Heart:before {
  content: "\e926";
}
.clara-Hamburger:before {
  content: "\e927";
}
.clara-Forward-Back:before {
  content: "\e929";
}
.clara-focus:before {
  content: "\e92a";
}
.clara-Flag:before {
  content: "\e92b";
}
.clara-Eye-Open:before {
  content: "\e92c";
}
.clara-Eye-Closed:before {
  content: "\e92d";
}
.clara-Exclamation:before {
  content: "\e92e";
}
.clara-Equity:before {
  content: "\e92f";
}
.clara-Document:before {
  content: "\e930";
}
.clara-Delete:before {
  content: "\e931";
}
.clara-Concierge:before {
  content: "\e932";
}
.clara-Company:before {
  content: "\e933";
}
.clara-Cloud:before {
  content: "\e934";
}
.clara-Clock:before {
  content: "\e935";
}
.clara-Date:before {
  content: "\e936";
}
.clara-Calendar:before {
  content: "\e937";
}
.clara-Bright:before {
  content: "\e938";
}
.clara-Bargraph:before {
  content: "\e939";
}
.clara-Back:before {
  content: "\e93a";
}
.clara-Aim:before {
  content: "\e93b";
}
.clara-Academy:before {
  content: "\e93c";
}
.clara-Logout:before {
  content: "\e90d";
}
.clara-AuditLog:before {
  content: "\e90e";
}
.clara-Arrow-Left:before {
  content: "\e901";
}
.clara-Arrow-Right:before {
  content: "\e902";
}
.clara-Arrow-Up:before {
  content: "\e908";
}
.clara-Dropdown:before {
  content: "\e900";
}
.clara-Documents:before {
  content: "\e903";
}
.clara-Notification:before {
  content: "\e904";
}
.clara-Health-Check:before {
  content: "\e905";
}
.clara-Maps:before {
  content: "\e906";
}
.clara-Cap-Table:before {
  content: "\e907";
}
.clara-Sharing:before {
  content: "\e909";
}
.clara-View:before {
  content: "\e90a";
}
.clara-Add:before {
  content: "\e90b";
}
.clara-Switch:before {
  content: "\e90c";
}
