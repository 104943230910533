@import "../../../../../styles/variables/index.scss";
.CheckboxInput {
    display: flex;
    width: 100%;
    flex-direction: column;
    > * {
        width: 100%;
    }
    .Columns {
        display: grid;
        width: 100%;
        height: auto;
        grid-row-gap: 0.6154rem;
        > div {
            display: grid;
            grid-template-columns: 16px 1fr;
            grid-column-gap: 16px;
            width: 100%;
            height: auto;
            justify-content: flex-start;
            align-items: center;
            > * {
                display: flex;
            }
        }
        &.col_1 {
            grid-template-columns: 1fr;
        }
        &.col_2 {
            grid-template-columns: repeat(2, 1fr);
        }
        &.col_3 {
            grid-template-columns: repeat(3, 1fr);
        }
        &.col_4 {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}
.CheckboxOption {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-grow: 3;
    > button {
        margin-right: 1rem;
    }
}
.buttonCheckboxDelete {
    background: $clara-white;
    padding: 0;
    margin: 0;
    border: 1px solid $clara-grey;
    width: 1.2308rem;
    height: 1.2308rem;
    border-radius: 0.3077rem;
    align-items: center;
    justify-content: center;
    &:focus {
        outline: 1px solid $clara-red;
        outline-offset: initial;
    }
    * {
        display: none;
    }
    &[data-checked="true"] {
        background: $clara-red;
        border-color: $clara-red;
        color: $clara-white;
        * {
            display: flex;
        }
    }
}
.buttonCheckboxDefault {
    background: $clara-white;
    padding: 0;
    margin: 0;
    border: 1px solid $clara-grey;
    width: 1.2308rem;
    height: 1.2308rem;
    border-radius: 0.3077rem;
    align-items: center;
    justify-content: center;
    &:focus {
        outline: 1px solid #2c74ff;
        outline-offset: initial;
    }
    * {
        display: none;
    }
    &[data-checked="true"] {
        background: $clara-blue;
        border-color: $clara-blue;
        color: $clara-white;
        * {
            display: flex;
        }
    }
}
.error {
}

.buttonCheckboxDefaultBasic {
    background: $clara-white;
    padding: 0;
    margin: 0;
    border: 1px solid $clara-grey;
    width: 1.2308rem;
    height: 1.2308rem;
    border-radius: 0.3077rem;
    align-items: center;
    justify-content: center;
    &:focus {
        outline: 1px solid #2c74ff;
        outline-offset: initial;
    }
    * {
        display: none;
    }
    &[data-checked="true"] {
        background: $clara-blue;
        border-color: $clara-blue;
        color: $clara-white;
        display: flex;
        * {
            display: flex !important;
            align-content: center;
            justify-content: center;
        }
    }
}
