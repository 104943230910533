.AutocompleteSelect{
    width: 100%;
    background: #ececec;
    padding: 10px;
    height:64px;
    .claraSelect {
        display: inline-grid;
        grid-template-columns: 200px auto;
        margin: 0.2rem 0 1.2rem 0;
      
        label {
          width: 200px !important;
          text-align: left;
        }
      }
      
}