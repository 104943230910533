@import "../../../../styles/variables/index";
.PrimaryButton {
    width: auto;
    min-width: 9.2308rem;
    height: 3.0769rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1.2308rem;
    text-align: center;
    color: $clara-white;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $bg-primary;    
    border-radius: 0.3077rem;
    font-size: 1.0769rem;
    position: relative;
    padding: 0 1.9231rem;
    &:hover {
        background: rgba($bg-primary-hover,0.8);
    }
    &:disabled {
        background: $clara-dark-grey;
        opacity: 0.3;
        &:hover {
            background: $clara-dark-grey;
        }
        &.loading{
            background: $bg-primary;   
            opacity: 1;
            &:hover {
                background: $bg-primary;   
                opacity: 1;
            }
        }
    }
    &:active{
        background: $clara-dark-blue;
        opacity: 1;
        &:hover {
            background: $clara-dark-blue;
            opacity: 1;
        }
    }
    
}
