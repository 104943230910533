.containerZoneMessages {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.containerNotMessages {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 5.8rem);
  position: absolute;
  span {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
  }
  label {
    margin-top: 0.7692rem;
    color: #828c98;
    font-size: 1rem;
  }
}

.containerThreadMessage {
  height: 100%;
  width: max-content;
  padding: 3rem 2rem;
  display: inline-flex;
  flex-direction: column;
}

.containerButtonEvent {
  width: 43.2308rem;
  padding-left: 2.7692rem;
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  button {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.containerIcon {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(50%);
}
