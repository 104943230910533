.PrimaryButton{
    width: auto;
    height: 40px;
    
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
    display: inline-block;
    background: #2c74ff;
    border-radius: 4px;
    min-width: 120px; 
}