@import "./../../../../styles/variables/colors.scss";

.BubbleContentsText {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.bubbleNormal {
  font-size: 0.9231rem !important;
  font-weight: 600 !important;
  line-height: 1.333rem !important;
}