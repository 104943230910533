.ContainerForm {
  display: grid;
  grid-template-columns: 20.6rem 29rem;
  column-gap: 1.8rem;
}

.TemplateLabel {
  grid-template-columns: 1fr 14.7692rem !important;
}
.TemplateLabelMin{
  grid-template-columns: 10rem 1fr !important;
}
.CalendarInputDate{
  width: 100%;
}

.buttonCheckBox {
  width: 12rem;
  height: 10rem;
}

.CheckboxButton {
  [data-contentButton="true"] {
    width: 146px;
  }
}