@import "../../../../../../../styles/variables/index.scss";

.skeletonDocumentsContainer {
  background-color: $clara-white;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.containerTable {
  table thead {
    tr {
      height: 0.7692rem;
      th {
        position: relative;
        top: 1.6923rem;
        padding-bottom: 1.5385rem;
      }
    }
  }
}

.table {
  border-spacing: 0;
  width: 100%;
  tr {
    height: 4.7rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    td {
      border-bottom: 1px solid #eee;
      padding-top: 1.7rem;
      font-size: 0.9231rem;
      color: $clara-dark-grey;
    }
  }
}

.skeletonIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.7692rem;
  margin-top: -0.3077rem;
}

.t1 {
  width: 6.9231rem;
}

.t2 {
  width: 5.0769rem;
}

.t3 {
  width: 16.1538rem;
}

.t4 {
  width: 11.2308rem;
}

.skelCenterAvatar {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-right: 1.2308rem;
  margin-top: -0.6154rem;
}
