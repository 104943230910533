.containerData {
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 15px;
  text-align: left;
  justify-content: space-between;
  > div {
    > div {
      > label {
        padding-right: 15px !important;
        width: 100% !important;
      }
    }
  }
  article {
    color: #828c98;
    font-size: 13px;
    
    >a{
      border-bottom: 1px solid #2c74ff ;
      color: #2c74ff;
      &:hover{
       border: hidden; 
      }
    }
  }

  h1{
    
    font-size: 18px !important;
    font-weight: normal !important;
  }

  h2 {
    
    font-size: 16px;
    margin-bottom: 0px;
    font-weight: 300;
  }
}

.textArea {
  display: inline-grid;
  align-items: flex-start;

  label {
    line-height: 1rem !important;
    max-width: 200px !important;
    align-self: start;
  }
}
.buttonOptions {
  display: inline-grid !important ;
  width: 580px !important;
  grid-template-columns: 200px 180px 180px;
  button {
    border-color: #eaf1fe !important ;
    margin-left: 0 !important;
  }

  label {
    width: 200px !important;
    text-align: left;
    color: #808080;
    
    font-size: 13px;
  }
}

.fileUpload {
  display: inline-grid;
  justify-content: space-between;
  grid-template-columns: 192px 385px;
  justify-items: end;
  margin: 0.2rem 0 1.2rem 0;

  label {
    max-width: 200px;
    line-height: 16px;
    text-align: left;
    padding-left: 10px;
    padding-right: 15px;

  }
}

.claraSelect {
  display: inline-grid;
  grid-template-columns: 200px auto;
  margin: 0.2rem 0 1.2rem 0;

  label {
    width: 200px !important;
    text-align: left;
  }
}

.submit {
  position: relative;
  left: calc(100% - 150px);
  margin-top: 2rem !important;
}

.horizontalOptionsButton {
  flex-wrap: nowrap !important;
  margin: 0 !important;
  justify-content: flex-start !important ;

  button {
    min-width: 125px !important;
    margin-left: 0.3rem !important;
    margin-right: 0.3rem !important;
  }
}
.RegisteredAddress{
  display: inline-grid !important ;
  width: 580px !important;
  grid-template-columns: 335px 130px 120px;
  button {
    border-color: #eaf1fe !important ;
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 102px !important ;
  }

  label {
    width: 200px !important;
    text-align: left;
    color: #808080;
    
    font-size: 13px;
  }

}


//Component Add 

.containerAddEntity{
  
  >h1{
    margin-bottom: 1rem !important;
  }

}

.containerButtons{
  width: 100%;
  display: inline-flex;
  justify-content: flex-end;
  margin-top: 1rem;

  button:first-child{
    background: #FFF;
    color:#2c74ff;
    border: 1px solid  #eaf1fe;    
    border-radius: 5px;
    height: 40px;
    padding: 0 1rem;
    font-weight: 600;
  }
  button:nth-child(2){
    margin-left: 1rem;
  }


}