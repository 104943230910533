.InputAndOptionsButtons {
    display: inline-flex;
    justify-content: space-between;
    text-align: end;
    grid-template-columns: auto 20rem;
    button {
        min-width: 4.5rem !important ;
    }
    div {
        width: 8.75rem;
    }
}