@import "../../../../../../../styles/variables/colors.scss";

.deleteInfo {
  font-family: "cl-regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.textArea {
  margin-top: 1rem;
  label{
    width: 34% !important;
  }
  textarea {
    position: relative;
    top: 20px;
    height:8rem !important;
  }
  padding-bottom: 20px;
}

.textCheckbox {
  margin-top: 1rem;
  font-family: "cl-regular";
  font-size: 12px;
  font-weight: 400;
  color: $clara-dark-grey;
  position: relative;
  top: 2px;
}

.errorCheckboxLabel {
  transform: translateY(100%);
  left: 0px;
  width: max-content !important;
  position: absolute;
}

.Checkbox div div label {
  grid-gap: 0 !important;
  gap: 0 !important;
}
.terminateInfo {
  margin-top: 1rem;
  font-family: "cl-regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}