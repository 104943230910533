@import "../../../../styles/variables/index";
.SecondaryButton{
    width: auto;
    height: 3.0769rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.2308rem;
    text-align: center;
    color: $palette-clara;
    display: inline-block;
    background: $bg-secondary;
    border-radius: 0.3077rem;
    border: 0.0769rem solid $palette-clara;
    min-width: 9.2308rem;
    
    &:hover,&:focus-visible {
        background: $bg-primary-hover;
        color: $clara-white;
        opacity: 0.8;
    }
    &:disabled {
        background: $clara-white;
        color: $clara-dark-grey;
        border-color: $clara-dark-grey;
        opacity: 0.3;
        &.loading{
            background: $bg-secondary;
            opacity: 1;
            &:hover ,&:focus{
                background: $bg-primary;   
                opacity: 1;
                color: $clara-white;
            }
        }
        
       
    }   
}

