@import "../../../../../../styles/variables/index.scss";

.containerPreviwer {
  border-width: 1px;
  border-radius: 5px;
  position: relative;
  border-style: dashed;
  border-color: $clara-blue;
  padding-left: 1.0769rem;
  padding-right: 1.0769rem;
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  box-sizing: border-box;
  // width: 23.3rem;
  width: 23rem;
  height: 23rem;
}

.containerPreviewerButton {
  padding-right: 3.0769rem;
}

.containerDocumentPreviewer {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.imagesContainer {
  width: 100%;
  height: 100%;
  // object-fit: cover;
  object-fit: contain;
}

.skeletonViwer {
  position: absolute;
}

.buttonLeftStep {
  border-radius: 0%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 1.3rem;
  min-width: 1.3rem;
  left: 0.8rem;
  height: 2.3077rem;
  background: $clara-light-blue;
  border-color: $clara-light-blue;
  cursor: pointer;
}

.buttonRightStep {
  border-radius: 0%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 1.3rem;
  min-width: 1.3rem;
  right: 0.8rem;
  height: 2.3077rem;
  background: $clara-light-blue;
  border-color: $clara-light-blue;
  cursor: pointer;
}

.containerButtons {
  position: absolute;
  right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 32px;
  height: 100%;
  top: 16px;
  padding-bottom: 32px;
}

.buttonActions {
  border-radius: 5px;
  border: 1px solid #eaf1fe;
  width: 32px;
  height: 32px;
  background-color: $clara-white !important;
}

.containerImage {
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-right: 16px;
  > img {
    transition: all 0.5s;
  }
}

.containerIframe {
  width: 100%;
  height: 100%;
  > iframe {
    width: 100%;
    height: 100%;
  }
}

.buttonDelete {
  position: absolute;
}

.moreContainer {
  display: flex;
  flex-direction: row;
  padding-top: 0.5692rem;
  justify-content: space-between;
}

.nameContainer {
  display: flex;
  flex-direction: row;
  padding-bottom: 0.5692rem;
  justify-content: space-between;
}

.flowLink {
  text-decoration: none;
  color: $clara-blue;
  cursor: pointer;
  &:hover {
    text-decoration: underline !important;
  }
}

.skeletonDocument {
  position: absolute;
  height: 266px;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: $clara-white;
  .skeletoncap {
    width: 100px;
    height: 90px;
    position: absolute;
    top: 18px;
    left: 50px;
    display: flex;
    flex-direction: column;
    grid-gap: 0.7692rem;
    gap: 0.7692rem;
    justify-content: center;
    background-color: $clara-white;
  }
  .skeletoncap2 {
    width: 140px;
    height: 116px;
    position: absolute;
    top: 108px;
    left: 50px;
    display: flex;
    flex-direction: column;
    grid-gap: 0.7692rem;
    gap: 0.7692rem;
    background-color: $clara-white;
  }
}

.containerMultiplePagePDF {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid $clara-preview-background;
  background-color:  $clara-preview-background;
}

.isLoadingPDF {
  border: 1px solid $clara-white !important;
  background-color:  $clara-white !important;
}
