.containerInputText {
  height: 40px;
  display: grid;
  grid-template-columns: auto 75px 226px 75px ;
  color: #808080;
  
  margin: .5rem 0 .2rem 0;

  label {
    text-align: left;
    align-self: center;
  }
}

.inputCode {
  input {
    width: 70px !important;
    padding: 0 0.5rem 0 0.7rem !important;
    justify-self: center;
    align-self: start;   
  }

  div > div{
        width: 100px;
        
  }
  > div {
    margin: 0 !important;
  }
  height: max-content;
}

.inputExt{  
  height: 40px !important;
}

.inputNumber {
  input {
    width: 221px !important;
    justify-self: center;
    padding: 0 0.5rem 0 0.7rem !important;
  }
  > div {
    margin: 0 !important;
  }
}
