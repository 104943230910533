.CloseChat {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
  &:hover {
    i:before {
      color: #2c74ff;
    }
  }
}
.ChatFormDeclaration{
  padding:1.5385rem!important;
}
.ChatContainer {
  width: calc(100% - 250px);
  text-align: center;
  padding: 0;
  margin: auto;
  display: inline-block;
  position: relative;
  min-height: 100%;
  height: auto;
  .Chat {
    width: 800px;
    text-align: center;
    margin: auto;

    form {
      width: 100%;
      margin: 40px 0;

      .FormButton {
        margin-top: 50px;
      }
      & > div {
        text-align: center;
        // display: block;
        // width: 100%!important;
        // &>div{
        //   margin-left:auto;
        //   margin-right:auto;
        //   display: block;
        //   width: auto!important;
        //   div{
        //     margin-left:auto;
        //     margin-right:auto;
        //   }
        // }
      }
    }
    .ChatClaraUpload {
      text-align: center;
    }
    .ChatInputDate {
      width: 100% !important;
      text-align: center;
      & > div {
        margin-left: auto;
        margin-right: auto;
        width: 380px !important;
      }
    }
    .ChatInputTextarea,
    .ChatInputText {
      width: 100% !important;
      margin-left: auto;
      margin-right: auto;
      display: block;
      text-align: center;
      z-index: 2;
      & > div {
        margin-left: auto;
        margin-right: auto;
        display: block;
        width: 380px !important;
        div {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
    .ChatInputClaraSelect {
      text-align: center !important;
    }

    .ChatInputClaraInputSelect {
      width: 100%;
      & > div {
        & > div {
          & > div {
            margin-left: auto !important;
            margin-right: auto !important;
          }
        }
      }
    }
    .ChatAuthorizedSharesSelect {
      text-align: center !important;
      display: inline-block;
      width: 150px !important;
      margin: 5px auto !important;
      span {
        width: 150px !important;
        input {
          width: 150px !important;
        }
      }
    }
    .ChatAuthorizedSharesInput {
      width: 154px !important;
      margin: 0 20px;
      display: inline-block;
      text-align: center;
      & > div {
        margin: 5px auto !important;
        display: block;
        width: 154px !important;
        div {
          margin-left: auto;
          margin-right: auto;
          input {
            width: 100%;
            padding: 0 20px;
          }
        }
      }
    }
    .IframesDiv {
      display: flex;
      flex-direction: column;

      div {
        .TitleText {
          font-size: 1.1538rem;
        }
        .QuestionText {
          p {
            font-size: 2.0769rem;
            margin-top: 0.7692rem;
            margin-bottom: 1.5385rem;
          }
          * {
            line-height: unset;
          }
        }
      }

      .tellmeMoreDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        .tellmeMoreDivContent {
          display: inline-block;
          width: auto;
        }
        a {
          margin-left: 10px;
          color: gray;
          text-align: left;
        }
      }
    }

    .FormButton {
      width: 118px;
      height: 40px;
      text-align: center;
      margin-left: 10px;
      margin-top: 10px;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: white;
      border-radius: 4px;
      background: #2c74ff;
      border: none;
    }
  }

  .Dropzone {
    border: 2px solid #18c5a8;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(112, 206, 143, 0.1);
    z-index: 2;
  }

  .DropZoneButton {
    max-height: 360px;
    border-radius: 4px;
    margin: auto;
    padding: 32px;
    color: black;
    position: relative;
    background-color: rgba(97, 203, 65, 0.1);

    p {
      span {
        color: #2c74ff;
        cursor: pointer;
      }
    }
  }

  .FilesContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    max-height: 360px;
    padding-top: 30px;
    margin-bottom: 10px;

    .FieldFile {
      margin: 10px;
      background-color: rgba(44, 116, 255, 0.15);

      &:last-child {
        margin-bottom: 25px;
      }

      .File {
        overflow: hidden;
        height: 38px;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-radius: 4px;

        p {
          position: unset !important;

          color: #2c74ff;
          font-size: 13px;
          margin-top: 1px;
        }

        .DeleteFileButton {
          cursor: pointer;
          color: #2c74ff;
        }
      }
    }
  }

  .CheckBoxGroup {
    max-height: 360px;
    overflow: auto;
    width: 222px;
    margin: auto;

    .CheckBox {
      margin-top: 15px;
      display: flex;
      padding: 5px;
      align-items: center;

      .Check {
        width: 15px;
        height: 15px;
        color: white;
        cursor: pointer;
        text-align: center;
        padding: 10px;
        border-radius: 5px;

        span {
          color: white;
          margin-top: -8px;
          margin-left: -3px;
        }
      }

      .CheckedDiv {
        border: 1px solid transparent;
        cursor: pointer;
        background: url(../../../images/checkbox1.svg) no-repeat center;
        background-color: #2c74ff;
      }

      .NotCheckedDiv {
        background-color: transparent;
        border: 1px solid grey;
      }

      div {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        text-align: left;

        span {
          color: grey;
        }
      }
    }
  }
}
.ChatContainer .Chat form input {
  position: relative;
}
.ModalChatForm {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}
.ChatForm {
  display: flex;
  margin: auto;
  justify-content: space-around;
  width: 742px;
  align-items: stretch;
  flex-wrap: wrap;
  text-align: center;
  .Avatar {
    justify-content: center;
    align-items: center;
    display: grid;
    flex-grow: 1;
    width: 106px;
    margin: 10px auto;
    text-align: center;

    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    * {
      display: block;
      margin: auto;
    }
    a {
      display: inline-block;
      text-transform: lowercase;
    }
    span {
      display: inline-block;
      margin: 0 1px;
    }
  }
}
.ChatFormMax3 {
  min-width: 318px;
}
.ChatFormMax4 {
  min-width: 424px;
}
.ChatFormMax5 {
  min-width: 530px;
}
.ChatFormMax6 {
  min-width: 636px;
}
.ChatFormMax7 {
  min-width: 742px;
}
.nextStep {
  background: #2c74ff !important;
  border: none !important;
  color: white !important;
  margin-top: 100px !important;
}
.SubCategories {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  .SubCategoriesElement {
    flex-grow: 1;
    width: auto;
    min-width: 30%;
    text-align: center;
    margin: 10px 10px;
    h3 {
      background: #ffffff;
      border-bottom: 1px solid #eaf1fe;
      display: inline-block;
      padding: 0px 20px;
      position: relative;
      width: 100%;
      span {
        background: #fff;
        border: 1px solid #fff;
        display: inline-block;
        padding: 0;

        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        display: inline-block;
        align-items: center;
        position: absolute;
        top: -14px;
        color: #192a3e;
        margin: auto;
        width: 90px;
        left: calc(50% - 45px);
        top: -15px;
      }
    }
    .AvatarSub {
      flex-grow: 1;
      width: 106px;
      margin: 10px auto;
      text-align: center;

      font-style: normal;
      font-weight: normal;
      font-size: 13px;

      * {
        display: block;
        margin: auto;
      }
      a {
        display: inline-block;
        text-transform: lowercase;
      }
      span {
        display: inline-block;
        margin: 0 1px;
      }
    }
  }
}
div.ChatClaraRadioButton {
  display: inline-block !important;
  width: 100% !important;
  .ChatClaraRadioButtonInput {
    display: inline-block !important;
    width: 100% !important;
    text-align: center;
  }
  .ChatClaraRadioButtonInputNominee {
    display: inline-block !important;
    width: 100% !important;
    text-align: center;
    label {
      width: 29.3077rem;
      display: inline-block;
      text-align: left;
      position: relative;
      left: 1.3077rem;
      margin-bottom: 1.5385rem;
      font-family: "cl-regular";
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 1.2308rem;
      color: #828c98;
    }
    div {
      div {
        button {
          margin-right: 4.0769rem;
        }
      }
    }
  }
}
.ChatCompanyName {
  width: 100%;
  .OptionButtonZone {
    width: 50%;
    display: inline-block;
    button {
      width: 200px;
      margin: 5px 20px;
      overflow: hidden;
      padding: 4px;
    }
  }
}
.ChatCompanyDetails {
  max-width: 440px;
  display: block;
  margin: auto;
  text-align: center;
  margin-bottom: 30px;
  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    width: 100%;
    color: #192a3e;
  }
  p {
    b {
      color: #000;
    }

    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #828c98;
    margin: 35px auto 7px auto;
  }
}
.contentBotonera {
  width: 100%;
  text-align: center;
  button {
    display: inline-block !important;
    margin: auto 50px;
  }
}
.buttonOption {
  width: 336px;
  margin: 20px auto;
  height: 40px;

  text-align: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #2c74ff;
  border-radius: 4px;
  border: 1px solid #2c74ff;
  background: #fff;
  display: block;
  &.width160 {
    width: 160px;
    display: inline-block;
    margin: 20px 40px;
  }
  &.buttonOptionSelected,
  &:hover {
    color: #fff !important;
    border: 1px solid #2c74ff !important;
    background: #2c74ff !important;
  }
  &.buttonWithAutomatic {
    width: auto !important;
    padding: 0 15px;
  }
}
.ChatPrimaryAsset {
  width: 440px;
  margin: auto;
  .contentCheckBox {
    display: flex;
    width: 200px;
    justify-content: space-around;
    margin: auto;
    text-align: center;
    align-items: flex-start;
    align-content: center;
  }
  .CheckBox {
    margin-top: 15px;
    display: inline-block;
    padding: 5px;
    align-items: center;
    text-align: center;
    div {
      text-align: center !important;
      margin: auto !important;
      label {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        color: #192a3e;
        width: 100px;
      }
    }
    .Check {
      width: 15px;
      height: 15px;
      color: white;
      cursor: pointer;
      text-align: center !important;
      margin: auto !important;
      padding: 10px;
      border-radius: 5px;

      span {
        color: white;
        margin-top: -8px;
        margin-left: -3px;
      }
    }

    .CheckedDiv {
      border: 1px solid transparent;
      cursor: pointer;
      background: url(../../../images/checkbox1.svg) no-repeat center;
      background-color: #2c74ff;
      text-align: center !important;
      margin: auto !important;
    }

    .NotCheckedDiv {
      background-color: transparent;
      border: 1px solid grey;
    }

    div {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      text-align: left;

      span {
        color: grey;
      }
    }
  }
}
.ChatQuestionnaire {
  font-style: normal;
  font-weight: normal;
  text-align: center;
  .contentField {
    display: block;
    width: 100%;
    margin: 20px auto;
    text-align: center;
  }
  h4 {
    background: #ffffff;
    border-bottom: 1px solid #eaf1fe;
    display: inline-block;
    padding: 0px 20px;
    position: relative;
    width: 100%;

    span {
      background: #fff;
      border: 1px solid #fff;
      display: inline-block;
      padding: 0;

      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      display: inline-block;
      align-items: center;
      position: absolute;
      top: -14px;
      color: #192a3e;
      margin: auto;
      width: 200px;
      left: calc(50% - 100px);
      top: -15px;
    }
    &.titleLine {
      span {
        width: 260px;
      }
    }
  }
  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    margin: 55px auto 14px;
    text-align: center;
  }
  p {
    font-size: 18px;
    line-height: 23px;
    margin: 10px 0 23px;
  }
  .labelChat {
    font-size: 13px;
    line-height: 16px;
    color: #828c98;
  }
}
.ChatADGMApproveDocumentsView {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.AvatarPrice {
  display: inline-block !important;
  span {
    line-height: 50px !important;
    font-size: 14px !important;
  }
}
.ChatShareCaptialOptions {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  width: 600px;
  margin: auto;
}
.contentFieldNote {
  margin: 0 0 0 218px;
  width: 400px;
  text-align: left;
  font-size: 13px;
  line-height: 16px;
  color: #828c98;
}
.contentFieldLabel {
  margin: 20px auto;
  text-align: left;
  label {
    margin: 0;
    width: 218px;
    text-align: left;
    font-size: 13px;
    line-height: 16px;
    color: #828c98;
    display: inline-block;
  }
  b {
    display: inline-block;
  }
}
.ChatDeclareProvideGenuineInformation {
  width: 100%;
  display: block;

  text-align: center;
}
.ChatAuthorizedSignatories {
  .columnTitles {
    width: 100%;
    display: block;

    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #828c98;
    text-align: left;
    .columntitleNumShares {
      margin-left: 232px;
      width: 150px;
      display: inline-block;
    }
    .columntitlePaidUnpaid {
      margin-left: 105px;
    }
  }
  .authorizedSharesTotals {
    margin-left: 20px !important;
    display: inline;
    width: auto;
    margin-right: 20px !important;
  }
  .authorizedSharesError {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    align-items: center;
    text-align: center;
    color: #ff606f;
    margin: 15px auto;
    display: block;
  }
  .authorizedSharesAmountError {
    color: #ff606f;
  }
  .percent {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 50px;
    color: #192a3e;
    display: inline-block;
    width: 70px;
    margin: 0 10px 0 0;
    text-align: left;
  }
}
.hidden {
  display: none;
}
.show {
  display: block;
}
.Avatar50 {
  width: 50px !important;
  height: 50px !important;
  span {
    line-height: 50px !important;
    font-size: 14px !important;
  }
}
.subTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #192a3e;
  margin: 50px auto 10px;
  width: 400px;
}
.ModalChat {
  border-top-color: #ff606f !important;
}
.ChatForm {
  padding: 0;
  width: 550px;
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 110.93%;
    color: #000;
    margin: 0 0 10px;
    width: 100%;
    text-align: center;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    display: inline-block;
    align-items: center;
    text-align: center;
  }
  .botonera {
    margin: 29px 0 0 0;
    text-align: right;
    button {
      background: #ffffff;
      border: 1px solid #eaf1fe;
      box-sizing: border-box;
      border-radius: 4px;

      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.01em;
      padding: 16px;
      min-width: 120px;
      margin-right: 10px;
      /* Clara Dark Gray */

      color: #65707e;
      &.nextButton {
        background: #2c74ff;
        border: 1px solid #2c74ffs;
        border-radius: 4px;
        color: #fff;
      }
    }
  }
}
.NumerOfCompany {
  width: 324px;
  margin: 60px auto 100px;
  text-align: left;
  label {
    width: 200px;
    display: inline-block;
    margin-right: 22px;
    line-height: 40px;
  }
  & > div {
    width: 10px !important;
    display: inline-block;
  }
  .ChatInputNumberOfCompanyText {
    width: 100px !important;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: center;
    z-index: 0;
    label {
      display: none;
    }
    & > div {
      margin-left: auto;
      margin-right: auto;
      margin-top: 0;
      display: block;
      width: 100% !important;
      input {
        padding: 0 20px;
        width: 100%;
      }
      div {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
div.ChatClaraRadioButtonMany {
  margin: 40px 0;
}
.ChatClaraRadioButtonOption {
  margin: 0 30px;
}

.icon {
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  border: 1px solid #e4e3e9;
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  &:before {
    content: " ";
    cursor: pointer;
    background-color: #a9b0bc;
    display: inline-block;
    -webkit-mask-size: 3px 13px;
    width: 25px;
    height: 25px;
    -webkit-mask: url(../../../images/back.svg) no-repeat center;
    display: inline-block;
    margin: 7px auto;
  }
  &:hover {
    &:before {
      background-color: #2c74ff;
    }
  }
}
.ChatADGMApproveDocuments {
  .Document {
    width: 100%;
    label {
      width: 100%;
      display: block;
      line-height: 24px;
      img {
        width: 24px;
        margin-right: 5px;
      }
      span {
      }
    }
    button {
      margin: 20px;
    }
  }
}
.FormLayout {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 999;
}
.Addres {
  display: block;
  width: 380px;
  margin: auto;
}
.contenFieldOneDirector {
  width: 500px;
  margin: auto;
}

.FileUploaderAndSelectorContainer {
  text-align: center;
}

.FileUploaderAndSelector {
  text-align: left;
  margin: auto;
  width: 380px;
  div {
    span {
      button {
        &:after {
          position: relative !important;
          left: -23px !important;
        }
      }
    }
  }
}
