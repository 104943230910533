@import "../../../../../../../styles/variables/index.scss";

.containerCompanyAddress {
    display: inline-grid;
    width: 100%;
    grid-template-rows: auto;
    grid-template-columns: 15.3846rem auto;
    align-items: center;
    label {
        color: $clara-dark-grey;
        font-size: 1.0769rem;
    }
    margin-bottom: 1.5rem;
}

.separator {
    border: hidden;
    border-top: 1px solid $clara-light-blue;
    width: 100%;
}

.containerAddressAndCurrency {
    width: 100%;
}

.containerCurrencies {
    display: inline-flex;
    flex-direction: column;
}
.containerTransactionCurrency {
    display: inline-grid;
    width: 100%;
    grid-template-rows: auto;
    grid-template-columns: 15.3846rem auto;
    align-items: center;
    label {
        color: $clara-dark-grey;
        font-size: 1.0769rem;
    }

    margin-top: 1.5rem;
}
