@import "./../../../../styles/variables/colors.scss";

.PaymentTotal {
  background: $clara-blue;
  display: grid;
  grid-template-rows: 5.5rem 1fr 3rem;
  height: 100%;
  overflow: hidden;
  .paymentCoupon {
    height: 100%;
    margin: auto;
    width: 34.1538rem;
    padding-right: 21px;
    box-sizing: content-box;
    color: $clara-white;
    overflow-x: hidden !important;
    > div {
      width: 100%;
    }
    .costbox {
      margin-bottom: 4.5rem;
      font-size: 1.3846rem;
      font-weight: 400;
      display: inline-block;
      div {
        display: flex;
        width: 100%;
        * {
          display: inline-block;
          align-self: flex-end;
        }
      }
      div.line1 {
        margin-bottom: 1.1538rem;
        display: block;
        line-height: 1.79;
        b {
          font-size: 1.8462rem;
          font-weight: 700;
          line-height: 1.2;
        }
      }
      div.line2 {
        align-items: flex-end;
        font-size: 1.0769rem;
        font-weight: 600;
        margin-left: 0.3846rem;
        b {
          font-size: 2.6154rem;
          font-weight: 700;
          margin: 0 0.3846rem;
          line-height: 2.3077rem;
        }
      }
    }
    .items {
      margin-bottom: 0;
    }
    .coupon {
      margin-bottom: 2.1rem;
      margin-top: 1.6154rem;
      .itemCoupon {
        margin-top: 0;
        padding-top: 0;
      }
      .itemVat {
        // padding-bottom: 1.6154rem;
        padding-bottom: 0.6rem;
      }
    }
    .couponApprobe {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      div {
        font-size: 1.0769rem;
        font-weight: 600;
        background: $clara-green;
        padding: 0.4615rem 0.9215rem;
        border-radius: 0.3846rem;
        min-width: 3.8462rem;
        text-align: center;
        margin-right: 0.3846rem;
      }
      button {
        margin: 0;
        background: transparent;
        color: $clara-white;
      }
      span {
        font-size: 0.9231rem;
        display: block;
        min-width: 100%;
        margin-top: 2px;
      }
    }
    .item {
      display: grid;
      font-weight: 600;
      grid-template-columns: 50% 50%;
      grid-template-rows: auto auto;
      padding: 10px 0;
      row-gap: 8px;
      &.bundleDiscount * {
        color: $clara-blue;
      }
      .product {
        font-size: 1.0769rem;
        justify-content: flex-end;
        align-items: center;
      }
      .amount {
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 13px;
        span {
          margin-right: 0.3846rem;
          display: flex;
          font-size: 0.7692rem;
          align-self: flex-end;
          // font-size: 0.7692rem;
        }
        b {
          display: flex;
          span {
            font-size: 1.0769rem;
            line-height: 1.0769rem;
          }
        }
      }
      .amountTotal {
        span {
          font-size: 1.0769rem;
          line-height: 1.0769rem;
          align-self: flex-end;
        }
        b {
          span {
            font-size: 1.3846rem;
            // line-height: 1.3846rem;
          }
        }
      }
      .detail {
        grid-column: 1/3;
        font-size: 0.9231rem;
        font-weight: 300;
        line-height: 1.5;
        b {
          font-weight: 700;
        }
      }
    }
    .itemTotal {
      margin-top: 10px;
    }
    .borderBottom {
      border-bottom: 1px solid $clara-white;
    }
    .buttonCoupon {
      width: 5.9231rem;
      height: 2.6rem;
      min-width: 5.9231rem;
      margin: 0.2308rem 0.3846rem;
      transition: all 0.5s;
      // display: none;
      opacity: 0;
    }
    .vissible {
      // display: inline-block;
      opacity: 1;
    }
    .productCoupon {
      height: 40px;
      position: relative;
    }
    .couponOpen {
      width: 200% !important;
      input {
        padding-right: 6.6923rem !important;
      }
      div:nth-child(2) {
        pointer-events: visible !important;
      }
    }
    .buttonCouponContent {
      transition: all 0.5s;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;

      div:nth-child(2) {
        width: 6.6923rem !important;
        height: auto;
        pointer-events: none;
      }
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.couponError {
  color: $clara-white;
  text-align: left;
  span {
    display: inline;
    text-align: left;
  }
  svg {
    display: none !important;
  }
}
.Terms {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  > * {
    margin: 0 2px !important;
    color: $clara-white !important;
    font-size: 0.9231rem !important;
  }
}
.Back {
  width: 34.1538rem;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: underline;
  > * {
    margin: 0 2px !important;
    color: $clara-white !important;
    font-size: 0.9231rem !important;
  }
}
.contentPaidImage {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.amountCoupon {
  margin-top: 9px;
}

.transactionOffer {
  span {
    color: #FF606F !important
  }
}