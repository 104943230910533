@import "../../../../../../../styles/variables/index.scss";

.container {
    display: flex;
    flex-direction: column;
}

.label {
    color: $clara-dark-grey;
    margin-bottom: 1.5rem;
    font-size: 1.0769rem;
}

.text {
    margin-bottom: 1.5rem;
}