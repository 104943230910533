@import "../../../../../styles/variables/index.scss";

.containerCategories {
  background: $clara-white;
  margin-bottom: 2.5rem;
}

.containerLabelText {
  margin-bottom: 1.6rem;
  width: 100%;
  display: grid;
  grid-template-columns: 9.0769rem auto;
}

.containerDates {
  display: inline-flex;
  width: 100%;
  margin: 0.25rem 0;
}

.IconBlock {
  display: grid;
  width: 100%;
  height: 2.1rem;
  grid-template-columns: 2.1rem 1fr;
  grid-template-rows: 0.5fr 0.5fr;
  column-gap: 0.7692rem;
  > div {
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
  }
  :first-child {
    grid-row: 1/3;
    height: 1.6rem !important;
    grid-row: 1/3;
    align-self: center;
  }
  :last-child {
    width: 100%;
    height: 100%;
  }
}

.containerTabsMenu {
  margin-top: 2rem;
}

.containerTab {
  height: 49vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 1rem;
  @media (max-height: 768px) {
    height: 30vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.TabArea {
  bottom: 0;
  transition: all 1.5s ease 0s;
  position: relative;
  height: 3.1rem;
  width: 100%;
  display: flex;
  > div {
    border-bottom: 0.0769rem solid $clara-light-blue;
    width: 100%;
  }
}

.skelTabs {
  display: flex;
  flex-direction: row;
  height: 2.3077rem;
  width: 100%;
  .skelTabsSpace {
    padding-right: 1.4rem;
  }
}

.containerTabPartiesUploaded {
  margin-top: 1.6rem;
  display: flex;
  flex-direction: column;
  > div:nth-child(2) {
    padding-top: 2rem;
  }
}

.initialIcon {
  margin-top: 1rem;
}

.containerAvatar {
  padding: 1rem 0;
  margin-left: 2.5px;
  display: inline-grid;
  grid-template-columns: 2.7692rem auto;
  width: 100%;
  align-items: center;
  > i {
    width: 100%;
    height: 1px;
  }
  position: relative;
}

.containerText {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > label {
    width: 100%;
    display: inline-flex;
    align-items: center;
    padding-left: 10px;
  }
  > span {
    width: 100%;
    display: inline-flex;
    align-items: center;
    padding-left: 10px;
  }
}
