@import "./../../../../../styles/variables/index.scss";
.ChooseDocumentsView {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    h1 {
        margin: 5.1rem 0 2.3rem 2.4rem;
        text-align: left;
        // color: #192a3e;
    }

}
.templateText{
    font-size:1.1rem;
}
.template{
    width:42rem;
}
.ContetCards {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0 2.4rem 0;
    > div {
        margin-bottom: 2.3rem;
        margin-left: 2.3rem;
    }
    > div:first-child {
        margin-left: 0;
    }
    > div:nth-child(4n + 1) {
        margin-left: 0;
    }
}
.docTypeCard {
    padding: 2.1rem 1.1rem 2rem 1.1rem;
    position: relative;
    width: 18.5rem;
    height: 26.2rem;
    background: $bg-white;
    border: 1px solid $bg-border;
    box-sizing: border-box;
    display: inline-block;
    border-radius: 0.3077rem;
    text-align: center;
    svg {
        margin-bottom: 2.0462rem;
    }
    i {
        font-size: 1.2308rem;
        position: relative;
        left: 11.5385rem;
        top: 1.1538rem;
        visibility: hidden;
    }
    span {
        color: $palette-clara;
        font-size: 96px;
        &::before {
            color: $palette-clara !important;
        }
    }
    h2 {
        margin: 0.7692rem auto;
        text-align: center;
    }
    p {
        margin-top: 0;
        margin-bottom: 10px;
        height: auto;
        text-align: center;
    }
    .Botonera {
        width: calc(100% - 2.2rem);
        position: absolute;
        bottom: 2rem;
        text-align: center;
    }
    button {
        &:hover {
            background: $palette-clara;
            color: #fff;
        }
    }

    &:hover {
        box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }
}
.Modal {
    margin-left: 2.8rem;
    margin-right: 2.8rem;
    margin-bottom: 2rem;
    margin-top: 3.6rem;
    h1 {
        font-family: "cl-regular", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        margin-bottom: 0.5rem;
        /* identical to box height, or 27px */

        /* Clara Black */

        color: $clara-black;
    }
    p {
        margin-top: 0.5rem;
        font-family: "cl-regular", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        margin-bottom: 1rem;
        /* Clara Black */

        color: $clara-black;
    }
    li {
        list-style: none;
        margin-bottom: 1.5rem;
        font-family: "cl-regular", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 146.1%;
        width: 35rem;
        /* or 26px */

        /* Clara Black */

        color: $clara-black;
    }
    li::before {
        content: "•";
        color: $clara-blue;
        display: inline-block;
        width: 1em;
    }
}

.BotoneraUpgrade {
    width: calc(100% - 2.2rem);
    position: absolute;
    bottom: 2rem;
    text-align: center;
    button {
        &:hover {
            background: $clara-upgrade-color-dark;
            color: #fff;
        }
        border-color: $clara-upgrade-color-dark;
        color: $clara-upgrade-color;
    }
}

//New View
.containerDocs {
    display: flex;
    width: 100%;
    overflow-x: hidden;
    height: 100%;
}

.columnContainerDocs {
    flex: 1;
    max-width: 50%;
    overflow: hidden;
    //height: 572px;
    height: 100%;
    padding-right: 1.1538rem;
}

.columnContainerDocs:first-child {
    background: white;
    padding: 2.4rem 2.6rem 0 2.4rem;
    // height: 572px;
}

.MuiTabRoot {
    span label {
        color: $clara-blue !important;
        font-weight: 400 !important;
        font-family: "cl-regular" !important;
        font-size: 14px !important;
    }
}


.categoryTabHeader:first-child {
    padding-top: 0 !important;
}

.categoryTabHeader {
    padding-bottom: 16px;
    padding-top: 30px;
    color: $clara-blue;
    display: flex;
    gap: 13.7px;
   
    .categoryTabHeaderText {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-family: "cl-regular";
        font-weight: 400;
    }
}
.cardListContainer {
    // display: grid;
    // grid-template-rows: 6.1rem;
    // grid-template-columns: repeat(auto-fill, 18.5rem);
    // grid-gap: 1.2rem;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.descriptionTitle {
    font-family: "cl-regular";
    font-weight: 400;
    font-size: 22px;
    margin-bottom: 22px;
}

.descriptionText {
    font-family: "cl-regular";
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
}

.tabsContainer {
    background: white;
    height: 2rem;
    padding: 0 0 2.4rem 0;
    margin-bottom: 50px;
}

.descriptionContainer {
    padding: 2.4rem 0 0 31px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: #FAFAFA;
    .spacer {
        padding-right: 1.8rem;
    }
}

.tabDescriptionTitle {
    font-family: "cl-regular";
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
}

.tabDescriptionText {
    font-family: "cl-regular";
    font-weight: 300;
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 0.9375rem;
}

.cardsScrollableContainer {
    overflow-y: hidden;
    max-height: calc(100% - 6.5rem);
    width: 100%;
    height: 100%;
}

.greenButton {
    border-color: $clara-upgrade-color-dark;
    color: $clara-upgrade-color;
    margin-bottom: 0;
    &:hover {
        border-color: $clara-upgrade-color-dark;
        color: $clara-upgrade-color-dark;
        background: transparent;
        opacity: 0.8;
    }
}

.buttonsContainer {
    display: flex;
    justify-content: flex-end;
    padding-top: .8rem;
    button {
        margin-left: 1.25rem;
    }
}

.descriptionContainerSelected {
    height: calc(100vh - 23.7rem);
    overflow: hidden;
    width: 100%;

    h3 {
        font-family: "cl-bold";
        font-weight: 400;
        font-size: 1.692rem;
    }
    h4 {
        color: $clara-blue;
        margin-top: 1.846rem;
        margin-bottom: 0;
    }
    ul {
        font-family: "cl-regular";
        font-weight: 400;
        font-size: 0.923rem;
        line-height: 1.846rem;
    }
    p {
        font-family: "cl-regular";
        font-weight: 400;
        font-size: 0.923rem;
        line-height: 1.846rem;
    }
}

.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 25.25rem);
}

.hiddenContainer {
    display: none;
}
.tabSkeletonContainer {
    width: 25%;
    margin: 5px;
}

.tabDescriptionTitleSkeleton {
    margin-bottom: 0.9375rem;
}

.line {
    width: 100%;
    margin: 0;
}

.title {
    height: auto;
    margin: 32px!important;
}

.DropDownArea{
    border: 1px solid #bec9d7;
    height: 42px;
    border-radius: 20px;
    cursor: pointer !important;
    width: 162px;
    box-sizing: border-box;

    &:hover {
      border-color: $clara-dark-blue;
    }

    div {
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    span {
        width: 100%;
        height: unset !important;
    }

    button {
        padding: 0;
        width: 100%;
        gap: 8px;
        border-radius: 20px !important;
    }

    label {
      text-align: left;
      padding: 0;
      margin: 0;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      cursor: pointer !important;
      text-transform: none;
      font-size: 1rem;
    }
}

.labelDropDown {
    padding: 0 1.5385rem 0 0;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-width: 30.7692rem;
    white-space: break-spaces;
    overflow: hidden;
    cursor: pointer !important;
    margin-left: 0.3077rem;
}

.centerFilterAvatar {
    display: grid;
    place-items: center;
}

.tabsMenu {
    display: flex;
    justify-content: space-between;
}

.searchTextContainer {
    margin: 20px 5px;
    border: 1px solid #EAF1FE;
    border-radius: 5px;
    width: 200px;
}

.selectedRegionInformationContainer {
    padding-bottom: 25px;
    border-bottom: 1px solid #BEC9D7;
    margin-bottom: 25px;
}

.selectedRegionInformationTitle {
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    margin-bottom: 10px;
}

.selectedRegionInformationDescription {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
}

.poweredBy {
    padding: 24px 0 10px 0;
    font-family: "cl-semibold", sans-serif;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.mintzImage {
    width: 36px;
    height: 36px;
    margin-right: 12px;
    padding-right: 6px;
}

.scrollbarSeparator{
    margin-top: 50px;
}