@import "../../styles/variables/colors";
@import "~./../../styles/extends/positions.scss";
@import "~./../../styles/extends/scrollbar.scss";
.BlueScreenLayout {
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  display: grid;
  grid-template-areas: "form menu";
  grid-template-columns: 1fr 411px;
}
.LeftPanel {
  grid-area: menu;
  width: 411px;
  background-color: $clara-light-blue;
  height: 100%;
  color: white;
  display: inline-block;
  position: relative;
  overflow: hidden;
  .titles {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 39px 48px;
    margin-top: 0;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
  }
}
.RightPanel {
  width: 100%;
  text-align: left;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: inline-block;
  height: 100%;
  overflow-y: auto;
  padding: 32px 100px 150px;
  position: relative;
  .CloseIcon {
    position: absolute;
    top: 2.2308rem;
    right: 2.3077rem;
    width: 0.9231rem;
    height: 0.9231rem;
    z-index: 1001;
  }
}
.StepFormZone {
  display: inline-block;
  height: auto;
  margin: 54px 0 0;
  text-align: left;
  max-width: 700px;
  .TitleScreen {
    margin-bottom: 35px;
  }
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    color: #192a3e;
    width: 100%;
    margin: 0;
  }
}