@import "./../../../../../styles/variables/colors.scss";
.CheckboxButton {
  width: 100% !important;
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 2.4615rem !important;
  padding: 2.4615rem 2rem 2.4615rem 4.1538rem !important;
}
.PaymentTotalContent {
  border-radius: 15px;
  background-color: $clara-light-blue;
  width: 35.2rem;
  height: 38rem;
  // padding: 2.9rem 3.4rem 1.2rem 3.4rem;
  padding-left: 31px;
  padding-right: 10px;
  padding-bottom: 31px;
  padding-top: 31px;
  overflow: hidden;
  display: flex;
  justify-self: flex-end;
  align-items: flex-start;
}
.PaymentTotal {
  background: transparent !important;
  color: $clara-black;
  height: 100%;
  width: 100%;
  grid-template-rows: 1fr;
  & > div {
    width: 100% !important;
  }
  * {
    color: $clara-black;
  }
  [data-type="item"] {
    display: grid;
    grid-template-columns: unset;
    grid-template-areas:
      "product price"
      "vat .";
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    grid-template-rows: unset;
    text-align: left;
    row-gap: 0;
    margin: 0 0 0.9rem 0;
    padding: 0;
    h2{
      margin-bottom: 1.2rem;
    }
    > div{
      width: 100%!important;
      gap:0.1rem;
    }
    & div:first-child {
      grid-area: product;
      font-weight: 400;
      
    }
    & div:nth-child(2) {
      grid-area: vat;
      display: flex;
      justify-content: flex-start;
      font-size: 10px;
      font-weight: 400;
      
    }
    & div:last-child {
      grid-area: price;
      font-weight: 400;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span {
        font-size: 10px;
        font-weight: 400;
      }
      b span{
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  button {
    color: $clara-white;
  }
  div,
  span {
    border-color: $clara-grey !important;
  }
  > div {
    display: flex;
    flex-direction: column;
    gap: 0;
    > div {
      margin: 0 !important;
    }
  }
}
