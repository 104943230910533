.Guide {
  width: 250px;
  text-align: left;
  color: black;
  display: inline-block;
  position:relative;
  .linePrevGuide {
    top: 39px;
    left: 22px;
    height: 35px;
    position: absolute;
    border-left: 2px solid #eaeaea;
  }
  .backButtonContent {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    margin:9px;
    
    a {
      display: flex;
      width: 30px;
      height: 30px;
      border: 1px solid #2c74ff;
      box-sizing: border-box;
      border-radius: 50%;
      transform: rotate(180deg);
      justify-content: center;
      align-items: center;
      margin-right: 10px;;
    }
    div {
      text-align: left;
      p {
        
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #2c74ff;
        margin: 0;
      }
      label {
        
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 13px;
        color: #bec9d7;
      }
    }
  }
  .container {
    width: 100%;
  }

  .step {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: #fff;

    .circle {
      background-color: #EAEAEA;
      border: 2px solid #EAEAEA;
      border-radius: 100%;
      width: 10px;    /* +6 for border */
      height: 10px;
      display: inline-block;
      margin-left: 8px;
      margin-top:4px;
    }

    .line {
      top: 14px;
      left: 12px;
      
      height: 29px;
      position: absolute;
      border-left: 2px solid #EAEAEA;
    }

    &:last-child {
      .line {
        border-left: 2px solid white;
        z-index: -1;
      }
    }

  }

  .stepper {
    position: relative;
  }


  .completed {
    visibility: visible;
    border-color: #2C74ff;
    cursor: pointer;

    .checked {
      background-color: #2C74ff;
      border-radius: 100%;
      width: 25px;
      height: 25px;
      margin-left: 1px;

      svg {
        margin-top: 1px;
      }
      .content{
        margin-left:13px;
      }

    }

    .line {
      border-left: 2px solid #eaeaea;
      top: 25px;
      height: 100%;
    }

    .content {
      color: #2C74FF;
      margin-top: 5px;
    }

  }

  .active {
    .circle {
      border-color: #2C74ff;
      background-color: #2C74ff;
    }

    .content {
      color: #2C74ff;
    }
  }

  .content {
    margin-left: 20px;
    display: inline-block;
    color: grey;
  }

}
