@import "./../../../../../styles/variables/colors.scss";
.StakeholderCard {
  margin-bottom: 0.6rem;
  width: 20rem;
  padding: 0.9231rem;
  position: relative;
  height: 123px;
  box-shadow: 2px 4px 12px 0px #2C74FF26;
  box-sizing: border-box;
  position: relative;
  &:hover {
    border: 2px solid $clara-blue;
  }
}
.iconFilter {
  position: absolute;
  right: 0.9231rem;
  top: 0.9231rem;
  cursor: pointer;
  z-index: 2;
}
.borderDash {
  border: 2px dashed $clara-blue;
}
