@import "../../../../../styles/variables/index.scss";

.checkboxMultiple div button div{
  height: 9.2308rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 0.6154rem;
  padding-top: 1.0769rem;
}