.list {
  margin: 0;
  padding: 0 0 20px 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  ::marker {
    color: #828c98;
  }
}

.radioInput{
  div {
    width: unset;
    margin-right: 10px;
  }
}