.contentInput{
    width: 100%;
    margin:1rem 0 ;
    text-align: right;
    position: relative;

    i{
        padding: 0.2rem;
        
        &:before{
            font-size: 2rem ;
            color: #192A3E;
        }
        position: absolute;
        
    }
}
.input{
    width: 100%;
    /* light gray */
    border: 1px solid #EAF1FE;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 0.6rem 0.6rem 0.6rem 2.5rem ;
    //background-color: #F7F8F9;
    &:focus{outline: none}
}
