@import "./../../../../../../styles/variables/colors.scss";

.items {
  margin-bottom: 0;
}

.item {
  display: grid;
  font-weight: 600;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto;
  padding: 10px 0;
  row-gap: 8px;
  .product {
    font-size: 1.0769rem;
    justify-content: flex-end;
    align-items: center;
  }
  .amount {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 13px;
    span {
      margin-right: 0.3846rem;
      display: flex;
      font-size: 0.7692rem;
      align-self: flex-end;
      // font-size: 0.7692rem;
    }
    b {
      display: flex;
      span {
        font-size: 1.0769rem;
        line-height: 1.0769rem;
      }
    }
  }
  .amountTotal {
    span {
      font-size: 1.0769rem;
      line-height: 1.0769rem;
      align-self: flex-end;
    }
    b {
      span {
        font-size: 1.3846rem;
        // line-height: 1.3846rem;
      }
    }
  }
  .detail {
    grid-column: 1/3;
    font-size: 0.9231rem;
    font-weight: 300;
    line-height: 1.5;
    b {
      font-weight: 700;
    }
  }
}
.itemTotal {
  margin-top: 10px;
}
.borderBottom {
  border-bottom: 1px solid $clara-white;
}
.borderTop {
  border-top: 1px solid $clara-white;
}