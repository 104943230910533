@import "./../../../../../styles/variables/colors.scss";

.DeleteForm {
  padding: 34px;
  min-width: 42.3077rem;
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 110.93%;
    color: $clara-black;
    margin: 0 0 10px;
  }
  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: $clara-red;
  }
  .botonera {
    margin: 6.1154rem 0 0 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right;
    button {
      text-align: center;
      letter-spacing: 0.01em;
      min-width: 120px;
      margin-right: 10px;
      &.nextButton {
        background: $clara-red;
        border: 1px solid $clara-red;
        border-radius: 4px;
        color: $clara-white;
      }
    }
  }
}

.ModalAdd {
  border-top-color: $clara-blue !important;
}
