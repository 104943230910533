@import "../../../../styles/variables/index.scss";
.titleOverlay {
  font-size: 3.6923rem !important;
  line-height: 3.6923rem;
  margin-bottom: 1.2308rem;
  display: block;
}

.legalContent {
  font-size: 1.0769rem !important;
  font-weight: 400 !important;
  line-height: 1.6;
  
}

.removePadding {
  padding-right: 0px !important;
}
.rightPannel {
  width: 100%;
  div {
    overflow: visible !important;
  }
  svg {
    width: 24.5385rem !important;
    position: absolute;
    height: 23.8462rem !important;
    right: 1.7692rem;
    bottom: 6.1538rem;
  }
}
.leftPannel {
  width: 100%;
  a{
    color: $clara-academy!important;
  }
}
