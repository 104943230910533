@import "../../../../styles/variables/colors.scss";

.fullContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.footerLinks {
    flex-direction: row;
    align-items: flex-end;
    display: flex;
    justify-content: center;
    margin-top: auto;
    white-space: nowrap;
    position: relative;
    left: 15px;
    .links {
        text-decoration: none;
        font-family: "cl-regular";
        font-weight: 300;
        font-size: 12px;
        margin-right: 30px;
        color: $clara-blue;
    }
}

.container {
    max-width: 37.8rem;
    padding-top: 5.2rem;
    padding-bottom: 1.6rem;
    height: 98%;
    display: flex;
    flex-direction: column;
    .formContainer {
        max-width: 37.8rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        div {
            max-width: 37.8rem;
            margin: 0;
        }
    }
    .loginTitle {
        display: flex;
        font-family: "cl-regular";
        font-weight: 400;
        font-size: 22px;
        color: $clara-blue;
        flex-direction: column;
        align-items: center;
    }
    .fieldContainer {
        margin-bottom: 30px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .termsAndConditions {
        display: flex;
        font-family: "cl-regular";
        font-weight: 300;
        font-size: 14px;
        color: $clara-dark-grey;
        flex-direction: column;
        margin-bottom: 14px !important;
        position: relative;
        right: 120px;
    }
    .submitButton {
        margin-top: 36px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 37.8rem;
        position: relative;
        right: 10px;
        div {
            justify-content: center !important;
        }
        button {
            width: 200px;
            background-color: $clara-blue;
            font-family: "cl-regular";
            font-weight: 600;
            font-size: 14px;
        }
    }
    .logInButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: "cl-regular";
        font-weight: 300;
        font-size: 14px;
        margin-top: 0.5rem;
        position: relative;
        color: $clara-black;
        a {
            font-weight: 600;
            color: $clara-blue;
        }
    }
    .linkedInButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        button {
            background-color: #3079b1;
            width: 200px;
            font-family: "cl-regular";
            font-weight: 500;
            font-size: 14px;
            color: $clara-white;
            border-radius: 4px;
            margin-bottom: 1rem;
            height: 40px;
        }
        .textBetweenButtons {
            display: block;
            width: 37.8rem;
            position: relative;            
            .text {
                margin: 20px;
            }
            .line {
                border-bottom: 1px $clara-light-blue solid;
                display: inline-block;
                width: calc(50% - 28px);
                margin-top: 8px;
            }
            span {
                margin-top: 5px;
                font-family: "cl-regular";
                font-weight: 300;
                font-size: 14px;
                color: $clara-black;
            }
        }
        .signUpLink {
            font-family: "cl-regular";
            font-weight: 300;
            font-size: 12px;
            width: 75%;
            color: $clara-dark-grey;
            text-align: center;
            a {
                font-weight: 300;
                color: $clara-blue;
                text-decoration: none;
            }
        }
    }

    .firstTerms {
        margin-top: 40px !important;
        margin-bottom: 5px !important;
        div {
            div {
                div {
                    div {
                        label {
                            span {
                                a {
                                    color: $clara-blue;
                                    font-weight: 300;
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .subTitleTextContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        .firstText {
            margin-top: 10px;
        }
        span {
            font-family: "cl-regular";
            font-weight: 300;
            font-size: 14px;
            color: $clara-black;
            text-align: center;
            margin-bottom: 11px;
        }
    }
    .notReceivedText {
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
            font-family: "cl-regular";
            font-weight: 300;
            font-size: 14px;
            color: $clara-black;
        }
    }
    .resendButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 15px !important;
        button {
            width: 200px;
            background-color: $clara-blue;
            font-family: "cl-regular";
            font-weight: 600;
            font-size: 14px;
        }
    }
    .verifyButton {
        margin-top: 40px !important;
        margin-bottom: 54px !important;
    }
}

.verificationContainer {
    margin-top: 40px;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .footerLinks {
        margin-top: auto;
    }
}

.errorMessage {
    font-family: "cl-regular";
    font-weight: 300;
    font-size: 12px;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 50px;
    color: $clara-red;
    svg {
        color: $clara-red;
    }
}

.marketingEmail {
    color: red;
    div {
        div {
            div {
                div {
                    label {
                        span {
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
}

.footerSeparator{
    position: relative;
    right: 15px;
    color: $clara-light-blue;
}
