.shareholderContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
}

.id {
  display: none;
}

.name {
  width: 20%;
  height: 100%;
  .nameTitle {
    height: 24px;
  }
  .nameDescription {
    display: flex;
    align-items: center;
    height: 40px;
  }
}

.percentage {
  width: 15%;
}

.shares {
  width: 26%;
}

.paidUnpaid {
  width: 19%;
}

.shareClass {
  width: 20%;
}

.disabled {
  input {
    background-color: #EAEAEA;
  }
}

.errorMessage{
  color: #ff606f;
  margin: -12px 0;
}