.downloadContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 27px;
}

.buttonCheckBox {
  width: 12rem;
  height: 10rem;
}

.CheckboxButton {
  [data-contentButton="true"] {
    width: 146px;
  }
}
