@import "./../../../../../styles/variables/colors.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}


.acceleratorVerificaction {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 474px;
  height: 100%;
  gap: 2em;
}

.form {
  width: auto;
  height: auto;
}

.fieldContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: center;
  padding-left: 3.1rem;
}

.errorMessageVerification {
  font-family: "cl-regular";
  font-weight: 300;
  font-size: 12px;
  flex-direction: row;
  justify-content: center;
  color: $clara-red;
  position: relative;
  svg {
      color: $clara-red;
      margin-left: 5px;
  }
}


.Accelerators {
    padding: 3.9231rem 3.1538rem 0 3.1538rem;
    display: flex;
    flex-direction: column;
    
    .titleColor {
      color: $clara-blue;
    }

    .programEnrolment {
      display: flex;
      flex-direction: column;
      margin-top: 2.4615rem;
    }

    .joinedProgramTitleContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .alreadyJoinedProgramEnrolment {
      display: flex;
      flex-direction: column;
      margin-top: 1.3846rem;
      height: 62px;

      .titleContainer {
        display: flex;
        align-items: center;
      }

      .title {
        font-size: 22px;
        line-height: 32px;
      }

      .status {
        height: 24px;
        margin-left: 24px;
        border-radius: 15px;
        padding: 5px;
        display: flex;
        align-items: center;
        width: 113px;
      }

      .statusSubmitted {
        background-color: $clara-green-generic;
      }

      .statusNotSubmitted {
        background-color: $clara-yellow;
      }

      .statusCircle {
        background-color: $clara-white;
        height: 18px;
        width: 18px;
        border-radius: 9px;
        display: flex;
        align-items: center;
        justify-content: center;

        .innerDotSubmitted {
          display: flex;
          align-content: center;
          justify-content: center;
          color: $clara-green-generic;
        }

        .innerDotNotSubmitted {
          background-color: $clara-yellow;
          height: 6px;
          width: 6px;
          border-radius: 3px;
        }
      }

      .statusText {
        color: $clara-white;
        margin-left: 8px;
        font-weight: bold;
        font-size: 14px;
        line-height: 32px;
      }

      .subTitle {
        color: $clara-dark-grey;
        font-size: 10px;
        font-weight: 400;
        margin-top: 16px;
      }
    }
}

.AcceleratorProgramContainer{
  display:grid;
  justify-items: end;
}

.CheckboxButtonContainer {
    margin-top: 2.30765rem;
}

.CheckboxButton {
    width: 486px !important;
    height: 96px;
    > span {
        font-weight: 700;
    font-size: 18px;
    }
}

.SaveButton {
  position: absolute;
  bottom: 20px;
}

.Tabs{
  border-bottom: 1px solid $clara-light-blue;
}