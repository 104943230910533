@import "./../../../../styles/variables/colors.scss";

.SquareContent {
  display: flex;
  // justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;

  .iconColor {
    display: flex;
    color: $clara-blue;
    .imageContent {
        height: 4.1538rem;
        width: 4.1538rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    .imageSize {
      max-width: 4.1538rem;
      max-height: 4.1538rem;
      object-fit: cover;
    }
  }
  .textMargin {
    margin-top: 0.6154rem;
    text-align: center;
    color: $clara-dark-grey;
  }
  .paymentMargin {
    margin-top: 6px;
    line-height: 1.2;
    text-align: center;
    color: $clara-dark-grey;
  }
  .textNumbers {
    margin-top: 0.6154rem;
    font-size: 1.2308rem;
    font-weight: 700;
    color: $clara-black;
    text-align: center;
  }
  .btnMargin {
    margin-top: 1.0769rem;
    text-align: center;
    color: $clara-dark-grey;
  }
}

.spanPayment {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.iconRed {
  color: $clara-pay !important;
}

.blackColor {
  color: $clara-black !important;
}