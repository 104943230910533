.ContainerGeneralChat{
  height:100%;
}
.ChatContainer {
  display: inline-block;
  background: #fff;
  position: relative;
  width: 100%;
  vertical-align: top;
  height: calc(100% - 70px);
  overflow: auto;
  padding: 72px 122px;
  text-align: left;
  & > div {
   

  }
}
