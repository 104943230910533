@import "../../../../../styles/variables/index.scss";

.toolbar {
  font-size: 16px;
  color: black;
  background-color: white;
  border-radius: 5px;
  width: fit-content;
  padding: 1rem !important;
  margin: 1rem !important;
  align-items: baseline !important;
  justify-content: space-between !important;
  display: flex !important;
}

.clickable {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  color: lightgray;
}

.containerPreview {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .containerPreviewFile {
    width: 100%;
    height: 100%;
    position: relative;
    iframe {
      width: calc(100% + 1px);
      height: calc(100% + 22px);
      border: transparent;
      margin-top: -1px;
      margin-left: -1px;
    }
    img {
      width: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
}
.allPages {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}
.loadingPreview {
  width: 100% !important;
  height: 100% !important;
  text-align: center !important;
  /* background: rgba(0, 0, 0, 0.1) !important; */
  display: block !important;
  position: absolute !important;
  left: 0px;
  top: 0;
  z-index: 1 !important;
}

.containerNotPreview {
  height: 100%;
  width: 100%; // calc(100% - 22rem);
  display: flex;
  justify-content: center;
  align-items: center;
}
.Reload {
  position: absolute;
  left: 10px;
  top: 5px;
  background: transparent;
  color: $clara-blue;
  font-size: 10px;
  cursor: pointer;
  width: auto !important;
  height: auto !important;
}

// .ToolbarTop{
//   display: flex;
//   justify-content: center;
//   width: 100%;
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: 1000;

// }

.ToolbarTop{
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  top: 1rem;
  left: 0;
  z-index: 1000;
}

.Pages{
  display:flex;
  background: $clara-white;
  font-family:$font-family;
  border-radius: 1.0769rem;
  padding: .3846rem;
  margin-right: .7692rem;
  margin-left: .7692rem;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  align-items: center;
  width: auto;
  height: 2.4615rem;
  div{
    margin-right: .1692rem;
    margin-left: .1692rem;
  }
}

.ToolbarRight{
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  z-index: 1000;
}

.centerContent {
  display: flex;
}