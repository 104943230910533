.rightContentImage{
  width: calc(100% - 4.9231rem);
  position: relative;
  height: 100%;
  img{
    position: absolute;
    top: 0;
  }
}
.cartTitle {
  font-size: 3.6923rem;
  font-size: 4.9231rem;
  font-weight: 800;
  line-height: 1.2;
}