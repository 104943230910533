
.VersionManagerView{
    height: 100%;
    width: 100%;
   

}
.assistance{
    display: none;
}
.message{
    display: none;
}