@import "src/styles/variables/colors.scss";

.ScrollableWizard {
  overflow: hidden;
}

.ModalTitle {
  font-size: 4.92308rem !important;
  font-family: 'cl-extrabold', serif !important;
  margin: 0 0 10px 0;
}

.ModalContent {
  box-sizing: border-box !important;
  display: grid;
  grid-template-columns: 38rem 1fr;
  grid-template-rows: 100%;
  overflow: visible;
  height: 100%;

  > p {
    font-family: "cl-regular", serif;
    color: $clara-black;
    padding-bottom: 3rem;
    font-size: 2.5rem !important;
    font-weight: 400 !important;
    line-height: 3.6rem;
  }
}

.RightContent {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.ContentText {
  width: 486px;

  > p {
    font-size: 24px;
    line-height: 2.6rem;
  }
}