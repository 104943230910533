@import "../../../../../styles/variables/colors.scss";

.buttonCheckBox {
  width: 12rem;
  height: 10rem;
}

.buttonCheckBox2 {
  width: 12rem;
  height: 9rem;
}

.Input {
  width: 100%;
}

.CheckboxButton {
  [data-contentButton="true"] {
    width: 146px;
  }
}