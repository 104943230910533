@import "./../../../../styles/variables/colors.scss";

.containerFooter {
  display: grid;
  grid-template-columns: auto 1fr 18rem;
  gap: 2rem;
  justify-items: end;
  align-items: center;
  padding: 0.5rem 0;
  padding-bottom: 5rem;
  align-items: baseline;
  button {
    width: 100%;
    margin-bottom: 1rem;
  }
  .textContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  .containerTitle {
    color: $clara-upgrade-color;
    font-size: 1.375rem;
    font-weight: 700;
    line-height: 1.5rem;
  }
  .containerSubTitle {
    color: $clara-black;
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

.containerDetail {
  display: flex;
  width: 23.7692rem;
  padding-bottom: 1rem;
  height: 2.688rem;
  align-items: flex-end;
  justify-content: flex-end;

  .itemstringAnnually{
    .stringAnnually {
        font-size: 0.75rem;
        font-weight: 700;
        color: $clara-black;
      }
    }
    .itemPriceAnnually {
      .priceAnnually {
        font-size: 1.375rem;
        font-weight: 700;
        color: $clara-black;
        padding: 0.125rem 0rem;
      }
      .vatData{
        font-size: 1.275rem;
        font-weight: 500;
      }
    }
  }