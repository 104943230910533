.containerComments {
    display: inline-flex;
    flex-direction: column;
    padding-left: 30px;
    width: 100%;
    height: 100%;
}
.chatsContainer {
    //background: skyblue;
    height: 480px;
    overflow-y: scroll;
    scroll-behavior: smooth;
}
.notesContainer {
    height: 364px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    margin-bottom: 1rem;
}

.comments {
    
    font-style: normal;

    font-size: 13px;
    label {
        font-weight: 600;
        color: #65707e;
    }
    span {
        color: #a9b0bc;
    }
}

.containerChat {
    display: grid;
    width: 100%;
    grid-template-columns: 3.5rem auto;
    grid-template-rows: 2rem auto;
    min-height: 70px;
    //background: violet;
    margin-bottom: 5px;
    border: hidden;
    border-radius: 10px;
}

.containerChatActive {
    background: #eaf1fe;
}

.headerChat {
    display: inline-flex;

    label {
        
        font-style: normal;
        font-weight: 700;
        color: #192a3e;
    }
    span {
        color: #a9b0bc;
        margin-left: 5px;
        font-size: 12px;
        margin-top: 2px;
    }
}

.avatar {
    grid-row: 1 / 3;
    position: relative;
    top: 10px;
}

.description {
    margin-top: 3px;
    order: 4;
    
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #192a3e;
}

.containerInput {
    margin-top: 10px;

    display: inline-flex;

    justify-content: space-between;
    height: 30px;
}

.sendButton {
    height: 35px;
    width: 35px;
    position: relative;
    border: 1px solid #a9b0bc;
    border-radius: 5px;
    right: 35px;
    transform: rotate(180deg);
    &:before {
        -webkit-mask: url(../../../images/arrowLeft.svg) no-repeat center;
        display: inline-block;
        width: 35px;
        height: 35px;
        content: " ";
        background: #a9b0bc;
    }
}

.activeButton {
    background: #2c74ff;
    border: hidden;
    cursor: pointer;
    &:before {
        background: #fff;
    }
}
.separator {
    text-align: center;
    border-bottom: 1px solid #eaf1fe;
    height: 25px;
    margin-bottom: 15px;
    &:after {
        content: "Today";
        width: 90px;
        height: 35px;
        justify-content: center;
        align-items: center;
        position: relative;
        top: 6px;
        display: inline-flex;
        font-size: 16px;
        background: #eaf1fe;
        color: #828c98;
        border-radius: 25px;
        font-weight: 600;
    }
}

.newComments {
    &:after {
        width: 160px;
        content: "New Comments";
        color: #ff606f;
    }
}

.inputComment {
    width: 400px;
    height: 35px;
    border: 1px solid #a9b0bc;
    border-radius: 5px;
    padding: 2px 15px 5px 20px;

    &:focus {
        border: 1px solid #2c74ff;
    }
}

.buttonModal {
    z-index: -1;
}

.EditIcon {
    a {
        &:before {
            -webkit-mask: url(../../../images/edit.svg) no-repeat center !important;
        }
    }
}

.DeleteIcon {
    a {
        &:before {
            -webkit-mask: url(../../../images/trash.svg) no-repeat center !important;
        }
    }
}

.Textarea {
    width: 100%;
    height: 165px;
    border-radius: 5px;
    padding: 1rem;
}
.containerInputComments {
    width: 100%;
    display: inline-flex;
}

.buttonSendComments {
    margin-left: 2rem;
    align-self: flex-end;
    width: 40px;
    height: 40px;
    padding: 0.5rem 0.6rem;
    border: 1px solid #a9b0bc;
    border-radius: 5px;
    transform: rotate(180deg);
    &:before {
        -webkit-mask: url(../../../images/arrowLeft.svg) no-repeat center;
        display: inline-block;
        width: 20px;
        height: 20px;
        content: " ";
        background: #a9b0bc;
    }
}
.AvatarImg{
    span{
        font-size: 1rem;
        line-height: 40px;
    }
    img{
        width:3.2308rem;
        height:3.2308rem;
    }
}