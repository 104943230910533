.LayoutForm {
  widows: 100%;
  height: 100%;
}
.LeftPanel {
  width: 430px;
  background-color: #2c74ff;
  height: 100%;
  color: white;
  display: inline-block;
  position: relative;
  overflow-x: hidden;
  .IconClara {
    padding: 23px 0px 23px 23px;
    display: inline-block;
    text-align: left;
    width: 100%;
  }
  &:before {
    content: " ";
    width: 350px;
    height: 700px;
    background: rgba(8, 41, 117, 0.2);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 75;
    border-bottom-left-radius: 700px;
    border-top-left-radius: 700px;
    pointer-events: none;
  }
  .LeftEducation {
    padding: 4rem 3rem 0rem 4rem;
  }
}
.RightPanel {
  display: inline-block;
  width: calc(100% - 430px);
  background-color: #fff;
  height: 100%;
  padding: 119px 100px;
  .CloseIcon {
    position: absolute;
    top: 55px;
    right: 30px;
    width: 12px;
    height: 12px;
    z-index: 1001;
    i {
      &:before {
        color: #192a3e;
      }
    }
    &:hover {
      i {
        &:before {
          color: #2c74ff;
        }
      }
    }
  }
  .ContentForm {
    width: 579px;
    h1 {
      
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 23px;
      color: #192a3e;
      padding: 0;
      margin: 0;
    }
    hr {
      border: 1px solid #eaf1fe;
      margin: 19px 0;
    }
    .ContentChildren {
      text-align: left;
      width: 100%;
    }
  }
}
