@import "../../../../../../styles/variables/index.scss";

.containterPanelMessages {
  width: 100%;
  height: 100%;
  padding-left: 2.3077rem;
}

.containerSearchAndButtonFilter {
  display: inline-flex;
  width: 100%;
  align-items: center;
  padding: 1.5rem 1rem 1rem 0;
  > div:first-child {
    margin: 0 0.5rem 0 0;
    input {
      height: 3.0769rem;
    }
  }
}

.containerZonaMessages {
  width: 100%;
  position: relative;
  height: calc(100% - 7.5rem);

}

.containerMessages {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-items: center;
  height: 100%;
}

.containerButtonFilter {
  width: 11rem;
  padding: 0 0.35rem;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  > label {
    color: $clara-dark-grey;
  }

  svg {
    color: $palette-clara;
  }
}

.filterLabelSelectButtonFilter {
  color: $palette-clara;
  padding-left: 0.3rem;
}

.openModalButtonFilter {
  display: inline-block;
  transform: rotate(180deg);
  transition: all 0.5s;
}

.closeModalButtonFilter {
  display: inline-block;
  transform: rotate(0deg);
  transition: all 0.5s;
}

.itemFilterMenuMessages {
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
}

.containerItemOptions {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
}

.Open {
  background: #eaf1fe;
}

.iconArrow {
  position: absolute;
  left: calc(100% - 1.5rem);
  transition: all 0.5s;
}

.iconArrowOpen {
  transform: rotate(180deg);
}

.iconArrowClose {
  transform: rotate(0);
}

.containerOptions {
  display: inline-flex;
  flex-direction: column;
  transition: all 1s;
  position: relative;
  margin: 0 !important;
  > div {
    margin-bottom: 0.5rem;
    > div {
      margin: 0.2rem 0;
      transition: all 2s linear;
      padding-left: 0.35rem;
      display: flex;
      flex-direction: column;
      gap: 0.4615rem;
    }
  }
}

.openOptions {
  min-height: 10rem;
}

.closeOptions {
  min-height: 0rem;
  display: none;
}

.tabElement {
  padding-bottom: 0.4rem !important ;
  cursor: pointer;
}
