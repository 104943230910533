@import "../../../../../../styles/variables/index.scss";

.popoverLabel {
    color: $palette-clara;
}

.titleContainer {
    p {
        position: relative;
        z-index: 120;
        width: 100%;
        color: $clara-dark-grey;
    }
}
.SelectorContainer {
    .optionSelectorContainerModal{
        margin-left: 0!important; 
    }
    .optionSelectorContainer {
        height: 5.6692rem;
        display: grid;
        width: calc(100% - 157px);
        position: relative;
        margin-left: 157px; 

        p {
            position: relative;
            right: 100%;
        }
        .infoLabel {
            margin-top: 0.7692rem;
            margin-left: 2.1538rem;
            width: calc(100% - 3.15rem);
            position: absolute;
            z-index: 100;
            top: 1.9231rem;
            left: 1.0769rem;
            color: #65707e;
            font-family: "cl-regular";
            font-style: normal;
            font-weight: 300;
            font-size: 1rem;
            line-height: 1.2308rem;
        }
        button {
            z-index: 2;
            width: 9.6154rem;
            height: 3.0769rem;
            order: 2;
            line-height: 40px;
            background: white;
            order: 2;
            text-align: left;
            color: #65707e;
            cursor: pointer;
        }
        .buttonRoleToCheck {
            &:before {
                cursor: pointer;
                content: " ";
                background-color: #a9b0bc;
                display: inline-block;
                -webkit-mask-size: 16px 16px;
                width: 16px;
                height: 16px;
                -webkit-mask: url(../../../../../../images/unborderedUncheckbox.svg) no-repeat center;
                margin: 11px 10px 11px 10px;
                display: inline-block;
            }
            &:after {
                content: " ";
                position: absolute;
                display: inline-block;
                -webkit-mask-size: 16px 16px;
                width: 16px;
                height: 16px;
                // -webkit-mask: url(/static/media/checked.d1f56060.svg) no-repeat center;
                margin: 11px 10px 11px 10px;
                display: inline-block;
                z-index: 100;
            }
        }

        .buttonRoleSelected {
            &:before {
                cursor: default;
                content: " ";
                background-color: #2c74ff;
                display: inline-block;
                -webkit-mask-size: 16px 16px;
                width: 16px;
                height: 16px;
                -webkit-mask: url(../../../../../../images/uncheckbox.svg) no-repeat center;
                margin: 11px 10px 11px 10px;
                display: inline-block;
            }
            &:after {
                content: " ";
                position: absolute;
                background-color: #fff;
                display: inline-block;
                left: 6px;
                -webkit-mask-size: 16px 16px;
                width: 16px;
                height: 16px;
                -webkit-mask: url(../../../../../../images/checked.svg) no-repeat center;
                margin: 11px 10px 11px 10px;
                display: inline-block;
                z-index: 100;
            }
        }
    }
}
