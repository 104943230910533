$color-blue-clara: #2c74ff;

.containerFileInput {
  border-width: 1px;
  border-radius: 5px;
  border-style: dashed;
  border-color: $color-blue-clara;
  padding-left: 1.0769rem;
  padding-right: 1.0769rem;
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  box-sizing: border-box;
  width: 23.3rem;
  height: 23rem;
}

.notFilesUploaded {
  flex-direction: row !important;
  box-sizing: border-box;
}

.FileUpload {
  width: 29rem !important;
  height: 28.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #eaf1fe;
  position: relative;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}