@import "../../../../../styles/variables/index.scss";

.textCompanyContent1 {
  color: $clara-black;
  font-size: 1rem;
  margin-bottom: 0.1rem;
  min-width: 25px;
  height: 1.2rem;
}

.containerAddressCompany {
  display: inline-flex;
  flex-direction: column;
}

.dataContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 12rem 1fr;
  gap: 1.5385rem;
  margin-bottom: 0.7692rem;
  .labelContainer {
    width: 100%;
    label {
      color: $clara-dark-grey;
    }
  }
  .defineSpace {
    min-height: 4.6154rem;
  }
  .valuesContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    span {
      color: $clara-black;
      margin-bottom: 0.1rem;
    }
  }
}
.managedByClara {
  border-radius: 4px !important;
  background: #FFF !important;
  box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.15) !important;
  color: $clara-black !important;
  font-size: 12px !important;
}

.statusTag {
  background-color: $clara-red !important;
  color: $clara-white !important;
  height: 16px !important;
  font-size: 10px !important;
}