@import "../../../../../styles/variables/index";
.text {
  font-weight: 700;
  font-size: 1.3846rem;
  color: $clara-black;
}
.money {
  font-weight: 700;
  font-size: 0.7692rem;
  color: $clara-black;
}
.price {
  font-weight: 800;
  font-size: 1.8462rem;
  color: $clara-black;
}