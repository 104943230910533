@import "../../../../styles/variables/index";
.RED{
    border-color:$status-red!important;
}
.selected_RED{
    border-color:$status-red!important;
    &:before {
        background-color:$status-red!important;
    }
}
.AMBER{
    border-color:$status-amber!important;
}
.selected_AMBER{
    &:before {
        background:$status-amber!important;
    }
}
.GREEN{
    border-color:$status-green!important;
}
.selected_GREEN{
    &:before {
        background:$status-green!important;
    }
}
.UNKNOWN{
    border-color:$status-unknown!important;
    
    
}
.selected_UNKNOWN{
    &:before {
        background:$status-unknown!important;
    }
}
.noMargin{
    margin: 0!important;
    padding: 0!important;
}