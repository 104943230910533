.ContainerView {
    margin: 0;
    padding: 0;
    height: auto;
    width: 1000px;
    clear: both;
    position: relative;
    display: inline-flex;
    flex-direction: column;
    text-align: left;
    align-items: center;
    justify-content: space-between;
  
    
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 40px;
    color: #65707e;
  }

.NoComments{
    font-size: 12rem;
    width: 246px;
    height: 109px;
    margin-top: calc(20vh - 54.5px );
}

.containerNotes{
    height: 41vh;
    display: inline-flex;
    justify-content: center;
}