@import "../../../../../styles/variables/index.scss";
.CheckboxButton {
    display: flex;
    width: 100%;
    flex-direction: column;

    > * {
        width: 100%;
    }
    .Columns {
        display: flex;
        width: 100%;
        height: auto;
        gap: 1.8462rem;
    }

    .row {
        flex-direction: row !important;
        flex-wrap: wrap;
    }
    .rowNoWrap {
        flex-direction: row !important;
    }
    .rowCenter {
        flex-direction: row !important;
        flex-wrap: wrap;
        justify-content: center;
    }
    .column {
        flex-direction: column !important;
    }
}
.CheckboxOption {
    width: auto;
    height: auto;
    display: flex;
}
