.containerForm {
    margin-top: 1.5rem;
    width: 100%;
    display: inline-flex;
    flex-direction: column;
}

.conteninerText {
    display: inline-flex;
    input {
        height: 100%;
        width: calc(100% - 1rem);
    }
    button {
        min-width: 6rem;
    }
}
.message {
    background: red;
    width: 130%;
    position: absolute;
    left: 0;
    transform: translateX(-50%);
}
//-----------------------------


.containerMessage {
    margin: 1rem 0;
    width: 46.1538rem;
    height: 5rem;
    padding: 1.5rem 1rem;
    background: rgba(24, 197, 168, 0.1);
    border-left: 2px solid #18c5a8;
}
