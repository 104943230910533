@import "../../../../../../styles/variables/index.scss";

.containerField {
    display: inline-flex;
    width: 100%;
    margin: 0.5rem 0;
    label {
        color: $clara-black;
        margin-right: 0.5rem;
        width: 10rem;
    }
    span {
        width: calc(100% - 10rem);
        color: $clara-dark-grey;
    }
}

.containerDetails {
    > label {
      padding: 1.5rem 0 0.5rem 0;
      font-weight: bold;
      color: $clara-black;
    }
    display: inline-flex;
    flex-direction: column;
    windows: 100%;
}
