.Header {
  background: #fff;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #ebeff2;
  text-align: left;
  position: relative;
  z-index: 14;
  img {
    margin: 0 0 0 20px;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  label{
    font-size: 16px;
  }
}
.ZoneDropDownCompany {
  display: inline-block;
  padding: 23px 0;
  vertical-align: top;
  width: auto;
  height: 100%;
  position: relative;
}

.ZoneDropDownCompanyContent {
  border-left: 1px solid #e4e3e9;
  padding-left:35px;
  min-width: 300px;
  overflow: hidden;
  height: 40px;
}
.logoWhite {
  background: url(../../../images/claraIconLogin.svg) no-repeat center !important;
  /* background: linear-gradient(196.06deg, #FFFFFF -12.56%, rgba(255, 255, 255, 0) 57.74%);
    opacity: 0.5;
    transform: rotate(-90deg); */
}
.headerLogo {
  color: #ffffff;
  
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 167.58%;
}
.buttonLoginHeader {
  background: transparent;
  color: #ffffff;

  //opacity: 0.5;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;

  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  //display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;

  height: 34px;
  width: 116px;
  cursor: pointer;
}
.textHeaderSignUp {
  padding-right: 17px;
  
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 167.58%;
  display: inline-block;
  margin-top: 6px;
  //display: flex;
  align-items: center;
  text-align: right;
  color: #ffffff;
}
.NavMenuLogin{
  border:none!important;
}
.NavMenu {
  position: relative;
  float: right;
  border-right: 1px solid #e4e3e9;
  height: 30px;
  margin: 20px 0;
  padding-right: 40px;
  a {
    display: inline-block;
    margin-left: 40px;
    text-align: left;
    position: relative;
    margin-top: 5px;
    &:before {
      content: " ";
      background-color: #a9b0bc;
      width: 24px;
      height: 24px;
      display: inline-block;
      -webkit-mask-size: 24px 24px;
    }
    img {
      display: inline-block;
      margin: 0;
    }
    span {
      display: inline-block;
      margin-left: 5px;
      margin-top: 2px;
      color: #848f99;
      font-size: 16px;
    }
    &:hover {
      color: #2c74ff !important;
    }
  }
}
.LinkCapTable {
  &:before {
    -webkit-mask: url(../../../images/capt_table.svg) no-repeat center !important;
  }
}
.LinkStakeHolderMap {
  line-height: 2em;
  &:before {
    -webkit-mask: url(../../../images/stake_holder_map.png) no-repeat center !important;
  }
}
.LinkDocuments{
  &:before{
    -webkit-mask: url(../../../images/documents.png) no-repeat center !important;
  }
}
.LinkDueDiligence {
  &:before {
    -webkit-mask: url(../../../images/health_check.png) no-repeat center !important;
  }
}
.LinkEngagements {
  &:before {
    -webkit-mask: url(../../../images/engagements.svg) no-repeat center !important;
  }
}
.LinkAcademy {
  &:before {
    -webkit-mask: url(../../../images/academy.svg) no-repeat center !important;
  }
}
.LinkSignUp {
  &:before {
    -webkit-mask: url(../../../images/onboarding.svg) no-repeat center !important;
  }
}

.LinkSelect {
  &:before {
    background-color: #2c74ff !important;
  }
  span {
    color: #2c74ff !important;
  }
}
.EditStartUp{
  &:before {
    -webkit-mask: url(../../../images/edit.svg) no-repeat center;
  }
}
.ChangeStartUp{
  &:before {
    -webkit-mask: url(../../../images/change.svg) no-repeat center;
  
  }
}
.AddNewStartup{
  &:before {
    -webkit-mask: url(../../../images/add_startup.svg) no-repeat center;
  }
}