@import "../../../styles/variables/index.scss";

.header {
    padding-bottom: 2.5rem;
}
.containerButtons {
    padding: 1.5rem 3.7rem 1.5rem 3.7rem;
    width: 100%;
    margin: 0;
}
.ScrollForm {
    max-height: calc(100vh - 3rem - 40px - 6.2rem);
    padding: 3.2rem 2.8rem 1.9rem 2.8rem;
    min-height: 300px;
    max-width: 100%;
    min-width: calc(620px + 5.6rem);
    width: calc(620px + 5.6rem);
    box-shadow: 0px -4px 10px $clara-dark-grey;
    z-index: 199999;
}

.auto {
    width: auto;
}

.standard {
    width: calc(620px + 5.6rem);
}

.wide {
    width: 819px;
}
