@import "../../../../styles/variables/index";
@import "~./../../../../styles/extends/positions.scss";
.RowAdd {
    @extend .flex;
    @extend .flex-start;
    @extend .flex-middle;
    margin-bottom: 1.875rem;
    position: relative;
    .Label {
        width: 40%;
        @extend .flex;
        @extend .flex-start;
        @extend .flex-middle;
        position: relative;
        height: 35.5px;
        color: $clara-dark-grey;
    }
    .Input {
        width: 60%;
        @extend .flex;
        @extend .flex-start;
        @extend .flex-middle;
        position: relative;
    }
    .Asterisk{
        color:$palette-error;
    }
    
}
.Top{
    align-items: flex-start;
}
