.top {
    position: relative;
    bottom: 6px;
    padding: 10px;
    z-index: 30;
  }
  
  .right {
    position: relative;
    left: 15px;
    top: 20px;
    padding: 10px;
    background: #fff !important;
    border: 1px solid #eee;
    box-sizing: border-box;
    z-index: 5;
  }
  
  .containerToolTip {
    position: relative;
  
    div {
      width: minmax(32px, 60px);
    }
  }
  .containerContend {
    width: auto;
  }
  
  @for $i from 0 through 4 {
    // Bucle que va de 0 a 4
  
    .child#{$i} {
      position: relative; //  creara la clase .childx
      right: calc(#{$i}* 8px); // con una posicion corrida desde la derecha a medida que avanza el bucle
      z-index: calc(7 - #{$i});
    }
  }
  .containerNames {
    margin: 0px;
    color: #2c74ff;
    height: 32px;
    width: 60px;
  }
  .nameHover {
    width: auto;
    margin: 5px 0px;
    cursor: pointer;
    &:hover {
      color: #2c74ff;
    }
  }
  .TooltipContainer {
    display: inline-block;
    position: relative;
    width: 100%;
    .Tooltip {
      padding: 3px 15px 3px 3px;
      display: none;
      border: 1px solid #2c74ff;
      border-radius: 3px;
      position: absolute;
      top: 0;
      right: -170px;
      &.normal {
        width: 155px;
      }
      &.big {
        width: 220px;
        right: -250px;
      }
      &.left {
        text-align: left;
        * {
          text-align: left;
        }
      }
      &.center {
        text-align: center;
        * {
          text-align: center;
        }
      }
      background: #fff;
      z-index: 1;
      i {
        font-size: 24px;
        display: inline-block;
        &:before {
          color: #2c74ff;
        }
      }
      label {
        display: inline-block;
        vertical-align: top;
        line-height: 15px !important;
        font-size: 12px;
        margin-top: 1px;
  
        margin-left: 5px;
      }
    }
    &:hover {
      .Tooltip {
        display: flex;
        z-index: 11;
      }
    }
  }
  .tooltipBorder {
    width: 0 !important;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; /* 40px height (20+20) */
    border-right: 10px solid #2c74ff;
    position: absolute;
    left: -10px;
    top: 0;
  }
  
  .tooltipBorderArrow {
    width: 0 !important;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; /* 40px height (20+20) */
    border-right: 10px solid white;
    position: absolute;
    left: -8px;
    top: 0;
  }
  