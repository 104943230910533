.Modal {
  width: auto;
  position: relative;
  height: auto;
  overflow: hidden;
  display: block;
  min-width: 200px;
  min-height: 100px;
  border-top-style: solid;
  border-top-color: #2c74ff;
  
}
.Header {
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  a {
    width: 12px;
    height: 12px;
    cursor: pointer;
    &:before {
      content: " ";
      background-color: #848f99;
      display: inline-block;
      -webkit-mask-size: 12px 12px;
      width: 12px;
      height: 12px;
      -webkit-mask: url(../../../../images/close.svg) no-repeat center;
    }
    &:hover {
      &:before {
        background-color: #2c74ff;
      }
    }
  }
}

.alertModalClass{
  border-top-color: #FF606F;
}