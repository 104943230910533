div:has(> div.documentUploaderFullLength){
    width:100%;
}

.documentUploaderFullLength {
    > span {
        width: 100%;
        max-width:100%;

        > div {
            width: 100%;

            > div {
                width: 100%;

                > div{
                    width: 100%;
                    
                    > div {
                        width: 100%;
                    }
                }
            }
        }
    }
}