@import "./../../../../styles/variables/colors.scss";

.ClaraSearch {
  width: 100%;
  display: block;
  position: relative;
  i {
    mask: url(../../../../images/search.svg) no-repeat center;
    mask-size: 1.0769rem 1.0769rem;
    width: 1.0769rem;
    height: 1.0769rem;
    background: $status-unknown;
    position: absolute;
    left: 0.7692rem;
    top: 0.6154rem;
  }
}
.search {
  max-width: 21rem;
  width:100%;
  height: 2.3077rem;
  margin: 0 0 0.7692rem;
  background: #f7f8f9;
  border: 0.0769rem solid #e4e3e9;
  box-sizing: border-box;
  border-radius: 0.3846rem;
  padding: 0.0769rem 2.3077rem 0;
  -webkit-border-radius: 0.3846rem;
  -moz-border-radius: 0.3846rem;
  -ms-border-radius: 0.3846rem;
  -o-border-radius: 0.3846rem;
}