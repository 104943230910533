.AuditLogs {
    width: 100%;
    height: 100%;
}

.ZoneTimeLine {
    width: 100%;
    >h1{
        margin:1rem 0  ;
    }
}
