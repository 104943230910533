

.skeletonCheckContainer {
  display: grid;
  width: 100%;
  height: auto;
  grid-row-gap: 0.6154rem;
}

.skeletonCheck {
  display: grid;
  grid-template-columns: 16px 1fr;
  grid-column-gap: 16px;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
}