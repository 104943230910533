.containerPassport {
  height: 40px;
  width: 100%;
  display: inline-grid;
  grid-template-columns: auto 194px 185px;
  color: #808080;
  margin: .2rem 0 1rem 0;
  align-items:flex-start;
  label {
    text-align: left;
    align-self: center;
    
  }
}

.inputCode {
  input {
    width: 185px !important;
    height: 40px !important;
    padding: 0 0.5rem 0 0.7rem !important;
  }
  > div{
    margin: 0 !important;
  }
}
