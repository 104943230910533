@import "../../../../styles/variables/index.scss";

.table {
  border-spacing: 0;
  width: 100%;
  tbody tr {
    height: 3.6rem;
    td {
      border-bottom: 1px solid #eee;
      padding-top: 1.7rem;
      font-size: 0.9231rem;
      color: $clara-dark-grey;
    }
  }
}

.t1 {
  width: 131px;
}

.t2 {
  width: 175px;
}

.t3 {
  width: 131px;
}

.t4 {
  width: 122px;
}

.t5 {
  width: 440px;
}