.ModalCheckForm {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}
.ClaraCheckForm {
  display: block;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: wrap;
  text-align: center;
}
.Circle {
  
  text-align: center;
  display: inline-block;
  margin: 10px auto;
  
  button{
    background: transparent;
    height: 50px;
    width: 60px;
  }
  .selected {
    & > div {
      & > div {
        opacity: 1;
      }
    }
  }
  .unselected {
    & > div {
      & > div {
        opacity: 0.2;
      }
    }
  }
  .Avatar {
    span {
      line-height: 50px !important;
      font-size: 14px !important;
    }
  }
  .Button {
    width: 50px;
    height: 50px;
    border: 1px solid #eaf1fe;
    border-radius: 50%;
    &:hover {
      border-color: #2c74ff;
    }
  }
}
.Options {
  display: flex;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  a {
    
    display: inline-block;
    align-items: center;
    text-align: center;
    text-transform: lowercase;
  }
  span{
    margin-left:0.2308rem;
    margin-right:0.2308rem;
  }
}
.CircleButton {
}
