@import "./../../../../../styles/variables/colors.scss";

.Invoices {
  padding-left: 2.3846rem;
  padding-right: 2.3846rem;
  padding-top: 3.9231rem;
  display: flex;
  flex-direction: column;
  .titleColor {
    color: $clara-blue;
    margin-bottom: 3.2308rem;
  }
}