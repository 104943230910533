@import "../../../../../styles/variables/index.scss";
.CheckboxButton {
  display: flex;
  width: 100%;
  flex-direction: column;
  > * {
    width: 100%;
  }
  .Columns {
    display: flex;
    width: 100%;
    height: auto;
    gap: 1.8462rem;
  }
}
.CheckboxOption {
  width: auto;
  height: auto;
  display: flex;
}


.button_size_width_xs {
  width: 11.23rem;
  height: 7.6154rem;
}

.button_size_width_md {
  width: 12rem;
}

.button_size_width_auto {
  width: auto !important;
}

.button_size_width_full {
  width: 100% !important;
}
.button_size_width_size2 {
  width: 316px !important;
  height: auto !important;
  min-height: 96px !important;
  text-align: left;
  padding-left: 55px;
}
