@import "../../../../styles/variables/index";
.TooltipInput {
  position: relative;
  width: 100%;
  height: 100%;
  .Tooltip {
    padding:0.2308rem 1.1538rem 0.2308rem 0.2308rem;
    display: none;
    border: 0.0769rem solid $palette-clara;
    border-radius: 0.2308rem;
    position: absolute;
    top: 0;
    right: -13.0769rem;
    &.normal{
      width: 11.9231rem;
    }
    &.big{
      width: 16.9231rem;
      right: -19.2308rem;
    }
    &.left{
      text-align:left;
      *{
        text-align:left;
      }
    }
    &.center{
      text-align:center;
      *{
        text-align:center;
      }
    }
    background: $bg-white;
    z-index:1;
    i {
      font-size: 1.8462rem;
      display: inline-block;
      &:before {
        color: $palette-clara;
      }
    }
    p {
      display: inline-block;
      vertical-align: top;
      line-height: 1.1538rem !important;
      font-size: 0.9231rem;
      margin-top: 0.0769rem;
      color: gray !important;
      margin-left: 0.3846rem;
      overflow-wrap: anywhere;
    }
  }

}
.tooltipBorder {
  width: 0!important;
  height: 0;
  border-top: 0.7692rem solid transparent;
  border-bottom: 0.7692rem solid transparent; /* 40px height (20+20) */
  border-right: 0.7692rem solid $palette-clara;
  position: absolute;
  left: -0.7692rem;
  top: 0;
}

.tooltipBorderArrow {
  width: 0!important;
  height: 0;
  border-top: 0.7692rem solid transparent;
  border-bottom: 0.7692rem solid transparent; /* 40px height (20+20) */
  border-right: 0.7692rem solid $bg-white;
  position: absolute;
  left: -0.6154rem;
  top: 0;
}
