.DetailsTab {
  max-width: 943px;
  overflow: hidden;
}

.spacing {
  height: 2.3846rem;
  align-items: center;
  display: flex;
}
