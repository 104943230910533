.containerForm {
    > div {
        margin: 0.5rem 0;
    }
    article {
        color: #828c98;
        font-size: 13px;
    }

    h1 {
        margin-bottom: 0.5rem !important;
    }
}
.LabelWithInput {
    width: 100%;
    display: inline-grid;
    grid-template-rows: 100%;
    grid-template-columns: auto 23.75rem;
    height: 3.125rem;
    align-items: center;
    transition: all 0.5s;
    opacity: 1;
    > label {
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
        line-height: 16px;
        color: #828c98;
    }
}

.containerButtonForm {
    display: inline-flex;
    width: 100%;
    justify-content: flex-end;
    button {
        margin-left: 1rem;
    }
}

.windowFile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    transition: opacity 1s, z-index 1s;
}

.Modal {
    h1 {
        &:after {
            content: " ";
            width: 1.2rem;
            height: 1.2rem;
            margin-left: 1rem;
            //  margin-top: 0.1rem;
            position: absolute;
            background: url(../../../../images/checkOk.svg) no-repeat center !important;
            font-size: 16px;
        }
    }
}

//input and button components

.buttonsAndInput {
    grid-template-columns: auto 20rem;
    label {
        width: 12.5rem !important;
    }
}

.doubleColumn {
    grid-template-columns: auto 20rem;
}

.smallComponent {
    grid-template-columns: auto 20rem;
    > div:nth-child(2) {
        width: 10.25rem;
    }
}

.inputNOC {
    display: inline-flex;
    justify-self: end;
    width: 20rem;
    input {
        width: 8.75rem;
    }
    > div:nth-child(2) {
        input {
            margin-left: 1.5rem;
        }
    }
}

//
.containerService {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
}
.itemService {
    display: inline-flex;
    justify-content: space-between;
}

.MenuServices {
    width: 38.3125rem;
    max-height: 18.75rem;
    padding: 1rem;
}

.containerMenuListService {
    margin-top: 1rem;
    overflow-y: auto;
    width: 100%;
    > div:first-child {
        color: #2c74ff;
    }
    > div {
        cursor: pointer;
        width: 100%;
        text-align: left;

        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 13px;
        color: #192a3e;
        padding: 0.625rem 1.5625rem;

        &:hover {
            background: #eee;
        }
    }
}

.containerServiceForm {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: auto 8.75rem;
    grid-template-rows: 1fr 1fr;
    grid-gap: 1rem 1rem;

    input {
        align-self: start;
    }

    button {
        align-self: end;
    }
}

.services_component_container {
    display: grid;
    grid-template-columns: 34.6154rem 8.6154rem;
    grid-template-rows: 1fr 1fr;
    row-gap: 1rem;
    column-gap: 1rem;
    margin: 1rem 0;
    >button{
        align-self: end;
    }
}
