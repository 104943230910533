@import "./../../../../styles/variables/colors.scss";

.containerForm {
  max-height: 41.5rem;
  overflow-y: auto;
  padding: 2.8rem;
  border-bottom: 1px solid $clara-light-blue;
  min-width: 53.3rem;
  max-width: 55rem;
}

.contentForm {
  margin-top: 2rem;
  margin-bottom: 2rem;
  p {
    margin-top: 1rem;
    line-height: 1.6rem;
  }
}

.containerButtons {
  padding-right: 3rem;
}

.title {
  text-align: left;
  width: 100%;
  margin: 0 0 40px;
}
