.OptionsButtonInput {
  width: 100%;
  text-align: center;
  margin: 30px auto 0;
  .OptionsBotonera {
    display: flex;
    margin: auto;
    justify-content: space-around;
    width: 420px;
    align-items: stretch;
    flex-wrap: wrap;
    .OptionButton {
      min-width: 120px;
      &.with160 {
        max-width: 160px;
      }
      &.withAuto {
        max-width: auto;
      }

      margin: 0 25px 20px;
      height: 40px;
      overflow: hidden;
      padding: 3px;
      flex-grow: 1;
      background: #fff;
      cursor: pointer;
      border: 1px solid #2c74ff;
      color: #2c74ff;

      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      border-radius: 4px;
      &.selected {
        background: #2c74ff;
        border: none;
        color: white;
      }
      &:hover {
        background: #2c74ff;
        border: none;
        color: white;
      }
    }
  }
}
.question {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #192a3e;
}
.Disclaimer {
  font-size: 13px;
}
.classNameError {
  grid-column: 3/3;
}
