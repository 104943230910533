@import "src/styles/variables/index.scss";

.divider {
  margin-top: 2.0769rem !important;
  margin-bottom: 2.0769rem !important;
  border-color: $border-disabled !important;
}

.questionContainer {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  position: relative;
  padding-left: 16px;
}

.questionContainer::before {
  content: counter(item, lower-alpha) '.';
  counter-increment: item;
  position: absolute;
  left: 0;
}

ol {
  counter-reset: item;
  list-style: none;
  padding-left: 0px;
}
