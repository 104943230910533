@import "normalize";
@import "variables/index";
@import "extends/positions";
@import "extends/scrollbar";
@import "utilities/index.scss";
// @import "fonts/claraLegal/claralegal";

html {
  font-size: 13px;
  overflow: hidden;
}

body {
  font-size: 1rem !important;
  font-family: "cl-regular", sans-serif !important;
  color: $color-primary !important;
  overflow: hidden;
  p {
    margin: 0;
  }
}

button,
input {
  outline: unset;
  border: hidden;
}

a {
  color: $clara-star;
  text-decoration: none;
  svg {
    color: $clara-link;
    margin-right: 1em;
  }
  &:hover {
    color: $clara-star;
    text-decoration: none;
    svg {
      color: $palette-clara;
    }
  }
}
