@import "../../../styles/variables/index.scss";

.arrow {
  line-height: 24px;
  width: 100px;
  margin: 0px;
  color: $clara-dark-grey;
  &:after {
    content: " ";
    width: 24px;
    height: 24px;
    background: url(../../../images/arrow_small.svg) no-repeat center !important;
    display: inline-block;
    line-height: 24px;
    cursor: pointer;
  }
}

.listTitle {
  color: grey;
}

.listItem {
  padding-top: 9px !important;
  padding-right: 20px !important;
  padding-left: 20px !important;
  padding-bottom: 0px !important;
  position: absolute;
  &:hover {
    background-color: gainsboro;
  }
}

.listItemText {
  span {
    font-size: 16px !important;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.paper {
  min-width: 120px;
}
