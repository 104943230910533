@import "../../../../styles/variables/index.scss";
.ModalContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 80px;
  height: auto;
  // width: 55rem;
  width: 62rem;
  min-height: 40rem;
  max-height: calc(100vh - 6.2rem);
  justify-content: center;
  align-items: center;
}

.DocumentPreview {
  width: 62rem;
  height: 36rem !important;
  padding-left: 0.7692rem;
  padding-right: 0.7692rem;
}

.DocumentPreviewBotonera {
  width: 100%;
  height: 6.1538rem;
  background: #ffffff;
  padding: 1.5385rem;
  text-align: right;
  // box-shadow: 0px -4px 10px rgba(125, 139, 156, 0.2);
  z-index: 199999;
  button {
    margin-left: 0.7692rem;
  }
}
.dialogPaper {
  height: auto;
}
.dialogModal {
  height: 100% !important;
  > {
    height: 100% !important;
  }
}
.ScrollForm {
  max-height: calc(100vh - 120px - 6.2rem) !important;
  padding: 0 !important;
  min-height: 300px !important;
  max-width: 100% !important;
  min-width: calc(620px + 5.6rem) !important;
  width: calc(620px + 5.6rem) !important;
  z-index: 199999;
  height: auto !important;
}
.dialogHeader {
  padding: 2.9rem 2.7rem 2.3rem 2.7rem;
}
