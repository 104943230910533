$color-blue-clara: #2c74ff;
$color-dark-grey: #828c98;

.containerNoFilesUpload {
    display: flex;
    flex-direction: column;
    gap: 1.0769rem;
    position: absolute;
    div{
        display: flex;
        justify-content: center;
        height: 3.3846rem ;
        gap:7px;
    }
    p{
        height:1.0769rem
    }
    span:hover{
        cursor:pointer;
    }
}

.text {
    grid-column-start: 1 ;
    grid-column-end: 7 ;
    color: $color-dark-grey;
    margin: 0px;
    > span {
        text-decoration: none;
        color: $color-blue-clara;
        cursor: pointer;
        &:hover {
          text-decoration: underline !important;
        }
    }
}