@import "./../../../../../styles/variables/colors.scss";

.claraAvatar {
  font-weight: 600;
  div div span span {
    font-size: 15px;
  }
}

.bigAvatarText {
  font-weight: 600;
  div div span span {
    font-size: 36px !important;
  }
}

.smallAvatarText {
  font-weight: 600;
  div div span span {
    font-size: 12px !important;
  }
}

.tinyAvatarText {
  font-weight: bold;
  div div span span {
    font-size: 8px !important;
  }
}

.square {
  border-radius: 0.7692rem !important;
  -webkit-border-radius: 0.7692rem !important;
  -moz-border-radius: 0.7692rem !important;
  -ms-border-radius: 0.7692rem !important;
  -o-border-radius: 0.7692rem !important;
  div div span span {
    font-size: 20px;
  }
}

.columData {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
}

.iconColor {
  color: $palette-clara !important;
}

.spaceTop {
  margin-top: 0.1538rem;
  font-weight: 600 !important;
  font-size: 1rem;
}

.spaceTopDash {
  margin-top: 0;
  font-weight: 600 !important;
  font-size: 0.8462rem;
}

.AvatarWait {
  width: 4.3rem;
  height: 4.3rem;
  background-color: $clara-block-blue;
  display: flex;
  border-radius: 0.7692rem;
  -webkit-border-radius: 0.7692rem;
  -moz-border-radius: 0.7692rem;
  -ms-border-radius: 0.7692rem;
  -o-border-radius: 0.7692rem;
}

.formCircle {
  height: 3.3077rem;
  width: 3.3077rem;
  background-color: $clara-block-blue;
  display: flex;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.AvatarDash {
  width: 2.7692rem;
  height: 2.7692rem;
  background-color: $clara-block-blue;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  display: flex;
}

.AvatarDashCircle {
  width: 2.7692rem;
  height: 2.7692rem;
  background-color: $clara-block-blue;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
}

.lockBadge {
  width: 1.5385rem;
  height: 1.5385rem;
  position: absolute;
  top: -0.2rem;
  left: -0.2rem;
  border: 1px solid $clara-white;
  background-color: $clara-yellow;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addBadge {
  width: 1.5385rem;
  height: 1.5385rem;
  position: absolute;
  top: -0.2rem;
  right: -0.2rem;
  border: 1px solid $clara-blue;
  background-color: $clara-white;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $clara-blue !important;
  cursor: pointer;
  &:hover {
    background-color: $clara-blue;
    color: $clara-white !important;
  }
}

.addClara {
  width: 1.5385rem;
  height: 1.5385rem;
  position: absolute;
  bottom: -0.2rem;
  left: -0.2rem;
  border: 1px solid $clara-blue;
  background-color: $clara-white;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatarContainer {
  position: absolute;
}

.iconColor {
  color: $clara-black !important;
}

.iconBlue {
  color: $clara-blue !important;
}

.lockBadgeBig {
  width: 2.7692rem;
  height: 2.7692rem;
  position: absolute;
  top: -0.3rem;
  left: -0.3rem;
  border: 2px solid $clara-white;
  background-color: $clara-yellow;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addBadgeBig {
  width: 2.7692rem;
  height: 2.7692rem;
  position: absolute;
  top: -0.3rem;
  right: -0.3rem;
  border: 2px solid $clara-blue;
  background-color: $clara-white;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $clara-blue !important;
  cursor: pointer;
  &:hover {
    background-color: $clara-blue;
    color: $clara-white !important;
  }
}