@import "../../../../../../styles/variables/index.scss";

.ZipCodeRegion{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 40%;
    gap: 1.1538rem;
    >div{
        width: 100%;

    }
}
.titleBilling{
    margin-bottom:1.1538rem;
}
.title{
    color: $clara-black!important;
}
.subTitle{
    color: $clara-dark-grey!important;
    padding: 10px 0 20px 0;
}
.secondaryButton{
    color: $clara-red!important;
    border-color: $clara-red!important;
    &:hover{
        background-color: $clara-red!important;
        color: $clara-white!important;
    }
}
.submitButton{
    background-color: $clara-red!important;
}
.rowAdd{
    justify-content: flex-start!important;
    align-items: flex-start;
}