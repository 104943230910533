@import "./../../../../../../styles/variables/colors.scss";

.containerModal {
    display: inline-flex;
    flex-direction: column;
    padding: 2rem 2.5rem;
    h2 {
        
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        margin-bottom: 2rem;
    }
}

.modalDelete {
  border-top-color: $clara-red !important;
}

.modalAssign {
    width: 38.25rem;
    min-height: 12.19rem;
}

.containerButtons {
    margin-top: 1rem;
    display: inline-flex;
    justify-content: flex-end;

    button:first-child {
        background: #fff;
        color: #2c74ff;
        margin-right: 1rem;
        border: 1px #eaf1fe solid;
    }
    button:only-child {
        background: #2c74ff;
        color: #fff;
    }
    > button {
        min-width: 102px !important;
        padding: 0 1rem !important;
    }
}

.containerInput {
    width: 100%;
    height: 40px;
    display: inline-flex;
    margin-bottom: 1rem;

    justify-content: space-between;
    input {
        width: 321px;
    }
    label {
        
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #828c98;
        margin-right: 2rem;
        align-self: center;
    }
}

.containerTextArea {
  width: 100%;
  // height: 40px;
  display: inline-flex;
  // margin-bottom: 1rem;
  margin-top: 1rem;

  justify-content: space-between;

  textarea {
    width: 100%;
    height: 70px;
    resize: none;
    font-size: 13px;
    border: 1px solid #c2cfe0;
    border-radius: 5px;
    // margin: 20px 0;
    outline: none;
    padding: 15px;

    &:focus {
      border-color: #2c74ff;
    }

  }
  label {

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #828c98;
    margin-right: 2rem;
    align-self: center;
  }
}

.readOnly {
    height: 40px !important;
    &:focus {
        border: 1px solid #c2cfe0 !important;
    }
}

.text {
    font-size: 19px;
    font-weight: 300;
    
    font-style: normal;
}

.textLabel {
    
    font-style: normal;
    color: #828c98;
}

.fileUpload {
    display: inline-flex;
    justify-content: space-between;
    label {
        max-width: 200px;
        line-height: 16px;
        text-align: left;
    }

   
}

.containerModalForm{
    >div{
        margin-top: 1rem;
    }
    
}

.formModal{
    width: 670px;
}

.disclaimer {
  width: 100%;
  height: 40px;
  display: inline-flex;
  // margin-bottom: 1rem;
  margin-top: 1rem;
  // color: red;
  justify-content: space-between;
  text-align: justify;
  font-size: 0.7rem;

  label {
    
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #828c98;
    margin-right: 2rem;
    align-self: center;
  }
}

.disclaimerColorRed {
  color: red;
}

.disclaimerColorBlue {
  color: #2c74ff;
}

.CheckBox {
  label {
    line-height: 38px;
  }
}

.deleteForm {
  padding: 34px;
  min-width: 550px;
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 110.93%;
    color: #192a3e;
    margin: 0 0 10px;
  }
  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #ff606f;
  }
  .Warning {
    font-weight: 700;
    font-size: 1.0769rem;
    color: $clara-red;
    width: 409px;
    margin: 15px 0 30px 40px;
  }
  .actionButtons {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;

    button {
      background: #ffffff;
      border: 1px solid #eaf1fe;
      box-sizing: border-box;
      border-radius: 4px;

      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.01em;
      padding: 16px;
      min-width: 120px;
      margin-right: 10px;
      /* Clara Dark Gray */

      color: #65707e;
      &.nextButton {
        background: #ff606f;
        border: 1px solid #ff606f;
        border-radius: 4px;
        color: #fff;
      }
    }

    //button:first-child {
    //  background: #fff;
    //  color: $clara-red;
    //  margin-right: 1rem;
    //  border: 1px #eaf1fe solid;
    //}
    //button:only-child {
    //  background: $clara-red;
    //  color: #fff;
    //}
    //> button {
    //  min-width: 102px !important;
    //  padding: 0 1rem !important;
    //}
  }
}

.containerModalIncorporation {
  display: inline-flex;
  flex-direction: column;
  padding: 2rem 2.5rem;
  width: 612px;
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 2rem;
  }
}

