@import "./../../../../styles/variables/colors.scss";

.TemplateLabel {
  grid-template-columns: 17.3846rem 1fr !important;
}

.TemplateLabelColumn {
  grid-template-columns: auto !important;
}
.disabled {
  background-color: #EAEAEA;
}
.TextArea {
  height: 8.615384615rem;
}

.TextAreaBig {
  height: 9.615384615rem;
}

.ContainerForm {
  display: grid;
  flex-direction: row !important;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}

.CommercialLicense {
  background: url("../../../../images/commercial_license.svg") no-repeat;
  width: 100%;
  height: 100%;
}

.ContainerForm {
  display: grid;
  grid-template-columns: 20.6rem 29rem;
  column-gap: 1.8rem;
}

.describeContainer {
  display: flex;
  flex-direction: column;
  color: $clara-dark-grey;
}

.checkboxMultiple div {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
}

.containerTableShares {
  border-spacing: 1rem 0;
  thead {
    tr {
      height: auto !important;
    }
    tr:first-child {
      display: none;
      th {
        display: none;
      }
    }
  }
  tr {
    height: 4.8rem !important;
    td:first-child {
      width: 10.769230769rem;
      border-bottom: 0px;
      padding-top: 20px;
    }
    td:nth-child(2) {
      width: 5.846153846rem;
      margin-right: 1rem;
      border-bottom: 0px;
    }
    td:nth-child(3) {
      width: 17.692307692rem;
      border-bottom: 0px;
    }
  }
}

.containerTableSharesNoBoder {
  border-spacing: 1rem 0;
  thead {
    tr {
      height: auto !important;
    }
    tr:first-child {
      display: none;
      th {
        display: none;
      }
    }
  }
  tr {
    height: 4.8rem !important;
    td:first-child {
      width: 10.769230769rem;
      border-bottom: 0px;
    }
    td:nth-child(2) {
      width: 5.846153846rem;
      margin-right: 1rem;
      border-bottom: 0px;
    }
    td:nth-child(3) {
      width: 17.692307692rem;
      border-bottom: 0px;
    }
  }
}

.buttonCheckBox {
  width: 12rem;
  height: 10rem;
}

.searchSize {
  width: 30.2308rem;
}

.CheckboxButton {
  [data-contentButton="true"] {
    width: 146px;
  }
}

.spacerTable {
  margin-bottom: 10px;
}

.spacerItems {
  padding-right: 10px;
}

.searchSize {
  width: 30.2308rem;
}

.flexOptionActivity {
  > div {
    flex-wrap: wrap !important;
  }
}

.buttonActivity {
  width: 11rem !important;
}

.sizeTwoLines {
  height: 2.4615rem;
  line-height: 1.2;
}
.lastInput {
  padding-bottom: 24px;
}