@import "../../../../../../../styles/variables/index.scss";

.containerForm {
  max-height: 616px;
  overflow-y: auto;
  padding-right: 2.8rem;
  padding-top: 3.6rem;
  border-bottom: 1px solid $clara-light-blue;
  padding-left: 2.8rem;
  padding-bottom: 3.8rem;
  width: calc(620px + 5.6rem);
}

.textContainer {
  div div div div input {
    height: 3.2308rem;
  }
}

.separator {
  hr {
    height: 0.01rem !important;
  }
}

.fileUploadContainer {
  div > div > div > div > span > div > div > div {
    width: 97% !important;
    white-space: nowrap;
  }
}

.containerButtons {
  padding-right: 3rem;
  margin-bottom: 1.9rem;
}

.title {
  text-align: left;
  margin: 0;
  width: 100%;
  margin-bottom: 40px;
}

.isHoldingChecbox {
  div label {
  }
  color: $clara-dark-grey;
}

.containerTable {
  table thead {
    tr:first-child {
      display: none;
      th {
        display: none;
      }
    }
    tr {
      th {
        position: relative;
        top: 22px;
      }
    }
  }
  table tbody tr:first-child td:first-child {
    div span {
      bottom: 3px;
      right: 219px;
    }
  }
  margin-bottom: 1rem;
}

.headerColumnTable {
  padding: 0.75rem 0;
  font-size: 0.9231rem;
  font-weight: 400;
}

.headerColumnTableCenter {
  padding: 0.75rem 0;
  font-size: 0.9231rem;
  font-weight: 400;
  display: flex;
  justify-content: center;
}

.headerColumnTableCenterSpace {
  padding: 0.75rem 0;
  font-size: 0.9231rem;
  font-weight: 400;
  display: flex;
  justify-content: center;
  margin-bottom: 2.3077rem;
}

.centerValues {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  color: $clara-dark-grey;
}

.centerValuesBlack {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  color: $clara-black;
}

.containerInnerTable {
  background: #f6faff;
  table {
    border-spacing: 0;
    width: 100%;
    tr:first-child {
      height: 3.7rem !important;
    }
    tbody tr td {
      border-bottom: none;
    }
    tr {
      height: 3.7rem !important;
    }
    tr td {
      height: 3.7rem !important;
    }
  }
}

.circle {
  -webkit-mask: url(../../../../../../../images/ellipse.svg) no-repeat center !important;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 6px;
}

.MISSING {
  background-color: $clara-red !important;
}

.Pending {
  background-color: $clara-yellow !important;
}

.Signed {
  background-color: $status-green !important;
}

.UNSIGNED {
  background-color: $clara-yellow !important;
}

.headerTitle {
  padding-bottom: 0px !important;
  margin-bottom: 0 !important;
}

.dropdownIcon {
  width: auto;
  height: auto;
  padding-left: 31.57px;
  color: $clara-blue;
  cursor: pointer;
}

.nameText {
  color: $clara-black;
}

.containerNoHeaderTable {
  position: relative;
  bottom: 30px;
  table thead {
    height: 0px !important;
    tr {
      height: 0px !important;
    }
  }
}

.hiddenHeader {
  visibility: hidden;
  height: 0;
}

.containerMenuOptionDocument {
  padding: 0.75rem;
}

.containerOption {
  padding: 0.5rem;
  cursor: pointer;
  color: $clara-black;
  font-size: 0.9231rem;
  font-weight: 400;
  &:hover {
    background: $clara-light-blue;
  }
}

.li {
  position: relative !important;
  transform: translateX(-50%);
}

.vestedIconContainer {
  position: absolute;
  right: 219px;
  bottom: -1px;
}

.popoverContainer {
  position: relative;
}

.nameTextStakeholder {
  display: block;
  position: absolute;
  top: 165px;
  color: #a8a8a8;
  font-family: "cl-regular";
  font-size: 12px;
  font-weight: 400;
}

.scrollbarModal {
  min-width: 819px;
}

.clickable {
  cursor: pointer;
  color: $clara-blue;
}
