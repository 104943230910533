.InputContainer {
    display: flex;
    position: relative;
    width: 120% ;
    text-align: left;

    .TextInput {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        align-items: center;
        width: 83%;
        align-items: flex-start;

        .TextInputChildren {
            margin-left: 10px;
        }

        label {
            margin-top: .5rem;
            color: gray;
            // margin-top: 30px;
        }

        textarea {
            font-size: 13px;
            width: 380px;
            height: 100px;
            border: 1px solid #c2cfe0;
            border-radius: 5px;
            // margin: 20px 0;
            outline: none;
            padding: 15px;

            &:focus {
                border-color: #2c74ff;
            }

        }

        .Error {
            border: 1px solid red !important;
        }
    }

    .TooltipContainer {
        color: gray;
        display: flex;
        align-items: center;
        position: absolute;
        right: -80px;

        .Tooltip {
            width: 144px;
            margin-left: 10px;
            margin-top: 15px;
        }
    }
}
.contentOnlyInput {
    position: relative;
}

.rightLabel {

    position: absolute;
    right: 23px;
    top: 14px;
    
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 120%;
    align-items: center;
    text-align: right;
    color: #65707e;

}


.MessageError {
    display: block;
    width: 100%;
    
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    text-align: right;
    color: #ff606f;
    max-width: 380px;
}
