.form{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content:flex-start;
}
.container{
  width: 53.2308rem;
  padding: 3.615384615rem 3.846153846rem 1rem 2.846153846rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  grid-gap: 2rem;
  column-gap: 2rem;
}
.header{
  margin-top: 1rem;
}
.buttons{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  grid-gap: 1rem;
}