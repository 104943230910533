.FilterZone{
    background: transparent;
    width: 400px;
    height:60px;
    position: absolute;
    text-align: left;
    top:-60px;
    right:0;
    
}
.MenuMoldalLink{
    
    padding: 10px 20px 10px 20px!important;
    
    &:before {
        display: none!important;
    }
    span{
        display: inline-block;
    }
}
.FilterGroup{
    width:100%;
    text-align: center;
    a{
        
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        align-items: center;
        color: #A9B0BC;
        margin: 15px 15px;
        padding:7px 20px;
        display: inline-block;
        cursor: pointer;
    }
}
.Select{
    height:60px;
    line-height: 60px;
    
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #BEC9D7;
    span{
        color: #2C74FF;
        margin-left:10px;
    }
}
.FilterType{
    position:absolute;
    right:0;
    top:0;
    margin: 18px 30px;
    a{
        background-color: #A9B0BC;
        width: 24px;
        height: 24px;
        display: inline-block;
        -webkit-mask-size: 24px 24px;
        margin-left:21px;
        cursor:pointer;
    }
}
.LIST{
    -webkit-mask: url(../../../../images/filter_list.png) no-repeat center;
}
.MAP{
    -webkit-mask: url(../../../../images/filter_map.png) no-repeat center;
}
.selectedType{
    background-color: #40a3e9!important;
}
.selected{
    background: #2c74ff;
    border-radius: 50px;
    color:#FFF!important;
}