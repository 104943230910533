@import "../../../../styles/variables/index";
.BreadCrumbsContent {
  width: 100%;
  overflow: auto;
  display: inline-block;
}
.BreadCrumbs {
  display: inline-block;
  width: auto;
  height: 3.1rem;
  margin: 0 3.7rem 0 2.3rem;
  white-space: nowrap;
  font-size: 0.7692rem;
  color: $clara-dark-grey;
  padding: 14px 0;
  visibility: hidden;
  > * {
    display: inline-block;
    // max-width: 20%;
    height: auto;
    margin-right: 0.5rem;
  }
  a {
    color: $clara-blue;
    white-space: nowrap;
    &:hover {
      color: $clara-blue;
      text-decoration: underline;
    }
  }
  label {
    color: $clara-dark-grey;
    &:last-child {
      white-space: nowrap;
    }
  }
}
.visible {
  visibility: visible;
}
