@import "../../styles/variables/index";
.SystemLayout {
    display:flex;
    align-items: center;
    justify-content: center;
    background: $bg-white;
    width: 100%;
    height: 100%;
    overflow: hidden;
    
}
