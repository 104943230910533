@import "./../../../../../../../src/styles/variables/colors";

.TemplateWizard {
  position: relative;
  width: 100%;
  height: 51.4rem !important;
  > div {
    display: grid;
    grid-template-rows: 1fr 6.0769rem;
    grid-template-columns: 1fr;
    width: 100%;
    height: 100%;
  }
  .Scrollbar {
    width: 100%;
    height: 100%;
    display: block;
  }
  .Content {
    padding: 4.9231rem;
    width: 100%;
    height: 100%;
    .Header {
      width: 100%;
      .Spacer {
        margin-bottom: 1.2308rem;
        margin-top: 1.2308rem;
      }
    }
    .Form {
      width: 100%;
      height: 100%;
      display: flex;
      gap: 1.8462rem;
      flex-direction: column;
    }
  }
}

.Botonera {
  display: grid;
  width: 100%;
  height: 6.08rem;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  padding: 1.5rem 3.6rem 1.5rem 2.4rem;
  border-top: 1px solid $clara-light-grey;
  box-sizing: border-box;
  .LeftButtons {
    display: flex;
    gap: 1.8462rem;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .RightButtons {
    display: flex;
    gap: 1.8462rem;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

.skeletonContainer {
  display: flex;
  flex-direction: row;
  grid-gap: 1.8462rem;
  gap: 1.8462rem;
}

.TemplateLabel {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 16.4615rem 1fr;
  grid-column-gap: 1.5385rem;
  .skeletonList {
    display: flex;
    flex-direction: column;
    gap: 1.8462rem;
    align-items: flex-start;
  }
  > * {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

.showSkeleton {
  position: absolute;
  width: 100%;
  background-color: $clara-white;
  height: 51.4rem !important;
  z-index: 2;
}
.GridTemplateWizardPreview {
  display: grid;
  grid-template-columns: 308px 340px;
  grid-gap: 21px;
  > div:first-child {
    justify-content: flex-start;
    align-items: flex-start;
  }
  > div:last-child {
    justify-content: flex-start;
    align-items: flex-end;
  }
}
.ClassFileUpload {
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
