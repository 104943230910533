@import "../../../../styles/variables/index";
.ClaraSelectContainer {
    width: 100%;
    display: block;
    text-align: left;

    span {
        position: relative;
        display: inline-block;
        text-align: left;
        hr {
            &:last-child {
                display: none;
            }
        }
    }
}
.AddCard{
    color:$clara-blue;
    padding: 0.9231rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
        margin-right: 10px;
    }
}