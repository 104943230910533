.form{
width: 100%;
  height: 100%;
  display: flex;
  justify-content:flex-start;
}
.container{
  padding: 24px 24px 24px 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  grid-gap: 2rem;
  column-gap: 2rem;
}
.header{
  margin-top: 1rem;
}
.buttons{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  grid-gap: 1rem;
}