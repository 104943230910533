@import "../../../../../../styles/variables/index.scss";

.containerCardMessage {
  width: 27.6154rem;
  border: 1px solid $color-border-line;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1.1538rem auto 1.1538rem;
  grid-template-rows: 1.1538rem minmax(2.3077rem, auto) 1.1538rem;
  gap: 1rem 1rem;
}

.containerCardMessageSelected {
  border: 1px solid $palette-clara;
}

.containerMessage {
  width: 100%;
  padding: 1rem;
  position: relative;
  display: block;
  padding-bottom: 0;
  margin-top: 1rem;
  > svg {
    position: absolute;
    top: 1.5px;
    left: 2px;
  }
}

.titleMessage {
  align-self: center;
  color: $color-primary;
  font-family: "cl-regular";
  font-weight: 700;
  font-size: 0.9231rem;
}

.textMessage {
  order: 4;
  grid-column-start: 1;
  grid-column-end: 3;
  font-family: "cl-regular";
  font-size: 0.9231rem;
  font-weight: 300;
  color: $color-text;
}

.dateMessage {
  order: 7;
  grid-column-start: 1;
  grid-column-end: 3;
  font-family: "cl-regular";
  font-size: 0.6154rem;
  color: $color-text;
  align-self: center;
  span {
    color: $color-primary;
  }
}

.containerCommentsCounts {
  display: inline-flex;
  order: 8;
  position: relative;
  > span {
    font-size: 10px;
    left: -10px;
    top: 30%;
    position: absolute;
  }
}
