.buttonCard {
  width: 484px !important;
  overflow: hidden;
}

.buttonCard div {
  transition: all 0.25s;
}

.buttonCard[data-active="SELECTED"] div[data-corner="ANGLE"],
.buttonCard:hover div[data-corner="ANGLE"] {
  background-color: #f4f8ff !important;
}

.rightContent{
  text-align: right;
  display: flex;
  justify-content: flex-end;
  padding-right: 36px;
}

.spacer {
  margin-bottom: 0.7692rem;
}