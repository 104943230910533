@import "./../../../../../../src/styles/variables/colors.scss";
$zIndexLeft: 50;
$zIndexRight: 100;
$zIndexCircle: 75;
$circleSize: 350px;
$circleSizeSmall: 15px;
$widthLeftEducation: 324px;
.GeneratorDocuments{

    height: calc(100vh - 5.39rem - 3.3rem);
    overflow: hidden;
    width: 100vw
}
.LeftEducation {
    grid-area: menu;
    width: 324px;
    background-color: $clara-light-blue;
    height: auto;
    color: white;
    z-index: $zIndexLeft;
    display: inline-block;
    position: relative;
    overflow: hidden;
    *{
        font-size:14px!important;
    }
    .LeftEducationInner{
      position:absolute;
      left:0;
      top:0;
      height: calc(100vh - 5.39rem);
      width: 324px;
      padding: 1.6923rem;
      background-color: $clara-light-blue;
      box-sizing: border-box;
    }
    header {
      text-align: left;
      padding: 5%;

      .genericFormIcon {
        width: $circleSizeSmall * 2;
        height: $circleSizeSmall;
        background-color: white;
        border-radius: $circleSizeSmall * 2 $circleSizeSmall * 2 0 0;
        z-index: $zIndexCircle;
        transform: rotate(-90deg);
        margin: 10px;
      }

      .titleIcon {
        margin-right: 30px;
        padding: 10px;
        border: 1px solid white;
        width: 30px;
        text-align: center;
        font-weight: bold;
      }
    }

    .titles {
      display: flex;
      flex-direction: column;
      text-align: left;
      padding: 39px 48px;
      margin-top: 0;
      height: 100%;
      overflow: hidden;
      box-sizing: border-box;
    }

    .littleLineContainer {
      box-sizing: border-box;
      padding-right: 200px;

      .littleLine {
        margin-top: 150px;
        color: white;
        width: 30px;
        display: inline-block;
      }
    }

    .title {
      font-size: 25px;
      font-weight: bold;
      z-index: $zIndexCircle * 2;
      text-align: left;
    }

    .subTitle {
      font-size: 15px;
      z-index: $zIndexCircle * 2;
      text-align: left;
      font-weight: bold;
    }

    .text {
      font-size: 14px;
      z-index: $zIndexCircle * 2;
      text-align: left;
    }

    .miniBorder {
      width: 30px;
      height: 30px;
      border-top: 1px solid white;
      z-index: $zIndexCircle * 2;
    }

    footer {
      display: flex;
      justify-content: left;
      position: absolute;
      bottom: 5px;
      left: 5px;

      p {
        padding: 7px;
        font-weight: bold;
        z-index: $zIndexRight;

        a {
          color: black;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
  .Loader {
    position: relative;
    width: 50px;
    height: 50px;
  

  & > :first-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-top-color: #2c74ff;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0); }
    100% { transform: rotate(360deg); }
  }
}