.Paper {
  box-shadow: unset !important;
  border: unset !important;
  width: 100%;
  height: auto;
  position: relative;
  top: 18px;

  Table {
    thead {
      th {
        width: auto;
        min-width: 16px;
        padding-left: 0px;
        div div {
          display: flex;
        }
      }
    }

    tbody {
      td {
        padding-left: 0px;
      }
    }
  }
}

//comuns
.Col1 {
  min-width: calc(40% - 100px);
  max-width: calc(40% - 100px);
  width: calc(40% - 100px);
}
.Col2 {
  min-width: 25%;
  max-width: 25%;
  width: 25%;
}
.Col3 {
  min-width: 20%;
  max-width: 20%;
  width: 20%;
}
.Col4 {
  min-width: 15%;
  max-width: 15%;
  width: 15%;
}
.Col5 {
  min-width: 15%;
  max-width: 15%;
  width: 15%;
}

.Col6 {
  min-width: 10;
  max-width: 10%;
  width: 10%;
}
.Col7 {
  min-width: 2%;
  max-width: 2%;
  width: 2%;
}

//parties

.containerParties {
  white-space: nowrap;
  display: flex;
  width: 130px;

  img {
    background: white;
    margin-right: 0px;
  }
}

.li {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  width: 32px;
  height: 37px;
  z-index: 1;
  white-space: nowrap;
  overflow: hidden;
  align-items: center;
}

//status

.containerStatues {
  display: flex;
  i {
    top: unset !important;
  }
}

.circle {
  -webkit-mask: url(../../../../images/ellipse.svg) no-repeat center !important;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin: 4px 5px 5px 0px;
  position: relative;
  top: 0.9em;
}

.Missing {
  background-color: #ff606f !important;
}

.Signed {
  background-color: #71e096 !important;
}

.Unsigned {
  background-color: #ffdec0 !important;
}

.Expired {
  background-color: #ff606f !important;
  box-sizing: border-box;
}

.Executed {
  background: #71e096;
}

.Pending {
  background: #fbbd2c;
}

.Draft {
  background: #848f99;
}

.Valid {
  background-color: #71e096 !important;
}

.Uploaded {
  background-color: #71e096 !important;
}

//fileName

.letterCell {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
  width: 22rem;
  text-align: left;
  margin: unset;
  padding-left: 26px;
  max-height: 2.5rem;
  &:before {
    content: " ";
    width: 24px;
    height: 24px;
    top: -5px;
    margin-right: 0.1em;
    display: inline-block;
    left: 0;
    position: absolute;
  }
}

.GENERATED {
  &:before {
    background: url(../../../../images/Agreement_Created.svg) no-repeat center !important;
  }
}

.UPLOADED {
  &:before {
    background: url(../../../../images/Agreement_Added.svg) no-repeat center !important;
  }
}

// modal buttom

.buttomModal {
  position: relative;
  left: 1.5rem;
}

.EditIcon {
  a {
    &:before {
      -webkit-mask: url(../../../../images/edit.svg) no-repeat center !important;
    }
  }
}

.DeleteIcon {
  a {
    &:before {
      -webkit-mask: url(../../../../images/trash.svg) no-repeat center !important;
    }
  }
}

.DownloadIcon {
  a {
    &:before {
      -webkit-mask: url(../../../../images/download.svg) no-repeat center !important;
    }
  }
}

.SignatureIcon {
  a {
    &:before {
      -webkit-mask: url(../../../../images/signature_icon.svg) no-repeat center !important;
    }
  }
}

.ReplaceIcon {
  a {
    &:before {
      -webkit-mask: url(../../../../images/Group.svg) no-repeat center !important;
    }
  }
}

.ViewICon {
  a {
    &:before {
      -webkit-mask: url(../../../../images/view_doc_details.svg) no-repeat center !important;
    }
  }
}

//leter
.letter {
  font-style: normal !important;
  font-weight: normal !important;
  color: #848f99 !important;
  white-space: nowrap;
  table-layout: fixed;
  overflow: hidden;
  margin: 0px -45px 0px 0px;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
  width: 100%;

  text-align: left;
}
.category {
  font-style: normal !important;
  font-weight: normal !important;
  color: #848f99 !important;
  white-space: nowrap;
  table-layout: fixed;
  overflow: hidden;
  margin: 0px -45px 0px 0px;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
  width: 120px;

  text-align: left;
}
.Col6 {
  width: 120px;
}
