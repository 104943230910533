@import "../../../../../styles/variables/index.scss";

.DetailsDocument {
  display: grid;
  grid-template-rows: auto 1fr;
}
.containerDetailsUpload {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 24.5385rem auto;
  padding-left: 1rem;
  height: 100%;
}
.containerPanel {
  padding-top: 2.2rem;
  border-right: 1px solid $color-border-line;
}

.titleDocument {
  color: $clara-blue;
  font-size: 1.8462rem;
  font-weight: 400;
  margin: 0;
  border-bottom: 1px solid $color-border-line;
  grid-column: 1/3;
  height: max-content;
  padding-bottom: 1.2rem;
}
.containerPreview {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2.3rem 2.3rem 2.3rem 2.3rem;
  width: calc(100% - 22rem);
  background: #f4f4f4;
}
.containerPreviewV4 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: auto;
  height: calc(100vh - 241px);
}
.Subscriptions {
  margin: 0;
  padding: 3.8rem 3.6rem 3.6rem 2.8rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative !important;
  background-color: $clara-white;
  > div {
    z-index: 1;
    > * {
      display: flex;
      width: 100%;
    }
    > svg {
      margin-bottom: 1.9rem;
      color: $clara-blue;
    }
    > h1 {
      font-size: 1.6923rem;
      line-height: 1.7rem;
      margin-bottom: 2.3rem;
    }
  }
  .circleImage {
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    display: inline-block;
    width: auto;
    z-index: 0;
  }

  .ContentText {
    width: 100%;
    flex-direction: column;
    p {
      font-size: 1.0769rem;
      width: 100%;
      margin-bottom: 1.9231rem;
      b {
        font-weight: 700;
      }
    }
  }
}
.greenButton {
  background: $clara-upgrade-color;
  &:hover {
    background: $clara-upgrade-color-dark;
  }
  &:disabled {
    background: $clara-dark-grey;
    &:hover {
      background: $clara-dark-grey;
    }
    &.loading {
      background: $clara-upgrade-color-dark;
      &:hover {
        background: v;
      }
    }
  }
  &:active {
    background: $clara-upgrade-color-dark;
    &:hover {
      background: $clara-upgrade-color-dark;
    }
  }
}
.ContentBotonera {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > * {
    margin-left: 1.2rem;
    margin-bottom: 0;
  }
}
.Scrollbar {
  width: 100%;
  height: calc(100vh - 287px);
  position: relative !important;
}
.containerPreviewFile {
  width: calc(100% - 294px);
  background: #f4f4f4;
  height: calc(100% - 260px);
}
