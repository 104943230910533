@import "../../../../../../../styles/variables/index.scss";

.twoColumnGrid {
    display: inline-grid;
    width: 100%;
    grid-template-rows: auto;
    grid-template-columns: 15.3846rem auto;
    align-items: center;
    label {
        color: $clara-dark-grey;
        font-size: 1.0769rem;
    }
    margin-bottom: 1.5rem;
}