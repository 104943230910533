@import "./../../../styles/variables/colors.scss";
.Title {
    font-size: 26px;
    font-weight: 600;
    color:$clara-dark-grey;
    margin: 0!important;
    justify-content: center;
    align-items: center;
}

.SubTitle {
    font-size: 18px;
    font-weight: 400;
    color:$clara-dark-grey;
    line-height: 26px;
}
.ErrorView{
    text-align: center;
    img{
        width: 11.7692rem;
    }
    *{
        margin: 1.1538rem 0;
    }
}