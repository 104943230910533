@import "../../../../styles/variables/index";

.UploadContainer {
  border: 1px;
  border-color: $clara-blue;
  box-sizing: border-box;
  border-radius: 6px;
  border-style: dashed;
}

.UploadContainerTrasnparent {
  border: 1px;
  border-color: transparent;
  box-sizing: border-box;
  border-radius: 6px;
  border-style: dashed;
}

.addPadding{
  padding-left: 5px;
  padding-right: 5px;
}

.Upload {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  text-align: left;
  overflow: hidden;
}

.Content {
  display: flex;
  flex-direction: row;
  padding-top: 1.2308rem;
  box-sizing: border-box;
  width: 100%;
}

.Files {
  hr {
    display: block;
    height: 0.0769rem;
    border: 0;
    border-top: 0.0769rem solid $clara-light-blue;
    margin: 0.3077rem 0;
    padding: 0;
  }
}

.Actions {
  display: flex;
  flex: 1;
  width: 100%;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 2.4615rem;
}

.Title {
  margin-bottom: 2.4615rem;
  color: $clara-brown-light;
}

.Filename {
  margin-bottom: 0.6154rem;
  font-size: 1.2308rem;
  color: $clara-brown-light;
}

.Row {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  height: 3.8462rem;
  padding: 0.6154rem;
  overflow: hidden;
  box-sizing: border-box;
}

.CheckIcon {
  opacity: 0.5;
  margin-left: 2.4615rem;
}

.ProgressWrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.botonera {
  margin: 1.5385rem auto 1.5385rem;
  display: flex;
  justify-content: flex-end;
  button {
    background: $clara-white;
    border: 0.0769rem solid $clara-light-blue;
    box-sizing: border-box;
    border-radius: 0.3077rem;
    -webkit-border-radius: 0.3077rem;
    -moz-border-radius: 0.3077rem;
    -ms-border-radius: 0.3077rem;
    -o-border-radius: 0.3077rem;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.2308rem;
    text-align: center;
    letter-spacing: 0.01em;
    padding: 0;
    min-width: 7.6923rem;
    height: 3.0769rem;
    margin-left: 0.7692rem;
    color: $color-label;
    &.nextButton {
      background: $clara-blue;
      border-radius: 0.3077rem;
      color: $clara-white;
      min-width: 8.4615rem;
      -webkit-border-radius: 0.3077rem;
      -moz-border-radius: 0.3077rem;
      -ms-border-radius: 0.3077rem;
      -o-border-radius: 0.3077rem;
    }
    &.cancelButton {
      min-width: 8.4615rem;
    }
  }
}

.Uploads {
  max-height: 14.8462rem;
  overflow: auto;
}