.Tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2.7rem 0 ;
    border-bottom: 1px solid #EAF1FE;
}

.Botonera{
    width: 100%;
    text-align: left;
    a {
        
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        margin-right:29px;
        color: #A9B0BC;
        cursor: pointer;
        &:hover{
            color:#2C74FF
        }
    }
    
}
.selected {
    color:#2C74FF!important;
}
.disabled{
    cursor: not-allowed!important;
    &:hover{
        color:#A9B0BC!important
    }
}
.ScrollButtons {
    display: flex;
    color: #CDCDCD;

    .Button {
        border: 1px solid #CDCDCD;
        background-color: white;
        width: 40px;
        height: 40px;
        text-align: center;
        display: inline-block;
        padding-top: 7px;
        font-size: 15px;
        border-radius: 50%;
        margin-right: 5px;

        &:hover {
            border: 1px solid #2C74FF;
            cursor: pointer;
        }
    }

    .Left {
    }

    .Right {

    }

    .Active {
        color: #787878;
        border: 1px solid #787878;
    }

    .Deactive {
        border: 1px solid #CDCDCD;
        color: #CDCDCD;

        &:hover {
            border: 1px solid #CDCDCD;
            cursor: default;
        }
    }
}
