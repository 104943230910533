.sizeListSmall {
  padding: 0 !important;
}

.sizeU {
  margin: 0;
  list-style: none;
    li::before {
    content: "\2022";
    color: black;
    font-weight: bold;
    display: inline-block;
    width: 2em;
    margin-left: -2.6em;
    margin-top: 0.3077rem;
  }
}