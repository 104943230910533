.headerIssues {
  margin-top: 50px;
  /* padding-left: 200px; */
  margin-left: 20px;
  width: 647px;
  text-align: left;
  box-shadow: 0px 2px 10px rgba(190, 192, 197, 0.35);
  border-radius: 4px;
  /* border: 1px solid; */
  background: #fff;
  border-bottom: 1px solid #e4e3e9;
}
.cardPrimary {
  border-left: 2px solid #fbbd2c;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(190, 192, 197, 0.35);
  border-radius: 4px;
  /* padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px; */
  //padding-bottom: 10px;
  margin-left: 25px;
  margin-right: 25px;
}
.AMBER {
  border-left: 2px solid #fbbd2c;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(190, 192, 197, 0.35);
  border-radius: 4px;
  /* padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px; */
  //padding-bottom: 10px;
  margin-left: 25px;
  margin-right: 25px;
}
.GREEN {
  border-left: 2px solid #71e096;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(190, 192, 197, 0.35);
  border-radius: 4px;
  /* padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px; */
  //padding-bottom: 10px;
  margin-left: 25px;
  margin-right: 25px;
}
.RED {
  border-left: 2px solid red;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(190, 192, 197, 0.35);
  border-radius: 4px;
  /* padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px; */
  //padding-bottom: 10px;
  margin-left: 25px;
  margin-right: 25px;
}
.UNKNOWN {
  border-left: 2px solid grey;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(190, 192, 197, 0.35);
  border-radius: 4px;
  /* padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px; */
  //padding-bottom: 10px;
  margin-left: 25px;
  margin-right: 25px;
}
.cardText {
  
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 177%;
  padding-top: 15px;
  padding-bottom: 29px;
  padding-right: 30px;
  padding-left: 30px;
  color: #a9b0bc;
  text-align: left;
  margin:0;
}
.cardTitle {
  
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #000000;
  padding-top: 20px;
  padding-bottom: 1px;
  padding-right: 267px;
  padding-left: 30px;
  text-align: left;
}
.h7 {
  
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */

  color: #192a3e;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 477px;
  overflow: hidden;
  display: inline-block;
}
.nameIssue {
  width: 100%;

  margin: 0;
  padding: 30px;
  display: inline-block;
  box-sizing: border-box;
}
.contentIssues{
  padding-bottom: 30px;
}

.issuesItem {
  
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  display: inline-block;
  width: 100px;
  text-align: right;
  color: #a9b0bc;
}
.issuesTotal {
  
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #a9b0bc;
  //margin-left: 328px;
  display: inline-block;
  width: 100px;
  padding: 6px 0;
}
.Issues {
  display: inline-block;
  width: 100%;
  height: calc(100% - 60px);
  overflow: hidden;
}
.ZoneMenu {
  width: 355px;
  background: #fff;
  height: 100%;
  border-right: 1px solid #e4e3e9;
  overflow: hidden;
  display: inline-block;
  padding: 40px 0 40px 0;
  box-sizing: border-box;
  overflow-x: hidden;
  .noHorizontalScroll {
    overflow-x: hidden !important;
  }
}
.ZoneIssues {
  width: calc(100% - 355px);
  display: inline-block;
  overflow: hidden;
  height: 100%;
}
.ZoneChartTitle {
  margin-bottom: 33px;
  padding: 0 26px 0 50px;
  box-sizing: border-box;
  h1 {
    line-height: 27px;
    margin: 0 0 10px 0;
    padding: 0;
  }
  h2 {
    
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #a9b0bc;
    margin: 0 0 0 0;
    padding: 0;
  }
}
.CategoryIssue {
  width: 277px!important;
  margin: 10px 30px 0 50px!important;
  height: auto!important;
  h1 {
    padding: 22px 0!important;
  }
  & > div {
    padding: 22px 0!important;
  }
  a {
    color: #a9b0bc;
    &:hover {
      color: #2c74ff;
    }
  }
}

.filterIcon {
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 20px;
  margin-left: 20px;
  i {
    cursor: pointer;
    background-color: #a9b0bc;
    display: inline-block;
    -webkit-mask-size: 19px 14px;
    width: 19px;
    height: 14px;
    -webkit-mask: url(../../../../images/filterIssues.svg) no-repeat center;
    display: inline-block;
    &:hover {
      background-color: #2c74ff;
    }
  }
}
.title {
  display: inline-block;
  padding: 22px 16px 22px 16px;
  margin: 0;
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid #e4e3e9;
  box-sizing: border-box;
  position: relative;
}
.h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 470px;
  color: #a9b0bc;
}
.issuesTotal {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */

  display: inline-block;
  margin: 0;
  padding: 0;
  width: 100px;
  text-align: right;
  color: #a9b0bc;
}
.LinkSelected {
  color: #2c74ff !important;
}
.ShowModal {
  width: 151px;
  height: 157px;
  margin: 0;
  padding: 0;
  border: 1px solid #e4e3e9;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.TitleModal {
  
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: #848f99;
  display: inline-block;
  padding: 23px 20px;
  width: 100%;
  box-sizing: border-box;
  a {
    float: right;
    width: 12px;
    height: 12px;
    cursor:pointer;
    &:before {
      content: " ";
      background-color: #848f99;
      display: inline-block;
      -webkit-mask-size: 12px 12px;
      width: 12px;
      height: 12px;
      -webkit-mask: url(../../../../images/close.svg) no-repeat center;
    }
    &:hover{
      &:before {
        background-color: #2c74ff;
      }
    }
  }
}
.BodyModal {
  width: 100%;
  margin: 0 20px;
}
.RowModal {
  
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #192a3e;
  margin-bottom: 13px;
}
.Check {
  width: 16px;
  height: 16px;
  border: 1px solid #a9b0bc;
  border-radius: 4px;
  display: inline-block;
  margin-right: 13px;
  cursor: pointer;
  &:before {
    content: " ";
  }
}
.CheckedRed {
  border-color: #ff606f;
  background: #ff606f;
  &:before {
    background-color: #fff;
    display: inline-block;
    -webkit-mask-size: 19px 14px;
    width: 16px;
    height: 14px;
    -webkit-mask: url(../../../../images/check.svg) no-repeat center;
  }
}
.CheckedYellow {
  border-color: #fbbd2c;
  background: #fbbd2c;
  &:before {
    background-color: #fff;
    display: inline-block;
    -webkit-mask-size: 19px 14px;
    width: 16px;
    height: 14px;
    -webkit-mask: url(../../../../images/check.svg) no-repeat center;
  }
}
.CheckedGreen {
  border-color: #71e096;
  background: #71e096;
  &:before {
    background-color: #fff;
    display: inline-block;
    -webkit-mask-size: 19px 14px;
    width: 16px;
    height: 14px;
    -webkit-mask: url(../../../../images/check.svg) no-repeat center;
  }
}
.issueMargin{
  margin-bottom: 20px;
  padding-bottom: 1.2rem;
}

.contanierUser{
  margin-left: 2rem;
  position: relative;
  bottom: 0.5rem;
  
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 138.05%;
  
  label{
  margin-right: 1rem;
  color: #BEC9D7;

  }
  
  span{
   color: #192A3E
  }

}

.positionIcon{
  bottom: 1.6rem;
  left: 32rem;
}