@import "../../../../../../../styles/variables/index.scss";

.containerRolesTab {
  display: flex;
  flex-direction: column;
  width: 95%;
  height: 24.4615rem;
  margin: 0 0 40px 0;
  .companyRolesContainer {
    padding-bottom: 46px;
    border-left: 1px solid $clara-light-blue;
    padding-left: 15px;
    position: relative;
    left: 2px;
    &:last-child {
      margin-bottom: 20px !important;
    }
    .companyTitle {
      .companyTitleText {
        font-family: "cl-regular";
        font-size: 16px;
        font-weight: 600;
        color: $clara-blue;
      }
      .lineDot {
        position: absolute;
        left: -18px;
        font-size: 20px;
        bottom: 0;
        color: $clara-light-blue;
      }
      .firstItem {
        background: white;
        height: 17px;
        bottom: 6px;
      }
      .holdingCompany {
        color: $clara-blue;
      }
      .notHoldingCompany {
        color: $clara-blue;
      }
      cursor: pointer;
      position: relative;
      margin-bottom: 1.5rem;
    }
  }
  .lastItem {
    border: none;
  }
  .dashLine {
    position: relative;
    left: 5px;
    bottom: 10px;
    border-left: 1px #eaf1fe solid;
  }
  .dashLineContent {
    position: relative;
    top: 10px;
    right: 2px;
  }
  .rolListContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    .rolList {
      display: flex;
      flex-direction: column;
      height: 14px;
      margin-right: 90px;
      ol {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
        padding: 0;
        margin: 0;
        position: relative;
        width: 45rem;
        li {
          list-style: none;
          display: list-item;
          margin-right: 79px;
          color: $clara-black;
          font-weight: 300;
          font-size: 12px;
        }
      }
    }
    .rolListTitle {
      margin-bottom: 1.5rem;
      white-space: nowrap;
      color: $clara-black;
      span {
        font-family: "cl-regular";
        font-size: 12px;
        font-weight: 400;
        color: $clara-dark-grey !important;
      }
    }
    .rolListText {
      font-family: "cl-regular";
      font-size: 12px;
      font-weight: 400;
      white-space: nowrap;
      color: $clara-dark-grey;
    }
  }
}
.responsibilitiesContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  .responsibilityList {
    display: flex;
    flex-direction: column;
    width: 29.25rem;
    height: auto;
    margin-right: 79px;
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      padding: 0;
      margin: 0;
      width: 45rem;
      li {
        position: relative;
        left: 13px;
        margin-bottom: 16px;
      }
    }
  }
  .responsibilityListTitle {
    margin-bottom: 10px;
    white-space: nowrap;
    span {
      font-family: "cl-regular";
      font-size: 12px;
      font-weight: 400;
      color: $clara-black;
    }
  }
  .responsibilityListText {
    font-family: "cl-regular";
    font-size: 12px;
    font-weight: 300;
    width: 14.375rem;
    color: $clara-dark-grey;
  }
}
.responsibility{
  margin-bottom: 1rem;
  
  span {
    font-family: "cl-regular";
    font-size: 12px;
    font-weight: 400;
    color: $clara-black;
  }
}