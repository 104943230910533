.ClaraAddInput {
  width: 100%;
  display: block;
  margin-bottom: 20px;
}
.Remove {
  color: #FF606F;
  cursor: pointer;
  width: 100%;
  text-align: right;
  display: block;
  &:before {
    content: " ";
    background-color: #FF606F;
    margin: 0 0 -3px 0;
    display: inline-block;
    margin-left: 2px;
    -webkit-mask-size: 14px 14px;
    width: 14px;
    height: 14px;
    position: relative;
    -webkit-mask: url(../../../../images/form_remove.svg) no-repeat center;
    margin-right: 8px;
  }
}
.Add {
  color: #2c74ff;
  cursor: pointer;
  width: 100%;
  display: block;
  &:before {
    content: " ";
    background-color: #2c74ff;
    margin: 0 0 -3px 0;
    display: inline-block;
    margin-left: 2px;
    -webkit-mask-size: 14px 14px;
    width: 14px;
    height: 14px;
    position: relative;
    -webkit-mask: url(../../../../images/form_plus.svg) no-repeat center;
    margin-right: 8px;
  }
}
.ZoneAddFields {
  width: 100%;
  h3 {
    
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    margin: 30px 0;
    padding: 0;
    color:#192A3E;
  }
}
.ZoneFields{
  width: 100%;
}