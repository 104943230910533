@import "./../../../styles/variables/index";
.linkedInButton {
  margin-top: 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  svg{
    margin-right: 10px;
  }

  button {
    background-color: #3079b1;
    width: 200px;
    font-family: "cl-regular";
    font-weight: 500;
    font-size: 14px;
    color: $clara-white;
    border-radius: 4px;
    height: 40px;
  }
}