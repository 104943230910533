@import "../../../../../../../styles/variables/index.scss";

.containerForm {
  //  max-height: 52.7692rem;
  overflow-y: hidden;
  padding-right: 14.5rem;
  padding-top: 3.3077rem;
  padding-left: 2.5385rem;
  padding-bottom: 9rem;
  width: 692px;
  overflow-x: hidden;
  //  border-top: 5px solid $clara-blue;
}

.containerButtons {
  padding-right: 3rem;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}

.firstTextContainer {
  margin-top: 18px;
  span {
    font-family: "cl-regular";
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}

.TextContainer {
  margin-top: 20px;
  font-family: "cl-regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
