@import "../../../../../styles/variables/index.scss";


.papper {
    padding: 1rem;
    box-shadow: none;
    box-sizing: border-box;
    outline: 1px solid $color-border-line !important;

    outline-offset: -1rem;
    background: transparent !important;
    display: block;
    outline: unset!important;
    
    border-radius: 0.7692rem;
    &:before {
        content: " ";
        transform: rotate(45deg) translate(calc(50% - 1px));
        width: 1rem;
        top: 0;
        
        height: 1rem;
        border-top: 1.6px solid $color-border-line;
        border-left: 1.6px solid $color-border-line;
        z-index: 2;
        background: $clara-white;
        display: block;
        position: absolute;
    }
    &.arrowCenter:before{
        left: calc(50% - 0.9231rem);
    }
    &.arrowLeft:before{
        left: 15%;
    }
    &.arrowLeft:before{
        right:  15%;
    }
}

.PopoverOption{
    display: inline-block;
    width: auto;
}
.containerElement {
    background: $clara-white;
    padding: 1.9rem 0.9rem;
    border:1px solid $clara-light-blue;
    border-radius: 10px;
}
.optionElement{
    background-color: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-height: 2rem;
    transition: all $default-transition-time;
    padding: 0 0.6rem;
    min-height: 1.9rem;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    &:hover{
        background-color: $clara-light-blue;
    }
}
