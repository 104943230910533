@import "../../../../../styles//variables/index.scss";

$border-radius-modal: 5px !default;
$border-with-modal: 0.5rem !default;
$max-height-modal: 51.9rem !default;
$height-modal-tour: 51.9rem !default;
$size-with: calc(86.9231rem + 3.0769rem);

.container-buttons-modal-tour {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 2.4rem;
  padding-right: 3.6rem;
  padding-top: 0.2308rem;
  height: 6.1538rem;
  border-top: 1px solid #eaf1fe;
  .container-steps-dots {
    max-height: -webkit-max-content !important;
    max-height: -moz-max-content !important;
    max-height: max-content !important;
  }
}

.container_modal_tour {
  .MuiDialog-paper {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-right: 3.0769rem;
    box-sizing: border-box !important;
    border-top: none;
    position: relative;
    box-shadow: none;
    background: transparent;
    overflow: hidden;
  }
  .MuiDialog-paperWidthMd {
    max-width: $size-with;
    box-sizing: border-box;
    overflow: visible;
  }
  .MuiDialog-scrollPaper {
    overflow-y: auto !important;
  }
}
.container-tour {
  border-top: $border-with-modal solid $clara-blue;
  border-radius: $border-radius-modal;
  box-sizing: content-box;
  max-width: 86.9231rem;
  background: #fff;
  box-shadow: $box-shadow !important;
  height: $height-modal-tour;
}

.container-blocks-modal-tour {
  height: $height-modal-tour - 0.9rem - 2.6rem - 2.6rem - $border-with-modal;
  box-sizing: border-box !important;
  width: 86.9231rem;
  display: grid;
  grid-template-columns: 45.3846rem 46.4615rem;
  grid-template-rows: 100%;
  overflow: visible;
}

.right-content-block-modal-tour {
  box-sizing: border-box;
  padding-top: 4.2rem;
  padding-bottom: 1.2rem;
  padding-left: 2.4rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  align-items: flex-start;
  overflow: visible;
}

.right-content-block-modal-tour-ilustration {
  overflow: visible;
  img,
  .lottie {
    position: relative;
    max-width: 80%;
    max-height: 80%;
  }
}

.left-content-block-modal-tour {
  padding-top: 6.6rem;
  padding-left: 4.9rem;
  padding-bottom: 1.2rem;
  padding-right: 2.4rem;
  height: 100%;
  box-sizing: border-box;
  align-items: center;

  > h1 {
    font-family: "cl-regular";
    margin: 0;
    color: $clara-black;
    padding-bottom: 2.4rem;
  }
  > p {
    font-family: "cl-regular";
    font-weight: normal;
    color: $clara-black;
    padding-bottom: 3rem;
  }
}

.custom_button_primary {
  margin-left: 1.8rem;
  &:disabled {
    opacity: 0.5;
    background: $bg-primary;
  }
}
