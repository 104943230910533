.InputContainer {
  display: flex;
  position: relative;
  width: 120% !important;
  text-align: left;
  .CalendarInput{
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    align-items: center;
    width: 83%;
    label{
      color: gray;
    }
    div{
      width: 380px;
      input{
        width:3000px;
        height:3rem;
      }
    }
  }
  .CalendarInputModal{
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    align-items: center;
    width: 83%;
    label{
      color: gray;
    }
    div{
      width: 13rem;
      input{
        width:3000px;
        height:3rem;

      }
    }
  }
  .TextInput {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    align-items: center;
    width: 83%;

    .TextInputChildren {
      margin-left: 10px;
    }

    label {
      color: gray;
      // margin-top: 30px;
      line-height:17px;
      display: inline;
      align-items: center;
    }

    input {
      font-size: 13px;
      width: 380px;
      height: 40px;
      border: 1px solid #c2cfe0;   
      border-radius: 5px;
      color: #192A3E;
      // margin: 20px 0;
      outline: none;
      &:disabled{
        background:#EAEAEA;
      }
      &:focus {
        border-color: #2c74ff;
      }
    }
    .ChatTextInput{
      position: relative;
      left: calc(50% - 90px);
    }
    .Error {
      border: 1px solid  #ff606f !important;
    }
  }
}
.paddingForLeftLabel{
  input{
  padding: 0 60px 0 60px;
}
}
.paddingForRightLabel{
  input{
  padding: 0 60px 0 20px;
  }
}
.calendarContainer {
  display: flex;
  position: relative;
  width: 120% !important;
  text-align: left;
  flex-direction: column;
  .CalendarInput{
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    align-items: center;
    width: 83%;
    label{
      color: gray;
    }
    div{
      width: 380px;
      input{
        width:3000px;
        height:3rem;

      }
    }
  }
  .CalendarInputModal{
    display: grid;
    margin-top: 8px;
    align-items: center;
    width: 83%;
    grid-template-columns: 20rem 1fr;
    grid-template-rows: auto;
    label{
      color: gray;
    }
    div{
      width: 13rem;
      input{
        width:3000px;
        height:3rem;
      }
    }
  }
  .TextInput {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    align-items: center;
    width: 83%;

    .TextInputChildren {
      margin-left: 10px;
    }

    label {
      color: gray;
      // margin-top: 30px;
    }

    input {
      font-size: 13px;
      width: 380px;
      height: 40px;
      padding: 0 60px 0 20px;
      border: 1px solid #c2cfe0;   
      border-radius: 5px;
      // margin: 20px 0;
      outline: none;
      &:disabled{
        background:#EAEAEA;
      }
      &:focus {
        border-color: #2c74ff;
      }
    }
    .ChatTextInput{
      position: relative;
      left: calc(50% - 90px);
    }
   
    
  }
}






.contentOnlyInput {
  position: relative;
  // display: block;
  // margin-top: 5px;

}

.rightLabel {

    position: absolute;
    right: 23px;
    top: 14px;
    
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 120%;
    align-items: center;
    text-align: right;
    color: #65707e;

}
.leftLabel {

  position: absolute;
  left: 23px;
  top: 14px;
  
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 120%;
  align-items: center;
  text-align: right;
  color: #65707e;

}

.MessageError {
  display: block;
  width: 100%;
  
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  text-align: right;
  color: #ff606f;
  max-width: 380px;
}

.MessageErrorDate {
  display: block;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  text-align: right;
  align-self: center;
  color: #ff606f;
  max-width: 380px;
  margin-left: 12.3rem;
}

.clearButton{
  background: url("../../../../images/close.svg") no-repeat center;
  width: 24px;
  height: 24px;
  content: " ";
  background-size: 16px;
  position: relative;
  right: 8%;
  top: 5px;
}
.Error {
  border: 1px solid red !important;
}
