@import "../../styles/variables/colors";
@import "~./../../styles/extends/positions.scss";
@import "~./../../styles/extends/scrollbar.scss";
.BlueScreenLayout {
  @extend .flex;
  @extend .flex-stretch;
  @extend .overflow-auto;
  width: 100%;
  height: 100%;
  min-width: 87.9rem;
}
.LeftPanel {
  flex-grow: 1;
  flex-basis: calc(100% - 49.2rem);
  background-color: $palette-clara;
  color: $clara-blue;
  @extend .overflow-hidden;
  @extend .scrollbar;
  position: relative;
  .circleImage{
    position: absolute;
  }
  .IconClara {
    padding: 1.7692rem 0rem 7.7692rem 1.7692rem;
    height: 2.3077rem;
    display: inline-block;
    text-align: left;
    width: 100%;
    z-index: 75;
    margin-top: 54px;
    margin-left: 78px;
    img{
      width: 114px;
      height: 43px;
    }
  }
}
.RightPanel {
  overflow-x: hidden;
  flex-grow: 0;
  background-color: $bg-white;
  width:49.2rem ;
  .CloseIcon {
    position: absolute;
    top: 2.2308rem;
    right: 2.3077rem;
    width: 0.9231rem;
    height: 0.9231rem;
    z-index: 1001;
  }
}
