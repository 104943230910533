.ContainerView{
    margin: 0;
    padding: 0;
    height: auto;
    width: 1050px;
    clear: both;
    position: relative;
    text-align: left;
    
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 40px;
    color: #65707e;

    display: grid;      
    grid-template-columns: 50% 50%;

}

.ContainerData{
    margin: 0;
    padding-right: 30px;

    position: relative;
    display: inline-block;
    clear: both;
    text-align: left;
    border-right: 1px solid #eaf1fe;
}
.ContainerDescription{
    display: inline-flex;
    flex-direction:column;
    
    span{
        color: #192A3E ;
        font-weight: 600;
        line-height: 20px;

    }
    a{
        margin-left: 5px;
        color:#2c74ff ;
        text-decoration: underline;

    }

}

.ContainerRow {
    margin: 0;
    padding: 0;
    height: auto;
    width: 100%;
    display: inline-block;
    label {
      width: 290px;
      display: inline-block;
    }
    span {
      //width: 380px;
      display: inline-block;
      color:#192A3E  ;
      ul {
        margin: 0;
        list-style-type: none;
        padding: 0;
        li {
          height: 20px;
        }
      }
    }
  }

  .status{
      &:before{
          content: ' ';
          display: inline-block;
          margin-right: 5px;
          width: 5px;
          height: 5px;
          position: relative;
          bottom: 2px;
          border-radius: 50%;

      }
      

  }

  .RED{
    &:before{
        background: #ff606f;
    }
  }
  .AMBER{
    &:before{
        background: #fcc72d;
    }
  }
  .GREEN{
    &:before{
        background: #71e096;
    }
  }
  .ResolutionButton {
    display: inline-block;
    margin: 10px;
    width: 174px;
    height: 40px;
    line-height: 40px;
    
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    align-items: center;
    letter-spacing: 0.01em;
    color: #fff;
    background: #2c74ff;
    border-radius: 4px;
}