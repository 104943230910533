.InputContainerCenterButton {
  display: inline-block;
  width: 100%;
  .TextInput {
    display: inline-block;
    width: 100%;
  }
}
.InputContainer {
  display: flex;
  position: relative;
  width: 120% !important;
  text-align: left;

  .TextInput {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    align-items: center;
    width: 83%;

    .TextInputChildren {
      margin-left: 10px;
    }

    label {
      color: gray;
      // margin-top: 30px;
    }

    input {
      font-size: 13px;
      width: 380px;
      height: 40px;
      padding: 0 60px 0 20px;
      border: 1px solid #c2cfe0;
      border-radius: 5px;
      // margin: 20px 0;
      outline: none;

      &:focus {
        border-color: #2c74ff;
      }
    }
    .ChatTextInput {
      position: relative;
      left: calc(50% - 90px);
    }
    .Error {
      border: 1px solid red !important;
    }
  }
}

.buttonContainers {
  position: relative;
  display: inline-block;
}

.buttonOption {
  width: 118px;
  height: 40px;
  text-align: center;
  margin-left: 10px;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #2c74ff;
  border-radius: 4px;
  border: 1px solid #2c74ff;
  background: #fff;
  &:focus{
    outline: 1px solid #2c74ff;
  }
}

.selectedButton {
  color: #fff !important;
  border: 1px solid #2c74ff !important;
  background: #2c74ff !important;
}

.largeTextButton {
  font-size: 11px;
}
.MessageError {
  display: block;
  width: 100%;
  
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  text-align: right;
  color: #ff606f;
  max-width: 380px;
}