.filepond--wrapper {
    width: 390px;
    height: 80px;
    margin-right: -10px;
}

.filepond--hopper {
    height: 80px;
}

.labelFile {
    display: flex!important;
    flex-direction: row!important;
    align-items: flex-start;
    justify-content: space-evenly;
}

.labelFile p {
    width: 250px;
    font-size: 13px;
}

.labelFileImg {
    margin: auto;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: blue;
}

.labelFileImg img {
    color: blue;
}

.filepond--root .filepond--drop-label {
    height: 80px;
    color: black;
    font-size: 18px;
}

.filepond--root .filepond--drop-label label {
    margin-top: -55px!important;
}


.filepond--panel-root {
    border-radius: 5px;
    background-color: #E9F2FF;
}

.filepond--label-action {
    color: blue;
    display: block;
    text-decoration: none;
    font-weight: bold;
    margin-top: 20px;
}

.filepond--file {
    position: static;
    display: flex;
    text-align: center;
    align-items: center;
    padding: 0.5625em 0.5625em;
    color: black;
    border-radius: 0.5em;
    background-color: #CECECE;
    border-color: transparent;
}

[data-filepond-item-state='processing-complete'] .filepond--file {
    background-color: #369763;
}

[data-filepond-item-state*='invalid'] .filepond--item-panel,
[data-filepond-item-state*='error'] .filepond--item-panel {
    background-color: #c44e47;
}

.filepond--file-info-sub {
    position: absolute;
    font-size: 20px;
    margin-top: 5px;
}

.filepond--file-info {
    font-size: 20px;
}

.filepond--file-status {
    margin-top: 10px;
    font-size: 20px;
}

.filepond--list {
    margin-right: 10px;
}

.filepond--item {
    display: block;
}

.filepond--file-status {
    margin-top: 10px;
}
