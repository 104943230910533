@import "./../../../../../styles/variables/colors.scss";
@import "../../../../../styles/variables/index.scss";

.containerUpgrade {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.upgradeTitle {
  padding: 32px 25px 0px 35px;
  font-weight: 400;
}
.containerCardsUpgrade{
    margin: 2.3rem 4.6rem;
    height: 55rem;
    display: flex;
    justify-content: space-around;

    .containerTwoCards{
        display: grid;
        grid-template-columns:362px 816px;
        grid-template-rows: auto;
        gap: 60px;
    }
}

.upgradeTitleProducts {
  margin-top: 4rem;
  font-size: 2.4615rem;
  color: $clara-upgrade-color;
  font-weight: 600;
  text-align: center;
}
.upgradeSubTitleProducts {
  margin: 2rem;
  font-size: 1.3846rem;
  color: $clara-black;
  font-weight: 400;
  text-align: center;
}

.containerListProductos {
  margin: 5rem 12.5rem;
}
.endData {
  margin: 0rem 12.5rem;
}
