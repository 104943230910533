@import "../../../styles/variables/index";
@import "~./../../../styles/extends/positions.scss";

.RadioCheckboxInput {
  width: 100%;
  .Options {
    @extend .flex;
    width: 100%;
  }
  .Option {
    @extend .flex;
    flex-basis: 100%;
    width: auto;
    justify-content: flex-start;
    align-items: center;
    height: 35px;
    min-height: 35px;
    :first-child {
      margin: 0;
      padding: 0 0.3846rem 0 0;
      width: auto;
    }
  }
}

.Horizontal {
  flex-direction: row;
}

.Vertical {
  flex-direction: column;
}

.Disabled {
  background-color: red !important;
}
