@import "./../../../../styles/variables/colors.scss";
.DropDownStartup {
    width: 20rem !important;
    border: 1px solid $clara-grey;
    height: auto;
    border-radius: 2.3077rem;
    position:absolute;
    top: 1rem;
    left: 100%;
    transform: translateX(calc(-100% - 1rem) );
    padding: 0.7692rem 0;
    &:hover {
        border-color: $clara-dark-blue;
    }
    .DropDownStartupButton{
        label{
            width: calc(100% - 2.3077rem ) ;
            text-transform: capitalize;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}
