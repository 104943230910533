@import "./../../../../styles/variables/colors.scss";

.SubscriptionDetails {
  max-width: 76rem;
  min-width: 55.2308rem;
  min-height: 22.3846rem;
  border-radius: 1.5385rem;
  -webkit-border-radius: 1.5385rem;
  -moz-border-radius: 1.5385rem;
  -ms-border-radius: 1.5385rem;
  -o-border-radius: 1.5385rem;
  border: 0.2308rem solid $clara-upgrade-color;
  background: url("../../../../images/SubscriptionDetails.svg") right bottom no-repeat;
  position: relative;
  .cornerContainer {
    display: flex;
    position: absolute;
    right: 0;
    .cornerDetail {
      width: 16.7692rem;
      height: 3.9231rem;
      background-color: #E96199;
      border-top-right-radius: 1.0769rem;
      justify-content: center;
      align-items: center;
      border-bottom-left-radius: 1.3846rem;
      display: flex;
      flex-direction: column;
      .textCorner {
        font-size: 1.0769rem;
        font-weight: 800;
      }
    }
    .cornerRounder {
      width: 1.3846rem;
      height: 3.9231rem;
      background-color: $clara-upgrade-color;
      .cornerAngle {
        width: 1.3846rem;
        height: 3.9231rem;
        background-color: white;
        border-top-right-radius: 1.3846rem;
      }
    }
  }
  .dataSpace {
    height: 100%;
    width: 44.6154rem;
    padding-left: 1.6154rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    .titleSpace {
      display: flex;
      flex-direction: row;
      height: 7.6923rem;
      align-items: center;
      .textTitle {
        font-size: 2.4615rem;
        font-weight: 700;
      }
      .textSubTitle {
        font-size: 1.3846rem;
        font-weight: bold;
        margin-left: 1.0769rem;
        margin-top: 0.4615rem;
      }
    }
    .listSpace {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, 1fr); 
      column-gap: 2.3077rem;
      button {
        width: max-content;
        margin-bottom: 0rem;
        position: absolute;
        right: 3rem;
        bottom: 3rem;
        width: 17rem;
        max-width: 19rem;
      }
    }
  }
}