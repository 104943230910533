.Paragraph {
  p {
    margin-bottom: 16px !important;
  }
}

.buttonCheckBox {
  width: 12rem;
  height: 10rem;
}

.CheckboxButton {
  [data-contentButton="true"] {
    width: 146px;
  }
}
