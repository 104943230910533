@import "./../../../../styles/variables/colors.scss";

.ClaraSelectContainer {
    width: 100%;
    display: block;
    text-align: left;
    span {
        position: relative;
        display: inline-block;
        text-align: left;
        hr {
            &:last-child {
                display: none;
            }
        }
    }
    .fakeCombo {
        width: 100%;
        height: 3.0769rem;
        border: 0.0769rem solid #c2cfe0;
        border-radius: 0.4615rem;
        align-items: center;
        display: flex;
        padding-left: 1.1538rem;
        color: #757575;
    }
}