.PhotoInput {
  justify-content: space-between;
  margin: 10px;
  position: relative;
  text-align: left;

  label {
    color: gray;
    margin-top: 15px;
  }

  .DivInput {
    font-size: 13px;
    width: 80px;
    height: 80px;
    border: 1px solid #C2CFE0;
    border-radius: 50%;
    margin: 20px 0;
    outline: none;
    margin-right: 300px;
    font-size: 50px;
    color: blue;
    display: flex;
    justify-content: center;
    position: relative;
    left: 70px !important;

    &:focus {
      border-color: #2C74FF;
    }

    &:hover {
      cursor: pointer;
    }

    img {
      width: 82px;
      height: 82px;
      border-radius: 50%;
    }
  }

  .ToolTipPhoto{
    margin-top: 20px;
  }

}
