.KnowHow{

}
.KnowHowBox{
    position: absolute;
    width: 310px;
    height: 362px;
    right: 30px;
    bottom: 120px;
    overflow: hidden;
    background: #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
}
.KnowHowBoxModal{
    position: relative;
    height: 432px;
    width: 310px;
    display: inline-block;
    overflow: hidden;
    background: #192A3E;
    box-shadow: unset;
    left:0;
    top:0;
}

.KnowHowButton {
    position: absolute;
    width: 60px;
    height: 60px;
    right: 30px;
    bottom: 30px;
    border-radius: 50%;
    background: #FFFFFF;
    box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.2);
    border: none;
    cursor: pointer;
    outline: none;
    &:hover{
        background: #e5e5e5;

    }
    z-index: 10;
}

.KnowHowImage {
    margin-right: 10px;
}
.KnowHowHeader{
    width: 100%;
    height: 54px;
    div{
        display:inline-block;
        width:50%;
        border: 1px solid #000;
        padding: 15px;
        box-sizing: border-box;
        height: 100%;
        img{
            display:inline-block;
            margin-right:5px;
        }
        label{
            
            font-size: 13px;
            font-weight: 600;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: #f7a1a1;
            color:#fff;
            display:inline-block;
            margin-top:4px;
        }
    }
}
.KnowHowSelect{
    border: 1px solid #f7a1a1!important;
}
.KnowHowSelectModal{
    width: 100%!important;
    border:none!important;
}

.KnowHowQuestion{
    width: 260px;
    height: 196px;
    margin: 25px;
    text-align: left;
    overflow-y: auto;
    overflow-x: show;
    ul{
        margin: 0;
        padding: 0 0 0 20px;
        color:#2C74FF;
        p{
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: normal;
            letter-spacing: 0.01em;
            color: #FFFFFF;
            border-bottom:1px solid rgba(228, 227, 233, 0.2);
            padding-bottom:22px;
            padding-top:22px;
            margin:0;
            cursor: pointer;
        }
        li{
            &:first-child{
                p{
                    padding-top:0;
                }
            }
        }

    }
}
.KnowHowFooter{
    p{
        color:#90A0B7;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: normal;
        letter-spacing: 0.01em;
        text-align: left;
        border-top:1px solid rgba(228, 227, 233, 0.2);
        padding:23px 25px;
        text-align: left;
        a{
            color:#2C74FF;
        }
    }
}
