@import "../../../../../../styles/variables/index.scss";

.contentView {
    width: auto !important;
    height: auto !important;
    border-top: $palette-clara 0.3077rem solid;
}

.ModalShare {
    width: auto !important;
    height: auto!important;
}

.ShareModalContainer {
    height: auto;
    label {
        color: $color-label;
    }
    h1 {
        // width: 549px;
        // height: 50px;
        // margin-left: 0;
        // margin-top: 0;
        // font-family: "cl-regular";
        // font-style: normal;
        // font-weight: normal;
        // font-size: 1.3846rem;
        // line-height: 3.8462rem;
        // color: $color-input;
    }
    .formContainer {
        display: flex;
        width: 95%;
        position: relative;
        bottom: 6.4615rem;
        left: 4.4462rem;
    }
}

.inputContainer {
    position: relative;
    bottom: 5.2308rem;
}

.roleSelectorContainer {
    margin-left: 2.6154rem;
    position: relative;
    bottom: 18rem;
    right: 4.4615rem;
    div {
        div {
            div {
                p {
                    position: relative;
                    right: 19.1538rem;
                    top: 2.0077rem;
                    z-index: 120;
                    width: 18rem;
                }
                div {
                    position: relative;
                    right: 27.1538rem !important;
                    top: 14.3923rem !important;
                    width: 34.6923rem;
                }
            }
        }
    }
}

.selectContainer {
    position: relative;
    z-index: 400;
    // div {
    //     div {
    //         div {
    //             span {
    //                 right: 3.8462rem;
    //                 z-index: 200;
    //                 width: 34.6923rem;
    //                 height: 3.0769rem;
    //                 span {
    //                     input {
    //                         width: 34.6923rem;
    //                         height: 3.0769rem;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }
}

.buttonsContainer {
    position: absolute;
    top: 40.1846rem;
    right: 4.6923rem;
}

.nameFieldContainer {
    z-index: 200;
    position: relative;
    right: 9.4392rem;
    margin: 0;
    top: -7rem !important;
    div {
        div {
            div {
                right: 0.5985rem;
                input {
                    width: 34.6923rem;
                }
            }
        }
        label {
            position: relative;
            left: 7.6923rem;
        }
    }
}

.scrollbar{
    width: 100%;
    height: 87%;
    box-shadow: 0px -5px 9px #828c98;
}

.selectRole{
    div{
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }
}

.buttonCheckBox{
    padding: 4rem;
}