.ContainerView {
    margin: 0;
    padding: 0;
    height: auto;
    width: 1000px; 
    clear: both;
    position: relative;
    display: block;
    text-align: left;
  
    
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 40px;
    color: #65707e;
  }
  .ContainerSearchAndTitle{
    display: flex;
    width: 100%;
    justify-content: space-between;
   
  }
  .containerFilterBox{
    width: 223px;
    height: 30px;
  
    justify-self: flex-end;
  }
  .titleTable{
      width: 200px;
    
  }
  .inputSearch{
    background: #EAF1FE;
  }
  .ContainerAdd{
    color: #2c74ff;
    width: 120px;
    margin-left: 25px;
    white-space: nowrap;
    margin-right: 10px;
    cursor: pointer; 
    position: absolute;
    right: 210px;
    top: -5px;
    &:before{
        
        content: " ";
        background-color: #2c74ff;
        display: inline-block;
        position: relative;
        right: 7.5%;
       -webkit-mask-size: 16px 16px;
        width: 12px;
        height: 12px;
       -webkit-mask: url(../../../../images/add_startup.svg) no-repeat center ;
       
    }
  
  }
  .searchAndAdd{
    display: inline-flex;
    align-items: center;
  }
  .ContainerData {
    margin: 0;
    padding: 0;
    height: auto;
         width: 700px;
    position: relative;
    display: inline-block;
    clear: both;
  }
  
  .ContainerDataTable {
    width: 100%;
  }
  .header{
    width: 34px;
    height: 13px;
    left: 752px;
    top: calc(50% - 13px/2 - 156px);
  
    
    font-style: normal!important;
    font-weight: normal!important;
    font-size: 11px!important;
    line-height: 13px;/* identical to box height */
    display: flex;
    align-items: center!important;

  }

  .EditIcon {
    a {
      &:before {
        -webkit-mask: url(../../../../images/edit.svg) no-repeat center !important;
      }
    }
  }
  .DeleteIcon {
    a {
      &:before {
        -webkit-mask: url(../../../../images/trash.svg) no-repeat center !important;
      }
    }
  }
  
  .DownloadIcon {
    a {
      &:before {
        -webkit-mask: url(../../../../images/download.svg) no-repeat center !important;
      }
    }
  }
.DocumentTable{
  th{
    display: flex!important;
  }
}
.uploadDocButton{
  right: 25%;
}
.Paper{
  background: red ;
}