.ContainerForm {
  display: grid;
  grid-template-columns: 20.6rem 29rem;
  column-gap: 1.8rem;
}

.TemplateLabel {
  grid-template-columns: 1fr 1fr !important;
}

.containerImages {
  display: flex;
  flex-direction: column;
}
