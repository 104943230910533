@import "./../../../../styles/variables/colors.scss";

.contentsState {
  width: 100%;
  height: 22.3077rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  .blueIcon {
    color: $clara-blue;
  }
  .titleFontStyle {
    margin-top: 0.9231rem;
  }
}

.textTitle{
  text-align: center;
  line-height: 1.3;
  display: block;
}