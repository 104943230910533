@import "../../../../styles/variables/index";

.Header {
  background: $bg-white;
  width: 100%;
  height: 5.39em;
  z-index: 14;
  border-bottom: 0.0769rem solid $bg-secondary-hover;
  display: grid;
  grid-template-columns: auto auto 7rem;
  grid-template-rows: 5.39em;
  grid-template-areas: "startup  menu user";
  .updateScale {
    display: grid;
    place-items: center;
    margin-left: 32px;
  }
  .update {
    display: grid;
    margin-left: 32px;
    column-gap: 24px;
    grid-template-columns: 1fr 1fr;
    place-items: center;
  }
  .startup {
    grid-area: startup;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .menu {
    grid-area: menu;
    display: flex;
    justify-content: flex-end;
    align-content: center;
    > div {
      background: transparent !important;
    }
  }
}

.greenButton {
  border-color: $clara-green-dark;
  color: $clara-green;
  width: 100%;
  margin-bottom: 0;
  &:hover {
    border-color: $clara-green-dark;
    color: $clara-green-dark;
    background: transparent;
    opacity: 0.8;
  }
}

.avatarSpace {
  width: auto;
  height: 100%;
  display: flex;
  margin-left: 2.3077rem;
  label {
    color: $clara-blue !important;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    cursor: inherit;
  }
}

.avatarCursorPointer {
  cursor: pointer;
}

.spaceBtns2 {
  height: 100%;
  color: $clara-blue !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.createborder {
  border-left: 0.0769rem solid $bg-secondary-hover;
  width: 0.0769rem;
  height: 2.4615rem;
}

.pointerArea {
  cursor: pointer;
}

.disableArea {
  color: $clara-grey !important;
}

.shareSpace {
  width: 3.0769rem;
  display: flex;
  justify-content: end;
}

.separator{
  border-right: 1px solid $clara-light-blue;
  height: 2.5315rem;
  align-self: center;  
}

.buttonSize {
  height: 35px;
  width: 75px;
  max-width: 75px;
  min-width: 75px;
  font-size: 12px;
}

.selectedTab {
  background-color: #EAF1FE;
  border-radius: 4px;
  padding: 0 16px;
  margin: 0 14px;
  height: 52px;
  >div {
    >label {
      color: $clara-blue;
    }
  }
}