.Arrow {
  color: #2c74ff;
}
.ArrowContainer {
  background-color: #eaf1fe;
  display: flex;
  align-items: center;
  padding: 0.1538rem 0.3846rem;
  height: 2.9231rem;
  width: 1.5385rem;
  margin-top: 0.8462rem;
  margin-bottom: 0.8462rem;
}
