.content{
  background: #FFF;
  width: 100%;
  height: calc(100% - 70px - 70px);
  overflow: auto;
  overflow-x: hidden;
  max-width: 100%;
}
.contentWithAssistance{
  width: calc(100% - 39px)!important;
}
.selectForm{
  background: #FFF;
}