.SecondaryButton{
    width: auto;
    height: 40px;
    
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #2c74ff;
    display: inline-block;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #EAF1FE;
    min-width: 120px;
}