@import "./../../../../styles/variables/colors.scss";
.PaymentView {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    > div {
        width: 50%;
        display: flex;
    }
   

    .blueZone {
        background: $clara-blue;
        display: grid;
        .circleImage{
            position: absolute;
            pointer-events: none;
            height: 100%;
          }
    }
    .paymentZone {
        background: $clara-white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        overflow-x: hidden;
    }
}

.ModalCheckout{
    width: 100%;
    height: 100%;
    display: none;
    
    div{
        width: 100%;
        height: 100%;
    }
    iframe{
        width: 100%;
        height: 100%;
        border: unset;
        padding: 2.1rem;
    }
}
.visible{
    display: block!important;
}