@import "./../../../styles/variables/colors";

.Country {
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    margin-right: 0.3rem;
    bottom: unset !important;
  }
  span {
    margin-left: 6px;
    width: auto;
    display: inline-block;
  }
}

.flag-default {
  background: url("../../../images/Clara-flag.png") no-repeat center;
  content: ' ';
}
