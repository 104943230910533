@import "./../../../../styles/variables/colors.scss";

.SquareBlock {
  width: 13.6923rem;
  min-height: 13.1538rem;
  border-radius: 0.3077rem;
  -webkit-border-radius: 0.3077rem;
  -moz-border-radius: 0.3077rem;
  -ms-border-radius: 0.3077rem;
  -o-border-radius: 0.3077rem;
  border: 0.0769rem solid $clara-input-outline-grey;
  padding-top: 2.3077rem;
  padding-bottom: 2.3077rem;
  padding-left: 0.9231rem;
  padding-right: 0.9231rem;
  display: flex;
  justify-content: center;
}

.payMode {
  border: 0.0769rem solid $clara-pay !important;
  background-color: $clara-pay-background !important;
}