@import "./../../../styles/variables/colors";
.IconBlock {
    display: grid;
    width: 100%;
    height: 2.1rem;
    grid-template-columns: 2.1rem 1fr;
    grid-template-rows: 0.5fr 0.5fr;
    column-gap: 0.7692rem;
    svg {
        path {
            color: $clara-blue !important;
        }
    }
    > div {
        justify-content: flex-start;
        align-items: center;
        white-space: nowrap;
        color: $clara-black;
    }
    :first-child {
        color: $clara-blue;
        grid-row: 1/3;
        width: 1.6rem !important;
        height: 1.6rem !important;
        grid-row: 1/3;
        align-self: center;
    }
    :last-child {
        width: 100%;
        height: 100%;
        color: $clara-dark-grey;
    }
}
