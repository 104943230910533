@import "./../../../../../../../styles/variables/colors.scss";

.HeaderCardStakeholder {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 3.3rem 1fr;
  column-gap: 1.2rem;
  row-gap: 0;
  position: relative;
  & > div:first-child {
    width: 3.3rem;
    height: 3.3rem;
  }
}

.HeaderTitle {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 0.6154rem;
  >div:first-child{
    width: calc(100% - 2.3077rem);
    overflow: hidden;
    height: 63px;
    h1{
      line-height: 1.5385rem;
      max-height: 38px;
    }
  }
}

@keyframes blink {
  0% {
    box-shadow: 0px 0px 0 #2d6907;
  }
  50% {
    box-shadow: 0px 0px 10px #2d6907;
  }
  100% {
    box-shadow: 0px 0px 0 #2d6907;
  }
}

.Icons {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  align-items: center; 
}


.lockBadge {
  position: absolute;
  top: -8px;
  left: -8px;
}
.ButtonViewProfie{
  display: flex;
  justify-content: flex-end;

}
.ZoneIcons{
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  & > div{
    font-size: 8px;
    font-weight: 300;
    color: $clara-grey;
    svg {
      height: 16px;
      width: 16px;
      margin-right: 7px;
      display: flex;
      justify-content: center;
      align-items: center;
      
        path {
          color: $clara-grey !important;
          fill: $clara-grey !important;
        }
      
    }
  }
}
.Cursor{
  cursor:pointer
}