@import "./../../../styles/variables/colors";
.Botonera {
    width: 100%;
    height: auto;
    margin-top: 1.9rem;
    display: flex;
    justify-content: flex-end;
    >*{
        margin-left: 1.2rem;
    }
}
