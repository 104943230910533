@import "./../../../../styles/variables/colors.scss";

.PaymentForm {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  > form {
    width: 100%;
    position: relative;
    height: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    > div {
      width: 38.1rem;
      &:first-child {
        margin-bottom: 2.1rem;
        margin-top: 2.1rem;
      }
    }
    .noMarginBottom {
      margin-bottom: 0;
    }
    h1 {
      margin-bottom: 1.5385rem;
      color: $clara-black;
      font-size: 1.6923rem;
    }
  }
}

.Botonera {
  width: 100%;
  margin-top: 2rem;
}

.Submit {
  position: relative;
  width: 100%;
  svg {
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px 20px 10px 0;
    box-sizing: content-box;
  }
}

.labelCheckBox {
  font-weight: 300;
  color: $clara-dark-grey;
  font-size: 0.8rem;
}

.rowAddCheckbox {
  height: 20px;
  margin-bottom: 1rem;
}

.ZipCodeRegion {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 40%;
  gap: 1.1538rem;
  > div {
    width: 100%;
  }
}

.LoadingButton {
  svg {
    padding: 0 !important;
  }
}

.Footer {
  font-weight: 400;
  font-size: 0.7692rem;
  color: $clara-dark-grey;
  text-align: center;
  width: 38.1rem;
  padding: 0 1.5385rem 1.5385rem;
}

.titleBilling {
  margin-bottom: 1.1538rem;
}

.paymentTerms {
  position: absolute;
  bottom: 16px;
  width: 485px;
}