.MissingInformation {
  width: 100%;
  margin: auto;
  padding: 17.6923rem 1.5385rem 1.5385rem 1.5385rem;
  box-sizing: content-box;
  height: 100%;
//  background: red;
  text-align: center;
  p {
    text-align: center;
  }

}

.Icon {
//  margin-left: 5rem;
}

.Title {
  font-size: 1.8462rem;
  color: darkgray;
  font-weight: bold;
}

.SubTitle {
  font-size: 1.1538rem;
  color: gray;
}
