@import "../../../../styles/variables/index.scss";
.DialogTitle {
  line-height: 4rem;
  border-width: 0px;
  left: 2rem;
  top: 0px;
  background: inherit;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: 'cl-regular-Regular', 'cl-regular', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.7rem;
  color: $clara-blue; // #2C74FF;
  text-align: left;
}
.DocumentPreview {
  position: relative;
  height: 100%;
  padding: 0 !important;
  width: 100%;
  .DocumentPreviewFile {
    // background-color: rgba(0, 0, 0, 0.78);
    background: $clara-preview-background;
    z-index: 500;
    position: relative;
    // height: calc(100% - 80px); // Generate documents with footer Buttons
    height: calc(100% + 2px); // Modal Preview
    // justify-content: center;
    // align-items: center;
    text-align: center;
    iframe {
      width: 100%;
      height: calc(100% + 21px);
      border: transparent;
      position: absolute;
      left: -1px;
      top: -1px;
    }
    img {
      max-width: 100%;
      max-height: 100%;
      // transform: scale(-50%, -50%);
      object-fit: cover;
    }
  }
  .DocumentPreviewBotonera {
    width: 100%;
    height: 80px;
    background: #ffffff;
    box-shadow: 0px -4px 10px rgba(125, 139, 156, 0.2);
    z-index: 199999;
    position: absolute;
    padding: 20px 130px;
    text-align: right;
    button {
      margin-left: 10px;
    }
  }
  .Background {
    background-color: #efefef;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
.DocumentsPreviewWithHeader {
  margin-bottom: 80px;
  height: calc(100% - 160px) !important;
}

.containerPreview {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 !important;
}
