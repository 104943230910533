.containerForm {
    margin-top: 1.5rem ;
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    label{
      line-height: 120%;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      /* Clara Dark Gray */
      color: #7d8b9c;
    }
  }
  .input{
    padding-left: 3rem;
  }
  .inputModal{
    padding-left: 0.8rem;
  }
  .containerButtons{
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
  }
  .errorForm {
    padding-right: 1rem;
  }
  
  
  .ContainerRow {
    //margin: 0 0 10px 0;
    padding: 0;
    height: auto;
    width: 100%;
    display: inline-block;
   
  }
  
  
  