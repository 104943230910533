.title_tour_content_left {
    > p {
        font-size: 3.6923rem !important;
        font-weight: 800 !important;
        margin: 0;
        font-family: "cl-regular";
    }
}
.text_tour_content_left {
    > p {
        font-size: 1.8462rem !important;
        font-weight: 400 !important;
        line-height: 38px;
    }
}
