.SearchStartUp {
  display: inline-block;
  width: 293px;
  text-align: center;
}
.ZoneSearchStartuo{
  border-bottom: 1px solid #e4e3e9;
}

.AddStartUp {
  text-align: left;
  border-bottom: 1px solid #e4e3e9;
  padding: 15px 20px;
  
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  position: relative;
  a {
      color:  #2c74ff;
    &:before {
      content: " ";
      -webkit-mask-size: 30px 30px;
      -webkit-mask: url(../../../../images/add_2.svg) no-repeat center;
      width: 30px;
      height: 30px;
      background:  #2c74ff;
    }
    span{
        margin: 7px 10px;
        display: inline-block;
    }
  }
}
.ListStartUps{
    max-height: 247px;
    width: 100%;
    padding: 20px;
    overflow: auto;
    a{
        width: 100%;
        margin: 0 0 20px 0;
        display: block;
        overflow: hidden;
        position: relative;
    }
    a:last-child{
      margin: 0;
    }
}
.selected{
    background: #2C74FF;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 14px;
}