.TextLoading {
    // width: 100%;
    display: grid;
    display: grid;
    grid-template-columns: auto auto;
    >*{
        justify-content: center;
        align-items: center;
    }
    div {
        width: 1.5385rem !important;
        height: 1.5385rem !important;
        margin-left: 0.7692rem;
        
        svg {
            color: #fff;
        }
    }
}

.text {
    position: relative;
    margin-right: 5px;
    top: 2px;
}