@import "../../../../style";

.ModalMenu {
  display: inline-block;
}

.Modal {
  max-width: 200px;
  height: auto;
  margin: 0;
  padding: 0;
  border: 1px solid #eaf1fe;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  max-height: 348px;
  overflow: hidden;
}

.Link {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  width: 100%;

  line-height: 16px;
  align-items: center;
  color: #a9b0bc;
  padding: 23px 41px 23px 20px;
  display: block !important;
  &:before {
    content: " ";
    cursor: pointer;
    -webkit-mask-size: 16px 16px;
    width: 16px;
    height: 16px;
    margin: 0 15px 0 0;
  }
  span {
    display: inline-block;
  }
}

.LinkSeparator {
  border-top: 1px solid #ebeff2;
}

.Arrow {
  position: absolute;
  .ArrowDiv span {
    content: " ";
    cursor: pointer;
    -webkit-mask-size: 16px 116px;
    -webkit-mask: url(../../../../images/arrow_down.svg) no-repeat center;
    width: 16px;
    height: 16px;
    margin: 0;
  }
  div {
    width: auto;
    height: auto;
    display: inline-flex;
    align-items: center;
  }
}

.Open {
  &:after {
    transform: rotate(0deg);
  }
}

.Close {
  &:after {
    transform: rotate(180deg);
  }
}

.CapTableComponent {
  width: 198px;
  padding: 0.9231rem;
  border-bottom: 1px solid #eaf1fe;
  &:hover {
    background-color: #eaf1fe;
    cursor: pointer;
  }
}

.ModalStyle {
  display: grid;
  min-height: 4.6rem;
  width: 16.5385rem;
  border: 1px solid #BEC9D7;
  border-radius: 2.7308rem;
  padding: 0.93rem;
  a {
    position: relative !important;
    display: grid;
    grid-template-columns: auto auto;
    gap: 1rem;
    > * {
      justify-content: center;
      align-items: center;
      display: flex;
      margin: 0;
      padding: 0;
    }
    > div {
      width: 100%;
      height: 100%;
    }
    .ArrowDiv {
    }
  }
}

.SelectTitle {
  color: #6a6c6e;
  padding-left: 20px;
  font-size: 14px;
}

.onlyvertical {
  overflow-x: hidden !important;
  div:last-child {
    border-bottom: none;
  }
}
