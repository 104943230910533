  .LegendDiv {
    display: grid;
    grid-template-columns: 2.5385rem 1fr;
    margin-bottom: 0.6154rem;
    .dotContainer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      div {
        border-radius: 50%;
        width: 0.6154rem;
        height: 0.6154rem;
      }
    }
    label {
      font-size: 0.9231rem;
    }
  }

