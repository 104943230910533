@import "./../../../../styles/variables/colors.scss";

.ScaleItem {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1.3846rem;
  .iconColor {
    color: $clara-black !important;
  }
  .textItem {
    margin-left: 1.0769rem;
    font-size: 1.3846rem;
    font-weight: 400;
    color: $clara-black;
  }
}