@import "../../../../../../styles/variables/index.scss";

.containerMessageTemplate {
    max-width: 65.3846rem;
    min-width: 38.4615rem;
    padding: 1rem 1.5rem;
    box-sizing: border-box;
    border: 1px solid $color-border-line;
    border-radius: 1.8462rem 1.8462rem 1.8462rem 0;
}

.containerStakeholderTemplate {
    label {
        color: $clara-blue;
        font-weight: 700;
    }

    ul {
        padding-inline-start: 0;
        list-style-type: none;
    }

    li {
        color: $color-text;
        line-height: 1.25rem;
        font-size: 0.9rem;
    }

    p {
        color: $color-text;
        margin: 1rem 0;
    }
}

.containerMessageTemplateWithIcon {
    width: max-content;

    position: relative;
    padding-left: 2.7692rem;
    padding-bottom: 1rem;
    display: inline-flex;
    flex-direction: column;
}

.containerIcon {
    position: absolute;
    bottom: 0;
    left: 0;
}

.containerAvatar {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateX(50%);
}
.date {
    color: $color-text !important;
    font-size: 0.7rem;
    span {
        color: $clara-black;
        font-weight: normal;
    }
}

.start {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.5rem;
}

.RED {
    &:before {
        color: $clara-red;
    }
}

.AMBER {
    &:before {
        color: $clara-yellow;
    }
}
.GREEN {
    &:before {
        color: $clara-green-remark;
    }
}

.UNKNOWN {
    &:before {
        color: $clara-grey;
    }
}
