@import "../../../../../../styles/variables/index";

.menuItem {
  height: 52px;
  display: grid;
  place-items: center;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  padding: 0 16px;
  position: relative;
}

.lastMenuItemMargin {
  margin-right: 32px;
}

.selectedTab {
  background-color: #EAF1FE;
  border-radius: 4px;
  color: $clara-blue;
}

.PopoverOption {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 !important;
  border-radius: 3px;
  box-shadow: 2px 5px 10px RGBA(0, 0, 0, 0.19);

  > div {
    &:hover {
      background-color: $clara-light-blue;
    }

    &:active {
      background-color: #B3CCFF;
    }
  }
}

.plusButton {
  margin: 0 16px;
  cursor: pointer;
}

.selectedPlusButton {
  >div {
    background-color: #266BEE;
  }
}

.PopoverMargin {
  margin-right: 168px;
}

.headerActionPanelButton {
  background-color: $clara-blue;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: grid;
  place-items: center;
  > span {
    text-align: center;
    font-size: 1.8rem;
    color: white;
    height: 100%;
    width: 100%;
    line-height: 1.15;
  }
}

.actionPanelOption {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 168px;
  height: 100px;
  color: $clara-blue;

  > div {
    height: 24px;
    width: 24px;
    margin-bottom: 10px;
  }

  > span {
    font-size: 14px;
    font-weight: 400;
  }
}

.actionPanelOption:has(div) {
  justify-content: center;
}
.TaksPending{
  background-color: #FFD6D6;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border-radius: 4px;
  background: #FF606F;
  position: absolute;
  right: 8px;
  top: 14px;
}