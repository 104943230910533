.zoneAdd {
  width: 100%;
  padding: 0;
  a {
    cursor: pointer;
    padding: 0.7692rem 1.9231rem 0.7692rem 3.8462rem;
    &:before {
      left: 1.9231rem;
      top: 0.9231rem;
    }
    &:hover {
      background: #eee;
    }
  }
}

.zoneList{
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  a {
    cursor: pointer;
    display: block;
    width: 100%;
    text-align: left;

    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 13px;
    color: #192a3e;
    padding: 0.7692rem 1.9231rem;
    &:hover {
      background: #eee;
    }
  }
}

.titleList{
    padding: 0.5rem 1.9231rem;
    font-size: 10px;
}

.zoneNewData {
  display: block;
  width: 100%;
  margin: 20px 0;
  position: relative;
}
.Separator {
  border: unset;
  height: 0.0769rem;
  background: #eaf1fe;
  margin: 0;
}
.inputLabel {
  width: 303px !important;
  display: inline-block;
  margin-right: 10px !important;
}
.buttonInputLabel {
  width: 67px !important;
  min-width: 67px !important;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
}
.Error {
  border: 1px solid #ff606f;
}
.MessageError {
  display: block;
  width: 100%;

  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  text-align: right;
  color: #ff606f;
}
.Show {
  display: block;
}
.Hide {
  display: none;
}
.Selected {
  background: #eee !important;
}
.Loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: rgba(226, 226, 226, 0.5);
  height: 100%;
  div {
    zoom: 0.5;
    top: 20px;
    position: absolute;
    left: 10px;
  }
}

.SelectAddInput{
    width: 100%;
}

