.container-RightContentTour {
    width: 100%;
    height: 100%;
    padding-right: 1rem;
    display: flex;
    box-sizing: border-box;
}
.image_tour {
    transform: scale(1);
    position: relative;
    left: 4rem;
}
