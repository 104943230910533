@import "./../../../../../../../../../../styles/variables/colors.scss";
.heightCheckboxButtonSimple {
  height: 100px;
}

.whoIsPep {
  display: flex;
  flex-direction: column;
  gap: 12px;
  line-height: 1.8462rem;
  font-size: 1.2308rem;
  color: $clara-black;
}