.ClaraSwitchButton {
  width: 100%;
  display: block;
  margin:20px 0;
  a {
    border: 1px solid #a9b0bc;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    display: inline-block;
    box-sizing: border-box;
    cursor: pointer;
    margin-right: 4px;

  }
}

.SelectedBLUE {
  background: #2c74ff;
  &:before {
    content:' ';
    background-color: #fff;
    display: inline-block;
    -webkit-mask-size: 14px 14px;
    width: 14px;
    height: 14px;
    -webkit-mask: url(../../../../images/check.svg) no-repeat center;
  }
}