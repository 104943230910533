.ADGMCheckboxContainer {
  display: flex;
  > div {
    flex-wrap: nowrap !important;
  }
}

.ADGMCheckboxButton {
  width: 332px;
}

.textPosition {
  >span {
    text-align: start;
  }
}