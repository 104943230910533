$clara-black: #192a3e;
$clara-blue: #2c74ff;
$clara-blue-over: #f4f8ff;
$clara-dark-grey: #828c98;
$clara-grey: #bec9d7;
$clara-light-blue: #eaf1fe;
$clara-light-grey: #dfdfdf;
$clara-preview-background: #f4f4f4;
$clara-red: #ff606f;
$clara-upgrade-color: #e96199;
$clara-upgrade-color-dark: #db568d;
$clara-green-generic: #18c5a8;
$clara-green-password: $clara-green-generic;
$clara-green-remark: $clara-green-generic;
$clara-green: $clara-upgrade-color;
$clara-yellow: #fcc72d;
$clara-orange: #FC9D2D;
$clara-white: #ffffff;
$clara-link: #2c74ff;
$clara-green-dark: #00b98f;
$clara-chat: #3c5fa0;
$clara-academy: #796efc;
$clara-dark-blue: #276bee;
$clara-gray-border: #dee5ed;
$clara-success-background: #d1f4ee;
$clara-brown-light: #555;
$clara-blue-disable: #99bbfd;
$clara-block-blue: #eaf1ff;
$clara-percent-bg: #f1f5fe;
$clara-percent-circle: #fac73f;
$clara-input-outline-grey: #c2cfe0;
$clara-pay: #ff6070;
$clara-pay-dark: #ec6e7a;
$clara-pay-background: #ffe1e4;
$clara-assistance-viewer-background: #ebf2fe;
$clara-drop-zone: #18c5a8;
$clara-unsigned: #ffdec0;
$clara-snackbar-error: #ffdfe2;
$clara-star: #796efc;
$clara-orange: #ff9753;
$clara-gray-text: #828c98;
$clara-error-file: #ff7e8b;
$clara-banner-background:#F4F3FF;
$clara-banner-detail: #796EFC;
$clara-track-progress: #D9D9D9;

$colors: (
  "black": $clara-black,
  "blue": $clara-blue,
  "blue-over": $clara-blue-over,
  "dark-grey": $clara-dark-grey,
  "grey": $clara-grey,
  "light-blue": $clara-light-blue,
  "light-grey": $clara-light-grey,
  "preview-background": $clara-preview-background,
  "white": $clara-white,
  "green-dark": $clara-green-dark,
  "pink": $clara-upgrade-color,
  "divider": $clara-light-blue,
  "dark-blue": $clara-dark-blue,
  "gray-border": $clara-gray-border,
  "gray-text": $clara-gray-text,
  "error": $clara-red,
  "error-file": $clara-error-file,
);
@each $name, $color in $colors {
  .text-color-#{$name} {
    color: $color;
  }
  .background-color-#{$name} {
    color: $color;
  }
  .border-color-#{$name} {
    border-color: $color;
  }
}
$colorsTemplate: (
  "primary": $clara-dark-grey,
  "secondary": $clara-blue,
  "error": $clara-red,
  "success": $clara-green,
  "warning": $clara-yellow,
  "alert": $clara-yellow,
  "chat": $clara-chat,
  "academy": $clara-academy,
  "back": $clara-black,
  "text-grey": $clara-gray-text,
);
@each $name, $color in $colorsTemplate {
  .text-color-#{$name} {
    color: $color;
  }
}
$backgroundTemplate: (
  "primary": $clara-dark-grey,
  "secondary": $clara-blue,
  "error": $clara-red,
  "success": $clara-green,
  "warning": $clara-yellow,
  "alert": $clara-yellow,
  "chat": $clara-chat,
  "academy": $clara-academy,
);
@each $name, $color in $backgroundTemplate {
  .background-color-#{$name} {
    color: $color;
  }
}
$borderTemplate: (
  "primary": $clara-dark-grey,
  "secondary": $clara-blue,
  "error": $clara-red,
  "success": $clara-green,
  "warning": $clara-yellow,
  "alert": $clara-yellow,
  "chat": $clara-chat,
  "academy": $clara-academy,
);
@each $name, $color in $borderTemplate {
  .border-color-#{$name} {
    border-color: $color;
  }
}

//Refactorizar con la intención de sacarlo
$clara-role-founder: #1d9ffc;
$clara-role-founder-over: #e8f5fe;
$clara-role-directors: #9146c3;
$clara-role-directors-over: #f4ecf9;
$clara-role-advisors: #62c2d7;
$clara-role-advisors-over: #e7f0f1;
$clara-role-employees: #ff4080;
$clara-role-employees-over: #ffebf2;
$clara-role-consultants: #fc7b1d;
$clara-role-consultants-over: #fef1e8;
$clara-role-investors: #fcc72d;
$clara-role-investors-over: #fef9ea;
$clara-role-shareholders: #f24c00;
$clara-role-shoreholders-over: #fdede5;
$clara-role-convertibleholders: #5c4ae5;
$clara-role-convertibleholders-over: #eeecfc;
$clara-role-warrantholders: #67ae3f;
$clara-role-warrantholders-over: #eff6eb;
$clara-role-optionholder: #a77647;
$clara-role-optionholder-over: #f6f1ec;
$clara-role-ip: #80eb9a;
$clara-role-ip-over: #f2fdf4;
$clara-role-thirdparty: #d43d5d;
$clara-role-thirdparty-over: #ffe4ea;
$clara-role-documents: #0abcc0;
$clara-role-documents-over: #e6f8f8;
$clara-role-ubo: #F43CF8;
$clara-role-ubo-over: #f9c4fa;

$palette-clara: #2c74ff;
$palette-error: #ff606f;
$palette-success: red;

$status-red: #ff606f;
$status-amber: #fbbd2c;
$status-green: #71e096;
$status-unknown: #a9b0bc;

$bg-primary: $palette-clara;
$bg-primary-hover: $palette-clara;
$bg-secondary: #fff;
$bg-secondary-hover: #ebeff2;
$bg-white: #fff;
$bg-border: #ebeff2;

$color-primary: #192a3e;
$color-secondary: #fff;
$color-label: #65707e;
$color-link: #848f99;
$color-input: #192a3e;
$color-disabled: #828c98;

$border-disabled: #eaf1fe;

$color-error: $palette-error;
$input-complete: #f4f8fe;

$green-checkout: #00b98f;

$color-error: $palette-error;
$input-complete: #f4f8fe;

$color-border-line: #eaf1fe;

$color-text: #828c98;

$black: #000;
