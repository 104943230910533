@import "./../../../../styles/variables/colors.scss";

.containerProduct {
  height: 6.813;
  display: grid;
  width: 100%;
  grid-template-columns: 3rem auto 10.025rem 10.025rem;
  grid-template-rows: 5rem;
  border-bottom: 1px solid $color-border-line;
  padding: 1.5rem 0;
  align-items: flex-start;
  .containerIcon{
    width: 1.5rem !important;
    height: 1.5rem !important;
  }
  .iconColor{
    height: 1.5rem;
    width: 1.5rem;
  }
  .containerProductDescription {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    .textItemTitle {
      font-size: 1.3846rem;
      font-weight: 600;
      color: $color-primary;
    }
    .textItemSubTitle {
      margin-top: 0.5rem;
      font-size: 1.3846rem;
      line-height: 1.3;
      color: $color-text;
      font-weight: 300;
      width: auto;
      max-width: 60rem;
    }
  }
}

.centerTag {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}