.Avatar {
  width: auto;
  display: flex;
  align-items: center;
  > div {
    width: 2.4615rem;
    min-width: 2.4615rem;
    height: 2.4615rem;
  }
  
}

.labelAvatar {
  margin-top: 0;
  margin-left: 10px;
  margin-right: 5px;
  font-weight: 600;
  color: gray !important;
  font-family: "cl-semibold";
}

.opacity {
  opacity: 0.5;
}

.CaptableStyle {
  font-weight: normal;
}

.link {
  color: #2c74ff !important;
}

.avatarPos {
  position: relative;
}
