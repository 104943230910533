@import "../../../../../../styles/variables/index.scss";

.containerForm {
    overflow: hidden;
    padding-right: 2.5rem;
    padding-top: 3.3077rem;
    padding-left: 2.5rem;
    padding-bottom: 6rem;
    width: 55rem;
}

.containerButtons {
    padding-right: 3rem;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
}

.title {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 120%;
    color: $clara-black;
}

.subTitle {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 138.05%;
    color: #65707e;
    text-align: left;
    margin: 10px 0 0 0;
}
