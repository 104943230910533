@use "sass:math";
@import "../../styles/variables/index.scss";
$size-logo-clara: 2.4615rem !default;

$size-circle-icon-onboarding-width: 66rem !default;
$size-circle-icon-onboarding-height: 63.8rem !default;

.circle-icon-onboarding {
    width: 66rem;
    height: 63.8rem;
    box-sizing: border-box;
    background: linear-gradient(230deg, #ffffff 20%, #0558e3 200%);
    border-radius: 50%;
    //border-left: 33rem solid #fff;
    opacity: 0.59;
    content: " ";
    display: block;
    position: relative;
    &:after {
        content: " ";
        width: 33rem;
        height: 33rem;
        background: #fff;
        display: block;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.container-onboarding-layout {
    position: relative;
    background: #f7faff !important;
    width: 100vw;
    height: 100vh;
}
.first-circle {
    top: -($size-circle-icon-onboarding-height/ 4.65);
    left: -33rem;
}
.second-circle {
    top: calc(100% - 33rem);
    left: calc(100% - 33rem);
    transform: rotate(150deg);
}
.clara-icon {
    top: 1.2rem;
    left: 2.4rem;
    z-index: 2;
}
