.buttonEditFloat {
  width: 125px;
  height: 40px;
  line-height: 40px;
  background: #ffffff;
  border: 1px solid #eaf1fe;
  box-sizing: border-box;
  border-radius: 4px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  text-align: left;
  cursor: pointer;
  outline: unset;
  span {
    display: inline-block;
    color: #65707e;
  }
  &:before {
    content: " ";
    background-color: #a9b0bc;
    display: inline-block;
    -webkit-mask-size: 16px 16px;
    width: 16px;
    height: 16px;
    -webkit-mask: url(../../../../images/edit.svg) no-repeat center;
    margin: 11px 10px 11px 10px;
    display: inline-block;
  }
}

.buttonEditOnKYC {
  top: 116px;
}
.buttonRemove {
  right: 15%;
  top: 116px;
  &:before {
    -webkit-mask: url(../../../../images/trash.svg) no-repeat center;
  }
}
.buttonSetPrimary {
  position: absolute;
  right: 10%;
  top: 38%;
  z-index: 2;
  background: white;
  text-align: left;
  color: #65707e;
  cursor: pointer;
  &:before {
    cursor: pointer;
    content: " ";
    background-color: #a9b0bc;
    display: inline-block;
    -webkit-mask-size: 16px 16px;
    position: relative;
    bottom: 12px;
    width: 16px;
    height: 16px;
    -webkit-mask: url(../../../../images/uncheckbox.svg) no-repeat center;
    margin: 11px 10px 11px 10px;
    display: inline-block;
  }
  &:after {
    content: " ";
    position: relative;
    bottom: 13px;
    right: 81%;
    display: inline-block;
    -webkit-mask-size: 16px 16px;
    width: 16px;
    height: 16px;
    // -webkit-mask: url(/static/media/checked.d1f56060.svg) no-repeat center;
    margin: 11px 10px 11px 10px;
    display: inline-block;
    z-index: 100;
  }
}
.movedPrimaryButton {
  right: 25%;
  top: 129px;
  height: 11px;
}

.buttonSelectedPrimary {
  position: absolute;
  right: 10%;
  top: 129px;
  height: 11px;
  z-index: 2;
  background: white;
  text-align: left;
  color: #65707e;
  cursor: default;
  &:before {
    cursor: default;
    content: " ";
    background-color: #2c74ff;
    display: inline-block;
    position: relative;
    bottom: 12px;
    -webkit-mask-size: 16px 16px;
    width: 16px;
    height: 16px;
    -webkit-mask: url(../../../../images/uncheckbox.svg) no-repeat center;
    margin: 11px 10px 11px 10px;
    display: inline-block;
  }
  &:after {
    content: " ";
    position: relative;
    bottom: 13px;
    right: 81%;
    background-color: #fff;
    display: inline-block;
    -webkit-mask-size: 16px 16px;
    width: 16px;
    height: 16px;
    -webkit-mask: url(../../../../images/checked.svg) no-repeat center;
    margin: 11px 10px 11px 10px;
    display: inline-block;
    z-index: 100;
  }
}
//-------------view kyc

.containerTitleAndButtons {
  display: inline-grid;
  grid-template-columns: auto 13rem 10rem 8.8125rem;
  grid-template-rows: 100%;
  width: 100%;
  align-items: center;
}

.buttonEditFloatKYC {
  width: 125px;
  order: 4;
  height: 40px;
  order: 4;
  line-height: 40px;
  background: #ffffff;
  border: 1px solid #eaf1fe;
  box-sizing: border-box;
  border-radius: 4px;
  z-index: 2;
  text-align: left;
  cursor: pointer;
  outline: unset;
  span {
    display: inline-block;
    color: #65707e;
  }
  &:before {
    content: " ";
    background-color: #a9b0bc;
    display: inline-block;
    -webkit-mask-size: 16px 16px;
    width: 16px;
    height: 16px;
    -webkit-mask: url(../../../../images/edit.svg) no-repeat center;
    margin: 11px 10px 11px 10px;
    display: inline-block;
  }
}

.buttonRemoveKYC {
  justify-self: right;
  margin-right: 1rem;
  &:before {
    -webkit-mask: url(../../../../images/trash.svg) no-repeat center;
  }
}
.buttonSetPrimaryKYC {
  z-index: 2;
  min-width: 13.4615rem;
  width: 125px;
  height: 40px;
  order: 2;
  line-height: 40px;
  background: white;
  order: 2;
  text-align: left;
  color: #65707e;
  cursor: pointer;
  &:before {
    cursor: pointer;
    content: " ";
    background-color: #a9b0bc;
    display: inline-block;
    -webkit-mask-size: 16px 16px;

    width: 16px;
    height: 16px;
    -webkit-mask: url(../../../../images/uncheckbox.svg) no-repeat center;
    margin: 11px 10px 11px 10px;
    display: inline-block;
  }
  &:after {
    content: " ";
    position: relative;
    bottom: 13px;
    right: 81%;
    display: inline-block;
    -webkit-mask-size: 16px 16px;
    width: 16px;
    height: 16px;
    // -webkit-mask: url(/static/media/checked.d1f56060.svg) no-repeat center;
    margin: 11px 10px 11px 10px;
    display: inline-block;
    z-index: 100;
  }
}

.buttonSelectedPrimaryKYC {
  height: 11px;
  min-width: 13.4615rem;
  z-index: 2;
  background: white;
  text-align: left;
  color: #65707e;
  order: 3;
  cursor: default;
  &:before {
    cursor: default;
    content: " ";
    background-color: #2c74ff;
    display: inline-block;
    position: relative;
    bottom: 12px;
    -webkit-mask-size: 16px 16px;
    width: 16px;
    height: 16px;
    -webkit-mask: url(../../../../images/uncheckbox.svg) no-repeat center;
    margin: 11px 10px 11px 10px;
    display: inline-block;
  }
  &:after {
    content: " ";
    position: relative;
    bottom: 52px;
    background-color: #fff;
    display: inline-block;
    -webkit-mask-size: 16px 16px;
    width: 16px;
    height: 16px;
    -webkit-mask: url(../../../../images/checked.svg) no-repeat center;
    margin: 11px 10px 11px 10px;
    display: inline-block;
    z-index: 100;
  }
}

//----------------------------
.AddZone {
  width: 150px;
  height: auto;
  line-height: 40px;
  background: #ffffff;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  a {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    display: block;
    color: #2c74ff;
    position: relative;
    &:before {
      content: " ";
      background-color: #2c74ff;
      margin: 0 0 -3px 0;
      display: inline-block;
      margin-left: 2px;
      -webkit-mask-size: 14px 14px;
      width: 14px;
      height: 14px;
      position: relative;
      -webkit-mask: url(../../../../images/form_plus.svg) no-repeat center;
      margin-right: 8px;
    }
  }
}
.TitleWithAvatar {
  line-height: 30px;
  font-size: 16px;
  margin: 0 0 30px 0;
  padding: 0;
  display: block;
  width: 100%;
  margin-bottom: 1.5385rem !important;
  label {
    margin: 0 0 0 20px;
    line-height: 30px;
  }
}
.SimpleAvatar {
  display: flex;
  align-items: center;
  width: 360px;
  img {
    margin-right: 0px;
  }
  label {
    font-weight: 700;
  }
}
.DoubleAvatar {
  display: flex;
  align-items: center;
  width: 360px;
  label {
    white-space: nowrap;
  }

  div:first-child {
    z-index: 10;
  }

  div:last-child {
    z-index: 5;
  }
}

.ContainerView {
  margin: 0;
  padding: 0;
  height: auto;
  width: 992px;
  clear: both;
  position: relative;
  display: block;
  text-align: left;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 40px;
  color: #65707e;
}
.ContainerData {
  margin: 0;
  padding: 0;
  height: auto;
  width: 700px;
  position: relative;
  display: inline-block;
  clear: both;
  text-align: left;
}

.ContainerDataRoles {
  margin: 0;
  padding: 0;
  height: auto;
  width: 100%;
  position: relative;
  display: inline-block;
  clear: both;
  text-align: left;
  padding-right: 15rem;
}

.containerTable {
  width: 100%;
  display: inline-flex;
  padding-right: 5rem;
}

.ContainerDataKYC {
  margin: 0;
  padding: 0;
  height: auto;
  width: 100%;
  position: relative;
  display: inline-grid;
  grid-template-columns: 12.75rem auto;
  grid-template-rows: 100%;
  clear: both;
  text-align: left;
}

.ContainerDataTable {
  width: 800px;
}

.ShareGrantView {
  border: 1px solid grey;
  padding: 10px;
  margin-bottom: 20px;
}

.ShareGrantTitleContainer {
  display: flex;
  justify-content: space-between;
  svg:hover {
    cursor: pointer;
  }
}

.ShareGrantTitle:not(:first-child) {
  margin-top: 30px;
}

.File {
  color: blue;
  &:hover {
    cursor: pointer;
  }
}
.ContainerRowMultiline {
  label {
    line-height: 18px;
  }
}

.ContainerRow {
  margin: 0;
  padding: 0;
  height: auto;
  width: 100%;
  display: inline-block;
  line-height: 18px;
  label {
    width: 290px;
    display: inline-block;
    margin-right: 45px;
  }
  span {
    //width: 380px;
    max-width: 355px;
    display: inline-block;
    ul {
      margin: 0;
      list-style-type: none;
      padding: 0;
      li {
        height: 40px;
      }
    }
  }
}
.ContainerDataPhoto {
  height: 50px;
  line-height: 50px;

  label {
    line-height: 50px;
  }
  span {
    //line-height: 50px;
    max-width: 350px;
    span {
      width: 32px !important;
      span {
        width: 32px !important;
        height: 32px;
        font-size: 10.6px;
        line-height: 32px;
      }
    }
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}
.Separator {
  border: unset;
  height: 0.0769rem;
  background: #eaf1fe;
  margin: 15px 0;
}
.modifiedSeparator {
  margin: 1px 0 15px 0;
  width: 155%;
}
.ContainerShareClass {
  border: 1px solid #eaf1fe;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 20px;
  position: relative;
  .ContainerData {
    padding: 0 20px;
  }
  .ShareGrantTitleContainer {
    padding: 20px;
  }
}
.ContainerGrant {
  border: 1px solid #eaf1fe;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 20px;
  position: relative;
  padding: 20px;
  .ContainerRow {
    span {
      width: 338px;
    }
  }
}
.FilterWidth {
  span {
    left: 31% !important;
    width: 100%;
  }
}

.GrantButtonOption {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
  a {
    border: none !important;
  }
}
.cellButton {
  text-align: center;
  div {
    text-align: center;
  }
  a {
    border: unset;
  }
}
.ContainerAvatar {
  & > span {
    label {
      margin: 0 0 0 20px;
      line-height: 40px;
    }
    span {
      text-align: center !important;
      width: 32px !important;
      height: 32px !important;
      span {
        width: 32px;
        padding: 0;
        height: 32px;
        line-height: 33px;
        font-size: 14px;
      }
    }
  }
}
.EditIcon {
  a {
    &:before {
      -webkit-mask: url(../../../../images/edit.svg) no-repeat center !important;
    }
  }
}
.DeleteIcon {
  a {
    &:before {
      -webkit-mask: url(../../../../images/trash.svg) no-repeat center !important;
    }
  }
}

.DownloadIcon {
  a {
    &:before {
      -webkit-mask: url(../../../../images/download.svg) no-repeat center !important;
    }
  }
}

.agentKYC {
  &:before {
    background: #fff;
    -webkit-mask: url(../../../../images/generate_document.svg) no-repeat center !important;
  }
}
.sendKYC {
  &:before {
    background: #fff;
    -webkit-mask: url(../../../../images/generate_document.svg) no-repeat center !important;
  }
}
.ContainerCheckOk {
  line-height: 24px;
  height: 24px;
  vertical-align: middle;
  span {
    //width: 380px;
    max-width: 440px;
    display: inline-block;
  }
  &:before {
    content: " ";
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url(../../../../images/checkOk.svg) no-repeat center;
    display: inline-block;
    margin: 0 10px 0 0;
  }
}
.signature {
  width: 106px;
  height: 26px;
  line-height: 40px;
  background: #2c74ff;
  border: 1px hidden;
  box-sizing: border-box;
  border-radius: 4px;
  position: absolute;
  align-items: center;
  right: 2px;
  bottom: 50px;
  z-index: 2;
  text-align: left;
  cursor: pointer;
  span {
    display: inline-block;
    color: #ffffff;
    position: relative;
    bottom: 9px;
    left: 5px;
  }
}

.ContainerRowTitle {
  color: black;

  font-weight: normal;
  margin-bottom: 20px;
}

.botoneraActions {
  position: relative;
  display: inline-block;
  div {
    margin-right: 10px;
  }
  button {
    background: #ffffff;
    border: 1px solid #2c74ff;
    box-sizing: border-box;
    border-radius: 4px;

    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.01em;
    color: #2c74ff;
    padding: 14px 20px 14px 20px;
    margin: 20px 20px 0 0;
  }
}

.Botonera {
  position: absolute;
  right: 0;
  top: 0;
  button {
    width: 118px;
    height: 40px;
    text-align: center;
    margin-left: 10px;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #65707e;
    border-radius: 4px;
    border: 1px solid #eaf1fe;
    background: transparent;
    &:first-child {
      background: #2c74ff;
      border: 1px solid #2c74ff;
      color: #fff;
    }
  }
}

.selectContainer {
  margin-bottom: 25px;
  div label {
    display: inline !important;
  }
  div span {
    text-align: right;
    position: absolute;
    display: inline-block;
    left: 46%;
  }
}

.pushedContainer {
  position: relative;
  display: inline-block;
  width: 100%;
}
.containerViewKYC {
  width: 100%;
  display: inline-flex;
  padding-top: 1rem;
  flex-direction: column;
  > div {
    margin: 0.5rem 0;
  }
}

.ContainerPushed {
  position: absolute;
  right: 0%;
  display: inline-block;
  margin: 25px 0 0 10px;
  width: 72%;
  top: 0%;
}

.entitiesContainer {
  position: relative;
  text-align: left;
  left: 0%;
  top: 0%;

  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  width: 23%;
  display: block;
  color: #828c98;
}

.entitiesContainerKYC {
  position: relative;
  text-align: left;

  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  padding-right: 1rem;
  display: block;
  color: #828c98;
}

.entityText {
  width: 77%;
  text-align: left;
  display: block;
  margin: 1px 0 2px 8px;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 180%;
  color: #192a3e;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.docButton {
  position: relative;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #2c74ff;
  cursor: pointer;
}

.textExtended {
  width: 30%;
}

.buttonModalOnActions {
  font-size: 14px !important;
  width: 100%;
}

.subCategoryEntity {
  margin-left: 25px;
}

.notClickable {
  cursor: default;
}

.selectedEntityText {
  color: #2c74ff !important;
}
.largeButton {
  margin-right: 20px;
}

.tableRow {
  width: 100%;
}

.tableColumn {
  width: 30%;
  color: black;
  display: inline-block;
  margin-left: 5px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.spacedSeparator {
  margin-top: 10px;
}

.firstSharesEditButton {
  position: absolute;
  left: 93%;
  top: 9px;
}

.secondSharesEditButton {
  position: relative;
  left: 93%;
  top: 5px;
}

.modifiedSeparatorShares {
  width: 120%;
}

.Pending {
  background: #fbbd2c;
}

.circle {
  -webkit-mask: url(../../../../images/ellipse.svg) no-repeat center !important;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin: 3px 5px 0 0 !important;
}

.letter {
  //width: 143px;
  //left: calc(50% - 143px/2 - 71.5px);
  //top: 35.26%;
  //bottom: -2.26%;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 13px !important;
  line-height: 32px !important;
  color: #848f99 !important;
  white-space: nowrap;
  table-layout: fixed;
  overflow: hidden;
}

.dsContainerVisible {
  border: 1px solid #eaf1fe;
  width: 30%;
  height: 10%;
  display: inline-block;
  padding: 7px;
  position: relative;
  border-radius: 6px;
  margin-left: 5px;
  label {
    width: 50px;
  }
}

.dsContainerHidden {
  display: none;
}

.ContainerRowParty {
  // margin: -10px 0 10px 5px;
  padding: 20px 0px 20px 0px;
  height: auto;
  width: 100%;
  display: inline-block;
  line-height: 18px;
  label {
    width: 290px;
    display: inline-block;
    margin-right: 45px;
  }
  span {
    //width: 380px;
    max-width: 355px;
    display: inline-block;
    ul {
      margin: 0;
      list-style-type: none;
      padding: 0;
      li {
        height: 20px;
      }
    }
  }
}

.Created {
  background: #848f99;
}

.Sent {
  background: #fbbd2c;
}

.Delivered {
  background: #fbbd2c;
}

.Completed {
  background-color: #71e096 !important;
}

.AuthorizedSignatoriesContainer {
  display: grid;
  grid-template-columns: minmax(11rem, 20rem) minmax(13rem, 20rem) minmax(12rem, 20rem);
  padding-right: 5px;
}

.AuthorizedSignatoriesItem {
  display: inline-flex;
}

.Signer {
  padding-bottom: 10px;
  margin-left: 42px;
}

.SignerTitle {
  font-size: 13px;
  // margin-left: 42px;
  margin-bottom: 5px;
}

.StatusTitle {
  font-size: 10px;
  padding-right: 5px;
  margin-right: auto !important;
}

.StatusValue {
  font-size: 13px;
  padding-right: 20px;
  white-space: nowrap;
  margin-right: auto !important;
}

.regularText {
  display: inline-block;
  color: black;
}

.ContainerNotEditable {
  margin-top: 15px;
}

.subEntityText {
  margin-left: 20px;
}

.containerModalIncorporation {
  display: inline-flex;
  flex-direction: column;
  padding: 2rem 2.5rem;
  width: 612px;
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 2rem;
  }
}

.ModalProposalAndEngagement {
  display: inline-flex;
  flex-direction: column;
  padding: 1rem 2.5rem;
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 5rem;
    width: 100%;
    margin-left: 1rem;
    &:before {
      content: " ";
      width: 1.2rem;
      height: 1.2rem;
      position: relative;

      top: 1.3rem;
      right: 1.8rem;
      background: url(../../../../images/checkOk.svg) no-repeat center !important;
      display: block;
      font-size: 18px;
    }
  }
  button {
    width: 12.625rem;
    align-self: flex-end;
  }
}
.containerButtons {
  margin-top: 2rem;
  display: inline-flex;
  justify-content: flex-end;

  button:first-child {
    background: #fff;
    color: #2c74ff;
    margin-right: 1rem;
    border: 1px #eaf1fe solid;
  }
  > button {
    min-width: 102px !important;
    padding: 0 1rem !important;
  }
}

.containerInput {
  width: 100%;
  height: 40px;
  display: inline-flex;
  margin-bottom: 1rem;

  justify-content: space-between;
  input {
    width: 321px;
  }
  label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #828c98;
    margin-right: 2rem;
    align-self: center;
  }
}

.readOnly {
  height: 40px !important;
  &:focus {
    border: 1px solid #c2cfe0 !important;
  }
}

.checkboxContainer {
  position: relative;
  left: 45%;
  margin-top: 10px;
}

.nameList {
  display: block;
  margin-bottom: -15px;
}

.SubContainerTitle {
  margin-top: 35px;
}

.agentSelector {
  span {
    right: 9px !important;
  }
}

.containerButtons {
  width: 100%;
  display: inline-flex;
  margin: 0 0 1rem 0;
}

.modalClient {
  display: inline-flex;
  flex-direction: column;
  padding: 2.5rem;
  width: 42.3077rem;
  height: 21.5385rem;
  justify-content: space-between;

  > div:first-child {
    display: inline-flex;
    flex-direction: column;
    > label {
      color: #2c74ff;
      margin-bottom: 3rem;
    }
  }

  h2 {
    font-family: cl-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    line-height: 29px;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }
}

.draftLabel {
  background: #ff606f;
  border-radius: 10px;
  padding: 2px 5px;
  color: #fff;
  font-size: 1.15rem;
}
.containerDraft {
  display: inline-flex;
  flex-direction: column;
  >label{
    margin-top: 0.5rem;
  }
}
