@import "../../../../styles/variables/index.scss";

.rootDialogContent{
    padding: 0 !important;
    max-width: 90vw !important;
    //border-top:  none !important;
    border-top: 5px solid $clara-blue !important;
    border-bottom-color: $clara-light-blue !important;
}

.extraWideModal{
    
}