@import "./../../../../../../styles/variables/colors";

.containerMessage {
  width: 100%;
  padding: 1rem;
  position: relative;
  display: block;
  padding-bottom: 0;
  margin-top: 1rem;
  .iconMessage {
    position: absolute;
    top: 0.1154rem;
    left: 0.1538rem;
  }
}

.containerCardMessage {
  width: 27.6154rem;
  border: 0.0769rem solid $color-border-line;
  box-sizing: border-box;
  border-radius: 0.3846rem;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1.1538rem auto 1.1538rem;
  grid-template-rows: 1.1538rem minmax(2.3077rem, auto) 1.1538rem;
  gap: 1rem 1rem;
}

.textMessage {
  order: 4;
  grid-column-start: 1;
  grid-column-end: 3;
}

.dateMessage {
  order: 7;
  grid-column-start: 1;
  grid-column-end: 3;
}

