@import "../../../../../styles/variables/colors.scss";

.labelAvatar{
  label {
    color: $clara-black !important;
  }
}

.labelLocked{
  color: gray  !important;
}

.filter {
  filter: grayscale(100%);
}

.claraIcon {
  width: 32px;
  height: 32px;
}

.claraLabel {
  font-weight: 600;
  color: #192a3e !important;
}