.DinamicBotonera{
  border-top: 1px solid #E4E3E9;
  padding:20px 0;
  width: 100%;
  text-align: center;
  span{
    text-align: center;
    margin: auto;
  }
  button{
    

    
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    height: 41px;
    min-width: 110px;
    display: inline-block;
    text-align: center;
    border: 1px solid;
    border-radius: 4px;
    margin-left: 15px;
    box-sizing: border-box;
   
    &:first-child{
      margin-left: 0;
    }
  }
}
.next{
    background: #2C74FF;
    color: #FFFFFF;
    border-color: #2C74FF;
}
.common{
  background: #FFFFFF;
  color: #A9B0BC;
  border-color: #A9B0BC;
}