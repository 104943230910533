@import "../../../styles/variables/index";
@import "~./../../../styles/extends/positions.scss";
.CardInput {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 1.5385rem;
  position: relative;
  > div {
    position: relative;
  }
  > div:first-child {
    grid-column: 1/3;
  }
  .afterComponent {
    width: 2.3077rem !important;
    height: 100%;
    margin: 5px;
  }
  .afterComponentCvv {
    width: 2.3077rem !important;
    height: 100%;
    padding: 5px;
  }
  .IconsCards {
    width: 6rem !important;
    display: flex;
    height: 2.7308rem;
    justify-content: flex-end;
    align-items: center;
  }
}
.InputCard {
  input {
    padding-right: 6rem !important;
  }
  div:nth-child(2) {
    width: 6rem !important;
  }
}
.classNameError {
  width: 100%;
  grid-column: 1/3;
  margin-top: -14px !important;
  span {
    width: 100%;
    grid-column: 1/3;
    display: inline-block;
  }
}
.CardStripeInput {
  width: 100%;
  display: block;
  position: relative;
  outline: none;
  height: 40px;
  font-size: 1rem;
  display: block;
  width: 100%;
  height: 40px;
  padding: 0.75rem 0.93rem;
  border: 1px solid #c2cfe0;
  color: #192a3e;
  border-radius: 0.3846rem;
  box-sizing: border-box;
  outline: none;
  font-family: inherit;
  font-weight: 300;
}
.CardStripeInputDisabled {
  background-color: #e9e9e9;
}

.CardStripeInputError {
  border: 1px solid #ff606f;
}

.StripeElementCvv input{
    color: blue!important;
}
.StripeElementCvv{
    color: green!important;
}