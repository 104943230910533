@import "../../../../../styles/variables/index.scss";

.input {
  width: 100%;
  border-radius: .4615rem;
  div {
    font-family: "cl-regular" !important;
    input {
      font-size: 1rem;
      display: block;
      width: 100%;
      height: 3.0769rem;
      padding: 0.75rem 0.93rem;
      border-left: 1px solid #c2cfe0;
      color: $color-input;
      box-sizing: border-box;
      outline: none;
      &:focus {
        border-color: $bg-primary;
      }
      &::placeholder {
        color: black;
        opacity: 0.5;
      } 
    }
  }
  div::after {
    content: none;
  }
  div::before {
    content: none;
  }
}

.dropdown {
  margin-left: .4615rem !important;
  div:nth-of-type(3) {
    border-radius: .4615rem;
    font-family: "cl-regular" !important;
    -webkit-border-radius: .4615rem;
    -moz-border-radius: .4615rem;
    -ms-border-radius: .4615rem;
    -o-border-radius: .4615rem;
    box-shadow: 0px 0.3077rem 0.7692rem rgba(0, 0, 0,0.1)!important;
    border: 1px solid #eaf1fe;
  }
}

.containerDropdownOn{
  border: 1px solid #c2cfe0;
  border-radius: .4615rem;
  position: relative;
  button{
    position: relative;
    left: 0;
    height: 3.0769rem;
    width: 3.0769rem;
    padding-right: .4615rem;
    box-sizing: content-box;
  }
  &>div> div> div{
    margin: 0!important;
  }
  &::before{
    content: ' ';
    position: absolute;
    left: 2.25rem;
    top: 1.3677rem;
    z-index: 2;
    width: 0; 
    height: 0; 
    border-left: .3077rem solid transparent;
    border-right: .3077rem solid transparent;
    border-top: .3846rem solid #000;
  }
}

.containerDropdownOff{
  border: 1px solid #c2cfe0;
  border-radius: .4615rem;
  position: relative;
}

.hasFocus{
  border-color: $bg-primary;
  width: 100%;
}

.notHasFocus{
  border-color: none;
  width: 100%;
}

.borderErrorInput {
  border-color: $color-error !important;
  &:focus {
    border-color: $color-error !important;
  }
}