@import "../../../../styles/variables/colors.scss";

.ClaraUploadContainer {
  width: 100%;
  display: block;
  text-align: left;
  label {
    color: $clara-dark-grey;
    text-align: left;
    line-height: 78px;
    width: 296px;
    display: inline-block;
  }
  span {
    position: relative;
    display: inline-block;
    text-align: left;
    // max-width: 380px;
    hr {
      &:last-child {
        display: none;
      }
    }
  }
}

.uploadZone {
  font-size: 13px;
  // width: 380px;
  height: auto;
  background: transparent;
  box-sizing: border-box;
}

.Dropzone {
  border: 3px solid #18c5a8;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.conentDrop {
  // width: 380px;
  height: auto;
  position: relative;
}

.DragActive {
  border: 3px solid #18c5a8;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.DropZone {
  font-size: 13px;
  width: 100%;
  height: 100%;
  border: 1px;
  border-color: $clara-blue;
  box-sizing: border-box;
  border-radius: 6px;
  border-style: dashed;
  position: relative;
  a {
    color: #828c98;
    // padding: 0 0 0 50px;
    padding: 0 0 0 0px;
    line-height: 40px;
    &:before {
      display: none;
      // width: 24px;
      // height: 24px;
      // top: 11px;
      // left: 15px;
      -webkit-mask: url(../../../../images/upload_24.svg) no-repeat center !important;
    }
  }
}

.Error {
  border: 1px solid #ff606f;
  padding: 3px;
  border-radius: 6px;
}

.MessageError {
  display: block;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  text-align: right;
  color: #ff606f;
  margin-top: 1px;
}

.NullResponse {
  color: blue;
  margin-top: 15px;
  margin-bottom: 10px;
  text-align: center;
  a {
    color: blue;
  }
}

.NullReponseLink {
  cursor: pointer;
  font-weight: bold;
  font-style: normal;
  text-align: center;
}

.browserText {
  color: $clara-blue !important;
}