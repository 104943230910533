@import "../../../../../styles/variables/index";
@import "~./../../../../../styles/extends/positions.scss";
.ScopingIntroView {
    width:100%;
    height:100%;
    display: block;
    position: relative;
    .tellmeMoreDiv {
      display: flex;
      justify-content: center;
      align-items: center;
      .tellmeMoreDivContent {
        display: inline-block;
        width: auto;
      }
      a {
        margin-left: 10px;
        color: gray;
        text-align: left;
      }
    }
    .introScoping {
      .titleScoping {
        margin-top:3.0769rem;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        width: 100%;
        color: #192a3e;
      }
      p {
        
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        margin: 41px 0 11px 0;
        color: #192a3e;
      }
      p.footerScoping {
        
        font-style: normal;
        vertical-align: initial !important;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        margin: 0;
        color: #192a3e;
      }
      button{
        margin:32px auto ;
        display: block
      }
      hr{
        color:#BEC9D7;
        background-color:#BEC9D7;
        height:1px;
        border-width:0;
      }
    }
    .containerLeft {
      position: relative;
      display: inline-block;
      text-align: left;
      width: 350px;
      margin-right: 150px;
      .pretitleContainer {
        position: relative;
        display: inline-block;
        text-align: left;
        
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        color: #276bee;
        p{
          text-align: left;
        }
      }
      .titleContainer {
        position: relative;
        display: inline-block;
        text-align: left;
        
        font-style: normal;
        font-weight: bold;
        font-size: 33.0831px;
        line-height: 130%;
        align-items: center;
        color: #276bee;
        p {
          text-align: left;
        }
      }
      .subTitleContainer {
        position: relative;
        display: inline-block;
        text-align: left !important;
        
        font-style: normal;
        font-weight: 300;
        font-size: 20.9333px;
        line-height: 150%;
        color: #192a3e;
        p {
          text-align: left;
        }
      }
      .buttonContainer {
        position: relative;
        display: inline-block;
        text-align: left;
        background: #ffffff;
        border: 1px solid #2c74ff;
        box-sizing: border-box;
        border-radius: 4px;
        
        font-style: normal;
        margin-right: 25px;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        align-items: center;
        text-align: center;
        letter-spacing: 0.01em;
        padding: 11px 40px 12px 40px;
        cursor: pointer !important;
        color: #2c74ff;
      }
    }
    .containerRight {
      display: inline-block;
      position: relative;
      width: 488px;
      text-align: right;
      .coupleImage {
        position: relative;
        display: inline-block;
        text-align: right;
      }
    }
  }
  .CloseChat{
    position: absolute;
    right: -100px;
    top: 65px;
  }