@import "../../../../../styles/variables/index";
.BotoneraIcons {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    width: auto !important;
    height: auto !important;
    padding: 0.5385rem !important;
    min-width: 0 !important;
    min-height: 0 !important;
    float: left;
}

.BotoneraIconsContent {
    outline: unset;
    div{
        outline: unset!important;
    }
    &.vertical {
        &.icons {
            width: 2.4rem;
            .BotoneraIcons {
                padding: 0 !important;
                border: unset;
                button {
                    width: 2.4rem !important;
                    height: 2.4rem !important;
                    margin:0;
                }
                div{
                    &.line{
                        margin: 0 0.2308rem;
                        height: 1px;
                        background-color: $clara-light-blue;
                        border: none;
                    }
                }
               
            }
        }
    }
    &.horizontal{
        &.icons {
            height: 2.4rem;
            width: auto;
            .BotoneraIcons {
                padding: 0 !important;
                border: unset;
                display: flex;
                >div{
                    width: 2.4rem !important;
                    height: 2.4rem !important;
                    display: inline-block;

                }
                button {
                    width: 2.4rem !important;
                    height: 2.4rem !important;
                    display: inline-block;
                }
                div{
                    &.line{
                        background-color: $clara-light-blue;
                        margin: 0.2308rem 0;
                        height: 1.94rem!important;
                        background-color: #EAF1FE;
                        border: none;
                        width: 1px !important;
                    }
                }
            }
        }
    }
}
