.TableShares {
  display: inline-block;
  width: 100%;
}

.HideRow {
  display: none !important;
}

.LastRow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  line-height: 1.2;
  height: 2.1538rem;
  margin-left: 0.3077rem;
  margin-right: 0.3077rem;
}

.Table {
  border: none !important;
  box-shadow: unset !important;
  max-width: 100%;
  overflow-x: hidden !important;
}

.Paper {
  border: none !important;
  box-shadow: unset !important;
  max-width: 100%;
  overflow-x: hidden !important;
}

.TableHeaderRow,
.TableRow {
  border: unset !important;
  height: 30px !important;
  font-size: 13px !important;
  font-weight: bold !important;
  position: relative !important;
  a {
    cursor: pointer;
  }
}

.TableHeaderCell {
  border: unset !important;
  border-bottom: 1px solid #f5f7f9 !important;
  height: 30px !important;
  font-size: 13px !important;
  font-weight: bold !important;
  vertical-align: top;
  img {
    margin-right: 10px;
    vertical-align: middle;
  }
}

.TotalsConvertiblesTableCell {
  color: grey !important;
  font-weight: 600 !important;
}

.TotalsConvertiblesTableCellNumber {
  font-weight: 600 !important;
}

.ShareIncentivePlanName {
  color: grey !important;
  font-weight: 600 !important;
}

.MissingConvertibles {
  margin-top: 20px;
  color: grey;
  p {
    display: inline;
    font-size: 14px;
    padding: 10px;
    line-height: 25px;
  }
}

.MissingShares {
  margin-top: 20px;
  color: grey;
  p {
    display: inline;
    font-size: 14px;
    padding: 10px;
    line-height: 25px;
  }
}

.TableCell {
  border: unset !important;
  border-bottom: 1px solid #e3e3e3 !important;
  height: 50px !important;
  font-size: 13px !important;
  vertical-align: top;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  &:first-child {
    padding-left: 0px !important;
  }
  .arrow {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
  .up {
    transform: rotate(180deg);
  }
  .down {
    transform: rotate(0deg);
  }
  img {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
  td {
    font-size: 15px !important;
    &:first-child {
      min-width: 400px !important;
    }
    &:not(:first-child) {
      color: gray !important;
      padding: 20px;
    }
  }
}

.ShareholdersHeader {
  background-color: #f5f7f9 !important;
  height: 65px !important;
  .TableCell {
    border-bottom: 1px solid #f5f7f9 !important;
    &:first-child {
      padding-left: 10px !important;
      color: #2c74ff !important;
      font-weight: 600;
    }
  }
}

.Clickable {
  &:hover {
    // cursor: pointer;
  }
}

.TableCellData {
  border: unset !important;
  border-bottom: 1px solid #f5f7f9 !important;
  height: 30px !important;
  font-size: 13px !important;
  vertical-align: top;
  background: rgba(247, 248, 249, 0.5) !important;
  font-style: normal;
  font-weight: 600 !important;
  &:first-child {
    padding-left: 11px !important;
  }
  background-attachment: fixed;
}

.TitleCell {
  border: unset !important;
  height: 30px !important;
  font-size: 11px !important;
  vertical-align: top;
  min-width: 170px !important;
}

.TotalRow {
  background-color: #f5f7f9;
  .TableCell:first-child {
    color: black;
    padding-left: 2.25em !important;
  }
  .TableCell {
    font-weight: bold;
  }
}

.prevButton {
  position: absolute;
  left: 0rem;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.nextButton {
  position: absolute;
  right: 0rem;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
