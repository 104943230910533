@import "../../../../styles/variables/index";

.skeletonDocument {
  width: 100%!important;
  height: 100%!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
  background-color: white;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;

  .SkelPos {
    position: relative;
  }
  .skeletoncap {
    width: 11.5rem;
    height: 10.2rem;
    position: absolute;
    top: 28px;
    left: 94px;
    display: flex;
    flex-direction: column;
    gap: 0.7692rem;
    justify-content: center;
  }
  .skeletoncap2 {
    width: 16.8769rem;
    height: 16.0769rem;
    position: absolute;
    top: 138px;
    left: 94px;
    display: flex;
    flex-direction: column;
    gap: 0.7692rem;
    background-color: $clara-white;
  }
}