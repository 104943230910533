
@import "../../../../styles/variables/index";
.leftEducationDisclaimer {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  z-index: 999;
  position: absolute;
  bottom: 20px;
  min-height: 50px;
  width: 70%;
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 167.58%;
    align-items: left;
    color: #ffffff;
  }
  p {
    font-style: normal;
    font-weight: 300;
    text-align: left;
    font-size: 11px;
    line-height: 167.58%;
    color: #ffffff;
  }
  a {
    color: #ffffff;
    text-decoration: underline;
   
  }
}

@keyframes onEntryDescription {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}
.LeftEducationFlex {
  display: grid;
  height: 100%;
  padding: 0;
  grid-template-rows: auto 1fr auto;
  overflow: hidden;
  width: 100%;
  font-size: 14px;
  line-height:1.714rem;
  gap: 1.1rem;
  
  a{
    text-decoration: none!important;
  }
  a:hover {
    
    text-decoration: none!important;
  }
  > * {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  h2 {
    margin: 0;
    color: white;
    text-align: left;
    overflow: visible;
    font-size: 24px;
    line-height: 30px;
    height: auto;
    font-weight: 600;
  }
  .EducationContainer {
    border-top: 1px solid $clara-white ;
    
    .ContentTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 11px 0;
      .Arrow {
        path{
          fill: $clara-blue;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }

    .ContentDescription {
      text-align: left;
      color: white;
      font-weight: lighter;
      height: 0;
      -webkit-animation: onEntryDescription 1s forwards; /* Safari 4+ */
      -moz-animation: onEntryDescription 1s forwards; /* Fx 5+ */
      -o-animation: onEntryDescription 1s forwards; /* Opera 12+ */
      animation: onEntryDescription 1s forwards; /* IE 10+, Fx 29+ */
      margin-top: 0;
      margin-bottom: 10px;
      a {
        color: #ffffff;
        font-weight: 600;
      }
    }
    .Visible {
      display: block;
    }
    .NoVisible {
      display: none;
    }
  }
}
