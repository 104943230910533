$size-with: calc(86.9231rem + 3.0769rem);

.containerModalOnboarding {
  .MuiDialog-paper {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-right: 3.0769rem;
    box-sizing: border-box !important;
    border-top: none;
    position: relative;
    box-shadow: none;
    background: transparent;
    overflow: hidden;
  }
  .MuiDialog-paperWidthMd {
    max-width: $size-with;
    box-sizing: border-box;
    overflow: visible;
  }
  .MuiDialog-scrollPaper {
    overflow-y: auto !important;
  }
}

.containerModalHowToUse {
  .MuiDialog-paper {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-right: 3.0769rem;
    box-sizing: border-box !important;
    border-top: none;
    position: relative;
    box-shadow: none;
    background: transparent;
    overflow: hidden;
  }
  .MuiDialog-paperWidthMd {
    max-width: $size-with;
    box-sizing: border-box;
    overflow: visible;
    width: auto !important;
    height: auto !important;
  }
  .MuiDialog-scrollPaper {
    overflow-y: auto !important;
  }
}