@import "./../../../../../styles/variables/colors.scss";

.containerListData {
  width: 400px;
  border-radius: 1.25rem;
  -webkit-border-radius: 1.25rem;
  -moz-border-radius: 1.25rem;
  -ms-border-radius: 1.25rem;
  -o-border-radius: 1.25rem; 
  margin-top: 80px;
  height: 343px;
  position: relative; 
  .content{
    padding: 28px 24px;
    width: 100%;
    height: 100%;
    border: 0.2308rem solid $clara-upgrade-color;
    background-color: #FFF;
    position: absolute;
    left: 0 ;
    top: 0;
    z-index: 1;
    border-radius: 1.25rem;
  }
  .rocket{
    position: absolute;
    width: 210px;
    top: -119px;
    right: -80px;
    transform: scaleX(-1);
    z-index: 0;
  }
  .clouds{
    position: absolute;
    width: 210px;
    left: -65px;
    bottom: -34px;
    z-index: 2;
  }
}

.listItems {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}