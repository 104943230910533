@import "../../../../../../../styles/variables/index.scss";

.headerColumnTable {
  padding: 0.75rem 0;
  color: $clara-dark-grey;
  font-size: 0.9231rem;
  font-weight: 400;
  padding-bottom: 1.9rem;
  padding-top: 0;
}

.headerColumnTableCenter {
  padding: 0.75rem 0;
  color: #828c98;
  font-size: 0.9231rem;
  font-weight: 400;
  padding-bottom: 1.9rem;
  padding-top: 0;
  display: flex;
  justify-content: center;
}

.coltext {
  color: $clara-black;
}
.colDisabled{
  color: $clara-grey;
}
.colFlexContent {
  display: flex;
  gap: 0.5rem;
}
.coltextCenter {
  color: $clara-black;
  display: flex;
  justify-content: center;
  width: 100%;
}

.containerRolesTab {
  width: 98%;
  height: 100%;
  position: relative;
  bottom: 1.7692rem;
}

.containerTable {
  // margin-top: 1rem;
  padding-top: 1rem;
  table thead {
    tr:first-child {
      display: none;
      th {
        display: none;
      }
    }
    tr {
      height: 10px;
      th {
        position: relative;
        top: 22px;
      }
    }
  }
}