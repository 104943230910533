@import "../../../../../../styles/variables/index";

.containerNoFilesUpload {
    display: flex;
    flex-direction: column;
    gap: 1.0769rem;
    div{
        display: flex;
        justify-content: center;
        gap:7px;
    }
    p{
        height:1.0769rem
    }
    span:hover{
        cursor:pointer;
    }
}

.text {
    grid-column-start: 1 ;
    grid-column-end: 7 ;
    color: $clara-black;
    margin: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.0769rem !important;
    > span {
        text-decoration: none;
        color: $clara-blue;
        cursor: pointer;
        &:hover {
          text-decoration: underline !important;
        }
    }
}