@import "./../../../../styles/variables/colors.scss";

.Map {
  width: 100%;
  height: 100%;
  background: transparent;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.ZoneMap {
  background: transparent;
  min-width: 80rem;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  padding: 1.5385rem 0 3.5385rem;
  height: auto;
}
.ContentGraph {
  min-width: 80rem;
  display: flex;
  position: relative;
  justify-content: space-around;
  > div {
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .ColumnGroupCompany {
    width: 24.9rem;
  }
  .ColumnRol {
    width: 15.3rem;
  }
  .ColumnStakeholder {
    width: 20rem;
  }
}
