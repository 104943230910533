.leaseDate {
    display: flex;
    column-gap: 40px;
}

.shortInput {
    max-width: 173px;
}

.dateInput {
    width: 152px;
}

.shortLabel {
    grid-template-columns: 5.8rem 1fr;
}

div:has(> div.documentUploaderFullLength){
    width:100%;
}

.documentUploaderFullLength {
    > span {
        width: 100%;
        max-width:100%;

        > div {
            width: 100%;

            > div {
                width: 100%;

                > div{
                    width: 100%;
                    
                    > div {
                        width: 100%;
                    }
                }
            }
        }
    }
}