@import "../../../../styles/variables/colors";

.DropDown {
  width: 100%;
  height: 100%;
  
  .Button {
    height: 100%;
    width: 100%;
    background: transparent;
    border-radius: 4px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover{
      background-color: rgba(0, 0, 0, 0.04);
    }
    svg{
      margin: 0;
    }
  }
}

.Popover {
  & > div {
    min-width: 8.8462rem !important;
  }
  ul {
    li {
      width: 100%;
      text-align: left;
      svg,
      img,
      i {
        margin-right: 1.2308rem;
      }
    }
  }
}

.Paper {
  overflow: hidden !important;
  max-height: 600px !important;
  ul {
    border: unset !important;
  }
}

.papper {
  box-sizing: border-box;
  border: 1px solid $color-border-line !important;
  overflow: visible !important;
  display: block;
  box-shadow: none !important;
  padding: 0 !important;
  margin-top: -0.5rem;
  width: 206px !important;
  max-height: 500px !important;
  & > span {
    transform: rotate(45deg) translate(calc(50% - 1px));
    width: 1rem;
    top: -0.9231rem;
    right: 1.5rem;
    height: 1rem;
    border-top: 1.6px solid $color-border-line;
    border-left: 1.6px solid $color-border-line;
    z-index: 2;
    background: #fff;
    display: block;
    position: absolute;
    z-index: 0;
  }
}

.papperSpace {
  box-sizing: border-box;
  border: 1px solid $color-border-line !important;
  overflow: visible !important;
  display: block;
  box-shadow: none !important;
  padding: 0 !important;
  margin-top: -0.5rem;
  width: 206px !important;
  max-height: 366px !important;
  margin-top: 7px;
  & > span {
    transform: rotate(45deg) translate(calc(50% - 1px));
    width: 1rem;
    top: -0.9231rem;
    right: 1.5rem;
    height: 1rem;
    border-top: 1.6px solid $color-border-line;
    border-left: 1.6px solid $color-border-line;
    z-index: 2;
    background: #fff;
    display: block;
    position: absolute;
    z-index: 0;
  }
}

.ListMenu {
  min-width: 16.2308rem;
  border-radius: 4px;
  border: unset;
  color: #bec9d7;
  margin: 0 !important;
  padding: 0 !important;
  background-color: transparent;
  z-index: 2;
  li {
    border-bottom: 1px solid #eaf1fe;
    display: grid;
    grid-template-columns: 36px 1fr;
    align-items: center;
    justify-content: start;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 16px;
    padding-right: 16px;
    color: $clara-blue !important;
    label {
      cursor: pointer !important;
      font-size: 12px;
    }
    &:hover {
      background-color: $clara-light-blue !important;
    }
  }
  li:last-child {
    border-bottom: unset;
  }
}

.arrow {
  // position: absolute;
  svg {
    margin-left: 0.9231rem;
    transition: all 0.5s;
    color: $palette-clara;
  }
}

.open {
  svg {
    // transform: rotate(180deg);
  }
}

.onlyvertical {
  overflow-x: hidden !important;
}