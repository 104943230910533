@import "./../../../../../styles/variables/colors.scss";

.MoneyValue{
  line-height: 1.3rem!important;
}
.Scrollbar{
  // height: 100%!important;
  // max-height: 308px;
  height: 300px;
  padding-right: 16px !important;
}