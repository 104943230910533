@import "./../../../../../../styles/variables/colors.scss";

.ModalShare {
  background: transparent;
  width: 47.1rem;
  padding: 3.2rem 3.1rem 1.9rem 3.1rem;
  min-width: 47.1rem;
}

.modalContainer {
  width: 47.1rem;
}

.reasonContainer {
  margin-top: 2rem;
}

.checkboxContainer {
  font-family: "cl-regular";
  font-size: 14px;
  line-height: 1.714rem;
  font-weight: 400;
  margin-bottom: 3.6875rem;
  color: $clara-dark-grey;
  display: flex;
  div:first-child {
    height: 16px;
    margin: 1.8px 2px 0 0;
    div:last-child span {
      position: absolute;
      top: 40px;
      left: 451px;
      width: 200px;
    }
    div:last-child svg {
      position: absolute;
      top: 40px;
      left: 650px;
    }
    label {
      grid-gap: 0;
      gap: 0;
    }
  }
}

.textCheckbox{
  color: #192a3e;
}