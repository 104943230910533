.FormSPVInADGMCheckboxContainer {
  display: flex;
  > div {
    > div {
      width: 100%;
    }
  }

  > div:last-child {
    margin-bottom: 20px;
  }
}

.FormSPVInADGMCheckboxButton {
  width: 100%;
}

.FormSPVInADGMCheckboxButtonText{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  padding-left: 26px;
  
  >span {
    text-align: start;
  }
}

.FormSPVInADGMCheckboxButtonMultipleListContainer {
  display: grid;
  grid-template-columns: 1.6fr 1.2fr;
  grid-gap: 1rem;

  >span {
    text-align: start;
  }
}

.FormSPVInADGMCheckboxButtonMultipleListItem {
  text-align: start;
}

.FormSPVInADGMCheckboxButtonMultipleListTitle {
  margin-bottom: 10px;
}

.FormSPVInADGMCheckboxCheckboxButtonList {
  margin-left: 10px;

  .FormSPVInADGMCheckboxCheckboxButtonListOption{
    display: flex;
    column-gap: 10px
  }
}