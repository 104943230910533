.containerShareForm {
    padding: 34px;
    min-width: 550px;
    min-height: 270px;
    h1 {
      
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 110.93%;
      color: #192a3e;
      margin: 0 0 30px;
    }
    h2 {
      
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      color: #ff606f;
    }
    .botonera {
      margin:  0;
      text-align: left;
      button {
        background: #2C74FF;
        border: hidden;
        box-sizing: border-box;
        border-radius: 4px;
  
        
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.01em;
        padding: 16px;
        min-width: 120px;
        margin-right: 10px;
        /* Clara Dark Gray */
  
        color: #fff;
        &.nextButton {
          background: #fff;
          border: 1px solid #EAF1FE;
          border-radius: 4px;
          color: #2C74FF;
        }
      }
    }
  }

.containerTextField{
 
  min-height: 90px;
  margin-bottom: 20px;
  
}
.ContainerClaraSelect{
  margin-bottom: 5px;
  padding: 0;
  & > div {
    margin: 0;
    label {
      width: 180px;
      margin: 0 20px 0 0;
      display: inline-block;
    }
    input {
      width: 380px;
      margin: 0;
    }
  }
  & > label {
    width: 180px;
    margin-right: 20px;
    display: inline-block;
  }

  & > span {
    width: 380px;
    display: inline-block;
    ul {
      margin: 0;
      list-style-type: none;
      padding: 0;
      li {
        height: 20px;
      }
    }
  }
}
.clearButton{
  position: relative;
  z-index: 10;
  bottom: 40px;
  left: 400px;
  width: 24px;
  height: 24px;
  
  background: url("../../../../images/close.svg") no-repeat center ;
  background-size: 18px;
  content: " ";

}

.TextField{
    
 
}
