.menuItem{
  padding: 0 20px;
}
.InputContainer {
  position: relative;
  text-align: left;

  .ClaraInputList {
    .formControl {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 60px; //preguntar
    }

    label {
      color: gray;

      img {
        margin-left: 7px;
        margin-top: 3px;
      }
    }
  }

  .select {
    font-size: 15px;
    width: 380px;
    height: 40px;
    border: 1px solid #c2cfe0;
    border-radius: 5px;
    margin: 0;
    outline: none;
    text-align: left;
    padding: 20px;
    margin-right: 5px !important;

    &:focus {
      border-color: #2c74ff;
    }
  }

  .selectInput {
    background: transparent !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 13px !important;
    color: initial !important;
    width: 380px;
    line-height: 25px;
    height: auto !important;
    //color: #a9b0bc;
  }
  .selectCountry {
    padding-bottom: 1.5rem;
  }
  .MenuContainer {
    display: inline-flex;
    flex-direction: column;
    background: red;
  }

  .menuItem {
   
      padding: 10px !important;
      border-radius: 4px;
      background: red !important;
      
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 28px;
      color: #192a3e;
      width: calc(100% - 20px);
      display: block;
      box-sizing: border-box;
      height: auto;
      margin: 2px 10px 0;

      &:hover,
      &:focus {
        background: rgba(44, 116, 255, 0.2) !important;
        color: #2c74ff !important;
      }
   
  }

  .Error {
    border: 1px solid red;
  }
}

.SelecMenu {
  box-sizing: border-box;
  min-width: 255px;
  padding: 10px 10px 0;
  pointer-events: none;

  input {
    pointer-events: auto;
  }

  h2 {
    margin: 0 0 7px 0;
    padding: 0 10px;
    
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    color: #848f99;
  }
}

.countryName {
  margin-left: 5px;
  text-align: left;
  line-height: 2.6;
}
.MessageError {
  display: block;
  width: 100%;
  
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  text-align: right;
  color: #ff606f;
  max-width: 380px;
}

.containerItems {
  width: max-content;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  //padding: 1rem;
  li {
    justify-content: flex-start;
    width: 100%;
    padding: 0 0 0 1rem;
  }
}
