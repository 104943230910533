@import "../../../../../../styles/variables/index";
.cardParentContainer {
    min-width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cardContainer {
    min-height: 6.1rem;
    min-width: 18.5rem;
    border: 2px solid #ebeef3;
    border-radius: 8px;
    margin-bottom: 16px;
    margin-right: 15px;
    font-family: "cl-regular";
    font-size: 12px;
    font-weight: 300;
    cursor: pointer;
}
.cardSelected {
    border: 2px solid $clara-blue;
    background: #f4f8ff;
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    min-height: 6.1rem;
    position: relative;
}

.checkContainer {
    width: 33px;
    height: 32px;
    background: #ebeef3;
    border-radius: 8px 0px 8px 0px;
    margin-bottom: 1px;
    padding: 9px;
    position: relative;
    bottom: 2px;
    right: 1px;
}

.checkSelected {
    background: $clara-blue;
}

.premiumInfoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 16px;
    margin-top: 6px;
    margin-right: 6px;
    text-align: center;
    color: $clara-blue;
    font-size: 8px;
    font-weight: 600;
    font-family: "cl-regular";
    border: 1px solid $clara-blue;
    border-radius: 8px;
}

.premiumStyle {
    color: $clara-green;
    padding-top: 3px;
    border: 1px solid $clara-green;
}

.nameContainer {
    text-align: center;
    width: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "cl-regular";
    font-size: 12px;
    font-weight: 300;
    position: relative;
    left: 10px;
}

.nameContainerSkeleton {
    text-align: center;
    width: 75%;
    margin: 3% 12.5%;
}
.checkCircle {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background: white;
    position: relative;
}

.tickIcon {
    svg {
        color: $clara-blue;
        z-index: 999;
        
    }
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
}

.premiumContainerSelected {
    background: white;
}
.betaStyle{
    display: inline-block;
    width: 46px;
    height: 16px;
    margin-top: 6px;
    padding-top: 3px;
    margin-right: 6px;
    text-align: center;
    color: $clara-white;
    font-size: 8px;
    font-weight: 600;
    font-family: "cl-regular";
    background:  $clara-orange;
    border-radius: 8px;
    position: absolute;
    right: 55px;
    top:0
}
