@import "./../../../../../../../../styles/variables/colors.scss";

.TemplateWizard {
  position: relative;
  width: 100%;
  height: 100% !important;
  > div {
    // display: grid;
    // grid-template-rows: 1fr 6.0769rem;
    // grid-template-columns: 1fr;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
  .Scrollbar {
    width: 100%;
    height: calc(100% - 6.08rem);
    display: block;
  }
  .Content {
    padding: 4.9231rem;
    width: 100%;
    height: 100%;
    .Header {
      width: 100%;
      .Spacer {
        margin-bottom: 1.2308rem;
        margin-top: 1.2308rem;
      }
    }
    .Form {
      width: 100%;
      height: 100%;
      display: flex;
      gap: 1.8462rem;
      flex-direction: column;
    }
  }
}

.Botonera {
  display: grid;
  width: 100%;
  height: 6.08rem;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  padding: 1.5rem 3.6rem 1.5rem 2.4rem;
  border-top: 1px solid $clara-light-grey;
  box-sizing: border-box;
  .LeftButtons {
    display: flex;
    gap: 1.8462rem;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .RightButtons {
    display: flex;
    gap: 1.8462rem;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

.skeletonContainer {
  display: flex;
  flex-direction: row;
  grid-gap: 1.8462rem;
  gap: 1.8462rem;
}

.TemplateLabel {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 16.4615rem 1fr;
  grid-column-gap: 1.5385rem;
  .skeletonList {
    display: flex;
    flex-direction: column;
    gap: 1.8462rem;
    align-items: flex-start;
  }
  > * {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

.showSkeleton {
  position: absolute;
  width: 100%;
  background-color: $clara-white;
  height: 51.4rem !important;
  z-index: 2;
}
.GridTemplateWizardPreview {
  display: grid;
  grid-template-columns: 308px 340px;
  grid-gap: 21px;
  > div:first-child {
    justify-content: flex-start;
    align-items: flex-start;
  }
  > div:last-child {
    justify-content: flex-start;
    align-items: flex-end;
  }
}
.ClassFileUpload {
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

// scss for frame_no_company
.GridTemplateWizardInfoPreview{
  display: grid;
  grid-template-columns: 370px 320px;
  grid-template-rows: minmax(0px, auto);
  //grid-gap: 30px;
  grid-column-gap: 30px;
  > div:first-child {
    grid-column: 1;
    grid-row: 1 / 3;
    justify-content: flex-start;
    align-items: flex-start;
  }
  > div:last-child {
    grid-column: 2;
    grid-row: 2;
    justify-content: flex-start;
    align-items: flex-end;
  }
}
.View {
  margin-top: 5.3rem;
  height: 360px;
//  border: 1px red solid;
  width: 320px;
}
.View>img{ 
  width: 100%;
  height: auto;
  object-fit: contain;
}

// scss for frame_yes_company
.GridTemplateWizardInfoPreviewCompany{
  display: grid;
  grid-template-columns: 466px 267px;
  grid-template-rows: minmax(120px, auto);
  grid-gap: 0px;
  > div:first-child {
    grid-column: 1;
    grid-row: 1 / 3;
    justify-content: flex-start;
    align-items: flex-start;
  }
  > div:last-child {
    grid-column: 2;
    grid-row: 2;
    justify-content: flex-start;
    align-items: flex-end;
  }
}
.ViewCompanyFomation {
  width: 267px;
}
.ViewCompanyFomation>img{
  width: 100%;
  height: auto;
  object-fit: contain;
}

.title_tour_content_left {
    div {
        font-size: 3.6923rem !important;
        font-weight: 800 !important;
        margin: 0;
        font-family: "cl-regular";
    }
}
.text_tour_content_left {
    div {
        font-size: 1.8462rem !important;
        font-weight: 400 !important;
        line-height: 38px;
    }
}
.container_RightContentTour {
    width: 100%;
    height: 100%;
    padding-right: 1rem;
    display: flex;
    box-sizing: border-box;
}
.whatsNextDialog {
  label {
    font-size: 20px !important;
  }
}
// .image_tour {
//     transform: scale(1);
//     position: relative;
//     left: 4rem;
// }
