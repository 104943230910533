@import "./../../../../styles/variables/colors.scss";

.Template {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 6.1538rem;
  row-gap: 2.4615rem;
  flex-direction: column;
  width: 100%;
  height: auto;
}

.Form {
  width: 100%;
  display: flex;
  gap: 1.8462rem;
  flex-direction: column;
  display: flex;
}

.Botonera {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 1.8462rem;
  border-top: 1px solid $clara-light-grey;
}