.CaymanIsBetterCheckboxContainer {
  display: flex;
  > div {
    > div {
      width: 100%;
    }
  }
}

.CaymanIsBetterCheckboxButton {
  width: 100%;
}

.CaymanIsBetterCheckboxButtonText{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  padding: 0 26px;

  > span {
    text-align: start;
  }

  .CaymanIsBetterCheckboxButtonList {
    margin-left: 20px;

    .CaymanIsBetterCheckboxButtonListOption{
      display: flex;
      column-gap: 20px
    }
  }
}