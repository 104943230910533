@import "../../../../../styles/variables/index.scss";

$step-dot-size: 0.9rem;
$step-dot-disable-color: #D5E4FF; 
$step-dot-enable-color: #EAF1FE;

.container-step-dots {
    display: inline-flex;
    button {
        margin-left: 0.9rem;
    }
    button:first-child {
        margin-left: 0px !important;
    }
}

.step-dot {
    padding: 0;
    width: $step-dot-size;
    height: $step-dot-size;
    background-color: $step-dot-enable-color;
    border-radius: 50%;
    border: none;

    &:disabled {
        background: $step-dot-disable-color;
        cursor: not-allowed;
        opacity: 0.3;
    }
}

.step-dot-selected {
    background-color: $clara-blue;
}
