@import "./../../../../styles/variables/colors.scss";
.Overview {
  display: inline-block;
  width: 100%;
  height: calc(100% - 60px);
  overflow: hidden;
}
.ZoneGraph {
  width: 353px;
  background: #fff;
  height: 100%;
  border-right: 1px solid #e4e3e9;
  overflow: auto;
  display: inline-block;
}
.ZoneLink {
  width: calc(100% - 354px);
  background: #fff !important;
  box-shadow: unset;
  height: 100%;
  overflow: hidden;
  display: inline-block;
  box-sizing: border-box;
}
.moreIssue{
  
  min-width: 15px ;
  
  font-style: normal;
  font-weight: 300;
  
  
  color:  #192A3E;
}
.Category {
  display: inline-block;
  width: 317px;
  height: max-content;
  overflow: hidden;
  text-align: left;
  margin: 20px 30px;
  h1 {
    
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #000;
    padding: 22px 20px;
    margin: 0;
    border-bottom: 1px solid #e4e3e9;
    span {
      
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 15px;
      float: right;
      margin-right: 0;
      color: #a9b0bc;
    }
  }
}
.SubCategories {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 22px 20px;
}
.Row {
  width: 100%;
  margin-bottom: 15px;
  a {
    
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height */
    text-decoration: none;
    color: #2c74ff;
    width: 206px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.Circles {
  overflow: hidden;
  width: 71px;
  text-align: right;
  display: inline-block;
  height: 15px;
  align-items: flex-start;
  span {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 3px;
    vertical-align: middle;
  }
}
.CircleRED {
  background: #ff606f;
}
.CircleGREEN {
  background: #71e096;
}
.CircleAMBER {
  background: #fbbd2c;
}
.CircleUNKNOWN{
  background: #BEC9D7;
}
.ZoneChartCircles {
  overflow: hidden;
  text-align: center;
  display: inline-block;
  width: 100%;
  padding: 0 26px 0 40px;
  box-sizing: border-box;
  margin-top:100px;
  margin-bottom: 100px;
  span {
    width: 47px;
    height:47px;
    border-radius: 50%;
    display: inline-block;
    margin: 0 10px;
    vertical-align: middle;
    
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    padding: 15px 0;
    box-sizing: border-box;
  }
}
.ZoneChartContent {
  width: 100%;
  height: 100%;
  padding: 40px 0 40px 0;
  box-sizing: border-box;
  overflow: hidden;
}
.ZoneChartTitle {
  margin-bottom: 0px;
  padding: 0 26px 0 50px;
  box-sizing: border-box;
  h1 {
    line-height: 27px;
    margin: 0 0 10px 0;
    padding: 0;
  }
  h2 {
    
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #a9b0bc;
    margin: 0 0 0 0;
    padding: 0;
  }
}
.LinkSelected{
  color:#2C74FF!important;
}

.cardText{
  font-size: 12px;
  position: absolute;
  bottom: 150px;
  left: 60px;
  max-width: 280px ;
}
.Botonera{
  width: 100%;
  text-align: center;
  button{margin:auto}
}
.TextLastRun{
  color: $clara-dark-grey;
  width: 100%;
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
}