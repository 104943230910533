@import "./../../../../../styles/variables/colors.scss";
.RolCard {
  margin-bottom: 0.6rem;
  height: 6.1538rem;
  box-shadow: 2px 4px 12px 0px #2C74FF26;
  width: 100%;
  padding: 12px;
  position: relative;
  width: 15.3846rem;
  > * {
    height: 100%;
  }
  &.Last {
    margin-bottom: 0;
  }

  border-color: $clara-chat;
  &[data-suggested="true"] {
    border-color: $clara-chat !important;
  }
  &[data-selected="true"] {
    background-color: #d8dfec;
    border-color: $clara-chat !important;
  }
  &:hover {
    background-color: #d8dfec;
    border-color: $clara-chat !important;
  }
}
.FOUNDER {
  border-color: $clara-role-founder;
  &[data-suggested="true"] {
    border-color: $clara-role-founder !important;
  }
  &[data-selected="true"] {
    background-color: $clara-role-founder-over;
    border-color: $clara-role-founder !important;
  }
  &:hover {
    background-color: $clara-role-founder-over;
    border-color: $clara-role-founder !important;
  }
}
.DIRECTOR {
  border-color: $clara-role-directors;

  &[data-suggested="true"] {
    border-color: $clara-role-directors !important;
  }
  &[data-selected="true"] {
    background-color: $clara-role-directors-over;
    border-color: $clara-role-directors !important;
  }
  &:hover {
    background-color: $clara-role-directors-over;
    border-color: $clara-role-directors !important;
  }
}
.ADVISOR {
  border-color: $clara-role-advisors;

  &[data-suggested="true"] {
    border-color: $clara-role-advisors !important;
  }
  &[data-selected="true"] {
    background-color: $clara-role-advisors-over;
    border-color: $clara-role-advisors !important;
  }
  &:hover {
    background-color: $clara-role-advisors-over;
    border-color: $clara-role-advisors !important;
  }
}
.EMPLOYEE {
  border-color: $clara-role-employees;

  &[data-suggested="true"] {
    border-color: $clara-role-employees !important;
  }
  &[data-selected="true"] {
    background-color: $clara-role-employees-over;
    border-color: $clara-role-employees !important;
  }
  &:hover {
    background-color: $clara-role-employees-over;
    border-color: $clara-role-employees !important;
  }
}
.CONSULTANT {
  border-color: $clara-role-consultants;

  &[data-suggested="true"] {
    border-color: $clara-role-consultants !important;
  }
  &[data-selected="true"] {
    background-color: $clara-role-consultants-over;
    border-color: $clara-role-consultants !important;
  }
  &:hover {
    background-color: $clara-role-consultants-over;
    border-color: $clara-role-consultants !important;
  }
}
.INVESTOR {
  border-color: $clara-role-investors;

  &[data-suggested="true"] {
    border-color: $clara-role-investors !important;
  }
  &[data-selected="true"] {
    background-color: $clara-role-investors-over;
    border-color: $clara-role-investors !important;
  }
  &:hover {
    background-color: $clara-role-investors-over;
    border-color: $clara-role-investors !important;
  }
}
.SHAREHOLDER {
  border-color: $clara-role-shareholders;

  &[data-suggested="true"] {
    border-color: $clara-role-shareholders !important;
  }
  &[data-selected="true"] {
    background-color: $clara-role-shoreholders-over;
    border-color: $clara-role-shareholders !important;
  }
  &:hover {
    background-color: $clara-role-shoreholders-over;
    border-color: $clara-role-shareholders !important;
  }
}
.CONVERTIBLEHOLDER {
  border-color: $clara-role-convertibleholders;

  &[data-suggested="true"] {
    border-color: $clara-role-convertibleholders !important;
  }
  &[data-selected="true"] {
    background-color: $clara-role-convertibleholders-over;
    border-color: $clara-role-convertibleholders !important;
  }
  &:hover {
    background-color: $clara-role-convertibleholders-over;
    border-color: $clara-role-convertibleholders !important;
  }
}
.WARRANTHOLDER {
  border-color: $clara-role-warrantholders;

  &[data-suggested="true"] {
    border-color: $clara-role-warrantholders !important;
  }
  &[data-selected="true"] {
    background-color: $clara-role-warrantholders-over;
    border-color: $clara-role-warrantholders !important;
  }
  &:hover {
    background-color: $clara-role-warrantholders-over;
    border-color: $clara-role-warrantholders !important;
  }
}
.OPTIONHOLDER {
  border-color: $clara-role-optionholder;

  &[data-suggested="true"] {
    border-color: $clara-role-optionholder !important;
  }
  &[data-selected="true"] {
    background-color: $clara-role-optionholder-over;
    border-color: $clara-role-optionholder !important;
  }
  &:hover {
    background-color: $clara-role-optionholder-over;
    border-color: $clara-role-optionholder !important;
  }
}
.IPASSIGNOR {
  border-color: $clara-role-ip;

  &[data-suggested="true"] {
    border-color: $clara-role-ip !important;
  }
  &[data-selected="true"] {
    background-color: $clara-role-ip-over;
    border-color: $clara-role-ip !important;
  }
  &:hover {
    background-color: $clara-role-ip-over;
    border-color: $clara-role-ip !important;
  }
}
.THIRDPARTY {
  border-color: $clara-role-thirdparty;

  &[data-suggested="true"] {
    border-color: $clara-role-thirdparty !important;
  }
  &[data-selected="true"] {
    background-color: $clara-role-thirdparty-over;
    border-color: $clara-role-thirdparty !important;
  }
  &:hover {
    background-color: $clara-role-thirdparty-over;
    border-color: $clara-role-thirdparty !important;
  }
}
.AUTHORIZEDSIGNATORY {
  border-color: $clara-role-documents;

  &[data-suggested="true"] {
    border-color: $clara-role-documents !important;
  }
  &[data-selected="true"] {
    background-color: $clara-role-documents-over;
    border-color: $clara-role-documents !important;
  }
  &:hover {
    background-color: $clara-role-documents-over;
    border-color: $clara-role-documents !important;
  }
}
.UBO {
  border-color: $clara-role-ubo;

  &[data-suggested="true"] {
    border-color: $clara-role-ubo !important;
  }
  &[data-selected="true"] {
    background-color: $clara-role-ubo-over;
    border-color: $clara-role-ubo !important;
  }
  &:hover {
    background-color: $clara-role-ubo-over;
    border-color: $clara-role-ubo !important;
  }
}
.iconFilter {
  position: absolute;
  right: 0.9231rem;
  top: 0.9231rem;
  cursor: pointer;
  z-index: 2;
}
.iconPlus {
  position: absolute;
  right: 0.9231rem;
  bottom: 0.9231rem;
  cursor: pointer;
  z-index: 2;
}
